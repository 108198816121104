import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CHEVRON_LEFT, FOTA_ICON_ADVANCE, CLOUD_UPLOAD_ICON } from 'src/app/shared/constants/strings';
import { PlatformAdminUtilityControllerService, SolutionTypeControllerService, TenantControllerService } from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { HttpClient } from '@angular/common/http';
import { BciLoaderService } from '@bci-web-core/core';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { OtaUpdatePackageControllerService } from 'src/app/services/ETAS/services';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';

@Component({
  selector: 'app-add-update-package',
  templateUrl: './add-update-package.component.html',
  styleUrls: ['./add-update-package.component.scss']
})
export class AddUpdatePackageComponent implements OnInit {
  public fotaIcon: string;
  backImage: string;
  public dropDownValues: any = {};
  cloudUploadImage: string;
  uploadedFile: any;
  showSpinner: any;
  solutionTypeId: any;
  public deviceInterfaceProviderId: string;
  iotHubId: any;
  profileId: any;
  payloadObject: {};

  constructor(private router:Router,
    private route: ActivatedRoute,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private responseHandlerService: ResponseHandlerService,
    private objectToIdConversionService: ObjectToIdConversionService,
    private otaUpdatePackageControllerService :OtaUpdatePackageControllerService,
    private loaderService:BciLoaderService,
    ) { }

  ngOnInit(): void {
    this.fotaIcon = FOTA_ICON_ADVANCE;
    this.backImage = CHEVRON_LEFT;
    this.cloudUploadImage = CLOUD_UPLOAD_ICON;
    this.getSolutionTypes();
  }

  navigateBack() {
    this.router.navigate([
      'ota-advance/update-package',{ }])
    }
    
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
        if ($event.child === 'deviceInterfaceProvider') {
          this.solutionTypeId = $event.value;
          this.getDeviceInterfaceProviders($event.value);
        }
        if ($event.child === 'businessProfile') {
          this.deviceInterfaceProviderId = $event.value;
          this.getBusinessProfileOnSolution(this.solutionTypeId, this.deviceInterfaceProviderId);
        }
        if ($event.child === 'iotHub') {
          this.getIotHubList( this.solutionTypeId, this.deviceInterfaceProviderId, $event.value);
          this.profileId = $event.value;
        }
        if ($event.child === 'iotName') {
          this.iotHubId = $event.value;
        }
    }
  }

  public getSolutionTypes() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getDeviceInterfaceProviders(solutionTypeId) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' }).subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceInterfaceProvider'] = resp;
        } else {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceInterfaceProvider'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public getIotHubList(solutionTypeId: string, deviceInterfaceProviderId: string, profileId: string ) {
    this.platformAdminUtilityControllerService.getIotHubsBySolDevIntfProvAndProfile({solutionTypeId: solutionTypeId,
    deviceInterfaceProviderId: deviceInterfaceProviderId, profileId: profileId }) .subscribe(resp => {
      if (resp && resp.length) {
        this.dropDownValues['iotHub'] = resp;
      } else {
        this.dropDownValues['iotHub'] = [];
      }
    },
      err => {
        this.dropDownValues['iotHub'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public getBusinessProfileOnSolution(solutionTypeId: string, deviceInterfaceProviderId: string) {
    this.solutionTypeControllerService
      .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId,
        deviceInterfaceProviderId:  deviceInterfaceProviderId }).subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public onSubmit(data) {
    let grpData;
    let formattedProperties={};
    this.showSpinner = this.loaderService.showProgressBar();
    data.properties.forEach(item => {
      formattedProperties[item.key] = item.value;
    });

    const createPackageDetails = {
      name: data.name,
      contentType:data.contentType,
      updateAgent: data.updateAgent,
      properties: formattedProperties
    };

    const updatePackageDetails = {
    solutionType:this.objectToIdConversionService.convertObjectToId(data['solutionType']),
    businessProfile:this.objectToIdConversionService.convertObjectToId(data['businessProfile']),
    deviceInterfaceProvider:this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']),
    iotHub:this.objectToIdConversionService.convertObjectToId(data['iotHub'])
    };

    const requestBody = {
      body : {
        metaData: JSON.stringify(updatePackageDetails),
        etasData: JSON.stringify(createPackageDetails),
        file: data.file,
      },
    };
    grpData = requestBody;
    this.otaUpdatePackageControllerService.createUpdatePackage(grpData).subscribe(
      resp => {
        if(resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'CREATED_SUCCESSFULLY');
          this.router.navigate([
            'ota-advance/update-package']); 
        }
      },(err) => { 
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      })
  }  
}
