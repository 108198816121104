import { Component, OnInit, ViewChild } from '@angular/core';
import {
  WHATSAPP_ICON,
  EDIT_IMAGE,
  DELETE_IMAGE
} from 'src/app/shared/constants/strings';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import {
  WhatsappProfileMappingControllerService,
  WhatsappProfileConnectionDetailsControllerService,
} from 'src/app/services/Whatsapp/services';
import { ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { HttpClient } from '@angular/common/http';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-profile-connection',
  templateUrl: './profile-connection.component.html',
  styleUrls: ['./profile-connection.component.scss']
})
export class ProfileConnectionComponent implements OnInit {
  public permissionList: Array<String> = [];
  public whatsappIcon = WHATSAPP_ICON;
  public editImage = EDIT_IMAGE;
  public deleteImage = DELETE_IMAGE;
  public showSpinner:any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public vendorId: string;
  public wabaNumber: string;
  public userIdModified = false;
  public passwordModified = false;
  public wabaIdModified = false;
  public wabaNameModified = false;
  public wabaNumberModified = false;
  public combinedProfileConnectionArray = [];
  public conditions;
  public hideOnReset;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  constructor(
    private accountControllerService: AccountControllerService,
    private whatsappVendorService: WhatsappProfileMappingControllerService,
    private route: ActivatedRoute,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,
    private httpService: HttpClient,
    private loaderService: BciLoaderService
  ) {}
  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.httpService
      .get('./assets/form-fields/whatsapp-profile-connection.json')
      .subscribe((data) => {
        [this.conditions] = data['conditions'];
        this.hideOnReset = this.conditions['hideOnReset'];
      });
    this.getAccount();
    this.getVendors();
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'vendor') {
          this.combinedProfileConnectionArray = [];
          this.profileId = $event.value;
          this.getVendorByProfileId();
          this.getProfileConnection();
          this.getWhatsAppAnalytics(this.profileId);
        }
      }
    }
  }
  getWhatsAppAnalytics(profileId : string) {
    this.whatsappProfileConnectionDetailsControllerService
        .fetchWhatsappAnalyticsDetails({ profileId: profileId}).subscribe(
        (resp) => {
          this.dynamicForm.createForm.patchValue({
            wabaNumber: resp.wabaNumber 
          });
          let profileConnectionObject = {};
          profileConnectionObject['wabaNumber'] = resp.wabaNumber ;
          this.combinedProfileConnectionArray.push(profileConnectionObject);
        },
        (err) => {
          this.dynamicForm.createForm.patchValue({
            wabaNumber : null
          });
          if (
            err.error.message !== 'T_WABA_NUMBER_PROFILE_NOT_MAPPED'
          ){
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          }
        }
      );
      

  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getVendors() {
    this.whatsappVendorService.getVendorList().subscribe(
      (resp) => {
        if (resp && resp.length) {
          resp.forEach((res) => {
            res['level'] = 1;
          });
          this.dropDownValues['vendor'] = resp;
        } else {
          this.dropDownValues['vendor'] = [];
        }
      },
      (err) => {
        this.dropDownValues['vendor'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getVendorByProfileId() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappVendorService
      .getAllBspMappingByProfileId({ profileId: this.profileId })
      .subscribe(
        (resp) => {
          if (resp && resp['vendorDetails']) {
            this.vendorId = resp['vendorDetails']['id'];
            this.dynamicForm.createForm.controls['vendor'].setValue(
              this.vendorId
            );
            this.dynamicForm.formBase.forEach((res) => {
              if (res['name'] === 'vendor') {
                res['displayNone'] = false;
                const event = {
                  source: 'MatSelect',
                  value: this.vendorId,
                };
                this.dynamicForm.selectNewField(event, res);
                this.loaderService.hideProgressBar(this.showSpinner);
              } else if (
                res['name'] === 'userId' ||
                res['name'] === 'password' ||
                res['name'] === 'wabaNumber' ||
                res['name'] === 'businessAccountId' ||
                res['name'] === 'businessAccountName'
              ) {
                res['displayNone'] = false;
              }
            });
          }
        },
        (err) => {
          this.vendorId = null;
          this.dynamicForm.formBase.forEach((res) => {
            if (
              res['name'] === 'vendor' ||
              res['name'] === 'userId' ||
              res['name'] === 'password' ||
              res['name'] === 'wabaNumber' ||
              res['name'] === 'businessAccountId' ||
              res['name'] === 'businessAccountName'
            ) {
              res['displayNone'] = true;
            }
          });
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfileConnection() {
    this.whatsappProfileConnectionDetailsControllerService
      .fetchProfileWhatsAppConnectionDetails({ profileId: this.profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const profileConnectionArray = resp;
            const profileConnectionData = [];
            let profileConnectionObject = {};
            profileConnectionArray.forEach((data) => {
              profileConnectionObject[
                data['connectionKey'] === 'userid'
                  ? 'userId'
                  : data['connectionKey'] === 'password'
                  ? 'password'
                  : data['connectionKey'] === 'Waba Id'
                  ? 'whatsappBusinessAccountId'
                  : data['connectionKey'] === 'Waba Name'
                  ? 'whatsappBusinessAccountName'
                  : ''
              ] = data['connectionValue'];
              profileConnectionObject[
                data['connectionKey'] === 'userid'
                  ? 'userNameId'
                  : data['connectionKey'] === 'password'
                  ? 'passwordId'
                  : data['connectionKey'] === 'Waba Id'
                  ? 'whatsappBusinessAccountIdId'
                  : data['connectionKey'] === 'Waba Name'
                  ? 'whatsappBusinessAccountNameId'
                  : ''
              ] = data['id'];
              profileConnectionData.push(profileConnectionObject);
              profileConnectionObject = {};
            });

            this.combinedProfileConnectionArray.push(
              Object.assign({}, ...profileConnectionData)
            );
            this.combinedProfileConnectionArray.forEach((res) => {
              if (res.hasOwnProperty('userId')) {
                if (res['userId'] && res['password']) {
                  setTimeout(() => {
                    this.dynamicForm.createForm.patchValue({
                      userId: res['userId'],
                      password: res['password'],
                    });
                  }, 800);
                }
              } else if (res.hasOwnProperty('whatsappBusinessAccountId')) {
                if (
                  res['whatsappBusinessAccountId'] &&
                  res['whatsappBusinessAccountName']
                ) {
                  setTimeout(() => {
                    this.dynamicForm.createForm.patchValue({
                      businessAccountId: res['whatsappBusinessAccountId'],
                      businessAccountName: res['whatsappBusinessAccountName'],
                    });
                  }, 800);
                }
              }
            });
          }
        },
        (err) => {
          this.combinedProfileConnectionArray = [];
          if (this.dynamicForm.createForm.controls.hasOwnProperty('userId')) {
            this.dynamicForm.createForm.patchValue({
              userId: null,
              password: null,
            });
          } else if (
            this.dynamicForm.createForm.controls.hasOwnProperty(
              'businessAccountId'
            )
          ) {
            this.dynamicForm.createForm.patchValue({
              businessAccountId: null,
              businessAccountName: null,
            });
          }
          if (
            err.error.message !== 'T_EMPTY_LIST' &&
            err.error.message !== 'T_VENDOR_PROFILE_NOT_MAPPED'
          ) {
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        }
      );
  }

  public updateProfileConnection(connectionData) {
    this.showSpinner = this.loaderService.showProgressBar();
    const params = {
      profileId: this.profileId,
      body: [
        {
          connectionKey: this.dynamicForm.createForm.controls[
            'businessAccountId'
          ]
            ? 'Waba Id'
            : 'userid',
          connectionValue: this.dynamicForm.createForm.controls[
            'businessAccountId'
          ]
            ? this.dynamicForm.createForm.controls['businessAccountId'].value
            : this.dynamicForm.createForm.controls['userId'].value,
          id: connectionData.hasOwnProperty('whatsappBusinessAccountIdId')
            ? connectionData['whatsappBusinessAccountIdId']
            : connectionData['userNameId'],
          modified: this.dynamicForm.createForm.controls['businessAccountId']
            ? this.wabaIdModified
            : this.userIdModified,
        },
        {
          connectionKey: this.dynamicForm.createForm.controls[
            'businessAccountName'
          ]
            ? 'Waba Name'
            : 'password',
          connectionValue: this.dynamicForm.createForm.controls[
            'businessAccountName'
          ]
            ? this.dynamicForm.createForm.controls['businessAccountName'].value
            : this.dynamicForm.createForm.controls['password'].value,
          id: connectionData.hasOwnProperty('whatsappBusinessAccountNameId')
            ? connectionData['whatsappBusinessAccountNameId']
            : connectionData['passwordId'],
          modified: this.dynamicForm.createForm.controls['businessAccountName']
            ? this.wabaNameModified
            : this.passwordModified,
        },
        {
          connectionKey: 'Waba Number',
          connectionValue: this.dynamicForm.createForm.controls['wabaNumber'].value,
          id: this.dynamicForm.createForm.controls['wabaNumber'].value,
          modified: this.wabaNumberModified,
        }
      ],
    };
    this.whatsappProfileConnectionDetailsControllerService
      .updateProfileWhatsAppConnectionDetails(params)
      .subscribe(
        (res) => {
          if (res) {
            this.combinedProfileConnectionArray = [];
            this.getProfileConnection();
            this.getWhatsAppAnalytics(this.profileId);
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'WHATSAPP_CONNECTION_DETAILS_UPDATE_SUCCESS'
            );
            this.wabaIdModified = false;
            this.wabaNameModified = false;
            this.userIdModified = false;
            this.passwordModified = false;
            this.wabaNumberModified = false;
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public saveProfileConnection() {
    this.showSpinner = this.loaderService.showProgressBar();
    const params = {
      profileId: this.profileId,
      body: [
        {
          connectionKey: this.dynamicForm.createForm.controls[
            'businessAccountId'
          ]
            ? 'Waba Id'
            : 'userid',
          connectionValue: this.dynamicForm.createForm.controls[
            'businessAccountId'
          ]
            ? this.dynamicForm.createForm.controls['businessAccountId'].value
            : this.dynamicForm.createForm.controls['userId'].value,
        },
        {
          connectionKey: this.dynamicForm.createForm.controls[
            'businessAccountName'
          ]
            ? 'Waba Name'
            : 'password',
          connectionValue: this.dynamicForm.createForm.controls[
            'businessAccountName'
          ]
            ? this.dynamicForm.createForm.controls['businessAccountName'].value
            : this.dynamicForm.createForm.controls['password'].value,
        },
        {
          connectionKey: 'Waba Number',
          connectionValue: this.dynamicForm.createForm.controls['wabaNumber'].value
        }
      ],
    };
    this.whatsappProfileConnectionDetailsControllerService
      .saveProfileWhatsAppConnectionDetails(params)
      .subscribe(
        (res) => {
          if (res) {
            this.getProfileConnection();
            this.getWhatsAppAnalytics(this.profileId);
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'WHATSAPP_CONNECTION_DETAILS_ADD_SUCCESS'
            );
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public onSubmit(event) {
    let connectionObject = {};
    if (this.combinedProfileConnectionArray.length) {
      this.combinedProfileConnectionArray.forEach((res) => {
        if (
          res.hasOwnProperty('userId') &&
          event.hasOwnProperty('userId')
        ) {
          if (res['userId'] !== event['userId']) {
            this.userIdModified = true;
          } else {
            this.userIdModified = false;
          }
          if (res['password'] !== event['password']) {
            this.passwordModified = true;
          } else {
            this.passwordModified = false;
          }
          if (this.userIdModified || this.passwordModified) {
             connectionObject = res;
          }
        } else if (
          res.hasOwnProperty('whatsappBusinessAccountId') &&
          event.hasOwnProperty('businessAccountId')
        ) {
          if (res['whatsappBusinessAccountId'] !== event['businessAccountId']) {
            this.wabaIdModified = true;
          } else {
            this.wabaIdModified = false;
          }
          if (
            res['whatsappBusinessAccountName'] !== event['businessAccountName']
          ) {
            this.wabaNameModified = true;
          } else {
            this.wabaNameModified = false;
          }
          if (this.wabaIdModified || this.wabaNameModified ) {
             connectionObject = res;
          }
        }else if (
          res.hasOwnProperty('wabaNumber') && event.hasOwnProperty('wabaNumber')
        ) {
          if(res['wabaNumber'] !== event['wabaNumber']){
            this.wabaNumberModified = true;
          }
          else{
            this.wabaNumberModified =false ;
          }
        }
      });
    }
    if (this.userIdModified || this.passwordModified || this.wabaNumberModified || this.wabaIdModified || this.wabaNameModified ) {
      this.updateProfileConnection(connectionObject);
    }
    else {
      this.saveProfileConnection();
    }
  }

  public onReset() {
    this.combinedProfileConnectionArray = [];
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['vendor'] = [];
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.vendorId = null;
    this.dynamicForm.formBase.forEach((res) => {
      if (this.hideOnReset.includes(res['name'])) {
        res['displayNone'] = true;
      }
    });
    this.dynamicForm.createForm.reset();
    this.getAccount();
    this.getVendors();
  }
}
