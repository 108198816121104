import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BciLoaderService } from '@bci-web-core/core';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { OtaUpdatePackageControllerService } from 'src/app/services/ETAS/services';
import { PlatformAdminUtilityControllerService, SolutionTypeControllerService, TenantControllerService } from 'src/app/services/Platform/services';
import { EDIT_IMAGE, FOTA_ICON_ADVANCE, VIEW_ICON } from 'src/app/shared/constants/strings';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';

@Component({
  selector: 'app-update-package',
  templateUrl: './update-package.component.html',
  styleUrls: ['./update-package.component.scss']
})
export class UpdatePackageComponent implements OnInit {
  fotaIcon: string;
  data: string[];
  smsStatusData: any;
  public dropDownValues: any = {};
  public permissionList: Array<String> = [];
  public accountId: string;
  public tenantId: string;
  public solutionTypeId;
  public profileId: string;
  public deviceInterfaceProviderId: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex: number = 0;
  limit: number = this.pageSizeOptions[0];
  public updatePackageData;
  public columns: any[];
  public jsonFileLoaded;
  public dataSource;
  ignoreList = [
    'updatePackageId','contentUri','contentType',
    'fileName','sizeInBytes','hashes','properties','privileged','deleted','updateAgent','etagHeader'
  ];
  packageCount:any;
  viewImage: string;
  editImage:string;
  public showSpinner: any;
  searchData:any;
  payloadObject:any;
  iotHubId:string;
  routeIotHubId:string;
  formData:any;
  routerSolutionTypeId: string;
  routeDeviceInterfaceProviderId: string;
  routeProfileId:string;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent; 

  constructor( private httpsService :HttpClient,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private objectToIdConversionService: ObjectToIdConversionService,
    private responseHandlerService: ResponseHandlerService,
    private route: ActivatedRoute,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private otaUpdatePackageControllerService :OtaUpdatePackageControllerService,private datePipe: DatePipe,
    private loaderService:BciLoaderService,private router: Router,) { }

  ngOnInit(): void {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.routeIotHubId = this.route.snapshot.paramMap.get('iotHubId');
    this.routerSolutionTypeId = this.route.snapshot.paramMap.get('solutionTypeId');
    this.routeDeviceInterfaceProviderId = this.route.snapshot.paramMap.get('deviceInterfaceProviderId');
    this.routeProfileId = this.route.snapshot.paramMap.get('profileId');
    this.getSolutionTypes();
    this.httpsService
        .get('./assets/form-fields/ota-update-package.json')
        .subscribe((data) => {
          this.data = data as string[];
          this.smsStatusData = this.data['fields'];
        });
    this.fotaIcon = FOTA_ICON_ADVANCE;
    this.viewImage = VIEW_ICON;
    this.editImage = EDIT_IMAGE;
    if(this.routeIotHubId){
      this.getDeviceInterfaceProviders(this.routerSolutionTypeId);
      this.getBusinessProfileOnSolution(this.routerSolutionTypeId, this.routeDeviceInterfaceProviderId);
      this.getIotHubList( this.routerSolutionTypeId, this.routeDeviceInterfaceProviderId, this.routeProfileId);
     this.formData = {
        'solutionType': this.routerSolutionTypeId,
        'deviceInterfaceProvider': this.routeDeviceInterfaceProviderId,
        'businessProfile': this.routeProfileId,
        'iotHub': this.routeIotHubId,
        'name':null,
        'agent': null,
        'createOn': null,
        'lastUpdateOn': null,
     }
     this.updatePackageEmitter(this.formData)
    }
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
        if ($event.child === 'deviceInterfaceProvider') {
          this.solutionTypeId = $event.value;
          this.getDeviceInterfaceProviders($event.value);
        }
        if ($event.child === 'businessProfile') {
          this.deviceInterfaceProviderId = $event.value;
          this.getBusinessProfileOnSolution(this.solutionTypeId, this.deviceInterfaceProviderId);
        }
        if ($event.child === 'iotHub') {
          this.getIotHubList( this.solutionTypeId, this.deviceInterfaceProviderId, $event.value);
          this.profileId = $event.value;
        }
        if ($event.child === 'name') {
          this.iotHubId = $event.value;
        }
    }
  }

   /**
    * @ngdoc method
    * @name updatePackage#getSolutionTypes
    *
    * @methodOf
    * updatePackage.controller:manageDevice
    *
    * @description
    * Description: To populate solution type dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
   public getSolutionTypes() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name updatePackage#getDeviceInterfaceProviders
    *
    * @methodOf
    * fota.controller:updatePackage
    *
    * @description
    * Description: To fetch list of deviceInterfaceProviders
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
  public getDeviceInterfaceProviders(solutionTypeId) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' }).subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceInterfaceProvider'] = resp;
        } else {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceInterfaceProvider'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  /**
    * @ngdoc method
    * @name updatePackage#getBusinessProfileOnSolution
    *
    * @methodOf
    * fota.controller:updatePackage
    *
    * @description
    * Description: To fetch list of businessProfiles
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
public getBusinessProfileOnSolution(solutionTypeId: string, deviceInterfaceProviderId: string) {
  this.solutionTypeControllerService
    .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId,
      deviceInterfaceProviderId:  deviceInterfaceProviderId }).subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['businessProfile'] = resp;
        } else {
          this.dropDownValues['businessProfile'] = [];
        }
      },
      err => {
        this.dropDownValues['businessProfile'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

  public getIotHubList(solutionTypeId: string, deviceInterfaceProviderId: string, profileId: string ) {
    this.platformAdminUtilityControllerService.getIotHubsBySolDevIntfProvAndProfile({solutionTypeId: solutionTypeId,
    deviceInterfaceProviderId: deviceInterfaceProviderId, profileId: profileId }) .subscribe(resp => {
      if (resp && resp.length) {
        this.dropDownValues['iotHub'] = resp;
      } else {
        this.dropDownValues['iotHub'] = [];
      }
    },
      err => {
        this.dropDownValues['iotHub'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  updatePackageEmitter(data,pdatePageIndex = true){
   this.payloadObject = {
    solutionType:this.objectToIdConversionService.convertObjectToId(data['solutionType']),
    businessProfile:this.objectToIdConversionService.convertObjectToId(data['businessProfile']),
    deviceInterfaceProvider:this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']),
    iotHub:this.objectToIdConversionService.convertObjectToId(data['iotHub']),
    updatePackageName:data?.name,
    updatePackageAgent: data?.agent,
    createdOn: this.formatDate(data?.createOn),
    updatedOn: this.formatDate(data?.lastUpdateOn), 
    };
    const searchData = {
      body: this.payloadObject,
      page:  pdatePageIndex ? 0: this.pageIndex,
      limit: this.limit,
    };
    this.showSpinner = this.loaderService.showProgressBar();
    this.otaUpdatePackageControllerService.getAllUpdatePackage(searchData).subscribe( resp =>{
      if(resp._embedded !== null){
        this.loaderService.hideProgressBar(this.showSpinner);
        const updatepacakge = resp._embedded.updatePackages;
        this.updatePackageData = updatepacakge
        this.packageCount = resp.page.totalElements;
        this.dataSource = new MatTableDataSource(this.updatePackageData);
          for (const data of this.updatePackageData) {
            data['name'] = data['name']
            data['agent'] = data['updateAgent']
            data['createdOn'] = this.datePipe.transform(data['createdOn'], 'MMM dd yyyy, h:mm:ss a');
            data['lastUpdatedOn'] = this.datePipe.transform(data['lastUpdatedOn'], 'MMM dd yyyy, h:mm:ss a');
          }
        this.columns = this.filterColumns(['name', 'agent', 'createdOn', 'lastUpdatedOn']);
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.columns = null;
        this.dataSource = null;
        this.responseHandlerService.returnToastMessage(
          'warning','NO_DATA_AVAILABLE');
      }
    }, (err) => {
      this.columns = null;
      this.dataSource = null;
      this.responseHandlerService.returnToastMessage('error', err.error.message);
      this.loaderService.hideProgressBar(this.showSpinner);
    });
    }

  public pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.limit= event.pageSize;
    this.updatePackageEmitter(this.payloadObject,false)
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  formatDate(date: Date | null | undefined): string | null {
    if (date != null) {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }
  
  public gotoViewPage(fieldData) {
    const queryParams = {
      updatePackageId: fieldData.updatePackageId,
      solutionTypeId: this.solutionTypeId !== undefined ? this.solutionTypeId : this.routerSolutionTypeId,
      iotHubId: this.iotHubId !== undefined ? this.iotHubId : this.routeIotHubId,
      deviceInterfaceProviderId: this.deviceInterfaceProviderId !== undefined ? this.deviceInterfaceProviderId : this.routeDeviceInterfaceProviderId,
      profileId: this.profileId!== undefined ? this.profileId : this.routeProfileId,
    };
    this.router.navigate(['ota-advance/package-details',queryParams]);
  }

  addUpdatePackage() {
  this.router.navigate(['ota-advance/add-package'])
  }

}
