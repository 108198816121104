import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import {
  DETAILS_IMAGE, PLAY_IMAGE, PAUSE_IMAGE, TERMINATE_IMAGE, EDIT_IMAGE, DELETE_IMAGE,
  MORE_OPTION, MANAGE, FOTA_ICON, CLOSE_ICON
} from 'src/app/shared/constants/strings';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SolutionTypeControllerService } from 'src/app/services/Platform/services/solution-type-controller.service';
import { TranslationService } from 'src/app/providers/translation-service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { Router,ActivatedRoute } from '@angular/router';
import { DeviceTypeControllerService, FotaJobControllerService } from 'src/app/services/DeviceMgmt/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import { BciLoaderService, TableStripedHorizontalDirective } from '@bci-web-core/core';
import {MapsAPILoader} from '@agm/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { clearInterval } from 'timers';
import { GeoFenceControllerService } from 'src/app/services/DeviceMgmt/services/geo-fence-controller.service';
@Component({
  selector: 'app-fota-management',
  templateUrl: './fota-management.component.html',
  styleUrls: ['./fota-management.component.scss']
})
export class FotaManagementComponent implements OnInit {

  public formData: any;
  public dropDownValues: any = {};
  public dataSource;
  public ignoreList = ['firmwareVersion', 'jobId', 'startTime', 'endTime'];
  public columns: any[];
  public defaultColumns: any[];
  public showSpinner :any;
  public fotaData: any;
  playImage;
  pauseImage;
  terminateImage;
  detailsImage;
  editImage;
  deleteImage;
  moreOptionsImage;
  play = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('search') public searchElementRef: ElementRef;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
 //const google: any;
  public fotaJobsCount = 0;
  public searchData;
  public statusColor;
  public solutionTypeId: string;
  public deviceTypeId: string;
  public pageKey: string;
  public fotaIcon: string;
  asideMenus: Array<AsideCard>;
  public deviceInterfaceProviderId: string;
  public profileId: string;
  lat: number;
  long: number;
  geoCoder: google.maps.Geocoder;
  zoom: number;
  MouseGmevent: typeof google.maps.event;
  closeIcon:string;
  devicelist: {}[];
  myMap: any;
  geoFenceForm: FormGroup;
  polygonArray: any[];
  public placeSearch:string;
  geoFenceId: string;
  deviceList: any;
  Devicelocations: any[];
  polygon: google.maps.Polygon;
  overlayCompleteEvent: any;
  jobProgressVal: number;
     constructor(private httpService: HttpClient, private translate: TranslationService,
    private dynamicSearchServiceService: DynamicSearchServiceService, private router: Router,
    private fotaJobControllerService: FotaJobControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService, private _matDialog: MatDialog,
    private responseHandlerService: ResponseHandlerService, private deviceTypeControllerService: DeviceTypeControllerService,
    private rightsideNavitemsService: RightsideNavitemsService,private ActivatedRoute: ActivatedRoute,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,private loaderService: BciLoaderService,
    private mapsAPILoader: MapsAPILoader,private GeoFenceControllerService:GeoFenceControllerService, 
    private ngZone: NgZone,private formBuilder: FormBuilder
    ) { }

  ngOnInit() {
       this.httpService
      .get('./assets/form-fields/fota-job-search.json')
      .subscribe(data => {
        this.formData = data['solution'];
        this.dropDownValues = this.dynamicSearchServiceService.generateDynamicFormDropdown(this.formData);
      });
    this.pageKey = 'fota';
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.getSolutionTypes();
    this.getStatusList();
    this.playImage = PLAY_IMAGE;
    this.pauseImage = PAUSE_IMAGE;
    this.terminateImage = TERMINATE_IMAGE;
    this.detailsImage = DETAILS_IMAGE;
    this.editImage = EDIT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.moreOptionsImage = MORE_OPTION;
    this.fotaIcon = FOTA_ICON;
    this.closeIcon = CLOSE_ICON;
    this.statusColor = { 'PAUSED': '#473d8f', 'RUNNING': 'orange', 'TERMINATED': 'red', 'CREATED': 'grey', 'COMPLETED': 'green' };
   
    this.geoFenceForm = this.formBuilder.group({
      geoFenceName: [''],
         shapeName: ['']
    });
  }

  /**
    * @ngdoc method
    * @name fotaManagement#getSolutionTypes
    *
    * @methodOf
    * fotaManagement.controller:manageDevice
    *
    * @description
    * Description: To populate solution type dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getSolutionTypes() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name fotaManagement#getDeviceInterfaceProviders
    *
    * @methodOf
    * fota.controller:fotaManagement
    *
    * @description
    * Description: To fetch list of deviceInterfaceProviders
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
  public getDeviceInterfaceProviders(solutionTypeId) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' }).subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceInterfaceProvider'] = resp;
        } else {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceInterfaceProvider'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public getDeviceTypes(solutionTypeId, deviceInterfaceProviderId) {
    this.deviceTypeControllerService
      .findAllDeviceTypesBasedOnSolutionType({ id: solutionTypeId, deviceInterfaceProviderId: deviceInterfaceProviderId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['deviceType'] = resp;
          } else {
            this.dropDownValues['deviceType'] = [];
          }
        },
        err => {
          this.dropDownValues['deviceType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getFirmwareVersions() {
    this.fotaJobControllerService
      .getFotaFirmwares({ solutiontypeId: this.solutionTypeId, devicetypeId: this.deviceTypeId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['firmwareVersion'] = resp;
          } else {
            this.dropDownValues['firmwareVersion'] = [];
          }
        },
        err => {
          this.dropDownValues['firmwareVersion'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getCreatedByList() {
    this.fotaJobControllerService.getFotaCreatorsList({ solutiontypeId: this.solutionTypeId }).subscribe(resp => {
      if (resp && resp.length) {
        this.dropDownValues['createdBy'] = resp;
      } else {
        this.dropDownValues['createdBy'] = [];
      }
    },
      err => {
        this.dropDownValues['createdBy'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      switch ($event.child.child) {
        case 'deviceInterfaceProvider':
          this.solutionTypeId = $event.value;
          this.getDeviceInterfaceProviders($event.value);
          break;
         case 'businessProfile':
          this.deviceInterfaceProviderId = $event.value;
          this.getBusinessProfileOnSolution(this.solutionTypeId, this.deviceInterfaceProviderId);
          break;
          case  'iotHub':
          this.getIotHubList( this.solutionTypeId, this.deviceInterfaceProviderId, $event.value);
          this.profileId = $event.value;
          break;
        case 'deviceType':
          this.getDeviceTypes(this.solutionTypeId, this.deviceInterfaceProviderId);
          this.getCreatedByList();
          break;
        case 'firmwareVersion':
          this.deviceTypeId = $event.value;
          this.getFirmwareVersions();
          break;
      }
    }
  }

/**
    * @ngdoc method
    * @name fotaManagement#getBusinessProfileOnSolution
    *
    * @methodOf
    * fota.controller:fotaManagement
    *
    * @description
    * Description: To fetch list of businessProfiles
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
public getBusinessProfileOnSolution(solutionTypeId: string, deviceInterfaceProviderId: string) {
  this.solutionTypeControllerService
    .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId,
      deviceInterfaceProviderId:  deviceInterfaceProviderId }).subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['businessProfile'] = resp;
        } else {
          this.dropDownValues['businessProfile'] = [];
        }
      },
      err => {
        this.dropDownValues['businessProfile'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

  /**
    * @ngdoc method
    * @name fotaManagement#getStatusList
    *
    * @methodOf
    * fotaManagement.controller:manageDevice
    *
    * @description
    * Description: To populate status dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getStatusList() {
    this.fotaJobControllerService.getFotaJobEnum()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['status'] = resp;
          } else {
            this.dropDownValues['status'] = [];
          }
        },
        err => {
          this.dropDownValues['status'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public searchFotaJobs(searchData) {
    const searchObj = {
      jobName: searchData.jobName && { id: searchData.jobName },
      solutionType: searchData.solutionType && { id: searchData.solutionType },
      deviceInterfaceProviderId: searchData.deviceInterfaceProvider && { id : searchData.deviceInterfaceProvider },
      profileId: searchData.businessProfile && { id: searchData.businessProfile },
      iotHubId: searchData.iotHub && { id: searchData.iotHub },
      deviceType: searchData.deviceType && { id: searchData.deviceType },
      firmwareVersion: searchData.firmwareVersion && { id: searchData.firmwareVersion },
      createdBy: searchData.createdBy && { id: searchData.createdBy },
      fromDate: this.formatDate(searchData.fromDate),
      toDate: this.formatDate(searchData.toDate), status: searchData.status
    };
    this.searchData = { body: searchObj, page: 0, limit: this.pageSize };
    this.getFotaJobsList(this.searchData);
  }

  public getFotaJobsList(searchData) {
    const arr = [];
    this.fotaJobControllerService.getFotaJobsBySearchCriteria(searchData).subscribe(resp => {
      if (resp && resp.jobs['length']) {
        this.solutionTypeId = resp.jobs[0].solutionType.id;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.fotaData = resp.jobs;
        this.fotaJobsCount = resp.totalElements;
        for (const data of this.fotaData) {
          data['action'] = null;
          data['solutionType'] = data['solutionType'] && data['solutionType']['name'];
          data['deviceType'] = data['deviceType'] && data['deviceType']['name'];
          data['firmwareVersion'] = data['firmwareVersion'] && data['firmwareVersion']['name'];
        }
        this.dataSource = new MatTableDataSource<any>(this.fotaData);
        this.defaultColumns = this.filterColumns(Object.keys(this.fotaData[0]));
        this.defaultColumns.splice(this.defaultColumns.indexOf('action')-1, 0,'progress');
        if (this.defaultColumns.length) {
          for (const column of this.defaultColumns) {
            if (column === 'jobName' || column === 'Firmware Name:Version' || column === 'createdBy' || column === 'status'
              || column === 'progress' || column === 'action') {
              arr.push(column);
            }
          }
        }
        this.columns = arr;
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.dataSource = null;
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.dataSource = null;
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

   /**
      * @ngdoc method
      * @name manageFota#getSelectedColumns
      *
      * @methodOf
      * fota.controller:manageFota
      *
      * @description
      * Description: Adds selected columns to the table
      *
      * @param {type} columnsList
      * @return {type} null
    */
   getSelectedColumns(columnsList) {
    if (columnsList) {
      for (const columnName of columnsList) {
        if (this.columns.find((column) => columnName === column) === undefined) {
          this.columns.push(columnName);
        }
      }
    }
  }

  /**
    * @ngdoc method
    * @name manageFota#pageEvent
    *
    * @methodOf
    * device.controller:manageFota
    *
    * @description
    * Description: To fetch next list of devices.
    *
    * @param {type} event
    * @return {type} null
  */
  public pageEvent(event) {
    this.searchData['page'] = event.pageIndex;
    this.searchData['limit'] = event.pageSize;
    this.getFotaJobsList(this.searchData);
  }

  /**
    * @ngdoc method
    * @name manageFota#filterColumns
    *
    * @methodOf
    * fota.controller:manageFota
    *
    * @description
    * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
    *
    * @param {type} columns
    * @return {type} list of columns
  */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public goToDetailsPage(data) {
    this.router.navigate(['job-details', { fotaJobId: data['jobId'], count: this.fotaJobsCount }]);
  }

  public gotoConfirmDialog(data = null, mode?) {
    this.fotaData = data;
    let message;
    switch (mode) {
      case 'play': if (data['status'] === 'RUNNING') {
        message = this.translate.translateErrorMessages('PAUSE_SELECTED_JOB');
      } else if (data['status'] === 'CREATED') {
        message = this.translate.translateErrorMessages('START_SELECTED_JOB');
      } else {
        message = this.translate.translateErrorMessages('RESUME_SELECTED_JOB');
      }
        break;
      case 'terminate': message = this.translate.translateErrorMessages('TERMINATE_SELECTED_JOB');
        break;
      case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_JOB');
        break;
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'play':
            if (data['status'] === 'RUNNING') {
              this.performActions({ action: 'pause', successMsg: 'JOB_PAUSE_SUCCESS', newStatus: 'PAUSED' }, data);
            } else if (data['status'] === 'CREATED') {
              this.performActions({ action: 'start', successMsg: 'JOB_START_SUCCESS', newStatus: 'RUNNING' }, data);
            } else {
              this.performActions({ action: 'resume', successMsg: 'JOB_RESUME_SUCCESS', newStatus: 'RUNNING' }, data);
            }
            break;
          case 'terminate': this.performActions({
            action: 'terminate', successMsg: 'JOB_TERMINATE_SUCCESS',
            newStatus: 'TERMINATED'
          }, data);
            break;
          case 'delete': this.deleteFotaJob(data);
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  public performActions(parameterObj, data: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.fotaJobControllerService.fota({ solutionTypeId: this.solutionTypeId, jobId: data['jobId'], action: parameterObj.action })
      .subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        data['status'] = parameterObj.newStatus;
        this.responseHandlerService.returnToastMessage('success', parameterObj.successMsg);
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public deleteFotaJob(data: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.fotaJobControllerService.deleteFotaJob({ fotaJobId: data.jobId }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'JOB_DELETE_SUCCESS');
      this.getFotaJobsList(this.searchData);
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public goToFotaEditPage(data: any) {
    this.router.navigate(['fota/manage-job-inprogress', { solutionTypeId: data['solutionTypeId'], fotaJobId: data['jobId'] }]);
  }

  public abortAction() {
    this._matDialog.closeAll();
  }

  formatDate(date) {
    if (date !== undefined) {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
  }

  /**
    * @ngdoc method
    * @name manageFota#filterColumns
    *
    * @methodOf
    * fota.controller:manageFota
    *
    * @description
    * Description: This method will return large or small based on the column size requirements
    *
    * @param {type} string
    * @return {type} string
  */
  public determineColumnSize(columnName) {
    if (columnName === 'action') {
      return 'column-small';
    } else {
      return 'column-large';
  }
}

public getIotHubList(solutionTypeId: string, deviceInterfaceProviderId: string, profileId: string ) {
  this.platformAdminUtilityControllerService.getIotHubsBySolDevIntfProvAndProfile({solutionTypeId: solutionTypeId,
  deviceInterfaceProviderId: deviceInterfaceProviderId, profileId: profileId }) .subscribe(resp => {
    if (resp && resp.length) {
      this.dropDownValues['iotHub'] = resp;
    } else {
      this.dropDownValues['iotHub'] = [];
    }
  },
    err => {
      this.dropDownValues['iotHub'] = [];
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
}
public onClose() {
  this._matDialog.closeAll();
}

 public getOtaJobProgress(contentProgress, jobId) {
 this.showSpinner = this.loaderService.showProgressBar();
 this.fotaJobControllerService.getFotaProgress({fotaId: jobId }).subscribe(resp => {
  if (resp) {
   this.loaderService.hideProgressBar(this.showSpinner);
  this.jobProgressVal = resp['progress'];
   this._matDialog.open(contentProgress, {
  maxHeight: '80vh',
  minWidth: '30vw',
  disableClose: true,
   data: null,
  })
 } else {
  this.loaderService.hideProgressBar(this.showSpinner);
 }
 }, err => {
 this.loaderService.hideProgressBar(this.showSpinner);
  this.onClose();
  });
}
}
