/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import {
  TenantControllerService, BusinessProfileControllerService, PlatformAdminUtilityControllerService,
  SolutionTypeControllerService,
  SubscriptionControllerService
} from '../../services/Platform/services';
import { ActivatedRoute, Router } from '@angular/router';
import { CREATE, PROFILE_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EnvService } from 'src/app/env.service';
import { DeviceMgmtUtilityControllerService } from 'src/app/services/DeviceMgmt/services';
import { BciLoaderService } from '@bci-web-core/core';

/**
  * @ngdoc component
  * @name profiles.component:createProfile
  *
  *
  * @description
  * description: To create a new business profile.
  *
  *
*/
@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {
  public submitted = false;
  public jsonFileLoaded;
  public showSpinner:any;
  public pageKey: string;
  public profileIcon;
  asideMenus: Array<AsideCard>;
  public dropDownValues: any = {};
  createProfileForm: UntypedFormGroup;
  deviceInterfaceproviderList = [];
  selectedSubscription;
  public profile;
  public selectedSolution;
  public solutionsSelected = [];
  constructor(private responseHandlerService: ResponseHandlerService,
    private tenantService: TenantControllerService, private subscriptionControllerService: SubscriptionControllerService,
    private env: EnvService,
    private platformAdminutilityControllerService: PlatformAdminUtilityControllerService,
    private profileControllerService: BusinessProfileControllerService,
    private router: Router, private solutionTypeControllerService: SolutionTypeControllerService,
    private rightsideNavitemsService: RightsideNavitemsService, private objectToIdConversionService: ObjectToIdConversionService,
    private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.jsonFileLoaded = 'business-profiles';
    this.pageKey = 'profile';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.profile = { profileId: this.route.snapshot.paramMap.get('profileId'), tenantId: this.route.snapshot.paramMap.get('tenantId') };
    this.getTenants();
    this.getBusinessType();
    this.getFreeRealmsList();
    this.getAllDeviceInterfaceProviders();
    this.profileIcon = PROFILE_ICON;
    this.createProfileForm = this.formBuilder.group({
      profileName: ['', [Validators.required, Validators.maxLength(50)]],
      realmName: [''],
      businessType: ['', Validators.required],
      tenant: ['', Validators.required],
      demoAccount:[false],
      solutionTypeProvidersubscriptionGrp: this.formBuilder.array([this.createSolutionTypeProviderGroup()])
    });
    if (this.profile['profileId']) {
      this.populateFormFieldValue();
    }
  }

  /**
    * @ngdoc method
    * @name createProfile#getTenants
    *
    * @methodOf
    * profiles.controller:createProfile
    *
    * @description
    * Description: To populate tenants dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getTenants() {
    this.tenantService.findAllTenantsBasic({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createProfile#getBusinessType
    *
    * @methodOf
    * profiles.controller:createProfile
    *
    * @description
    * Description: To populate business types dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getBusinessType() {
    this.platformAdminutilityControllerService
      .findAllBusinessTypes()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessType'] = resp;
          } else {
            this.dropDownValues['businessType'] = [];
          }
        },
        err => {
          this.dropDownValues['businessType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createProfile#getSolutionType
    *
    * @methodOf
    * profiles.controller:createProfile
    *
    * @description
    * Description: To populate solution types dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getSolutionType(group) {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = this.updateSolutionTypeList(resp);
            group.get('solutionType')['dropdownValues'] = this.dropDownValues['solutionType'];
            if (this.solutionsSelected) {
              group.get('solutionType')['dropdownValues'] = resp;
            }
          } else {
            group.get('solutionType')['dropdownValues'] = [];
          }
        },
        err => {
          group.get('solutionType')['dropdownValues'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createProfile#getFreeRealmsList
    *
    * @methodOf
    * profiles.controller:createProfile
    *
    * @description
    * Description: To populate realms dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getFreeRealmsList() {
    this.platformAdminutilityControllerService
      .getAvailableRealmsList()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['realmName'] = resp;
          } else {
            this.dropDownValues['realmName'] = [];
          }
        },
        err => {
          this.dropDownValues['realmName'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createProfile#createProfile
    *
    * @methodOf
    * profiles.controller:createProfile
    *
    * @description
    * Description: To create new business profile.
    *
    * @param {type} data
    * @return {type} null
  */
  public saveProfile() {
    this.submitted = true;
    const data = this.createProfileForm.value;
    // to convert solutionTypeProvidersubscriptionGrp formcontrols to common details.
    const solutionLinkingObject = [];
    let length = 0;
    if (data['solutionTypeProvidersubscriptionGrp'] && data['solutionTypeProvidersubscriptionGrp'].length) {
      length = data['solutionTypeProvidersubscriptionGrp'].length;
    }
    for (let index = 0; index < length; index++) {
      const iotList = [];
      const group = {
        'solutionType': this.objectToIdConversionService.convertObjectToId
          (data['solutionTypeProvidersubscriptionGrp'][index]['solutionType']),
        'deviceInterfaceProvider': data['solutionTypeProvidersubscriptionGrp'][index]['deviceInterfaceProvider'] ?
        this.objectToIdConversionService.convertObjectToId(data['solutionTypeProvidersubscriptionGrp']
        [index]['deviceInterfaceProvider']) : null
      };
      if (data['solutionTypeProvidersubscriptionGrp'][index]['subscription'] &&
      data['solutionTypeProvidersubscriptionGrp'][index]['subscription'].length) {
        data['solutionTypeProvidersubscriptionGrp'][index]['subscription'].forEach(iotHub => {
          iotList.push(this.objectToIdConversionService.convertObjectToId(iotHub));
        });
        group['iothubDetails'] = iotList;
      } else {
        group['iothubDetails'] = [];
      }
      solutionLinkingObject.push(group);
    }
    const sendData: any = {
      'name': data['profileName'],
      'businessType': this.objectToIdConversionService.convertObjectToId(data['businessType']),
      'solutionTypes': data['solutionType'],
      'realmName': data['realmName'] ? data['realmName'] : null,
      'solutionLinking': solutionLinkingObject,
      'demoAccountEnabled':data['demoAccount']
    };
    this.showSpinner = this.loaderService.showProgressBar();
    if (!this.profile['profileId']) {
      this.profileControllerService
        .saveBusinessProfiles({ tenantId: data['tenant'], body: sendData })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'BUSINESS_PROFILE_CREATE_SUCCESS');
            this.router.navigate(['profiles/manage-profiles', { tenantId: data['tenant'] }]);
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {   
      this.profileControllerService
        .updateBusinessProfiles({ profileId: this.profile['profileId'], tenantId: data['tenant'], body: sendData })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'BUSINESS_PROFILE_EDIT_SUCCESS');
            this.router.navigate(['profiles/manage-profiles', { tenantId: data['tenant'] }]);
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    }
  }

  public getDeviceInterfaceProviders(solutionTypeId, group) {
    this.solutionsSelected.push(solutionTypeId);
    this.platformAdminutilityControllerService
      .deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['deviceInterfaceProvider'] = resp;
            group.get('deviceInterfaceProvider')['dropdownValues'] = Object.assign([], this.dropDownValues['deviceInterfaceProvider']);
          } else {
            group.get('deviceInterfaceProvider')['dropdownValues'] = [];
            group.get('subscription').patchValue(null);
            group.get('subscription').disable();
          }
        },
        err => {
          group.get('deviceInterfaceProvider')['dropdownValues'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
          group.get('subscription').patchValue(null);
          group.get('subscription').disable();
        }
      );
  }

  public updateSolutionTypeList(allSolutionsList) {
    // to remove selected solution from list for next row
    const filteredSolutionsList = allSolutionsList.filter(solutionItem => !this.solutionsSelected.includes(solutionItem.id));
    return filteredSolutionsList;
  }

  public getSubscriptions(providerId: string, solutionTypeId: string, group) {
    const arr = [];
    this.dropDownValues['subscription'] = [];
    this.subscriptionControllerService.getAllSubscriptionsBasic({ solutionTypeId: solutionTypeId, providerId: providerId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            for (const subscription of resp) {
              if (subscription['isLinkedToBusinessProfile'] === false) {
                arr.push(subscription);
              }
              if (this.selectedSubscription && this.selectedSubscription.length) {
                for (const selectedSubscrn of this.selectedSubscription) {
                  if (subscription['id'] === selectedSubscrn) {
                    arr.push(subscription);
                  }
                }
              }
            }
            this.dropDownValues['subscription'] = arr;
            group.get('subscription')['dropdownValues'] = Object.assign([], this.dropDownValues['subscription']);
          } else {
            this.dropDownValues['subscription'] = [];
            group.get('subscription').patchValue(null);
            group.get('subscription').disable();
          }
        },
        err => {
          this.dropDownValues['subscription'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
          group.get('subscription').patchValue(null);
          group.get('subscription').disable();
        }
      );
  }

  public createSolutionTypeProviderGroup() {
    const group = this.formBuilder.group({
      solutionType: ['', Validators.required],
      deviceInterfaceProvider: [''],
      subscription: [{ value: '', disabled: true }],
    });
    group.get('solutionType')['dropdownValues'] = Object.assign([], []);
    group.get('deviceInterfaceProvider')['dropdownValues'] = Object.assign([], []);
    group.get('subscription')['dropdownValues'] = Object.assign([], []);
    this.getSolutionType(group);
    return group;
  }

  public addSolutionTypeProviderGroup() {
    const solutionProviderGrp = this.createSolutionTypeProviderGroup();
    (<UntypedFormArray>this.createProfileForm.get('solutionTypeProvidersubscriptionGrp')).push(solutionProviderGrp);

  }

  public removeSolutionTypeProviderGroup(index: number) {
    (<UntypedFormArray>this.createProfileForm.get('solutionTypeProvidersubscriptionGrp')).removeAt(index);
  }

  public disableSubscriptionField(event, group) {
    let provider;
    if (event.value) {
      if (this.deviceInterfaceproviderList) {
        provider = this.deviceInterfaceproviderList.find(deviceInterfaceProvider => deviceInterfaceProvider.id === event.value);
      }
      if (provider) {
        group.get('subscription').enable();
        if (provider['name'] !== this.env['providerBosch']) {
            this.getIotHubList(event.value, group.controls['solutionType'].value, group);
        } else {
            this.getSubscriptions(event.value, group.controls['solutionType'].value, group);
        }
      }
    } else {
      group.get('subscription').patchValue(null);
      group.get('subscription').disable();
    }
  }

  public onReset() {
    this.createProfileForm.reset();
  }

  public populateFormFieldValue() {
    this.profileControllerService.getBusinessProfile({
      tenantId: this.profile.tenantId,
      profileId: this.profile.profileId
    }).subscribe(resp => {
      if (resp && resp['solutionLinking']) {
        (<UntypedFormArray>this.createProfileForm.get('solutionTypeProvidersubscriptionGrp')).controls = [];
        resp['solutionLinking'].forEach(solution => {
          let fromGroup;
          const iotList = [];
          if (solution['iothubDetails']) {
            if (solution['iothubDetails'] && solution['iothubDetails'].length) {
              solution['iothubDetails'].forEach(iotHub => {
                iotList.push(iotHub.id);
               });
            }
            fromGroup = this.formBuilder.group({
              solutionType: [solution['solutionType']['id'], Validators.required],
              deviceInterfaceProvider: [solution['deviceInterfaceProvider']['id']],
              subscription: [iotList]
            });
          } else {
            fromGroup = this.formBuilder.group({
              solutionType: [solution['solutionType']['id'], Validators.required],
              deviceInterfaceProvider: [solution['deviceInterfaceProvider']['id']],
              subscription: ['']
            });
          }
          (<UntypedFormArray>this.createProfileForm.get('solutionTypeProvidersubscriptionGrp')).push(fromGroup);
          if (solution['deviceInterfaceProvider']['name'] === this.env['providerBosch']) {
            this.selectedSubscription = iotList;
          }
          this.selectedSolution = solution['solutionType'];
          this.getSolutionType(fromGroup);
          this.getDeviceInterfaceProviders(solution['solutionType']['id'], fromGroup);
          this.disableSubscriptionField({ value: solution['deviceInterfaceProvider']['id'] }, fromGroup);
        });
      }
      this.createProfileForm.patchValue({
        profileName: resp['name'],
        realmName: resp['realmName'],
        businessType: resp['businessType']['id'],
        tenant: resp['tenant']['id'],
        demoAccount: resp['demoAccountEnabled']
      });
    });
  }

  public getAllDeviceInterfaceProviders() {
    this.deviceInterfaceproviderList = [];
    this.platformAdminutilityControllerService
      .deviceInterfaceProviders({ solutionTypeId: '', profileId: '', active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.deviceInterfaceproviderList = resp;
          } else {
            this.deviceInterfaceproviderList = [];
          }
        },
        err => {
          this.deviceInterfaceproviderList = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }
public getIotHubList( deviceInterfaceProviderId: string, solutionTypeId: string, group) {
  this.platformAdminutilityControllerService.getIotHubsBySolDevIntfProvAndProfile({solutionTypeId: solutionTypeId,
  deviceInterfaceProviderId: deviceInterfaceProviderId}).subscribe(resp => {
    if (resp && resp.length) {
      this.dropDownValues['subscription'] = resp;
      group.get('subscription')['dropdownValues'] = Object.assign([], this.dropDownValues['subscription']);
    } else {
      this.dropDownValues['subscription'] = [];
      group.get('subscription').patchValue(null);
      group.get('subscription').disable();
    }
  },
    err => {
      this.dropDownValues['subscription'] = [];
      group.get('subscription').patchValue(null);
      group.get('subscription').disable();
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
}
}
