/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form';

import { AppendSpacePipe } from './pipes/title-case-pipe';
import {
  BciLayoutModule,
  BciSharedModule
} from '@bci-web-core/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AsideNavCardComponent } from './aside-nav-card/aside-nav-card.component';
import { SearchComponentComponent } from './search-component/search-component.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { FooterModule } from './footer/footer.module';
import { PopupOverlayComponent } from './popup-overlay/popup-overlay.component';
import { DynamicFormDeviceComponent } from './dynamic-form-device/dynamic-form-device.component';
import { ConfirmationDialogPopupComponent } from './confirmation-dialog-popup/confirmation-dialog-popup.component';
import { RightSideNavOverlayComponent } from './right-side-nav-overlay/right-side-nav-overlay.component';
import { FotaDeviceListComponent } from '../shared/fota-device-list/fota-device-list.component';
import { ShowHistoryDailogComponent } from '../shared/show-history-dialog/show-history-dialog.component';
import { StaticTagsComponent } from '../shared/static-tags/static-tags.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule}from '@angular/material/slider';
import {MatDatepickerModule} from '@angular/material/datepicker';
@NgModule({
    declarations: [DynamicFormComponent, AppendSpacePipe, HeaderComponent, FooterComponent, AsideNavCardComponent,
        MenuComponent, SearchComponentComponent, PopupOverlayComponent, DynamicFormDeviceComponent,
        ConfirmationDialogPopupComponent, MenuComponent, SearchComponentComponent, PopupOverlayComponent, DynamicFormDeviceComponent,
        RightSideNavOverlayComponent, StaticTagsComponent,
        FotaDeviceListComponent, ShowHistoryDailogComponent],
    exports: [DynamicFormComponent, AppendSpacePipe, HeaderComponent, FooterComponent, AsideNavCardComponent,
        SearchComponentComponent, DynamicFormDeviceComponent, ConfirmationDialogPopupComponent,
        RightSideNavOverlayComponent, StaticTagsComponent],
    imports: [
        CommonModule,
        BciLayoutModule,
        BciSharedModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatTabsModule, MatSidenavModule, MatToolbarModule, MatListModule, MatMenuModule, MatAutocompleteModule, MatTooltipModule,
        FlexLayoutModule,
        RouterModule,
        FooterModule,
        MatDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSliderModule,
        MatTableModule,
        MatPaginatorModule,
        MatChipsModule
    ],
    providers: [DatePipe]
})
export class SharedModule {
}

