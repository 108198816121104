/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { HttpClient } from '@angular/common/http';
import {
  DeviceControllerService,
  DeviceTypeControllerService,
  ManufacturerControllerService,
  C2DDirectMethodControllerService,
  DeviceEnrollmentControllerService,
  FotaSelectionControllerService,
  DeviceToQueueControllerService,
  TagControllerService
} from 'src/app/services/DeviceMgmt/services';
import { DeviceSearchDto, CommonId } from 'src/app/services/DeviceMgmt/models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TenantControllerService,
  BusinessProfileControllerService,
  SolutionTypeControllerService,
  PlatformAdminUtilityControllerService
} from 'src/app/services/Platform/services';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
  MANAGE,
  CONNECTED,
  DELETE_IMAGE,
  CONFIGURE_IMAGE,
  REBOOT_IMAGE,
  ADD_TAGS,
  REMOVE_TAGS,
  RESTART_IMAGE,
  FOTA_IMAGE,
  MORE_OPTION,
  THUMBUP_IMAGE,
  THUMBDOWN_IMAGE,
  DEVICE_ICON,
  CONNECTED_ICON,
  DISCONNECTED_ICON,
  BOSCH_IOT_ICON,
  AZURE_ICON,
  AWS_ICON,
  REGISTERED_ICON,
  ZELIOT_ICON, C2D_BLACK_ICON,
  DEVICE_TUNNELING_ICON, DEV_STATS_ACTION_ICON
} from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { SearchComponentComponent } from 'src/app/shared/search-component/search-component.component';
import { StaticTagsComponent } from 'src/app/shared/static-tags/static-tags.component';
import { BciLoaderService } from '@bci-web-core/core';
/**
 * @ngdoc component
 * @name device.component:manageDevice
 *
 *
 * @description
 * description: To perform crud operations on the list of devices.
 *
 *
 */
@Component({
  selector: 'app-manage-device',
  templateUrl: './manage-device.component.html',
  styleUrls: ['./manage-device.component.scss','../common-device-styles.scss']
})
export class ManageDeviceComponent implements OnInit {
  public showSpinner :any;
  public deviceData;
  public columns: any[];
  public defaultColumns: any[];
  public dropDownValues: any = {};
  ignoreList = [
    'id',
    'links',
    'account',
    'manufacturer',
    'devicePropertySupport',
    'deviceInterfaceProvider',
  ];
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public formData: any;
  public data;
  public deviceCount = 0;
  public matTabOptions = [];
  public currentTabValue;
  public permissionList: Array<String> = [];
  selection = new SelectionModel<CommonId>(true, []);
  public mode: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  searchDto: any = null;
  configureImage: string;
  rebootImage: string;
  deleteImage: string;
  restartImage: string;
  fotaImage: string;
  connectedImage: string;
  disconnectedImage: string;
  registeredImage: string;
  boschIotIcon: string;
  azureIcon: string;
  awsIcon: string;
  zeliotIcon: string;
  thumbUpImage: string;
  thumbDownImage: string;
  moreOptionsImage: string;
  tenantId: any;
  solutionTypeId: any;
  deviceTypeId: any;
  fotaDetails = {};
  hideFotaBtn = true;
  tabToBeLoaded;
  index = 0;
  selectionCriteria;
  selectedTab;
  selectAllDevices = false;
  public deviceIcon: string;
  public selectedTag: any;
  public jsonFileLoaded;
  public tagsArray = [];
  public deviceList = [];
  public availableTags = [];
  public searchCriteria;
  public deviceInterfaceProviderId: string;
  public profileId: string;
  public deviceTunnelIcon: string;
  public deviceStatsIcon: string;
  public c2dIcon: string;
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  @ViewChild(StaticTagsComponent) dynamicForm: StaticTagsComponent;
  selectedDevices: any;
  constructor(
    private translate: TranslationService,
    private route: ActivatedRoute,
    private dynamicSearchServiceService: DynamicSearchServiceService,
    private tenantControllerService: TenantControllerService,
    private manufacturerControllerService: ManufacturerControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private deviceControllerService: DeviceControllerService,
    private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog,
    private httpService: HttpClient,
    private router: Router,
    private c2dDirectMethodControllerService: C2DDirectMethodControllerService,
    private rightsideNavitemsService: RightsideNavitemsService,
    private deviceEnrollmentControllerService: DeviceEnrollmentControllerService,
    private deviceToQueueControllerService: DeviceToQueueControllerService,
    private fotaSelectionControllerService: FotaSelectionControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private tagControllerService: TagControllerService,
    private tagService: TagsServiceProvider,
    private loaderService: BciLoaderService
  ) {
    this.tagService.refreshSearchSubject.subscribe((refreshSearch) => {
      if (refreshSearch) {
        this.reloadData(refreshSearch);
      }
    });
  }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.pageKey = 'device';
    this.jsonFileLoaded = 'attach-tag';
    this.searchCriteria = 'ADVANCE';
    this.rightsideNavitemsService
      .getRightsideNavItems(MANAGE, this.pageKey)
      .then((navItemsList) => {
        this.asideMenus = navItemsList as Array<AsideCard>;
      });
    if (this.permissionList.indexOf('DEV_SER_BP') > -1) {
      this.matTabOptions.push({ label: 'Business', value: 'business' });
    }
    if (this.permissionList.indexOf('DEV_SER_ST') > -1) {
      this.matTabOptions.push({ label: 'Solution', value: 'solution' });
    }
    if (this.matTabOptions[0].value === 'business') {
      this.getTenant();
      this.currentTabValue = 'business';
    } else if (this.matTabOptions[0].value === 'solution') {
      this.getSolutionType();
      this.currentTabValue = 'solution';
    }
    this.httpService
      .get('./assets/form-fields/device-search.json')
      .subscribe((data) => {
        this.dynamicSearchServiceService.setFormData(data as string[]);
        if (!this.fotaDetails['searchDto'] && !this.fotaDetails['url']) {
          this.formData = this.dynamicSearchServiceService.generateDynamicForm(
            this.currentTabValue
          );
        } else if (
          this.fotaDetails['searchDto'] ||
          (!this.fotaDetails['searchDto'] &&
            this.fotaDetails['url'] === 'manage-job-inprogress')
        ) {
          this.formData = this.dynamicSearchServiceService.generateDynamicForm(
            this.currentTabValue
          );
          this.getSolutionType();
          this.tabGroup.selectedIndex = 1;
        }
        this.dropDownValues =
          this.dynamicSearchServiceService.generateDynamicFormDropdown(
            this.formData
          );
      });

    this.configureImage = CONFIGURE_IMAGE;
    this.rebootImage = REBOOT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.restartImage = RESTART_IMAGE;
    this.fotaImage = FOTA_IMAGE;
    this.connectedImage = CONNECTED_ICON;
    this.disconnectedImage = DISCONNECTED_ICON;
    this.registeredImage = REGISTERED_ICON;
    this.moreOptionsImage = MORE_OPTION;
    this.deviceIcon = DEVICE_ICON;
    this.boschIotIcon = BOSCH_IOT_ICON;
    this.azureIcon = AZURE_ICON;
    this.awsIcon = AWS_ICON;
    this.zeliotIcon = ZELIOT_ICON;
    this.deviceTunnelIcon = DEVICE_TUNNELING_ICON;
    this.fotaDetails = history.state;
    this.deviceStatsIcon = DEV_STATS_ACTION_ICON;
    this.c2dIcon = C2D_BLACK_ICON;
    if (this.fotaDetails['solutionTypeDetails']) {
      this.currentTabValue = 'solution';
      this.selectedTab = 'ADVANCE';
      this.getSelectedIndex();
      this.getSolutionType();
      if (!this.fotaDetails['allDevicesFlg']) {
        this.fotaDetails['deviceList'] = this.fotaDetails['deviceList'].map(
          (device) => {
            return device['id'];
          }
        );
        this.selection['selected'].push(...this.fotaDetails['deviceList']);
      } else {
        this.selectAllDevices = true;
      }
      const body = {
        solutionType: {
          id: this.fotaDetails['solutionTypeDetails']['id'],
          loadChild: { childName: 'deviceInterfaceProvider' },
        },
        deviceType: { id: this.fotaDetails['deviceTypeDetails']['id'] },
        deviceInterfaceProvider: {
          id: this.fotaDetails['deviceInterfaceProvider']['id'],
          loadChild: { childName: 'businessProfile' },
        },
        businessProfile: {
          id: this.fotaDetails['businessProfile']['id'],
          loadChild: { childName: 'iotHub' },
        },
        iotHub: {
          id: this.fotaDetails['iotHubDetails']['id'],
          loadChild: { childName: 'deviceType' },
        },
      };
      this.fotaDetails['searchDto'] = this.fotaDetails['searchDto']
        ? this.fotaDetails['searchDto']
        : { body: body, page: 0, limit: this.pageSize };
      this.fotaDetails['searchDto']['body'] = body;
      this.searchDto = this.fotaDetails['searchDto'];
      this.selectionCriteria = body;
      this.deviceControllerService
        .getDeviceCountBySearchType({
          searchtype: this.currentTabValue,
          body: body,
        })
        .subscribe(
          (resp) => {
            this.deviceCount = resp;
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );

      this.getDevices(this.fotaDetails['searchDto']);
    }
    this.thumbUpImage = THUMBUP_IMAGE;
    this.thumbDownImage = THUMBDOWN_IMAGE;
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;

    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row['serialNo']));
  }

  public checkboxLabel(row?: CommonId): string {
    this.fotaDetails['allDevicesFlg'] = this.isAllSelected();
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  /**
   * @ngdoc method
   * @name ManageDeviceComponent#matChange
   *
   * @methodOf
   * device.controller:ManageDeviceComponent
   *
   * @description
   * to trigger action like fetching data in case tab changes.
   *
   * @param {type} click event
   * @return {type} null
   */
  public matChange(event) {
    this.data = null;
    this.columns = null;
    this.currentTabValue = this.matTabOptions[event.index].value;
    this.formData = this.dynamicSearchServiceService.generateDynamicForm(
      this.currentTabValue
    );
    this.dropDownValues =
      this.dynamicSearchServiceService.generateDynamicFormDropdown(
        this.formData
      );
    this.selection.clear();
    if (event.index === 0) {
      this.getTenant();
    } else if (event.index === 1) {
      this.getSolutionType();
    }
  }

  /**
   * @ngdoc method
   * @name ManageDeviceComponent#getSelectedIndex
   *
   * @methodOf
   * device.controller:getSelectedIndex
   *
   * @description
   * Change the default selection of Tab to solution.
   *
   * @param {type} click event
   * @return {type} null
   */
  getSelectedIndex(): number {
    if (this.fotaDetails['searchDto']) {
      return 1;
    }
    return 0;
  }

  /**
   * @ngdoc method
   * @name manageDevice#gotoModal
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Open popup for crud operations.
   *
   * @param {type} content,data, mode
   * @return {type} null
   */
  public gotoModal(content, data = null, mode?) {
    this.mode = mode;
    this.deviceData = data;
    this._matDialog.open(content, { disableClose: true, data });
  }

  /**
   * @ngdoc method
   * @name manageDevice#searchDevicesEmitter
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description:
   *
   * @param {type}
   * @return {type}
   */
  public searchDevicesEmitter(event) {
    this.searchDevices(event);
    this.data = null;
    this.columns = null;
  }

  /**
   * @ngdoc method
   * @name manageDevice#searchDevices
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Search device based business or solution
   *
   * @param {type} dropdown values
   * @return {type} details of group based on search
   */
  public searchDevices(searchData) {
    const tags = [];
    if (searchData.tags) {
      this.selectedTag = searchData['tags'];
      searchData.tags.forEach((tagId) => {
        if (tagId) {
          const object = { id: tagId };
          tags.push(object);
        }
      });
    }
    if (this.searchDto) {
      this.fotaDetails['deviceList'] = [];
      this.fotaDetails['firmwareArtf'] = null;
      this.fotaDetails['allDevicesFlg'] = false;
      this.selectAllDevices = false;
      this.selection.clear();
    }
    this.deviceCount = 0;
    let searchObj: DeviceSearchDto;
    if (this.currentTabValue === 'business') {
      searchObj = {
        tenant: searchData.tenant && { id: searchData.tenant },
        businessProfile: searchData.businessProfile && {
          id: searchData.businessProfile,
        },
        state: searchData.state && searchData.state,
        status: searchData.status && searchData.status,
        deviceGroup: searchData.groupNameBusiness && {
          id: searchData.groupNameBusiness,
        },
        fromDate: searchData.fromDate && this.formatDate(searchData.fromDate),
        toDate: searchData.toDate && this.formatDate(searchData.toDate),
        id: searchData.id,
      };
      this.searchDto = { body: searchObj, page: 0, limit: this.pageSize };
    } else if (this.currentTabValue === 'solution') {
      searchObj = {
        solutionType: searchData.solutionType && {
          id: searchData.solutionType,
        },
        deviceType: searchData.deviceType && { id: searchData.deviceType },
        state: searchData.state && searchData.state,
        status: searchData.status && searchData.status,
        deviceGroup: searchData.groupNameSolution && {
          id: searchData.groupNameSolution,
        },
        firmwareVersion: searchData.firmwareVersion && {
          id: searchData.firmwareVersion,
        },
        deviceInterfaceProvider: searchData.deviceInterfaceProvider && {
          id: searchData.deviceInterfaceProvider,
        },
        businessProfile: searchData.businessProfile && {
          id: searchData.businessProfile,
        },
        iotHub: searchData.iotHub && { id: searchData.iotHub },
        fromDate: searchData.fromDate && this.formatDate(searchData.fromDate),
        toDate: searchData.toDate && this.formatDate(searchData.toDate),
        id: searchData.id,
        tags: tags,
      };
      this.searchDto = { body: searchObj, page: 0, limit: this.pageSize };
      this.fotaDetails['solutionTypeDetails'] = searchData.solutionType
        ? this.dropDownValues['solutionType'].find(
            (solution) => solution['id'] === searchData['solutionType']
          )
        : null;
      this.fotaDetails['deviceTypeDetails'] = searchData.deviceType
        ? this.dropDownValues['deviceType'].find(
            (device) => device['id'] === searchData['deviceType']
          )
        : null;
      this.fotaDetails['currentFirwareVersion'] = searchData.firmwareVersion
        ? this.dropDownValues['firmwareVersion'].find(
            (device) => device['id'] === searchData['firmwareVersion']
          )
        : null;
      this.fotaDetails['groupDetails'] = searchData.groupNameSolution
        ? this.dropDownValues['groupNameSolution'].find(
            (device) => device['id'] === searchData['groupNameSolution']
          )
        : null;
      this.fotaDetails['deviceInterfaceProvider'] =
        searchData.deviceInterfaceProvider
          ? this.dropDownValues['deviceInterfaceProvider'].find(
              (provider) =>
                provider['id'] === searchData['deviceInterfaceProvider']
            )
          : null;
      this.fotaDetails['businessProfile'] = searchData.businessProfile
        ? this.dropDownValues['businessProfile'].find(
            (profile) => profile['id'] === searchData['businessProfile']
          )
        : null;
    }

    if (searchData.criteria === 'ADVANCE') {
      this.searchCriteria = 'ADVANCE';
      this.deviceControllerService
        .getDeviceCountBySearchType({
          searchtype: this.currentTabValue,
          body: this.searchDto.body,
        })
        .subscribe(
          (resp) => {
            this.deviceCount = resp;
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    } else {
      if (searchData.criteria === 'BASIC') {
        this.searchCriteria = 'BASIC';
      }
      this.deviceCount = 1;
    }
    this.getDevices(this.searchDto);
    this.selection.clear();
  }

  /**
   * @ngdoc method
   * @name manageDevice#getDevices
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Fetches devices list.
   *
   * @param {type} searchdto
   * @return {type} null
   */
  public getDevices(searchdto) {
     this.showSpinner = this.loaderService.showProgressBar();
    const arr = [];
    if (this.currentTabValue === 'business') {
      this.deviceControllerService
        .getDeviceByBusinessProfile(searchdto)
        .subscribe(
          (resp) => {
            if (resp && resp['length']) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.deviceData = resp;
              this.data = resp;
              for (const data of this.deviceData) {
                data['action'] = null;
                data['status'] = data['status'] === 'ACTIVE' ? true : false;
                data['account'] = data['account'] && data['account']['name'];
                data['tenant'] = data['tenant'] && data['tenant']['name'];
                data['profile'] = data['profile'] && data['profile']['name'];
                data['manufacturer'] =
                  data['manufacturer'] && data['manufacturer']['name'];
                data['solutionType'] =
                  data['solutionType'] && data['solutionType']['name'];
                data['deviceType'] =
                  data['deviceType'] && data['deviceType']['name'];
                data['interfaceProvider'] =
                  data['deviceInterfaceProvider'] &&
                  data['deviceInterfaceProvider']['name'];
                data['isFirmUpdtInProgess'] = data['isFirmUpdtInProgess']
                  ? 'YES'
                  : 'NO';
              }
              this.defaultColumns = this.filterColumns(
                Object.keys(this.deviceData[0])
              );
              this.defaultColumns.unshift('select');
              if (this.defaultColumns.length) {
                for (const column of this.defaultColumns) {
                  if (
                    column === 'select' ||
                    column === 'serialNo' ||
                    column === 'state' ||
                    column === 'status' ||
                    column === 'action' ||
                    column === 'profile' ||
                    column === 'solutionType'
                  ) {
                    arr.push(column);
                  }
                }
              }
              this.columns = arr;
            } else {
              this.data = null;
              this.columns = null;
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'warning',
                'NO_DATA_AVAILABLE'
              );
            }
          },
          (err) => {
            this.data = null;
            this.columns = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    } else if (this.currentTabValue === 'solution') {
      this.deviceControllerService.getDeviceBySolutionType(searchdto).subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.deviceData = resp;
            this.data = resp;
            if (!this.fotaDetails['allDevicesFlg']) {
              if (this.fotaDetails['deviceList']) {
                this.fotaDetails['deviceList'] = [
                  ...this.selection['selected'],
                ];
                this.fotaDetails['deviceList'].forEach((row) => {
                  if (row !== undefined) {
                    this.selection.select(row);
                  }
                });
              }
            } else {
              if (this.selectedDevices && this.selectedDevices.length) {
                let selectDevices = false;
                this.data.forEach((row) => {
                  if (row !== undefined) {
                    this.selectedDevices.forEach((val) => {
                      if (row['serialNo'] === val.id) {
                        this.selection.select(row['serialNo']);
                        selectDevices = true;
                      }
                    });
                  }
                });
                if (!selectDevices) {
                  this.data.forEach((row) => {
                    if (row !== undefined) {
                      this.selection.select(row['serialNo']);
                    }
                  });
                }
              } else {
                this.data.forEach((row) => {
                  if (row !== undefined) {
                    this.selection.select(row['serialNo']);
                  }
                });
              }
            }
            for (const data of this.deviceData) {
              this.fotaDetails['solutionTypeDetails'] = data['solutionType'];
              this.fotaDetails['deviceTypeDetails'] = data['deviceType'];
              this.fotaDetails['deviceInterfaceProvider'] =
                data['deviceInterfaceProvider'];
              this.fotaDetails['businessProfile'] = data['profile'];
              this.fotaDetails['iotHubDetails'] = data['iotHubDetails'];
              const isFotaApplicable = data['devicePropertySupport'].find(
                (property) => property['devicePropertyName'] === 'FOTA'
              );
              data['FOTAApplicable'] =
                isFotaApplicable && isFotaApplicable['active'];
              this.hideFotaBtn = isFotaApplicable['active'];
              data['status'] = data['status'] === 'ACTIVE' ? true : false;
              data['account'] = data['account'] && data['account']['name'];
              data['tenant'] = data['tenant'] && data['tenant']['name'];
              data['profileId']= data['profile'] && data['profile']['id'];
              data['profile'] = data['profile'] && data['profile']['name'];
              data['manufacturer'] =
                data['manufacturer'] && data['manufacturer']['name'];
              data['solutionType'] =
                data['solutionType'] && data['solutionType']['name'];
              data['deviceType'] =
                data['deviceType'] && data['deviceType']['name'];
              data['interfaceProvider'] =
                data['deviceInterfaceProvider']['name'];
              data['isFirmUpdtInProgess'] = data['isFirmUpdtInProgess']
                ? 'YES'
                : 'NO';
              data['tags'] = data['tags'];
              data['action'] = null;
            }
            this.defaultColumns = this.filterColumns(
              Object.keys(this.deviceData[0])
            );
            this.defaultColumns.unshift('select');
            if (this.defaultColumns.length) {
              for (const column of this.defaultColumns) {
                if (
                  column === 'select' ||
                  column === 'serialNo' ||
                  column === 'state' ||
                  column === 'status' ||
                  column === 'action' ||
                  column === 'profile' ||
                  column === 'solutionType' ||
                  column === 'tags'
                ) {
                  arr.push(column);
                }
              }
            }
            this.columns = arr;
          } else {
            this.data = null;
            this.columns = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'warning',
              'NO_DATA_AVAILABLE'
            );
          }
        },
        (err) => {
          this.data = null;
          this.columns = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
  }

  /**
   * @ngdoc method
   * @name manageDevice#getSelectedColumns
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Adds selected columns to the table
   *
   * @param {type} columnsList
   * @return {type} null
   */
  getSelectedColumns(columnsList) {
    if (columnsList) {
      for (const columnName of columnsList) {
        if (
          this.columns.find((column) => columnName === column) === undefined
        ) {
          this.columns.push(columnName);
        }
      }
    }
  }
  /**
   * @ngdoc method
   * @name manageDevice#activateDevice
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: De-Activates particular device.
   *
   * @param {type} data
   * @return {type} null
   */
  public activateDevice(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    data['status'] = 'ACTIVE';
    this.deviceControllerService
      .activateDevice({ id: data['serialNo'] })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'DEVICE_ACTIVATE_SUCCESS'
          );
          this.getDevices(this.searchDto);
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          data['status'] = false;
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#deActivateDevice
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: De-activates particular device.
   *
   * @param {type} data
   * @return {type} null
   */
  public deActivateDevice(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    data['status'] = false;
    this.deviceControllerService
      .deactivateDevice({ id: data['serialNo'] })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'DEVICE_DEACTIVATE_SUCCESS'
          );
          this.getDevices(this.searchDto);
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          data['status'] = true;
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#deleteDevice
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Deletes particular device.
   *
   * @param {type} data
   * @return {type} null
   */
  public deleteDevice(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar()
    this.deviceControllerService.deleteDevice({ id: data['serialNo'] }).subscribe(
      resp => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'DEVICE_DELETE_SUCCESS');
        this.deviceCount -= 1;
        this.getDevices(this.searchDto);
      },
      err => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  /**
   * @ngdoc method
   * @name manageDevice#abortAction
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Closes the popup and restore the device active/inactive state.
   *
   * @param {type} null
   * @return {type} null
   */
  public abortAction() {
    this._matDialog.closeAll();
    if (
      this.mode === 'delete' ||
      this.mode === 'reboot' ||
      this.mode === 'configure' ||
      this.mode === 'restart' ||
      this.mode === 'fota'
    ) {
    } else {
      this.deviceData['status'] = !this.deviceData['status'];
    }
  }

  /**
   * @ngdoc method
   * @name manageDevice#filterColumns
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
   *
   * @param {type} columns
   * @return {type} list of columns
   */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  /**
   * @ngdoc method
   * @name manageDevice#checkPermission
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Checks whether the logged in user has permission to perform crud operations.
   *
   * @param {type} key
   * @return {type} boolean
   */
  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  /**
   * @ngdoc method
   * @name manageDevice#getTenant
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate tenant dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getTenant() {
    this.tenantControllerService
      .findAllTenantsBasic({ active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#updateDropdownValues
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate dependent dropdown.
   *
   * @param {type}  dropdown select event
   * @return {type} null
   */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.parentName === 'deviceType' && $event.child.child['length']) {
        $event.child.child.forEach((dependentDropdown) => {
          if (dependentDropdown === 'groupNameSolution') {
            this.getGroupNameSolution($event.value);
            this.deviceTypeId = $event.value;
          }
          if (dependentDropdown === 'firmwareVersion') {
            this.getFirmwareVersion($event.value);
          }
        });
      }
      switch ($event.child.child) {
        case 'businessProfile':
          if ($event.parentName === 'tenant') {
            this.getProfile($event.value);
            this.tenantId = $event.value;
          }
          if ($event.parentName === 'deviceInterfaceProvider') {
            this.deviceInterfaceProviderId = $event.value;
            this.getBusinessProfileOnSolution(
              this.solutionTypeId,
              this.deviceInterfaceProviderId
            );
            this.getAvailableTags(this.solutionTypeId);
          }
          break;
        case 'deviceType':
          this.getDeviceType(
            this.solutionTypeId,
            this.deviceInterfaceProviderId
          );
          break;
        case 'groupNameBusiness':
          this.getGroupNameBusiness($event.value);
          break;
        case 'deviceInterfaceProvider':
          this.dropDownValues['tags'] = [];
          this.getDeviceInterfaceProviders($event.value);
          this.solutionTypeId = $event.value;
          break;
        case 'iotHub':
          this.getIotHubList(
            this.solutionTypeId,
            this.deviceInterfaceProviderId,
            $event.value
          );
          this.profileId = $event.value;
          break;
        case 'firmwareVersion':
          this.selectedTag = $event.value;
      }
    } else if ($event.parentName === 'solutionType') {
      this.dropDownValues['tags'] = [];
    }
  }

  /**
   * @ngdoc method
   * @name manageDevice#getProfile
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate business profile dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getBusinessProfileOnSolution(
    solutionTypeId: string,
    deviceInterfaceProviderId: string
  ) {
    this.solutionTypeControllerService
      .findByBusinessProfilesListBasicForSolutionTypeId({
        solutionTypeId: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#getSolutionType
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate solution type dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#getGroupNameSolution
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate group name dropdown.
   *
   * @param {type} solutionTypeId
   * @return {type} null
   */
  public getGroupNameSolution(deviceTypeId) {
    this.deviceEnrollmentControllerService
      .findAllGroupNamesBasedOnDeviceType({ id: deviceTypeId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['groupNameSolution'] = resp;
          } else {
            this.dropDownValues['groupNameSolution'] = [];
          }
        },
        (err) => {
          this.dropDownValues['groupNameSolution'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#getGroupNameBusiness
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate group name dropdown.
   *
   * @param {type} businessProfileId
   * @return {type} null
   */
  public getGroupNameBusiness(businessProfileId) {
    this.deviceControllerService
      .findDeviceGroupByTenantAndBusinessprofile({
        tenantId: this.tenantId,
        profileId: businessProfileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['groupNameBusiness'] = resp;
          } else {
            this.dropDownValues['groupNameBusiness'] = [];
          }
        },
        (err) => {
          this.dropDownValues['groupNameBusiness'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#getDeviceType
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate device type dropdown.
   *
   * @param {type} solutionTypeId, type
   * @return {type} null
   */
  public getDeviceType(solutionTypeId, deviceInterfaceProviderId) {
    this.deviceTypeControllerService
      .findAllDeviceTypesBasedOnSolutionType({
        id: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId,
        active: 'true',
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['deviceType'] = resp;
          } else {
            this.dropDownValues['deviceType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['deviceType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#getFirmwareVersion
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate firmware version type dropdown.
   *
   * @param {type} solutionTypeId, type
   * @return {type} null
   */
  public getFirmwareVersion(deviceGroup) {
    const firmwareDto = {
      group: deviceGroup && { id: deviceGroup.id },
      solutionType: { id: this.solutionTypeId },
      deviceType: { id: this.deviceTypeId },
    };
    this.deviceEnrollmentControllerService
      .findAllFirmware({ body: firmwareDto })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['firmwareVersion'] = resp;
          } else {
            this.dropDownValues['firmwareVersion'] = [];
          }
        },
        (err) => {
          this.dropDownValues['firmwareVersion'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageDevice#pageEvent
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To fetch next list of devices.
   *
   * @param {type} event
   * @return {type} null
   */
  public pageEvent(event) {
    this.searchDto['page'] = event.pageIndex;
    this.searchDto['limit'] = event.pageSize;
    // if previous pagination any devices selected manually get select those devices ony
    if (this.selectedDevices && this.selectedDevices.length) {
      this.fotaDetails['allDevicesFlg'] = true;
    }
    this.getDevices(this.searchDto);
    this.selectAllDevices = true;
  }

  /**
   * @ngdoc method
   * @name manageDevice#configureDevice
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To configure the device.
   *
   * @param {type} data
   * @return {type} null
   */
  public configureDevice(data) {
    this._matDialog.closeAll();
    if (data['state'] === CONNECTED) {
      this.router.navigate([
        'configure-devices',
        { value: data['serialNo'], enrollment: 'individual' },
      ]);
    } else {
      this.responseHandlerService.returnToastMessage(
        'error',
        'DEVICE_CONFIGURE_ERROR_DISCONNECTED'
      );
    }
  }

  /**
   * @ngdoc method
   * @name manageDevice#rebootDevice
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To reboot the device.
   *
   * @param {type} data
   * @return {type} null
   */
  public rebootDevice(data) {
    this._matDialog.closeAll();
    if (data['state'] === CONNECTED) {
      this.c2dDirectMethodControllerService
        .invokeReboot({ id: data['serialNo'], body: { methodName: 'Reboot' } })
        .subscribe(
          (resp) => {
            this.responseHandlerService.returnToastMessage(
              'success',
              'DEVICE_REBOOT_SUCCESS'
            );
          },
          (err) => {
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    } else {
      this.responseHandlerService.returnToastMessage(
        'error',
        'DEVICE_REBOOT_ERROR_DISCONNECTED'
      );
    }
  }

  /**
   * @ngdoc method
   * @name manageDevice#onClose
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To close the popup.
   *
   * @param {type} null
   * @return {type} null
   */
  public onClose() {
    this._matDialog.closeAll();
    this.tagsArray = [];
    this.selection.clear();
    this.selectAllDevices = false;
  }

  /**
   * @ngdoc method
   * @name manageDevice#gotoConfirmDialog
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To open the popup.
   *
   * @param {type} null
   * @return {type} null
   */
  public gotoConfirmDialog(data = null, mode?) {
    this.deviceData = data;
    this.mode = mode;
    let message;
    switch (mode) {
      case 'edit':
        if (data['status']) {
          message = this.translate.translateErrorMessages(
            'ACTIVATE_SELECTED_DEVICE'
          );
        } else {
          message = this.translate.translateErrorMessages(
            'DEACTIVATE_SELECTED_DEVICE'
          );
        }
        break;
      case 'delete':
        message = this.translate.translateErrorMessages(
          'DELETE_SELECTED_DEVICE'
        );
        break;
      case 'reboot':
        message = this.translate.translateErrorMessages(
          'REBOOT_SELECTED_DEVICE'
        );
        break;
      case 'configure':
        message = this.translate.translateErrorMessages(
          'CONFIGURE_SELECTED_DEVICE'
        );
        break;
      case 'restart':
        message = this.translate.translateErrorMessages(
          'RESTART_SELECTED_DEVICE'
        );
        break;
      case 'fota':
        message = this.translate.translateErrorMessages(
          'UPDATE_SELECTED_DEVICE'
        );
        break;
      case 'tunnel':
        message = this.translate.translateErrorMessages(
          'TUNNEL_SELECTED_DEVICE'
        );
        break;
        case 'stats':
          message = this.translate.translateErrorMessages(
            'VIEW_DEVICE_DETAILS'
          );
        break;
        case 'c2d':
          message = this.translate.translateErrorMessages(
            'CALL_C2D_METHOD'
          );
        break;
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: { message: message },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        switch (mode) {
          case 'edit':
            if (data['status']) {
              this.activateDevice(data);
            } else {
              this.deActivateDevice(data);
            }
            break;
          case 'delete':
            this.deleteDevice(data);
            break;
          case 'configure':
            this.configureDevice(data);
            break;
          case 'reboot':
            this.rebootDevice(data);
            break;
          case 'restart':
            break;
          case 'tunnel':
            this.router.navigate([
              'device-tunneling',
              {
                serialNo: data['serialNo'],
                businessProfile: this.fotaDetails['businessProfile']['id'],
                solutionType: this.fotaDetails['solutionTypeDetails']['id'],
              },
            ]);
            break;
            case 'stats':
              this.router.navigate([
                'system-application-graphs',
                {
                  serialNo: data['serialNo'],profileId:data['profileId']
                },
              ]);
              break;
              case 'c2d':
              this.router.navigate([
                'c2d-method',
                {
                  serialNo: data['serialNo']
                },
              ]);
              break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  /**
   * @ngdoc method
   * @name manageDevice#formatDate
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: Format the date in required format.
   *
   * @param {type} date
   * @return {type} date as a string
   */
  formatDate(date) {
    if (date !== undefined) {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
  }

  /**
   * @ngdoc method
   * @name ManageDeviceComponent#selectDevices
   *
   * @methodOf
   * device.controller:selectDevices
   *
   * @description
   * select or unselect all devices in the table.
   *
   * @param {type} null
   * @return {type} null
   */
  selectDevices() {
    this.selectAllDevices = !this.selectAllDevices;
    if (this.selectAllDevices) {
      this.data.forEach((row) => {
        if (row !== undefined) {
          this.selection.select(row['serialNo']);
        }
      });
      this.fotaDetails['allDevicesFlg'] = true;
    } else {
      // when click on unselect all previous pagination selected devices removing
      if (this.selectedDevices && this.selectedDevices.length) {
        this.data.forEach((row) => {
          if (row !== undefined) {
            this.selectedDevices.forEach((val, index) => {
              if (row['serialNo'] === val.id) {
                this.selectedDevices.splice(index, 1);
              }
            });
          }
        });
      }
      this.fotaDetails['allDevicesFlg'] = false;
      this.selection.clear();
    }
  }

  public selectAllDevicesCheckBox(event, deviceId) {
    this.getSelectUnselectDevices(event, deviceId);
    if (this.isAllSelected()) {
      this.selectAllDevices = !this.selectAllDevices;
    }
  }

  /**
   * @ngdoc method
   * @name ManageDeviceComponent#getSelectUnselectDevices
   * @methodOf
   * device.controller:getSelectUnselectDevices
   * @description
   * to get the previous pagination select and unselect devices
   */
  public getSelectUnselectDevices(event, deviceId) {
    if (this.selectedDevices && this.selectedDevices.length) {
      if (!event.checked) {
        this.selectedDevices.splice(
          this.selectedDevices.findIndex((val) => val.id === deviceId),
          1
        );
      }
      this.selectedDevices = [
        ...this.selection['selected'].map((device) => {
          return { id: device };
        }),
        ...this.selectedDevices,
      ];
    } else {
      this.selectedDevices = this.selection['selected'].map((device) => {
        return { id: device };
      });
    }
    this.selectedDevices = this.selectedDevices.filter(
      (value, index, arr) =>
        arr.findIndex((value2) => value2.id === value.id) === index
    );
    this.selectAllDevices = false;
  }

  /**
   * @ngdoc method
   * @name ManageDeviceComponent#createFota
   *
   * @methodOf
   * device.controller:createFota
   *
   * @description
   * navigates to fota job with selection criteria and selected devices.
   *
   * @param {type} null
   * @return {type} null
   */
  createFota() {
    const deviceList = this.selection['selected'].map((device) => {
      return { id: device };
    });
    if (this.searchCriteria === 'ADVANCE') {
      if (this.selectAllDevices) {
        this.fotaDetails['allDevicesFlg'] = true;
        this.fotaDetails['deviceList'] = null;
      } else {
        this.fotaDetails['allDevicesFlg'] = false;
        this.fotaDetails['deviceList'] = deviceList;
      }
    } else if (this.searchCriteria === 'BASIC') {
      if (this.selectAllDevices) {
        this.fotaDetails['allDevicesFlg'] = false;
        this.fotaDetails['deviceList'] = deviceList;
      }
    }
    this.fotaDetails['searchDto'] = { body: this.searchDto['body'], page: 0, limit: this.pageSize };
    this.showSpinner = this.loaderService.showProgressBar();
    if (!this.fotaDetails['jobId']) {
      const body = {
        deviceTypeDetails: this.fotaDetails['deviceTypeDetails'],
        solutionTypeDetails: this.fotaDetails['solutionTypeDetails'],
        currentFirwareVersion: this.fotaDetails['currentFirwareVersion'],
        groupDetails: this.fotaDetails['groupDetails'],
        deviceList: this.fotaDetails['deviceList'],
        allDevicesFlg: this.fotaDetails['allDevicesFlg'],
        deviceInterfaceProvider: this.fotaDetails['deviceInterfaceProvider'],
        businessProfile: this.fotaDetails['businessProfile'],
        iotHubDetails: this.fotaDetails['iotHubDetails'],
      };
      this.fotaSelectionControllerService
        .saveFotaSelection({
          body: body,
        })
        .subscribe(
          (resp) => {
            if (resp) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.router.navigateByUrl('/fota/manage-job-inprogress', {
                state: this.fotaDetails,
              });
            }
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    } else {
      this.router.navigateByUrl('/fota/manage-job-inprogress', {
        state: this.fotaDetails,
      });
    }
  }

  public syncDevicesQueue() {
    this.deviceToQueueControllerService.deviceToQueue().subscribe(
      (resp) => {
        this.responseHandlerService.returnToastMessage(
          'success',
          'DEVICE_SYNC_SUCCESS'
        );
      },
      (err) => {
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getDeviceInterfaceProviders(solutionTypeId) {
    this.platformAdminUtilityControllerService
      .deviceInterfaceProviders({
        solutionTypeId: solutionTypeId,
        profileId: '', active: 'true'
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['deviceInterfaceProvider'] = resp;
          } else {
            this.responseHandlerService.returnToastMessage(
              'warning',
              'NO_DATA_AVAILABLE'
            );
            this.dropDownValues['deviceInterfaceProvider'] = [];
          }
        },
        (err) => {
          this.dropDownValues['deviceInterfaceProvider'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name CreateDevice#getAvailableTags
   *
   * @methodOf
   * firmware.controller:manageDevice
   *
   * @description
   * Description: Fetch the list of available static tags
   * @param {type} solutionTypeId
   * @return {type} null
   */
  public getAvailableTags(solutionTypeId: string) {
    this.dropDownValues['tags'] = null;
    this.tagControllerService
      .findAllTagsBySolutionTypeId({ solutionTypeId: solutionTypeId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tags'] = resp;
            this.availableTags = resp;
            if (this.dynamicForm) {
              resp.forEach((tag) => {
                if (tag) {
                  this.dynamicForm.stringArray.push(tag.name);
                }
              });
            }
            // Below code line clears previously selected tags, if same solutiontype is selected again
            this.searchForm.fields['tags'] = [];
          } else {
            this.dropDownValues['tags'] = [];
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name ManageDevive#openModal
   *
   * @methodOf
   * firmware.controller:manageDevice
   *
   * @description
   * Description: Opens up the modal to select and attach the tags
   * @param {type} content
   * @return {type} null
   */
  public openModal(content) {
    const deviceList = this.selection['selected'].map((device) => {
      return { id: device };
    });
    this.deviceList = deviceList;
    this.getAvailableTags(this.solutionTypeId);
    if (deviceList.length) {
      deviceList.forEach((selectedDevice) => {
        if (this.deviceData.indexOf(selectedDevice)) {
          this.deviceData.forEach((device) => {
            if (device.tags && device.serialNo === selectedDevice.id) {
              device.tags.forEach((tag) => {
                if (this.tagsArray && this.tagsArray.indexOf(tag) === -1) {
                  this.tagsArray.push(tag);
                }
              });
            }
          });
        }
      });
    }
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '30vw',
      disableClose: true,
      data: null,
    });
  }

  /**
   * @ngdoc method
   * @name ManageDevice#getAvailableTags
   *
   * @methodOf
   * firmware.controller:manageDevice
   *
   * @description
   * Description: Will call the tags common service to perform operations like adding and removing the tags from ui
   * @param {type} $event
   * @return {type} null
   */
  addOrRemoveTags($event) {
    this.tagService.addOrRemoveTags(
      $event,
      this.dynamicForm,
      this.availableTags,
      'deviceList',
      this.deviceList
    );
  }

  /**
   * @ngdoc method
   * @name ManageDevice#populateExistingTags
   *
   * @methodOf
   * firmware.controller:manageDevice
   *
   * @description
   * Description: This method will populate attached tags to the selected device list, once dynamic form on modal is loaded. Its event based
   * @param {type} $event
   * @return {type} null
   */
  public populateExistingTags($event) {
    if (this.dynamicForm && this.tagsArray.length) {
      this.dynamicForm.selectedValuesArray = this.tagsArray;
    }
  }

  public reloadData(refreshSearch) {
    if (refreshSearch && refreshSearch === 'refreshAttachSearch') {
      this.getDevices(this.searchDto);
      this.loaderService.hideProgressBar(this.showSpinner);
      this.getAvailableTags(this.solutionTypeId);
    } else if (refreshSearch && refreshSearch === 'refreshDetachSearch') {
      this.getDevices(this.searchDto);
      this.loaderService.hideProgressBar(this.showSpinner);
      this.selectAllDevices = false;
    }
  }

  /**
   * @ngdoc method
   * @name determineColumnSize
   *
   * @methodOf
   * firmware.controller:manageDevice
   *
   * @description
   * Description: This method will return large or small based on the column size requirements
   * @param {type} string
   * @return {type} string
   */
  public determineColumnSize(columnName) {
    if (
      columnName === 'serialNo' ||
      columnName === 'profile' ||
      columnName === 'solutionType' ||
      columnName === 'tags'
    ) {
      return 'column-large';
    } else {
      return 'column-small';
    }
  }

  public getIotHubList(
    solutionTypeId: string,
    deviceInterfaceProviderId: string,
    profileId: string
  ) {
    this.platformAdminUtilityControllerService
      .getIotHubsBySolDevIntfProvAndProfile({
        solutionTypeId: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId,
        profileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['iotHub'] = resp;
          } else {
            this.dropDownValues['iotHub'] = [];
          }
        },
        (err) => {
          this.dropDownValues['iotHub'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
}
