/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfileWhatsAppConnectionDto } from '../models/profile-whats-app-connection-dto';
import { WhatsappAnalyticsDto } from '../models/whatsapp-analytics-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappProfileConnectionDetailsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetchProfileWhatsAppConnectionDetails
   */
  static readonly FetchProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchProfileWhatsAppConnectionDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.FetchProfileWhatsAppConnectionDetailsPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchProfileWhatsAppConnectionDetails(params: {
    profileId: string;
  }): Observable<Array<ProfileWhatsAppConnectionDto>> {

    return this.fetchProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>) => r.body as Array<ProfileWhatsAppConnectionDto>)
    );
  }

  /**
   * Path part for operation saveProfileWhatsAppConnectionDetails
   */
  static readonly SaveProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProfileWhatsAppConnectionDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
    body?: Array<ProfileWhatsAppConnectionDto>
  }): Observable<StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.SaveProfileWhatsAppConnectionDetailsPath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProfileWhatsAppConnectionDetails(params: {
    profileId: string;
    body?: Array<ProfileWhatsAppConnectionDto>
  }): Observable<Array<ProfileWhatsAppConnectionDto>> {

    return this.saveProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>) => r.body as Array<ProfileWhatsAppConnectionDto>)
    );
  }

  /**
   * Path part for operation deleteProfileWhatsAppConnectionDetails
   */
  static readonly DeleteProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProfileWhatsAppConnectionDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
    profConnectionDetailsId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.DeleteProfileWhatsAppConnectionDetailsPath, 'delete');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('profConnectionDetailsId', params.profConnectionDetailsId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileWhatsAppConnectionDetails(params: {
    profileId: string;
    profConnectionDetailsId: string;
  }): Observable<string> {

    return this.deleteProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateProfileWhatsAppConnectionDetails
   */
  static readonly UpdateProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfileWhatsAppConnectionDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
    body?: Array<ProfileWhatsAppConnectionDto>
  }): Observable<StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.UpdateProfileWhatsAppConnectionDetailsPath, 'patch');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileWhatsAppConnectionDetails(params: {
    profileId: string;
    body?: Array<ProfileWhatsAppConnectionDto>
  }): Observable<Array<ProfileWhatsAppConnectionDto>> {

    return this.updateProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>) => r.body as Array<ProfileWhatsAppConnectionDto>)
    );
  }

  /**
   * Path part for operation fetchWhatsappAnalyticsDetails
   */
  static readonly FetchWhatsappAnalyticsDetailsPath = '/api/v1/whatsapp-analytics/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchWhatsappAnalyticsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchWhatsappAnalyticsDetails$Response(params: {
    profileId: string;
  }
): Observable<StrictHttpResponse<WhatsappAnalyticsDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.FetchWhatsappAnalyticsDetailsPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappAnalyticsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchWhatsappAnalyticsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchWhatsappAnalyticsDetails(params: {
    profileId: string;
  }
): Observable<WhatsappAnalyticsDto> {

    return this.fetchWhatsappAnalyticsDetails$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappAnalyticsDto>) => r.body as WhatsappAnalyticsDto)
    );
  }


}
