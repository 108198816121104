import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {
  WHATSAPP_ANALYTICS_ICON,
  WHATSAPP_BUSINESS_NUM_ICON,
  WHATSAPP_QUALITY_ICON,
  WHATSAPP_MSG_LIMIT_ICON,
  INFO_ICON_IMAGE,
  COPY_IMAGE,
  OPT_OUT,
  FILTER_ICON ,
  DOWNLOAD_ICON_BLUE
} from 'src/app/shared/constants/strings';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { MatPaginator } from '@angular/material/paginator';
import { WhatsappProfileConnectionDetailsControllerService } from 'src/app/services/Whatsapp/services';
import { BciLoaderService } from '@bci-web-core/core';
import { WhatsappDeliveryReportControllerService } from 'src/app/services/Whatsapp-helper/services';
import { TemplateAnalyticsDto } from 'src/app/services/Whatsapp-helper/models/template-analytics-dto';
import { FormGroup, FormControl } from '@angular/forms';
import { DateValidatorService } from 'src/app/providers/date-validator.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-whatsapp-analytics',
  templateUrl: './whatsapp-analytics.component.html',
  styleUrls: ['./whatsapp-analytics.component.scss']
})
export class WhatsappAnalyticsComponent implements OnInit{

  public permissionList: Array<String> = [];
  public whatsappNumIcon: string;
  public whatsappQualityIcon : string;
  public whatsappAnalyticsIcon : string;
  public whatsappMsgLimitIcon : string;
  public infoIcon : string;
  public copyIcon : string;
  public dataSource: any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public wabaNumber: string;
  public msgLimit : string ;
  public qualityScore: string;
  public profileIdSelected : boolean;
  public showSpinner: any;
  public data :TemplateAnalyticsDto[];
  public templateCount:number;
  public optOutCounts:string = '0';
  public optOutIcon:string;
  public filterIcon:string;
  public downloadIcon:string;
  columns: string[] = ['templateName','sent','delivered','read','readRate','qualityScore'];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [5,10,20];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  public dateSelected;
  public dateRangeForm;

  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  constructor( 
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private accountControllerService: AccountControllerService,
    private whatsappDeliveryReportControllerService : WhatsappDeliveryReportControllerService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,
    private loaderService: BciLoaderService, private _matDialog: MatDialog,
    private dateValidatorService: DateValidatorService, private datePipe: DatePipe
    ) { }

  ngOnInit(): void {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.whatsappNumIcon = WHATSAPP_BUSINESS_NUM_ICON;
    this.whatsappQualityIcon = WHATSAPP_QUALITY_ICON;
    this.whatsappAnalyticsIcon = WHATSAPP_ANALYTICS_ICON;
    this.whatsappMsgLimitIcon = WHATSAPP_MSG_LIMIT_ICON;
    this.infoIcon = INFO_ICON_IMAGE;
    this.copyIcon = COPY_IMAGE;
    this.optOutIcon = OPT_OUT;
    this.filterIcon = FILTER_ICON;
    this.downloadIcon = DOWNLOAD_ICON_BLUE;
    this.getAccount();
    this.createDateRangeForm();
  }


  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
          this.profileIdSelected = false;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
          this.profileIdSelected = false;
        }
        if ($event.child === 'status') {
          this.profileId = $event.value;
          this.pageSize = this.pageSizeOptions[0];
          this.pageIndex = 0;
          this.dataSource = [];
          this.getWhatsAppAnalytics(this.profileId);
          this.getTemplatesAnalytics(this.profileId);
          this.getOptout();
        }
      }
    }
  }

  getWhatsAppAnalytics(profileId : string) {
    this.whatsappProfileConnectionDetailsControllerService
        .fetchWhatsappAnalyticsDetails({ profileId: profileId}).subscribe(
        (resp) => {
          if (resp) {
            this.wabaNumber = resp.wabaNumber;
            this.qualityScore = resp.qualityRatingScore;
            this.msgLimit = resp.newMsgLimit;
            this.profileIdSelected = true;
          }
        },
        (err) => {
          this.profileIdSelected = false;
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );

  }

  getTemplatesAnalytics(profileId : string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappDeliveryReportControllerService
        .fetchProfileMappedTemplateAnalytics({ profileId: profileId}).subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.data = resp;
            this.templateCount = this.data.length;
            this.dataSource = new MatTableDataSource<TemplateAnalyticsDto>(this.data.slice(0,5));
          }
          this.loaderService.hideProgressBar(this.showSpinner);
      },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dataSource = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );

  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
        return false;
    }
    return true;
  }

  public copyText(id) {
    const input = document.createElement('input');
    input.value = id;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.responseHandlerService.returnToastMessage('success', 'COPIED_TO_CLIPBOARD');
  }

  public pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    let chunk = this.data.slice(this.pageIndex*(this.pageSize), (this.pageIndex+1)*(this.pageSize));
    this.dataSource = new MatTableDataSource<TemplateAnalyticsDto>(chunk);
  }

  public filterOptout() {
    if(this.dateRangeForm.valid) {
      this._matDialog.closeAll();
      this.getOptout();
    }
  }

  public openFilterOptout(content) {
    this._matDialog.open(content, { maxHeight: '150vh', minWidth: '35vw', disableClose: true});
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public checkDate() {
    if(this.dateRangeForm.controls['fromDate'].value && this.dateRangeForm.controls['toDate'].value ) {
     const isValidDate = this.dateValidatorService.validateDateRange(this.dateRangeForm.controls['fromDate'].value,this.dateRangeForm.controls['toDate'].value );
     if((isValidDate.hasOwnProperty('invalidDateRange') && !isValidDate['invalidDateRange'])|| isValidDate.hasOwnProperty('invalidDate')) 
     {
       this.dateRangeForm.controls['fromDate'].setErrors(null);
     } else {
       this.dateRangeForm.controls['fromDate'].setErrors({
         invalidDate: true,
       });    
     }
    }
    }

  public createDateRangeForm() {
    this.dateRangeForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl('')
    });
  }

  public getOptout() {
     const params = {
        profileId: this.profileId,
    }
    if(this.dateRangeForm && this.dateRangeForm.touched && this.dateRangeForm.valid) {
      params['fromDate'] = this.datePipe.transform(this.dateRangeForm.value['fromDate'],'yyyy-MM-dd');
      params['toDate'] = this.datePipe.transform(this.dateRangeForm.value['toDate'],'yyyy-MM-dd');
    }
        this.whatsappDeliveryReportControllerService.optOutCount(params).subscribe(
    (resp) => {
        this.optOutCounts = resp;
  },
    (err) => {
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    }
  );
  }

  public downloadOptoutReport() {
    const params = {
      profileId: this.profileId,
    }
    if(this.dateRangeForm && this.dateRangeForm.touched && this.dateRangeForm.valid) {
      params['fromDate'] = this.datePipe.transform(this.dateRangeForm.value['fromDate'],'yyyy-MM-dd');
      params['toDate'] = this.datePipe.transform(this.dateRangeForm.value['toDate'],'yyyy-MM-dd');
    }
    this.whatsappDeliveryReportControllerService.downloadWhatsappOptOutReports(params).subscribe(
      (resp) => {
        const FileSaver = require('file-saver');
        const timestamp = new Date();
        FileSaver.saveAs(resp, 'opt_out_report'.concat('_', this.datePipe.transform(timestamp, 'yyyy-MM-dd_hh.mm.ss'), '.xlsx'));
    },
      (err) => {
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public onResetFilter() {
    this.dateRangeForm.reset();
    this.getOptout();
  }
}
