import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { SIM_MANAGE_ICON } from 'src/app/shared/constants/strings';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SolutionTypeControllerService } from 'src/app/services/Platform/services';
import { CommonId } from 'src/app/services/DeviceMgmt/models';
import { SelectionModel } from '@angular/cdk/collections';
import {
  DELETE_IMAGE,
  EDIT_IMAGE,
  SIM_PLAN_ICON,
  WHITE_LISTING_SIM_ICON,
  MORE_OPTION,
  COPY_IMAGE,
  LINKED_ICON,
  NOT_LINKED_ICON
} from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { VendorControllerService } from 'src/app/services/SimMgmt/services/vendor-controller.service';
import { SearchComponentComponent } from 'src/app/shared/search-component/search-component.component';
import { SimMgmtControllerService } from 'src/app/services/SimMgmt/services/sim-mgmt-controller.service';
import { SimControllerService } from 'src/app/services/SimMgmt/services/sim-controller.service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { UtilityControllerService } from 'src/app/services/SimMgmt/services/utility-controller.service';
import { BciLoaderService } from '@bci-web-core/core';
import { SimProviderControllerService } from 'src/app/services/SimMgmt/services/sim-provider-controller.service';
@Component({
  selector: 'app-manage-sim',
  templateUrl: './manage-sim.component.html',
  styleUrls: ['./manage-sim.component.scss']
})
export class ManageSimComponent implements OnInit {
  public showSpinner :any;
  public certificteData;
  public columns: any[];
  public jsonFileLoaded;
  public data;
  public simSearchData;
  public simCount = 0;
  public mode: string;
  public matTabOptions = [];
  public dropDownValues: any = {};
  ignoreList = [
    'id',
    'profiles',
    'vendorId', 'secondaryProfileName', 'simPrice', 'active',
    'customerVendorId', 'simProviderName', 'primaryProfileName', 'simRegistrationId'
  ];
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public formData: any;
  public simFormData: any;
  public currentTabValue = 'sims';
  public simIcon;
  public solutionTypeId: string;
  public businessProfileId: string;
  public permissionList: Array<String> = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  selection = new SelectionModel<CommonId>(true, []);
  searchDto: any = null;
  simProviderSimListDto: any = null;
  public searchData;
  public simData;
  public Icon;
  public editIcon;
  public deleteImage;
  public viewPlanImage;
  public customerVendorId: string;
  public plansData;
  public plansColumns: any[];
  selectAllSims = false;
  public simWhiteListIcon;
  public jsonFileLoadedForWhiteList: string;
  public simReferenceId: string;
  public moreOptionsImage: string;
  public selectedSimPlan: string;
  public copyImage: string;
  linkedImage: string;
  notLinkedImage: string;
  public selectedSimProvider: any;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  @ViewChild(DynamicFormComponent)
  whiteListingDynamicForm: DynamicFormComponent;
   selectedSims: any[];
  constructor(
    private translate: TranslationService,
    private route: ActivatedRoute,
    private dynamicSearchServiceService: DynamicSearchServiceService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private _matDialog: MatDialog,
    private httpService: HttpClient,
    private responseHandlerService: ResponseHandlerService,
    private simManagementControllerService: SimMgmtControllerService,
    private vendorControllerService: VendorControllerService,
    private router: Router,
    private simControllerService: SimControllerService,
    private _snackBar: MatSnackBar, private simProviderControllerService: SimProviderControllerService,
    private utilityControllerService: UtilityControllerService,private loaderService: BciLoaderService
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.jsonFileLoaded = 'sim-search';
    this.pageKey = 'sim';
    this.matTabOptions.push({ label: 'Vendor Sims ', value: 'sim' });
    this.matTabOptions.push({ label: 'Provider Sims', value: 'simProvider' });
    this.httpService
      .get('./assets/form-fields/sim-search.json')
      .subscribe((data) => {
        this.dynamicSearchServiceService.setFormData(data as string[]);
        this.formData = this.dynamicSearchServiceService.generateDynamicForm(
          this.matTabOptions[0].value
        );
        this.dropDownValues =
          this.dynamicSearchServiceService.generateDynamicFormDropdown(
            this.formData
          );
      });
    this.getSolutionType();
    this.deleteImage = DELETE_IMAGE;
    this.simIcon = SIM_MANAGE_ICON;
    this.editIcon = EDIT_IMAGE;
    this.viewPlanImage = SIM_PLAN_ICON;
    this.simWhiteListIcon = WHITE_LISTING_SIM_ICON;
    this.moreOptionsImage = MORE_OPTION;
    this.copyImage = COPY_IMAGE;
    this.linkedImage = LINKED_ICON;
    this.notLinkedImage = NOT_LINKED_ICON;
  }

  public copyText(id){
    const tempElement = document.createElement('textarea');
    tempElement.value = id;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);
    this.responseHandlerService.returnToastMessage('success', 'COPIED_TO_CLIPBOARD');
  }

  /**
   * @ngdoc method
   * @name manageSim#matChange
   *
   * @methodOf
   * sim.controller:manageSim
   *
   * @description
   * to trigger action like fetching data in case tab changes.
   *
   * @param {type} click event
   * @return {type} null
   */
  public matChange(event) {
    this.data = null;
    this.columns = null;
    this.currentTabValue = this.matTabOptions[event.index].value;
    let tabIndex = event.index;
      if (this.currentTabValue === 'sim') {
        this.changeTabs('sim-search', 'sim', tabIndex);
      } else if (this.currentTabValue === 'simProvider') {
        this.changeTabs('sim-search-no-vendor', 'simProvider', tabIndex);
      }
      this.getSolutionType();
  }

  public changeTabs(jsonFileTobeLoaded, tabName, tabIndex) {
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['solutionType'] = [];
    this.dropDownValues['vendor'] = [];
    this.dropDownValues['simStatus'] = [];
    if (this.formData) {
      this.formData = null;
    }
    this.simData = null;
    this.simSearchData = null;
    this.simCount = null;
    this.data = null;
    this.columns = null;
    this.simData = null;
    this.selectedSims = null;
    this.currentTabValue = tabName;
    this.httpService
      .get('./assets/form-fields/' + jsonFileTobeLoaded + '.json')
      .subscribe((data) => {
        this.dynamicSearchServiceService.setFormData(data as string[]);
        this.formData = this.dynamicSearchServiceService.generateDynamicForm(
          this.matTabOptions[tabIndex].value
        );
        this.dropDownValues =
          this.dynamicSearchServiceService.generateDynamicFormDropdown(
            this.formData
          );
      });
  }

  /**
   * @ngdoc method
   * @name ManageSim#getSolutionType
   *
   * @methodOf
   * certificate.component:ManageSim
   *
   * @description
   * gets the list of solution types using GET method
   *
   * @param {type} null
   * @return {type} null
   */
  /* @description: to populate solution type dropdown */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name determineColumnSize
   *
   * @methodOf
   * simModule:managesim
   *
   * @description
   * Description: This method will return large or small based on the column size requirements
   * @param {type} string
   * @return {type} string
   */
  public determineColumnSize(columnName) {
    if (
      columnName === 'mobileNo' ||
      columnName === 'simStatus' ||
      columnName === 'iccid' ||
      columnName === 'simProfiles' ||
      columnName === 'profileType' ||
      columnName === 'simReferenceId' ||
      columnName === 'linkedWithDevice'||
      columnName === 'simId' ||
      columnName === 'primaryProfile' ||
      columnName === 'secondaryProfile' ||
      columnName === 'provider'
    ) {
      return 'column-large';
    } else {
      return 'column-small';
    }
  }

  /**
   * @ngdoc method
   * @name ManageSim#getBusinessProfileOnSolution
   *
   * @methodOf
   * certificate.component:ManageSim
   *
   * @description
   * Description: To populate profile dropdown.
   *
   * @param {type} solutionTypeId
   * @return {type} null
   */
  public getBusinessProfileOnSolution(solutionTypeId: string) {
    this.solutionTypeControllerService
      .findByBusinessProfilesListBasicForSolutionTypeId({
        solutionTypeId: solutionTypeId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  /**
   * @ngdoc method
   * @name ManageSim#updateDropdownValues
   *
   * @methodOf
   * firmware.controller:ManageSim
   *
   * @description
   * Description: To populate dependent dropdown.
   *
   * @param {type} dropdown select event
   * @return {type} null
   */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child) {
        if ($event.child.child === 'businessProfile') {
          this.getBusinessProfileOnSolution($event.value);
          this.solutionTypeId = $event.value;
          this.getSimStatus();
        }
        if ($event.child.child === 'customerVendorId') {
          this.getVendorsList($event.value, this.solutionTypeId);
        }
        if ($event.child.child === 'simProvider') {
          this.getAllSimProviders();
          this.businessProfileId =  $event.value;
       }
      }
    }
  }

  /**
   * @ngdoc method
   * @name ManageSim#searchGroupsEmitter
   *
   * @methodOf
   * sim.controller:ManageSim
   *
   * @description
   * Description:
   *
   * @param {type}
   * @return {type}
   */
  public searchSimEmitter(event) {
    this.data = null;
    this.searchForSim(event);
  }

  /**
   * @ngdoc method
   * @name manageSim#searchForCertificate
   *
   * @methodOf
   * sim.controller:manageSim
   *
   * @description
   * Description: below method is intended to form search object based on basic or advance search criteria
   *
   * @param {searchData}
   * @return {type}
   */
  public searchForSim(searchData) {
    if (searchData['solutionType'] && searchData['businessProfile'] && searchData['customerVendorId']) {
      this.customerVendorId = searchData['customerVendorId'];
      this.searchData = {
        body: {
          businessProfileId: searchData['businessProfile'],
          customerVendorId: searchData['customerVendorId'],
          solutionTypeId: searchData['solutionType'],
          simStatus: searchData['simStatus']
        },
        page: 0,
        limit: this.pageSize,
      };
      this.getSimList(this.searchData);
      this.searchDto = this.searchData;
    } else if (searchData['solutionType'] && searchData['businessProfile'] && searchData['simProvider']) {
      this.searchData = {
        body: {
          bprefId: searchData['businessProfile'],
          simProviderId: searchData['simProvider'],
          solutionTypeId: searchData['solutionType']
        },
        page: 0,
        limit: this.pageSize,
      };
      this.getProviderBasedSimList(this.searchData);
      this.simProviderSimListDto = this.searchData;
      this.selectedSimProvider = searchData['simProvider'];
    }
    else  {
      this.responseHandlerService.returnToastMessage(
        'warning',
        'PLEASE_SELECT_MANDATORY_FIELDS'
      );
    }
  }

  /**
   * @ngdoc method
   * @name manageSim#getSimList
   *
   * @methodOf
   * sim.controller:manageSim
   *
   * @description
   * Description: Wil serch certificates and populates the data in certificate table
   *
   * @param {searchData}
   * @return {type}
   */
  public getSimList(searchData) {
       this.showSpinner = this.loaderService.showProgressBar();
    this.simControllerService.getAllSims1(this.searchData).subscribe(
      (resp) => {
        if (resp && resp['simDetailsList'].length) {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.simData = resp['simDetailsList'];
          this.data = resp['simDetailsList'];
          this.data.paginator = this.paginator;
          this.simCount = resp['totalSimDetails'];
          for (const data of this.simData) {
            data['vendor'] = data['vendorId']['name'];
            data['simReferenceId'] = data['simReferenceId'];
            data['mobileNo'] = data['mobileNo']
              ? this.numberMask(data['mobileNo'])
              : null;
            data['iccid'] = data['iccid'];
            data['profileType'] = data['profileType'];
            if (data['profileType'] === 'DUAL') {
              data['simProfiles'] =
                data['profiles']['primary']['name'] +
                ', ' +
                data['profiles']['secondary']['name'];
            } else {
              data['simProfiles'] =
                data['profiles'] && data['profiles']['primary']['name'];
            }
            data['action'] = null;
          }
          if (this.selectedSims && this.selectedSims.length) {
              this.data.forEach((row) => {
              if (row !== undefined) {
                this.selectedSims.forEach((val) => {
                  if (row['simReferenceId'] === val.id) {
                    this.selection.select(row['simReferenceId']);
                  
                  }
                });
              }
            });
            } 
        
          this.columns = this.filterColumns(Object.keys(this.simData[0]));
          this.columns.unshift('select');
        } else {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.data = null;
          this.responseHandlerService.returnToastMessage(
            'warning',
            'NO_DATA_AVAILABLE'
          );
        }
      },
      (err) => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }
  /**
   * @ngdoc method
   * @name manageSim#numberMask
   *
   * @methodOf
   * device.controller:manageSim
   *
   * @description
   * Description: making mask for mobileNumber
   *
   * @param {type} passing mobileNumber
   * @return {type} string
   */
  public numberMask(mobileNo: string) {
    const mask = mobileNo.substring(2, mobileNo.length - 3).replace(/\d/g, '*');
    return (
      mobileNo.substring(0, 2) + mask + mobileNo.substring(mobileNo.length - 3)
    );
  }

  /**
   * @ngdoc method
   * @name manageSim#pageEvent
   *
   * @methodOf
   * sim.controller:manageSim
   *
   * @description
   * Description: To fetch the next list of sims.
   *
   * @param {type} event
   * @return {type} null
   */
  public pageEvent(event) {
    this.searchData['page'] = event.pageIndex;
    this.searchData['limit'] = event.pageSize;
    if (this.currentTabValue === 'sim') {
      this.getSimList(this.searchData);
    } else {
      this.getProviderBasedSimList(this.searchData);
    }
   // this.selection.clear();
    this.selectAllSims = false;
  }

  /**
   * @ngdoc method
   * @name manageSim#filterColumns
   *
   * @methodOf
   * sim.controller:manageSim
   *
   * @description
   * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
   *
   * @param {type} columns
   * @return {type} list of columns
   */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  /**
   * @ngdoc method
   * @name manageSim#getSolutionType
   *
   * @methodOf
   *sim.controller:manageSim
   *
   * @description
   * Description: To populate solution type dropdown.
   *
   * @param {type} profileId
   * @return {type} null
   */
  public getVendorsList(businessProfileId: string, solutionTypeId: string) {
    this.vendorControllerService
      .getSimVendorsByProfileAndSolutionType({
        businessProfileId: businessProfileId,
        solutionTypeId: solutionTypeId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['customerVendorId'] = resp;
          } else {
            this.dropDownValues['customerVendorId'] = [];
          }
        },
        (err) => {
          this.dropDownValues['customerVendorId'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  public gotoConfirmDialog(data, mode?, content?, simPlanDetails?) {
    this.mode = mode;
    let message;
    switch (mode) {
      case 'delete':
        if (this.permissionList.indexOf('SIM_D') > -1) {
          this.simData = data;
          message = this.translate.translateErrorMessages(
            'DELETE_SELECTED_SIM'
          );
        }
        break;
      case 'activate':
        message = this.translate.translateErrorMessages(
          'ACTIVATE_CONFIRM_MESSAGE'
        );
        break;
      case 'deactivate':
          if (this.currentTabValue === 'sim') {
          message = this.translate.translateErrorMessages(
            'DEACTIVATE_CONFIRM_MESSAGE'
          ); 
        } else if (this.currentTabValue === 'simProvider') {
          message = this.translate.translateErrorMessages(
            'DEACTIVATE_SIM_MSG'
          ); 
        }
        break;
      case 'planList':
        message = this.translate.translateErrorMessages(
          'ACTIVATE_CONFIRM_MESSAGE_BULK'
        );
        break;
    }

    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: { message: message },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        switch (mode) {
          case 'delete':
            if (this.currentTabValue === 'sim') {
              this.deleteSim(this.simData);
            } else if (this.currentTabValue = 'simProvider') {
              this.deleteProviderBasedSim(this.simData);
            }
            break;
          case 'activate':
            if (this.currentTabValue === 'sim') {
              if (data && data['simStatus'] && data['simStatus'] === 'ACTIVATED') {
                this.changeSimPlans(data, simPlanDetails);
              } else {
                this.activatePlan(data, simPlanDetails);
              }
            } else if (this.currentTabValue = 'simProvider') {
              this.activateProviderBasedSim(data);
            }
            break;
          case 'deactivate':
            if (this.currentTabValue === 'sim') {
              this.deactivateSims();
            } else if (this.currentTabValue = 'simProvider') {
              this.deactivateProviderBasedSim(data);
            }
            break;
          case 'planList':
            this.getSimPlans(data, content);
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }
  public deleteSim(simData) {
    this.showSpinner = this.loaderService.showProgressBar();
    let SimDeleteDto;
    if (simData) {
      SimDeleteDto = {
        bprefId: simData && simData['bprefId'],
        simReferenceIds: [simData['simReferenceId']],
      };
    } else if (this.selection.selected) {
      SimDeleteDto = {
        bprefId: simData && simData['bprefId'],
        simReferenceIds: this.selection.selected,
      };
    }
    this.simControllerService.deleteSims({ body: SimDeleteDto }).subscribe(
      (resp) => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'success',
          'SIM_DELETE_SUCCESS'
        );
        this.getSimList(this.searchDto);
        if (this.selection.selected.length) {
          this.selection.clear();
        }
      },
      (err) => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public abortAction() {
    this._matDialog.closeAll();
    this.simData['active'] = !this.simData['active'];
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public editOrActivateSim(data, mode) {
    this.simData = data;
    this.mode = mode;
    switch (mode) {
      case 'edit':
        if (this.currentTabValue === 'sims') {
          if (this.permissionList.indexOf('SIM_U') > -1) {
            this.jsonFileLoaded = 'sim-individual-registration';
            if (data['profileType'] === 'DUAL') {
              this.router.navigate([
                'sim/sim-registration',
                {
                  simReferenceId: data['simReferenceId'],
                  profileType: 'DUAL',
                  primaryProfile: data['profiles']['primary']['id'],
                  secondaryProfile: data['profiles']['secondary']['id'],
                  jsonFileLoaded: this.jsonFileLoaded
                },
              ]);
            } else {
              this.router.navigate([
                'sim/sim-registration',
                {
                  simReferenceId: data['simReferenceId'],
                  profileType: 'SINGLE',
                  primaryProfile: data['profiles']['primary']['id'],
                  jsonFileLoaded: this.jsonFileLoaded
                },
              ]);
            }
          }
        } else if (this.currentTabValue = 'simProvider') {
          if (this.permissionList.indexOf('SIM_U') > -1) {
            this.jsonFileLoaded = 'sim-individual-registration-no-vendor';
            if (data['profileType'] === 'DUAL') {
              this.router.navigate([
                'sim/sim-registration',
                {
                  simRegistrationId: data['simId'],
                  profileType: 'DUAL',
                  primaryProfile: data['primaryProfile'],
                  secondaryProfile: data['secondaryProfile'],
                  jsonFileLoaded: this.jsonFileLoaded,
                  businessProfileId: this.businessProfileId,
                  solutionTypeId: this.solutionTypeId,
                  simProviderId : this.selectedSimProvider
                },
              ]);
            } else {
              this.router.navigate([
                'sim/sim-registration',
                {
                  simRegistrationId: data['simId'],
                  profileType: 'SINGLE',
                  primaryProfile: data['primaryProfile'],
                  jsonFileLoaded: this.jsonFileLoaded,
                  businessProfileId: this.businessProfileId,
                  solutionTypeId: this.solutionTypeId,
                  simProviderId : this.selectedSimProvider
                },
              ]);
            }
          }
        }
        break;
    }
  }

  public getSimPlans(data, content) {
    const planListData = [];
       this.showSpinner = this.loaderService.showProgressBar();
    if (data) {
      if (data['simReferenceId']) {
        this.getIndividualSimDetails(data['simReferenceId']);
      }
      this.customerVendorId = this.customerVendorId;
      this.simManagementControllerService
        .getSimPlans({
          customerVendorId: this.customerVendorId,
        })
        .subscribe(
          (resp) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.plansData = resp;
            resp.forEach((row) => {
              planListData.push({
                planName: row['planName'],
                planDescription: row['planDescription'],
                price: row['price'],
              });
            });
            this.plansColumns = this.filterColumns(
              Object.keys(planListData[0])
            );
            this.plansColumns.push('action');
            this._matDialog.open(content, {
              maxWidth: '800px',
              data: data,
            });
          },
          (err) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }

  public activatePlan(data,simPlanDetails) {
    const planData = simPlanDetails;
    const simData = data;
    this.showSpinner = this.loaderService.showProgressBar();
    let requestDto = {};
    if (simData && simData.length) {
      requestDto = {
        customerVendorId: this.customerVendorId,
        planCode: planData['planCode'],
        simReferenceIds: simData,
      };

      this.simManagementControllerService
        .activateSim({
          body: requestDto,
        })
        .subscribe(
          (resp) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_ACTIVATE_SUCCESS'
            );
            this.onClose();
          },
          (err) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            if (err.status === 201 && err.error.text === 'SUCCESS') {
              this.responseHandlerService.returnToastMessage(
                'success',
                'SIM_ACTIVATE_SUCCESS'
              );
              this.selection.clear();
              this.getSimList(this.searchDto);
              this.onClose();
              if (this.selection.selected.length) {
                this.selection.clear();
              }
            } else if (err.status === 207) {
              this._snackBar
                .open(
                  this.translate.translateErrorMessages('SIM_ACTIVATE_FAILURE'),
                  'DOWNLOAD',
                  {
                    verticalPosition: 'top',
                    panelClass: ['warning'],
                  }
                )
                .onAction()
                .subscribe(() => {
                  this.downloadSimOperationDetails(err.error.text);
                });
            } else {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'error',
                err.error.message
              );
            }
          }
        );
    }
  }
  public deactivateSims() {
       this.showSpinner = this.loaderService.showProgressBar();
    const simrefIdArray = this.selection.selected;
    const idArray = [];
    simrefIdArray.forEach((simrefId) => {
      if (simrefId) {
        idArray.push(simrefId);
      }
    });
    const requestDto = {
      simReferenceIds: idArray,
    };
    this.simManagementControllerService
      .deActivateSim({
        body: requestDto,
      })
      .subscribe(
        (resp) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'SIM_DEACTIVATE_SUCCESS'
          );
          this.onClose();
          this.selection.clear();
          this.getSimList(this.searchDto);
          if (this.selection.selected.length) {
            this.selection.clear();
          }
        },
        (err) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          if (err.status === 201 && err.error.text === 'SUCCESS') {
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_DEACTIVATE_SUCCESS'
            );
            this.selection.clear();
            this.onClose();
            this.simReferenceId = '';
            this.getSimList(this.searchDto);
            if (this.selection.selected.length) {
              this.selection.clear();
            }
          } else if (err.status === 207) {
            this._snackBar
              .open(
                this.translate.translateErrorMessages('SIM_DEACTIVATE_FAILURE'),
                'DOWNLOAD',
                {
                  verticalPosition: 'top',
                  panelClass: ['warning'],
                }
              )
              .onAction()
              .subscribe(() => {
                this.downloadSimOperationDetails(err.error.text);
              });
            this.selection.clear();
            this.getSimList(this.searchDto);
            if (this.selection.selected.length) {
              this.selection.clear();
            }
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        }
      );
  }

  public onClose() {
    this.selectedSimPlan = '';
    this._matDialog.closeAll();
    this.jsonFileLoadedForWhiteList = '';
  }

  public selectAllSimsCheckBox(event,simReferenceId) {
    this.getSelectUnselectSims(event, simReferenceId);
    if (this.isAllSelected()) {
      this.selectAllSims = !this.selectAllSims;
    }
  }

  
  /**
   * @ngdoc method
   * @name ManageDeviceComponent#getSelectUnselectSims
   * @methodOf
   * device.controller:getSelectUnselectSims
   * @description
   * to get the previous pagination select and unselect sims
   */


   public getSelectUnselectSims(event, simReferenceId) {
    if (this.selectedSims && this.selectedSims.length) {
      if (!event.checked) {
        this.selectedSims.splice(
          this.selectedSims.findIndex((val) => val.id === simReferenceId),
          1
        );
      }
      this.selectedSims = [
        ...this.selection['selected'].map((device) => {
          return { id: device };
        }),
        ...this.selectedSims,
      ];
    } else {
      this.selectedSims = this.selection['selected'].map((device) => {
        return { id: device };
      });
    }
    this.selectedSims = this.selectedSims.filter(
      (value, index, arr) =>
        arr.findIndex((value2) => value2.id === value.id) === index
    );
    this.selectAllSims = false;
  }

  public isAllSelected() {
    let numRows;
    const numSelected = this.selection.selected.length;
    if (this.currentTabValue === 'sim') {
       numRows = this.data.length;
    } else if (this.currentTabValue === 'simProvider') {
       numRows = this.simSearchData.length;
    }   
    return numSelected === numRows;
  }

  public masterToggle() {
    if (this.currentTabValue === 'sim') {
      this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) =>
          this.selection.select(row['simReferenceId'])
        );
    } else if (this.currentTabValue === 'simProvider') {
      this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) =>
          this.selection.select(row['simId'])
        );
    }
  }

  /**
   * @ngdoc method
   * @name ManageSimComponent#selectSims
   *
   * @methodOf
   * sim.controller:selectSims
   *
   * @description
   * select or unselect all sims in the table.
   *
   * @param {type} null
   * @return {type} null
   */

  selectSims(event) {
    this.selectAllSims = !this.selectAllSims;
    if (event.checked) {
        if (this.currentTabValue === 'sim') {
          this.data.forEach((row) => {
            if (row !== undefined) {
              this.selection.select(row['simReferenceId']);
            }
        });
        } else if (this.currentTabValue === 'simProvider') {
          this.simSearchData.forEach((row) => {
            if (row !== undefined) {
              this.selection.select(row['simId']);
            }
        });
        }
    } else {
      this.selection.clear();
    }
  }

  public openModalForWhiteListing(content, simReferenceId) {
    this.plansData = null;
    this.jsonFileLoadedForWhiteList = 'sim-whitelisting';
    this.simReferenceId = simReferenceId;
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '25vw',
      disableClose: true,
      data: null,
    });
  }

  public whiteListingSim(data) {
    const simArray = {};
    const SimWhiteListDto = [];
       this.showSpinner = this.loaderService.showProgressBar();
    if (data['phoneNumber']) {
      simArray['simReferenceId'] = this.simReferenceId;
      simArray['phoneNumber'] = data['phoneNumber'];
      SimWhiteListDto.push(simArray);
      this.simManagementControllerService
        .smsWhiteListSim({ body: SimWhiteListDto })
        .subscribe(
          (resp) => {
            if (resp && resp['status'] === 200) {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'success',
                'SIM_WHITELISTING_SUCCESS'
              );
              this.onClose();
              this.simReferenceId = '';
            } else if (resp['status'] === 400) {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'error',
                resp['remarks']
              );
            }
          },
          (err) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            if (err.status === 201 && err.error.text === 'SUCCESS') {
              this.responseHandlerService.returnToastMessage(
                'success',
                'SIM_WHITELISTING_SUCCESS'
              );
              this.selection.clear();
              this.getSimList(this.searchDto);
              this.onClose();
              this.simReferenceId = '';
              if (this.selection.selected.length) {
                this.selection.clear();
              }
            } else if (err.status === 207) {
              this._snackBar
                .open(
                  this.translate.translateErrorMessages(
                    'WHITELISTING_FAILURE_MSG'
                  ),
                  'DOWNLOAD',
                  {
                    verticalPosition: 'top',
                    panelClass: ['warning'],
                  }
                )
                .onAction()
                .subscribe(() => {
                  this.downloadSimOperationDetails(err.error.text);
                });
            } else {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'error',
                err.error.message
              );
            }
          }
        );
    }
  }
  public resetForm($event) {
    if (this.whiteListingDynamicForm) {
      this.whiteListingDynamicForm.ngOnInit();
    }
  }
  public changeSimPlans(data, simPlanDetails) {
    const planData = simPlanDetails;
    const simData = data;
    const reqData = {};
    const requestDto = [];
    reqData['simReferenceId'] = simData['simReferenceId'];
    reqData['planCode'] = planData['planCode'];
    requestDto.push(reqData);
       this.showSpinner = this.loaderService.showProgressBar();
    this.simManagementControllerService
      .changeSimPlan({
        body: requestDto,
      })
      .subscribe(
        (resp) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'CHANGE_PLAN_SUCCESS_MSG'
          );
          this.onClose();
        },
        (err) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          if (err.status === 201 && err.error.text === 'SUCCESS') {
            this.responseHandlerService.returnToastMessage(
              'success',
              'CHANGE_PLAN_SUCCESS_MSG'
            );
            this.selection.clear();
            this.onClose();
            this.simReferenceId = '';
            this.getSimList(this.searchDto);
            if (this.selection.selected.length) {
              this.selection.clear();
            }
          } else if (err.status === 207) {
            this._snackBar
              .open(
                this.translate.translateErrorMessages(
                  'CHANGE_PLAN_FAILURE_MSG'
                ),
                'DOWNLOAD',
                {
                  verticalPosition: 'top',
                  panelClass: ['warning'],
                }
              )
              .onAction()
              .subscribe(() => {
                this.downloadSimOperationDetails(err.error.text);
              });
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        }
      );
  }
  /**
   * @ngdoc method
   * @name manageSim#downloadFile
   *
   * @methodOf
   * certificate.component:manageSim
   *
   * @description
   * Description: Helper method to convert responce of download certificateapi into downloadable file
   *
   * @param {type} data {any}, fileName {string}
   * @return {type} null
   */
  public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob([data], { type: 'text/plain' });
    FileSaver.saveAs(blob, fileName);
  }

  /**
   * @ngdoc method
   * @name manageSim#generateFileName
   *
   * @methodOf
   * certificate.component:manageSim
   *
   * @description
   * Description: Helper method to generate file name as 'Response.txt' for root certificate.
   *
   * @param {type} filename {string}, format {zip}
   * @return {type} null
   */
  public generateFileName(fileName, format) {
    return fileName + '.' + format;
  }

  public downloadSimOperationDetails(fileRefId) {
    this.utilityControllerService
      .downloadJsonResponseFile({ fileRefId: fileRefId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.downloadFile(resp, this.generateFileName('Response', 'txt'));
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getIndividualSimDetails(simReferenceId) {
    this.simControllerService.getSimDetailsBySimRefId({
      simRefId: simReferenceId
    }).subscribe( simResp => {
      if (simResp  && simResp['planCode']) {
        this.selectedSimPlan = simResp['planCode'];
      } else {
        this.selectedSimPlan = '';
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getSimStatus() {
    this.simManagementControllerService
      .getAllSimStatus()
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['simStatus'] = resp;
          } else {
            this.dropDownValues['simStatus'] = [];
          }
        },
        (err) => {
          this.dropDownValues['simStatus'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getAllSimProviders() {
    this.simProviderControllerService
      .getAllSimProviders()
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['simProvider'] = resp;
          } else {
            this.dropDownValues['simProvider'] = [];
          }
        },
        (err) => {
          this.dropDownValues['simProvider'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        });
    }

    public getProviderBasedSimList(searchData) {
      this.showSpinner = this.loaderService.showProgressBar();
      this.simProviderControllerService.getAllSims(this.searchData).subscribe(
        (resp) => {
          if (resp && resp['simDetailsList'].length) {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.simData = resp['simDetailsList'];
            this.simSearchData = resp['simDetailsList'];
            this.simSearchData.paginator = this.paginator;
            this.simCount = resp['totalSimDetails'];
            for (const data of this.simSearchData) {
              data['simId'] = data['simRegistrationId'];
              data['mobileNo'] = data['mobileNo']
                ? this.numberMask(data['mobileNo'])
                : null;
              data['iccid'] = data['iccid'];
              data['profileType'] = data['profileType'];
              data['provider'] = data['simProviderName'];
              data['primaryProfile'] = data['primaryProfileName'];
              data['secondaryProfile'] = data['secondaryProfileName'];
              data['status'] = data['active'] === true ? 'ACTIVATED' : 'DEACTIVATED';
              data['action'] = null;
            }
            if (this.selectedSims && this.selectedSims.length) {
                this.simSearchData.forEach((row) => {
                if (row !== undefined) {
                  this.selectedSims.forEach((val) => {
                    if (row['simId'] === val.simId) {
                      this.selection.select(row['SimId']);
                    }
                  });
                }
              });
            }
            this.columns = this.filterColumns(Object.keys(this.simData[0]));
            this.columns.unshift('select');
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.simSearchData = null;
            this.responseHandlerService.returnToastMessage(
              'warning',
              'NO_DATA_AVAILABLE'
            );
          }
        },
        (err) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }

public deleteProviderBasedSim(simData) {
  this.showSpinner = this.loaderService.showProgressBar();
  let SimDeleteDto;
  if (simData) {
    SimDeleteDto = {
        bprefId: this.businessProfileId,
        solutionTypeId: this.solutionTypeId,
        simRegistrationIds: [simData['simId']],
      };
  } else if (this.selection.selected) {
      SimDeleteDto = {
      bprefId: this.businessProfileId,
      solutionTypeId: this.solutionTypeId,
      simRegistrationIds: this.selection.selected,
    };
    }
    this.simProviderControllerService.deleteBulkSims({ body: SimDeleteDto }).subscribe(
      (resp) => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'success',
          'SIM_DELETE_SUCCESS'
        );
        this.getProviderBasedSimList(this.searchDto);
        if (this.selection.selected.length) {
          this.selection.clear();
        }
      },
      (err) => {
         this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public activateProviderBasedSim(simData) {
    this.showSpinner = this.loaderService.showProgressBar();
    let SimDeleteDto;
    const idArray = [];
    let simIDArray = this.selection.selected;
    simIDArray.forEach((simId) => {
      if (simId) {
        idArray.push(simId);
      }
    });
      SimDeleteDto = {
          bprefId: this.businessProfileId,
          solutionTypeId: this.solutionTypeId,
          simRegistrationIds: idArray,
        };      
      this.simProviderControllerService.activateSims({ body: SimDeleteDto }).subscribe(
        (resp) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'ACTIVATE_SIM_SUCCESS'
          );
          this.getProviderBasedSimList(this.searchDto);
          if (this.selection.selected.length) {
            this.selection.clear();
          }
        },
        (err) => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
    public deactivateProviderBasedSim(simData) {
      this.showSpinner = this.loaderService.showProgressBar();
      let SimDeleteDto;
      const idArray = [];
      let simIDArray = this.selection.selected;
      simIDArray.forEach((simId) => {
        if (simId) {
          idArray.push(simId);
        }
      });
        SimDeleteDto = {
            bprefId: this.businessProfileId,
            solutionTypeId: this.solutionTypeId,
            simRegistrationIds: idArray,
          };      
        this.simProviderControllerService.deactivateSims({ body: SimDeleteDto }).subscribe(
          (resp) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'DEACTIVATE_SIM_SUCCESS'
            );
            this.getProviderBasedSimList(this.searchDto);
            if (this.selection.selected.length) {
              this.selection.clear();
            }
          },
          (err) => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
      }
}
