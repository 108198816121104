/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslationService } from 'src/app/providers/translation-service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  JOBNAME_MAXLENGTH,
  TIMEINTERVAL_MAXLENGTH,DEFAULT_ICON,CIRCLE_SELECTED_ICON,POLYGON_SELECTED_ICON,TERRAIN_ICON,SATELLITE_ICON,LAYERS_ICON, CONCURRENCY, TIMEINTERVAL_MINLENGTH, TIMEINTERVAL, INFO_ICON_IMAGE,
  CONCURRENCY_TYPE, CIRCLE_ICON,POLYGON_ICON, OPERATING_TYPE, CONCURRENCY_MINLENGTH, CONCURRENCY_MAXLENGTH, CREATE, FOTA_ICON,
   ERROR_THRESHOLD_TYPE, CLOSE_ICON, BACK_LEFT_SMALL,WHITE_DELETE_ICON,WATCH_ON
} from 'src/app/shared/constants/strings';
import { FirmwareControllerService } from 'src/app/services/DeviceMgmt/services/firmware-controller.service';
import { UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { FotaJobControllerService } from 'src/app/services/DeviceMgmt/services/fota-job-controller.service';
import { FotaSelectionControllerService } from 'src/app/services/DeviceMgmt/services';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { FotaDeviceListComponent } from 'src/app/shared/fota-device-list/fota-device-list.component';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { BusinessProfileControllerService, PlatformAdminUtilityControllerService, TenantControllerService } from 'src/app/services/Platform/services';
import { EnvService } from 'src/app/env.service';
import { BciLoaderService } from '@bci-web-core/core';
import { GeoFenceControllerService } from 'src/app/services/DeviceMgmt/services/geo-fence-controller.service';
import { MapsAPILoader } from '@agm/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonId } from 'src/app/services/DeviceMgmt/models';
import { Observable } from 'rxjs';
import{ EventSourcePolyfill } from 'event-source-polyfill';
import { KeycloakService } from 'keycloak-angular';
import { MatSelect } from '@angular/material/select';

/**
  * @ngdoc component
  * @name fota.component:createFota
  *
  *
  * @description
  * description: To create a fota job.
  *
*/
@Component({
  selector: 'app-create-fota',
  templateUrl: './create-fota.component.html',
  styleUrls: ['./create-fota.component.scss']
})
export class CreateFotaComponent implements OnInit  {
  public dropDownValues: any = {};
  submitted = false;
  timeDifference;
  isSchedularValid;
  date = new Date();
  weekDays: string[];
  fotaForm: UntypedFormGroup;
  currentTime = Date().slice(16, 21);
  fotaState;
  public showSpinner :any;
  solutionTypeId;
  fotaJobId;
  fotaData;
  timeInterval_minlength = TIMEINTERVAL_MINLENGTH;
  timeInterval_maxlength = TIMEINTERVAL_MAXLENGTH;
  concurrency_minlength = CONCURRENCY_MINLENGTH;
  concurrency_maxlength = CONCURRENCY_MAXLENGTH;
  concurrencyValue = CONCURRENCY_MINLENGTH;
  @ViewChild('search') public searchElementRef: ElementRef;
  geoFenceTableRef: MatTable<any>;
  numSelectedRows: number=0;
  polygon: any;
  circle: any;
  backLeftImage: any;
  editExistingJob: boolean;
  existingGeofenceList: any;
  deleteImage: string;
  viewImage: any;
  ellipseIcon:any;
  polygonIcon:any;
  tags: any;
  selectedTags: any;
  deviceTypes: any;
  selectedDeviceTypeId: any;
  existViewPolygon: google.maps.Polygon;
  existViewCircle: google.maps.Circle;
  geofencelist: any;
  existingGeofenceDetail: any;
  maptype: any;
  proceedToFota: boolean = true;
  selectUnselectFlag: boolean = false;
  defaultIcon: any;
  satelliteIcon: any;
  layersIcon: string;
  terrainIcon: any;
  circleSelectedIcon: any;
  polygonSelectedIcon: any;
  existingGeofenceId: string;
    noDeviceDataFound: boolean=true;
  @ViewChild('mytable') set content(content: MatTable<any>) {
    if(content) { // initially setter gets called with undefined
        this.geoFenceTableRef = content;
    }
 }
  selectedCriteria = false;
  disableBtn = false;
  checkTodayDate;
  isNotToday = false;
  public pageKey: string;
  public fotaIcon: string;
  public matTabOptions = [];
  public infoIconImage;
  asideMenus: Array<AsideCard>;
  tenantValues:any;
  businessProfileValues:any;
  tenantId: string;
  profileId: any;
  geoFenceEnable: boolean;
  myMap: any;
  polygonArray: any[];
  geoFenceForm: UntypedFormGroup;
  Devicelocations: any;
  deviceList: any;
  geoFenceId: any;
  overlayCompleteEvent: any;
  placeSearch:any
  lat: number;
  long: number;
  geoCoder: google.maps.Geocoder;
  zoom:any;
  geoFenceBlock: boolean;
  columns: string[];
  data: any;
  selection = new SelectionModel<CommonId>(true, []);
  selectAllDevices: boolean=false;
  deviceListSection: boolean;
  geoFencedeviceList: any;
  closeIcon;
  Ota_Details: any;
  circleRadius: number;
  CircleArray: any[];
  mygeofencetabelref: any;
  minZoomLevel=3;
  @ViewChild('selectMultiTagsDropdown') selectMultiTagsDropdown: MatSelect;
  constructor(private translate: TranslationService,
    private responseHandlerService: ResponseHandlerService, private router: Router, private route: ActivatedRoute,
    private firmwareControllerService: FirmwareControllerService, private formBuilder: UntypedFormBuilder,
    private objectToIdConversionService: ObjectToIdConversionService, private fotaJobControllerService: FotaJobControllerService,
    private _matDialog: MatDialog, private fotaSelectionControllerService: FotaSelectionControllerService,
    private rightsideNavitemsService: RightsideNavitemsService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private env: EnvService,private loaderService: BciLoaderService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private tenantControllerService: TenantControllerService, private mapsAPILoader: MapsAPILoader,
    private GeoFenceControllerService:GeoFenceControllerService, 
    private ngZone: NgZone,  private keycloak: KeycloakService,
    private changeDetectionRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.getTenant();
    this.pageKey = 'fota';
    this.fotaIcon = FOTA_ICON;
    this.infoIconImage = INFO_ICON_IMAGE;
    this.closeIcon = CLOSE_ICON;
    this.backLeftImage =BACK_LEFT_SMALL;
    this.deleteImage =WHITE_DELETE_ICON;
    this.ellipseIcon=CIRCLE_ICON;
    this.polygonIcon=POLYGON_ICON;
    this.defaultIcon= DEFAULT_ICON;
    this.satelliteIcon=SATELLITE_ICON;
    this.terrainIcon=TERRAIN_ICON;
    this.layersIcon = LAYERS_ICON;
    this.viewImage = WATCH_ON;
    this.circleSelectedIcon= CIRCLE_SELECTED_ICON;
    this.polygonSelectedIcon=POLYGON_SELECTED_ICON;
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.fotaForm = this.formBuilder.group({
      jobName: ['', [Validators.required, Validators.maxLength(JOBNAME_MAXLENGTH)]],
      firmwareArtf: ['', Validators.required],
      jobStartDate: [this.date, Validators.required],
      jobStartTime: [this.currentTime, Validators.required],
      fotaJobTimingsDtoList: this.formBuilder.array([]),
      fotaEmailList: this.formBuilder.array([this.createEmailFormControl()]),
      operatingType: ['', Validators.required],
      concurrency: [''],
      concurrencyType: [''],
      actionType: [''],
      timeForcedStartTime: [this.currentTime, Validators.required],
      timeForcedStartDate: [this.date, Validators.required],
      campaignType: ['', Validators.required],
      numberOfGroups: [''],
      triggerThreshold: [''],
      errorThreshold: ['', [Validators.min(0), Validators.max(100)]],
      errorThresholdType: [ERROR_THRESHOLD_TYPE],
      timeInterval: ['']
    });
    this.getConcurrencyType();
    this.getOperatingType();
    this.getCampaignType();
    this.addFormGroups();
    this.fotaState = history.state?history.state:this.Ota_Details;
    this.Ota_Details?this.disableBtn=false:null;
    if (this.fotaState.solutionTypeDetails && this.fotaState.deviceTypeDetails) {
      if (this.fotaState.deviceInterfaceProvider['name'] === this.env['providerAws']) {
        this.getFirmwareList(this.fotaState.solutionTypeDetails['id'], this.fotaState.deviceTypeDetails['id'],
        this.fotaState.deviceInterfaceProvider['id'], this.fotaState.iotHubDetails['id']);
      } else {
        this.getFirmwareList(this.fotaState.solutionTypeDetails['id'], this.fotaState.deviceTypeDetails['id'],
        this.fotaState.deviceInterfaceProvider['id']);
      }
    }
    this.solutionTypeId = this.fotaState['jobId'] ? this.fotaState['solutionTypeDetails']['id']
      : this.route.snapshot.paramMap.get('solutionTypeId');
    this.fotaJobId = this.fotaState['jobId'] ? this.fotaState['jobId'] : this.route.snapshot.paramMap.get('fotaJobId');
    if (this.solutionTypeId && this.fotaJobId) {
      this.selectedCriteria = false;
      this.getFotaDetails(this.fotaJobId);
      this.editExistingJob =true;
    }
    if (!this.fotaJobId && !this.fotaState['searchDto']) {
      this.fotaSelectionControllerService.getFotaSelection()
        .subscribe(
          resp => {
            if (resp) {
              this.selectedCriteria = true;
              this.fotaState = resp;
              if (this.fotaState.deviceInterfaceProvider['name'] === this.env['providerAws']) {
                this.getFirmwareList(this.fotaState.solutionTypeDetails['id'], this.fotaState.deviceTypeDetails['id'],
                this.fotaState.deviceInterfaceProvider['id'], this.fotaState.iotHubDetails['id']);
              } else {
                this.getFirmwareList(this.fotaState.solutionTypeDetails['id'], this.fotaState.deviceTypeDetails['id'],
                this.fotaState.deviceInterfaceProvider['id']);
              }
            } else {
              this.fotaForm.disable();
              this.disableBtn = true;
            }
          },
          err => {
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.fotaForm.disable();
            this.disableBtn = true;
          }
        );
    }

   
    this.geoFenceForm = this.formBuilder.group({
      geoFenceName: ['',Validators.required]
       });

  }

  /**
      * @ngdoc method
      * @name createFota#addFormGroups
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Invokes a function to add dynamic formgroups.
      *
      * @param {type} null
      * @return {type} null
  */
  addFormGroups() {
    this.weekDays = ['EVERYDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    this.weekDays.forEach(day => {
      if (day === 'EVERYDAY') {
        this.addGroup(this.formBuilder.group({
          dayOfWeek: [true],
          jobStartTime: [''],
          jobEndTime: ['']
        }));
      } else {
        this.addGroup(this.formBuilder.group({
          dayOfWeek: [{ value: '', disabled: true }],
          jobStartTime: [{ value: '', disabled: true }],
          jobEndTime: [{ value: '', disabled: true }]
        }));
      }
    });
  }

  /**
      * @ngdoc method
      * @name createFota#addGroup
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Add formgroups to fotaForm.
      *
      * @param {type} null
      * @return {type} null
  */
  addGroup(dynamicFormGroup) {
    (<UntypedFormArray>this.fotaForm.get('fotaJobTimingsDtoList')).push(dynamicFormGroup);
  }

  /**
      * @ngdoc method
      * @name createFota#addEmailControl
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Add formcontrol to fotaEmailList formArray.
      *
      * @param {type} null
      * @return {type} null
  */
  public addEmailControl() {
    const emailControl = this.createEmailFormControl();
    (<UntypedFormArray>this.fotaForm.get('fotaEmailList')).push(emailControl);
  }

  /**
      * @ngdoc method
      * @name createFota#removeEmail
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Removes email from fotaEmailList formArray.
      *
      * @param {type} index
      * @return {type} null
  */
  public removeEmail(index: number) {
    (<UntypedFormArray>this.fotaForm.get('fotaEmailList')).removeAt(index);
  }

  /**
      * @ngdoc method
      * @name createFota#removeEmail
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Returns dynamic formcontrol.
      *
      * @param {type} null
      * @return {type} null
  */
  private createEmailFormControl() {
    return this.formBuilder.control('', [Validators.required,
    Validators.pattern('^[a-z0-9A-Z]+(\\.[_a-z0-9A-Z]+)*@[a-z0-9-A-Z]+(\\.[a-z0-9-A-Z]+)*(\\.[a-zA-Z]{2,15})$')]);
  }

  /**
      * @ngdoc method
      * @name createFota#checkboxChange
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Enables/Disables form fields based on checkbox selection.
      *
      * @param {type} index
      * @return {type} null
  */
  checkboxChange(indexNumber) {
    const formArray: UntypedFormArray = this.fotaForm.get('fotaJobTimingsDtoList') as UntypedFormArray;
    if (indexNumber === 0) {
      // if everyday checkbox is true - disable all other fields except everyday row
      if (formArray.controls[indexNumber]['controls']['dayOfWeek'].value) {
        Object.keys(formArray.controls[indexNumber]['controls']).forEach(key => {
          formArray.controls[indexNumber]['controls'][key].enable();
        });
        formArray.controls.forEach((group, index) => {
          if (index !== 0) {
            Object.keys(group['controls']).forEach(key => {
              group['controls'][key].disable();
              group['controls'][key].patchValue('');
            });
          }
        });
        this.checkTimeDifference(formArray, indexNumber);
        this.isSchedularValid = false;
      } else {
        // if everyday checkbox is false - disable everyday row time fields
        Object.keys(formArray.controls[indexNumber]['controls']).forEach(key => {
          if (key !== 'dayOfWeek') {
            formArray.controls[indexNumber]['controls'][key].disable();
            formArray.controls[indexNumber]['controls'][key].patchValue('');
          }
        });
        // enable all other checkbox fields
        formArray.controls.forEach((group, index) => {
          if (index !== 0) {
            Object.keys(group['controls']).forEach(key => {
              if (key === 'dayOfWeek') {
                group['controls'][key].enable();
              }
            });
          }
        });
        this.timeDifference = false;
      }
    } else {
      // if either of monday - sunday checkbox value is true then enable time fields of that particular day and add validation dynamically
      if (formArray.controls[indexNumber]['controls']['dayOfWeek'].value) {
        Object.keys(formArray.controls[indexNumber]['controls']).forEach(key => {
          formArray.controls[indexNumber]['controls'][key].enable();
          formArray.controls[indexNumber]['controls'][key].setValidators([Validators.required]);
          formArray.controls[indexNumber]['controls'][key].updateValueAndValidity();
          this.checkTimeDifference(formArray, indexNumber);
          this.isSchedularValid = false;
        });
      } else {
        // if either of monday - sunday checkbox value is false then disable time fields of that particular day
        Object.keys(formArray.controls[indexNumber]['controls']).forEach(key => {
          if (key !== 'dayOfWeek') {
            formArray.controls[indexNumber]['controls'][key].disable();
            formArray.controls[indexNumber]['controls'][key].patchValue('');
          }
        });
        this.timeDifference = false;
      }
    }
  }

  /**
      * @ngdoc method
      * @name createFota#checkTimeDifference
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Checks whether start time formfield is greater than end time formfield.
      *
      * @param {type} formArray, index
      * @return {type} null
  */
  checkTimeDifference(formArray, indexNumber) {
    const startTime = formArray.controls[indexNumber]['controls']['jobStartTime'].value;
    const endTime = formArray.controls[indexNumber]['controls']['jobEndTime'].value;
    if (startTime !== '' && endTime !== '') {
      const start_time = this.parseTime(startTime);
      const end_time = this.parseTime(endTime);
      const diff = (Number(end_time) - Number(start_time)) / (1000 * 60);
      this.timeDifference = (diff < 0) ? indexNumber : false;
    }
  }

  /**
      * @ngdoc method
      * @name createFota#parseTime
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: creates a date with time.
      *
      * @param {type} timeValue
      * @return {type} object
  */
  parseTime(timeValue) {
    const date = new Date();
    if (timeValue) {
      const time = timeValue.match(/(\d+)(:(\d\d))?\s*(p?)/);
      date.setHours(Number(time[1]) + ((Number(time[1]) < 12 && time[4]) ? 12 : 0));
      date.setMinutes(Number(time[3]) || 0);
      date.setSeconds(0, 0);
      return date;
    }
  }

  /**
      * @ngdoc method
      * @name createFota#getFirmwareList
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Populate firmware list dropdown.
      *
      * @param {type} solutionTypeId, deviceTypeId, deviceInterfaceProviderId
      * @return {type} null
  */
  public getFirmwareList(solutionTypeId = null, deviceTypeId = null, deviceInterfaceProviderId, iotHubDetailsId?: string) {
    const allFirmwares = 'true';
    const firmwareDto = {
      solutionType: this.objectToIdConversionService.convertObjectToId(solutionTypeId),
      deviceType: this.objectToIdConversionService.convertObjectToId(deviceTypeId),
      deviceInterfaceProvider: this.objectToIdConversionService.convertObjectToId(deviceInterfaceProviderId),
    };
    if (iotHubDetailsId) {
      firmwareDto['iotHubDetails'] = this.objectToIdConversionService.convertObjectToId(iotHubDetailsId);
    }
    this.firmwareControllerService
      .getAllFirmware({ allFirmwares, body: firmwareDto })
      .subscribe(
        resp => {
          if (resp.firmwareList && resp.firmwareList.length) {
            resp.firmwareList.forEach(firmware => {
              firmware['name'] = firmware['firmwareName'];
              firmware['id'] = firmware['firmwareId'];
            });
            this.dropDownValues['fwversion'] = resp.firmwareList;
          } else {
            this.dropDownValues['fwversion'] = [];
          }
        },
        err => {
          this.dropDownValues['fwversion'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
      * @ngdoc method
      * @name createFota#getConcurrencyType
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Populate ConcurrencyType dropdown.
      *
      * @param {type} null
      * @return {type} null
  */
  public getConcurrencyType() {
    this.dropDownValues['concurrencyType'] = [
      {
        'id': 'RELATIVE',
        'name': 'RELATIVE',
      },
      {
        'id': 'ABSOLUTE',
        'name': 'ABSOLUTE',
      }
    ];
  }

  /**
      * @ngdoc method
      * @name createFota#getOperatingType
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Populate OperatingType dropdown.
      *
      * @param {type} null
      * @return {type} null
  */
  public getOperatingType() {
    this.dropDownValues['operatingType'] = [
      {
        'id': 'DeviceTwin',
        'name': 'DEVICETWIN',
      },
      {
        'id': 'CloudToDevice',
        'name': 'CLOUDTODEVICE',
      }
    ];
  }

  /**
    * @ngdoc method
    * @name createFota#getCampaignType
    *
    * @methodOf
    * fota.controller:createFota
    *
    * @description
    * Description: Populate OperatingType dropdown.
    *
    * @param {type} null
    * @return {type} null
*/
  public getCampaignType() {
    this.dropDownValues['campaignType'] = [
      {
        'id': 'GROUP_CAMPAIGN',
        'name': 'GROUP_CAMPAIGN',
      },
      {
        'id': 'TIME_CAMPAIGN',
        'name': 'TIME_CAMPAIGN',
      }
    ];
  }

  /**
      * @ngdoc method
      * @name createFota#gotoConfirmDialog
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Opens the popup.
      *
      * @param {type} null
      * @return {type} null
  */
 
  public gotoConfirmDialog() {
    const message = this.translate.translateErrorMessages('START_FOTA_JOB');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onSubmit('submitAndplay');
      } else {
        this.abortAction();
      }
    });
  }

  /**
      * @ngdoc method
      * @name createFota#abortAction
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Close the popup.
      *
      * @param {type} null
      * @return {type} null
  */
  public abortAction() {
    this._matDialog.closeAll();
  }

  /**
      * @ngdoc method
      * @name createFota#continueWithSelectedCriteria
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Continue Fota Job creation with the previous selection criteria and selected devices.
      *
      * @param {type} null
      * @return {type} null
  */
  continueWithSelectedCriteria() {
    this.selectedCriteria = false;
  }

  /**
      * @ngdoc method
      * @name createFota#onSubmit
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: creates fota form.
      *
      * @param {type} buttonType
      * @return {type} null
  */
  onSubmit(buttonType) {
    const body = this.fotaForm.value;
    this.submitted = true;
    if (body['fotaJobTimingsDtoList']) {
      const schedulerList = body['fotaJobTimingsDtoList'].filter((jobTiminigs, index) => {
        if (jobTiminigs['dayOfWeek'] === true) {
          jobTiminigs['dayOfWeek'] = this.weekDays[index];
        }
        return jobTiminigs['dayOfWeek'] !== false && jobTiminigs['dayOfWeek'] !== '';
      });
      body['fotaJobTimingsDtoList'] = schedulerList;
      if (body['campaignType'] === 'GROUP_CAMPAIGN' || schedulerList.length) {
        this.isSchedularValid = false;
      } else {
        this.isSchedularValid = true;
      }
    }
    if (this.fotaForm.invalid || this.isSchedularValid || this.isNotToday) {
      return;
    }
    this.saveFota(body, buttonType);
  }

  /**
    * @ngdoc method
    * @name createFota#formatDate
    *
    * @methodOf
     * fota.controller:createFota
    *
    * @description
    * Description: Format the date in required format.
    *
    * @param {type} date
    * @return {type} date as a string
  */
  formatDate(date) {
    if (date !== undefined) {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    return date;
  }

  /**
      * @ngdoc method
      * @name createFota#onReset
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Resets fota form.
      *
      * @param {type} null
      * @return {type} null
  */
  onReset() {
    this.fotaForm.reset();
    this.fotaForm.patchValue({
      jobName: this.fotaState['jobName']
    });
    this.submitted = false;
  }

  /**
      * @ngdoc method
      * @name createFota#navigateToManageDevicePage
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Navigates to manage device component.
      *
      * @param {type} null
      * @return {type} null
  */
  goToManageDevicePage() {
    this.fotaState['url'] = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    this.router.navigateByUrl('/devices/manage-devices', { state: this.fotaState });
  }

  gotoGeoFencingPage(){
  this.geoFenceBlock = true;
   this.getGeoFenceDetails();
    }

    public getGeoFenceDetails(){
      this.GeoFenceControllerService.getGeoFenceDetails({
        tenantId:this.tenantId,
        profileId:this.profileId
        }).subscribe(res=>{
      if(res){
        this.loaderService.hideProgressBar(this.showSpinner);
        this.existingGeofenceList= res;
          }
      },  err=>{
          this.responseHandlerService.returnToastMessage('error', err.error.message);
      })
    }
  /**
      * @ngdoc method
      * @name createFota#onDiscard
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Discards the previous selection criteria and selected devices.
      *
      * @param {type} null
      * @return {type} null
  */
  onDiscard() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.selectedCriteria = false;
    this.fotaForm.disable();
    this.disableBtn = true;
    this.deleteSelection();
    this.reInitializeGeoFenceSection();  
    this.loaderService.hideProgressBar(this.showSpinner);
   }
     public navigateBack(){
       this.reInitializeDeviceListSection();
       this.geoFenceBlock = false;
       this.placeSearch='';
       this.geoFenceForm.get('geoFenceName').setValue('');
       this.clearDrewShape();
     }
        public reInitializeGeoFenceSection(){
          this.geoFenceBlock = false;
          this.geoFencedeviceList?.length!==0?this.geoFencedeviceList =[]:null;
          this.selection?.selected.length!==0?this.selection.clear():null;
          this.deviceListSection=false;
          this.polygonArray = undefined;
          this.CircleArray = undefined;
          this.geoFenceEnable = false;
          this.Devicelocations=[];
        }

        deleteSelection() {
          this.fotaSelectionControllerService.deleteFotaSelection()
            .subscribe(
              resp => {
                if (!resp) {
                  this.fotaJobId = this.fotaState['solutionTypeDetails'] = this.fotaState['deviceTypeDetails'] = null;
                }
              },
              err => {
                this.responseHandlerService.returnToastMessage('error', err.error.message);
              }
            );
        }

  /**
      * @ngdoc method
      * @name createFota#getFotaDetails
      *
      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: Populates the fota form with the values received.
      *
      * @param {type} solutionTypeId, fotaJobId
      * @return {type} null
  */
  public getFotaDetails(fotaJobId) {
    const fotaJobTimingsArray = [];
    this.weekDays.forEach(day => fotaJobTimingsArray.push({
      'dayOfWeek': '',
      'jobStartTime': '',
      'jobEndTime': ''
    }));
    this.fotaJobControllerService.getFotaJobById({
      fotaJobId: fotaJobId
    }).subscribe(
      resp => {
        if (!this.fotaState['jobId']) {
          this.fotaState = resp;
        }
        if (this.fotaState['userFotaSelectionDto']) {
          if (this.fotaState['userFotaSelectionDto']['deviceInterfaceProvider']['name'] === this.env['providerAws']) {
            this.getFirmwareList(this.fotaState['userFotaSelectionDto']['solutionTypeDetails']['id'],
            this.fotaState['userFotaSelectionDto']['deviceTypeDetails']['id'],
            this.fotaState['userFotaSelectionDto']['deviceInterfaceProvider']['id'],
            this.fotaState['userFotaSelectionDto']['iotHubDetails']['id']);
          } else {
            this.getFirmwareList(this.fotaState['userFotaSelectionDto']['solutionTypeDetails']['id'],
            this.fotaState['userFotaSelectionDto']['deviceTypeDetails']['id'],
            this.fotaState['userFotaSelectionDto']['deviceInterfaceProvider']['id']);
          }
        }
        if (this.fotaState['timeCampaignDto']) {
          this.fotaState['timeCampaignDto']['fotaJobTimingsDtoList'].forEach(day => {
            const index = this.weekDays.indexOf(day['dayOfWeek']);
            fotaJobTimingsArray[index] = day;
          });
        }
        (<UntypedFormArray>this.fotaForm.get('fotaEmailList')).controls = [];
        this.fotaState['fotaEmailList'].forEach(emailId => {
          (<UntypedFormArray>this.fotaForm.get('fotaEmailList')).push(this.formBuilder.control(emailId, [Validators.required,
          Validators.pattern('^[a-z0-9A-Z]+(\\.[_a-z0-9A-Z]+)*@[a-z0-9-A-Z]+(\\.[a-z0-9-A-Z]+)*(\\.[a-zA-Z]{2,15})$')]));
        });
        this.fotaForm.patchValue({
          jobName: this.fotaState['jobName'],
          firmwareArtf: this.fotaState['firmwareArtf'] !== null ? this.fotaState['firmwareArtf']['id'] : null,
          jobStartDate: this.fotaState['jobStartDate'],
          jobStartTime: this.fotaState['jobStartTime'],
          fotaJobTimingsDtoList: fotaJobTimingsArray,
          operatingType: this.fotaState['operatingType'],
          campaignType: this.fotaState['campaignType'],
          actionType: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['actionType'] : null,
          numberOfGroups: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['numberOfGroups'] : null,
          timeForcedStartDate: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['timeForcedStartDate'] : null,
          timeForcedStartTime: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['timeForcedStartTime'] : null,
          triggerThreshold: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['triggerThreshold'] : null,
          errorThresholdType: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['errorThresholdType'] : null,
          errorThreshold: this.fotaState['groupCampaignDto'] ? this.fotaState['groupCampaignDto']['errorThreshold'] : null,
          concurrency: this.fotaState['timeCampaignDto'] ? this.fotaState['timeCampaignDto']['concurrency'] : null,
          concurrencyType: this.fotaState['timeCampaignDto'] ? this.fotaState['timeCampaignDto']['concurrencyType'] : null,
          timeInterval: this.fotaState['timeCampaignDto'] ? this.fotaState['timeCampaignDto']['timeInterval'] : null
        });
        this.getTabOptions(this.fotaState['campaignType']);
        const formArray: UntypedFormArray = this.fotaForm.get('fotaJobTimingsDtoList') as UntypedFormArray;
        formArray.controls.forEach((group, index) => {
          Object.keys(group['controls']).forEach(key => {
            if (index === 0 && !group['controls']['dayOfWeek'].value && key !== 'dayOfWeek') {
              group['controls'][key].disable();
            } else {
              if (group['controls'][key].value || key === 'dayOfWeek') {
                group['controls'][key].enable();
              } else {
                group['controls'][key].disable();
              }
            }
        });
      });
        const searchObj = {
          solutionType: { 'id': this.fotaState['userFotaSelectionDto']['solutionTypeDetails']['id'] },
          deviceType: { 'id': this.fotaState['userFotaSelectionDto']['deviceTypeDetails']['id'] },
          deviceGroup: { 'id': this.fotaState['userFotaSelectionDto']['groupDetails'] ?
          this.fotaState['userFotaSelectionDto']['groupDetails']['id'] : null },
          firmwareVersion: { 'id': this.fotaState['userFotaSelectionDto']['currentFirwareVersion'] ?
          this.fotaState['userFotaSelectionDto']['currentFirwareVersion']['id'] : null }
        };
        this.fotaState['searchDto'] = { body: searchObj, page: 0, limit: 10 };
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  /**
      * @ngdoc method
      * @name createFota#createFota

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: creates a new/updates the fota job
      *
      * @param {type} body, buttonType
      * @return {type} null
  */
  saveFota(body, buttonType) {
    this.showSpinner = this.loaderService.showProgressBar();
    const groupCampaignObj = {
      'actionType': body['actionType'],
      'timeForcedStartDate': body['timeForcedStartDate'],
      'timeForcedStartTime': body['timeForcedStartTime'],
      'numberOfGroups': body['numberOfGroups'],
      'triggerThreshold': body['triggerThreshold'],
      'errorThreshold': body['errorThreshold'],
      'errorThresholdType': ERROR_THRESHOLD_TYPE
    };
    const timeCampaignObj = {
      'concurrency': body['concurrency'],
      'concurrencyType': body['concurrencyType'],
      'timeInterval': body['timeInterval'],
      'fotaJobTimingsDtoList': body['fotaJobTimingsDtoList']
    };
    const UserFotaSelectionObj = {
      'allDevicesFlg': this.fotaJobId ? this.fotaState['userFotaSelectionDto']['allDevicesFlg'] : this.fotaState['allDevicesFlg'],
      'currentFirwareVersion': this.fotaJobId ?
      this.fotaState['userFotaSelectionDto']['currentFirmwareVersion'] : this.fotaState['currentFirmwareVersion'],
      'deviceInterfaceProvider': this.fotaJobId ?
      this.fotaState['userFotaSelectionDto']['deviceInterfaceProvider'] : this.fotaState['deviceInterfaceProvider'],
      'deviceTypeDetails': this.fotaJobId ?
      this.fotaState['userFotaSelectionDto']['deviceTypeDetails'] : this.fotaState['deviceTypeDetails'],
      'solutionTypeDetails': this.fotaJobId ?
      this.fotaState['userFotaSelectionDto']['solutionTypeDetails'] : this.fotaState['solutionTypeDetails'],
      'groupDetails': this.fotaJobId ? this.fotaState['userFotaSelectionDto']['groupDetails'] : this.fotaState['groupDetails'],
      'deviceList': this.fotaJobId ? this.fotaState['userFotaSelectionDto']['deviceList'] : this.fotaState['deviceList'],
      'businessProfile': this.fotaJobId ? this.fotaState['userFotaSelectionDto']['businessProfile'] : this.fotaState['businessProfile'],
      'iotHubDetails': this.fotaJobId ? this.fotaState['userFotaSelectionDto']['iotHubDetails'] : this.fotaState['iotHubDetails']
    };
    const fotaObj = {
      'jobStartDate': body['jobStartDate'] instanceof Date ? this.formatDate(body['jobStartDate']) : body['jobStartDate'],
      'firmwareArtf': this.objectToIdConversionService.convertObjectToId(body['firmwareArtf']),
      'saveAndPlayFlag': (buttonType === 'submit' || buttonType === 'update') ? false : true,
      'proceedWithFotaUpdate': true,
      'operatingType': body['operatingType'],
      'active': true,
      'fotaEmailList': body['fotaEmailList'],
      'jobName': body['jobName'],
      'jobStartTime': body['jobStartTime'],
      'campaignType': body['campaignType'],
      'userFotaSelectionDto': UserFotaSelectionObj,
      'timeCampaignDto': body['campaignType'] === 'TIME_CAMPAIGN' ? timeCampaignObj : null,
      'groupCampaignDto': body['campaignType'] === 'GROUP_CAMPAIGN' ? groupCampaignObj : null
    };
    if (buttonType === 'submit' || (!this.fotaState['jobId'] && buttonType === 'submitAndplay')) {
      this.fotaJobControllerService
        .saveFotaJob({ body: fotaObj })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'FOTA_JOB_CREATE_SUCCESS');
            if (resp['deviceFotaJobMap'].length) {
              const dialogRef = this._matDialog.open(FotaDeviceListComponent, {
                maxWidth: '400px',
                disableClose: true, data: { devices: resp['deviceFotaJobMap'] }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.router.navigate(['fota/manage-job-completed']);
                  this.deleteSelection();
                } else {
                  this.abortAction();
                }
              });
            } else {
              this.deleteSelection();
              this.router.navigate(['fota/manage-job-completed']);
            }
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {
      this.fotaJobControllerService
        .updateFotaJob({ fotaJobId: this.fotaJobId, body: fotaObj })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'FOTA_JOB_EDIT_SUCCESS');
            this.router.navigate(['fota/manage-job-completed']);
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    }
  }

  public getTabOptions(event) {
    if (event === 'GROUP_CAMPAIGN') {
      this.matTabOptions = [{ 'label': 'Group Campaign', 'value': 'GroupCampaign', 'disabled': false },
      { 'label': 'Time Campaign', 'value': 'TimeCampaign', 'disabled': true }];
      this.fotaForm.get('actionType').enable();
      this.fotaForm.get('timeForcedStartDate').enable();
      this.fotaForm.get('timeForcedStartTime').enable();
      this.fotaForm.get('numberOfGroups').enable();
      this.fotaForm.get('actionType').setValidators(Validators.required);
      this.fotaForm.get('numberOfGroups').setValidators([Validators.required, Validators.pattern('[0-9]+')]);
      this.fotaForm.get('triggerThreshold').setValidators([Validators.min(0), Validators.max(100)]);
      const timeControlGroup = this.fotaForm.controls['fotaJobTimingsDtoList']['controls'];
      for (const formGroup of timeControlGroup) {
        formGroup.controls['jobStartTime'].clearValidators();
        formGroup.controls['jobEndTime'].clearValidators();
      }
    } else {
      this.matTabOptions = [{ 'label': 'Time Campaign', 'value': 'TimeCampaign', 'disabled': false },
      { 'label': 'Group Campaign', 'value': 'GroupCampaign', 'disabled': true }];
      this.fotaForm.get('concurrency').setValidators([Validators.required, Validators.min(CONCURRENCY_MINLENGTH),
        Validators.max(CONCURRENCY_MAXLENGTH)]);
      this.fotaForm.get('concurrencyType').setValidators(Validators.required);
      this.fotaForm.get('timeInterval').setValidators([Validators.required, Validators.min(TIMEINTERVAL_MINLENGTH),
        Validators.max(TIMEINTERVAL_MAXLENGTH)]);
      this.fotaForm.get('timeInterval').setValue(TIMEINTERVAL);
      this.fotaForm.get('concurrency').setValue(CONCURRENCY);
      this.fotaForm.get('concurrencyType').setValue(CONCURRENCY_TYPE);
      this.fotaForm.get('actionType').disable();
      this.fotaForm.get('timeForcedStartDate').disable();
      this.fotaForm.get('timeForcedStartTime').disable();
      this.fotaForm.get('numberOfGroups').disable();

    }
  }

  public onActionTypeChange(event) {
    switch (event.value) {
      case 'FORCED':
        this.isNotToday = false;
        this.fotaForm.get('timeForcedStartTime').reset();
        this.fotaForm.get('timeForcedStartTime').disable();
        this.fotaForm.get('timeForcedStartTime').patchValue({ mandatory: false });
        this.fotaForm.get('timeForcedStartDate').reset();
        this.fotaForm.get('timeForcedStartDate').disable();
        this.fotaForm.get('timeForcedStartDate').patchValue({ mandatory: false });
        break;
      case 'TIME_FORCED':
        this.fotaForm.get('timeForcedStartTime').enable();
        this.fotaForm.get('timeForcedStartTime').patchValue({ mandatory: true });
        this.fotaForm.get('timeForcedStartDate').enable();
        this.fotaForm.get('timeForcedStartDate').patchValue({ mandatory: true });
        break;
    }
  }

  isToday(controlName) {
    const currentDate = new Date();
    let  selectedDate;
    let time;
    if (controlName.getAttribute('formControlName') === 'jobStartDate' || controlName.getAttribute('formControlName') === 'jobStartTime') {
      selectedDate = new Date(this.fotaForm.controls.jobStartDate.value);
      time = this.fotaForm.controls.jobStartTime.value.match(/(\d+)(:(\d\d))?\s*(p?)/);
    } else if (controlName.getAttribute('formControlName') === 'timeForcedStartDate' ||
    controlName.getAttribute('formControlName') === 'timeForcedStartTime') {
      selectedDate = new Date(this.fotaForm.controls.timeForcedStartDate.value);
      time = this.fotaForm.controls.timeForcedStartTime.value.match(/(\d+)(:(\d\d))?\s*(p?)/);
    }
    if (selectedDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)) {
      const date = new Date();
      date.setHours(Number(time[1]) + ((Number(time[1]) < 12 && time[4]) ? 12 : 0));
      date.setMinutes(Number(time[3]) || 0);
      date.setSeconds(0, 0);
      const start_time = date;
      const diff = (Number(new Date()) - Number(start_time)) / (1000 * 60);
      this.checkTodayDate = diff > 1 ? true : false;
      this.isNotToday = false;
    } else if (selectedDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
      this.checkTodayDate = false;
      this.isNotToday = true;
    } else {
      this.isNotToday = false;
      this.checkTodayDate = false;
    }
  }


  selectDevices(event) {
    this.selectAllDevices = !this.selectAllDevices;
    if (event.checked) {
      this.geoFencedeviceList.forEach((row) => {
        if (row !== undefined) {
          this.selection.select(row['deviceId']);
        }
      });
    } else {
      this.selection.clear();
    }
    this.numSelectedRows = this.selection.selected.length;
  }
  public selectAllDevicesCheckBox(event, deviceId) {
     if (this.isAllSelected()) {
      this.selectAllDevices = !this.selectAllDevices;
    }
  //  this.selectAllDevices = false;
  }
  public isAllSelected() {
    this.numSelectedRows = this.selection.selected.length;
    const numRows = this.geoFencedeviceList.length;

    return this.numSelectedRows === numRows;
  }
  /**
   * @ngdoc method
   * @name manageDevice#getProfile
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate business profile dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
   public getProfile(tenantId: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.tenantId = tenantId;
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          if (resp && resp.length) {
            this.businessProfileValues = resp;
            } else {
            this.businessProfileValues = [];
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.businessProfileValues = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

   public getTags(val) {
    if(val== "null"){
      this.selectedDeviceTypeId = null;
      this.tags =[];
      return null ;
    } else {
      this.selectedDeviceTypeId = val=="null"?null:val;
    }
    this.selectedDeviceTypeId = val=="null"?null:val;
    this.showSpinner = this.loaderService.showProgressBar();
     this.GeoFenceControllerService.findAllTagsFilter({ tenantId: this.tenantId,profileId:this.profileId}).subscribe(
    resp=>{
          if(resp){
            this.loaderService.hideProgressBar(this.showSpinner);
            this.tags = resp;
            }
            },
    err =>{
          if(err.status !== 404) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        });
    }

   public tagsChange(val,dropdown){
    let tagsArr = [];
     if(val.includes("null")){
      this.selectMultiTagsDropdown.options.forEach((option) => {
        if(!this.selectUnselectFlag){
          option.select();
        }
        if(option.selected){
         this.tags.map(ele=>{
            if(option.value == ele.id){
              tagsArr.push(ele.id);
          }
        });
        } 
        });
      this.selectUnselectFlag=true;
      this.selectedTags = tagsArr.slice().toString();
    }else {
      if(this.selectUnselectFlag){
        this.selectMultiTagsDropdown.options.forEach((option) => option.deselect());
        this.selectUnselectFlag = false;
        this.selectedTags =null;
      } else {
        this.selectedTags= val.toString();
      }
    }
   }

     public getDeviceTypes(){
        this.showSpinner = this.loaderService.showProgressBar();
        this.GeoFenceControllerService.getDeviceTypeFilter({ tenantId: this.tenantId,profileId:this.profileId}).subscribe(
      resp => {
                if(resp){
                this.loaderService.hideProgressBar(this.showSpinner);
                this.deviceTypes = resp;
              }
               },
      err => {
                if (err.status !== 404) {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', err.error.message);
              }
            });
       }

  /**
   * @ngdoc method
   * @name manageDevice#getTenant
   *
   * @methodOf
   * device.controller:manageDevice
   *
   * @description
   * Description: To populate tenant dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
   public getTenant() {
    this.tenantControllerService
      .findAllTenantsBasic({ active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.tenantValues = resp;
          } else {
            this.tenantValues = [];
          }
        },
        (err) => {
          this.tenantValues = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
      * @ngdoc method
      * @name createFota#validateGeofence

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: validating geofence form fields geofence names, circle and polygon
      *               on click on "draw fence" button .
      *
      * @param {type} body, buttonType
      * @return {type} null
  */


  public validateGeofence(profileId){
    this.profileId = profileId;
    this.businessProfileControllerService.getBusinessProfile({ tenantId: this.tenantId,profileId:this.profileId}).subscribe(
    resp => {
      if (resp) {
          this.geoFenceEnable = resp.geoFenceEnabled;
      }
    },
    err => {
        if (err.status !== 404) {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    }
  );
  this.getDeviceTypes();
  }
  
    public onClose() {
    this._matDialog.closeAll();
    }

 /**
      * @ngdoc method
      * @name createFota#onMapReady

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: this method will called when googlemaps loaded,
      * -> this method passed as event emitter in agm-map (mapReady)="onMapready($map)" in html file
      * 1. intilizing map and declaring initial zoom of the app 
      * 2. get the location on searching input field
      * 3. to store the map object in "this.myMap" variable.
      * @param {type} body, buttonType
      * @return {type} null
  */

onMapReady(map) {
  this.mapsAPILoader.load().then(() => {
   this.geoCoder = new google.maps.Geocoder;
   let search:HTMLElement = this.searchElementRef.nativeElement;
   search.addEventListener('keyup',()=>{
     if(this.placeSearch.length==0){
       map.setOptions({
         center:{
           'lat':28.41682404477514, 
           'lng':42.89996116637957
         },
         zoom:3
       })
     }
      })
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
   autocomplete.addListener("place_changed", () => {
     this.ngZone.run(() => {
           let place: google.maps.places.PlaceResult = autocomplete.getPlace();
         if (place.geometry === undefined || place.geometry === null) {
           return;
         }
         this.lat = place.geometry.location.lat();
         this.long = place.geometry.location.lng();
          map.setOptions({
         center:{
           'lat': place.geometry.location.lat(), 
           'lng':place.geometry.location.lng()
         },
            zoom:10
          })
        });
      });
    });
    this.myMap=map;
    map.setOptions({
      zoomControl: "true",
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      center:{
        'lat':28.41682404477514, 
        'lng':42.89996116637957
      },
      zoom:3
    });
    }

 /**
      * @ngdoc method
      * @name createFota#initDrawingManager

      * @methodOf
      * fota.controller:initDrawingManager
      *
      * @description
      * Description: in this method, for polygon and circle shape opearation implementation 
      * 1. to draw the polygon shape need to enable in drawingmanager
      * 2. to draw the circle shape need to enable circle in drawing manager based input field
      * @param {type} body, buttonType
      * @return {type} null
  */

    public initDrawingManager(myMap: any,shapeName) {
    let drawingManager = new google.maps.drawing.DrawingManager();
      const options = {
      drawingControl: false,
      drawingControlOptions: {
        position : google.maps.ControlPosition.TOP_RIGHT,
        drawingModes:[google.maps.drawing.OverlayType.POLYGON,google.maps.drawing.OverlayType.CIRCLE]
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: '#adaeaf',
        fillOpacity: 0.6,
        geodesic: true
      },
      drawingMode: shapeName=='circle'?google.maps.drawing.OverlayType.CIRCLE:google.maps.drawing.OverlayType.POLYGON,
      circleOptions: {
        fillColor: '#adaeaf',
        fillOpacity: 0.6,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        zIndex: 1,
        draggable:true
      }
    };
    drawingManager.setOptions(options);
    drawingManager.setMap(myMap);

  
     /**
      * Description :1 overlaycomplete method will called when complete drawing of
      * circle or polygon any shape on the map
      * 2. inside this function we need to get circle and polygon cordinates
      * 3. based on shape name we need to get separate coordinates for circle and polygon
      * 
    **/
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (e)=> {
        this.overlayCompleteEvent = e;
        const element = document.querySelector('.geoFenceSection');
        element.classList.contains("addFlex")?null:element.classList.add('addFlex');
      if( e.type=='polygon'){
        this.polygon = e.overlay;
        this.constructPolygonCoordinates(e);

      /**
      * Description : this "set_at" event listner will call when edit polygon vertex or change the polygon length 
      *  inside this function we need to get new polygon cordinates after edit of polygon vertexes
      * 
    **/

        google.maps.event.addListener(this.polygon.getPath(), 'set_at', ()=> {
          this.constructPolygonCoordinates(e);
        });

    /**
      * Description : this "insert_at" event listner will call when adding new vertex to existing polygon shape
      *  inside this function we need to get new polygon cordinates after inserting new vertex of existing polygon 
      * 
    **/
        google.maps.event.addListener(this.polygon.getPath(), 'insert_at', ()=>{
           this.constructPolygonCoordinates(e);
        });
     }
         if(e.type =='circle'){
            this.circle = e.overlay;
          }
        drawingManager.setOptions({
        drawingMode: null,
        drawingControl: false
      });

        /**
      * Description :1 "dragend" method will called when completion of dragging(moving) any shape
      * circle or polygon any shape on the map
      * 2. inside this function we need to get new position of circle and polygon cordinates after dragging completed
      * 3. based on shape name we need to get separate coordinates for circle and polygon
      * 
    **/
      google.maps.event.addListener(e.overlay,'dragend',(dragendevent)=>{
      if(this.polygon){
        this.circle=undefined;
        this.CircleArray =undefined;
        let polygonCoords = [];
        let path = this.polygon.getPath();
        for (let i = 0; i < path.getLength(); i++) {
          polygonCoords.push(path.getAt(i).toJSON());
        }
        this.polygonArray =polygonCoords;
      } 
      if(this.circle){
        this.polygon=undefined;
        this.polygonArray=undefined;
        let circle = this.circle;
        this.CircleArray =[];
        this.CircleArray.push({lat:circle.center.lat(),lng:circle.center.lng()});
      }
        })

      /**
      * Description : this "circlecomplete" event listner will call when drawing circle shape 
      * completed on the map, after that we need to get cicle co-ordinates
      * 
    **/
      google.maps.event.addListener(drawingManager, 'circlecomplete', (circle)=> {
          this.circleCoordinates(circle); 
    /**
      * Description : this "radius_changed" event listner will call when edit the circle radius after completing circle
      * after that we need to get cicle co-ordinates
      * 
    **/
          google.maps.event.addListener(circle, 'radius_changed', ()=> {
          this.circleCoordinates(circle); 
          });

      /**
      * Description : this "center_changed" event listner will call when alter the circle center after completing of circle draw
      * after that we need to get cricel-radius
      * 
    **/
        google.maps.event.addListener(circle, 'center_changed', ()=> {
          this.polygon=undefined;
          this.polygonArray=undefined;
          this.circleCoordinates(circle); 
        });

    });
  });
 }

  public circleCoordinates(circle){
  this.CircleArray =[];
  this.CircleArray.push({lat:circle.center.lat(),lng:circle.center.lng()});
  this.circleRadius = circle.radius/1000;
  }

    public constructPolygonCoordinates(e){
    this.circleRadius =null;
    this.polygonArray = [];
    e.overlay.getPath().getArray().forEach(x=>{
        this.polygonArray.push({lat:x.lat(),lng:x.lng()});
    });
  }

       /**
      * @ngdoc method
      * @name createFota#drawGeoFence

      * @methodOf
      * fota.controller:drawGeoFence
      *
      * @description
      * Description: after selecting the shape in the Form , this method will call on clicking of "drawFence" button
      * 1. we are validating the form and checking existing geofenceNames.
      * @param {type} body, buttonType
      * @return {type} null
  */

  public drawGeoFence(val,selectedIcon){
    if(this.overlayCompleteEvent || this.existingGeofenceDetail){
      this.responseHandlerService.returnToastMessage(
        'warning',
        'SHAPE_EXIST'
      );
    } else {
      if(selectedIcon == 'CIRCLE_ICON'){
        this.ellipseIcon=CIRCLE_SELECTED_ICON;
        this.polygonIcon=POLYGON_ICON;
      }
      if(selectedIcon == 'POLYGON_ICON'){
        this.polygonIcon= POLYGON_SELECTED_ICON;
        this.ellipseIcon=CIRCLE_ICON;
      }
      this.initDrawingManager(this.myMap,val);
    }
   
  }

    /**
      * @ngdoc method
      * @name createFota#reInitializeDeviceListSection

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: reset the all map operations, after selected devices created to OTA
      * @param {type} body, buttonType
      * @return {type} null
  */
 public reInitializeDeviceListSection(){
    const element = document.querySelector('.geoFenceSection');
    element.classList.contains("addFlex")?element.classList.remove('addFlex'):null;
    this.geoFencedeviceList?.length!==0?this.geoFencedeviceList =[]:null;
    this.deviceListSection=false;
    this.polygonArray = undefined;
    this.CircleArray = undefined;
    this.existViewCircle? this.existViewCircle.setMap(null):null;
    this.existViewPolygon? this.existViewPolygon.setMap(null):null;
    this.selection?.selected.length!==0?this.selection.clear():null;
    if(this.Devicelocations){
      this.myMap.setOptions({
        center:{
          'lat':28.41682404477514, 
          'lng':42.89996116637957
        },
        zoom:3
      })
    }
    this.Devicelocations =undefined;
    this.existingGeofenceDetail = undefined;
    this.selectedDeviceTypeId =null;
    this.selectedTags=null;
    this.ellipseIcon= CIRCLE_ICON;
    this.polygonIcon = POLYGON_ICON
  }


  /**
      * @ngdoc method
      * @name createFota#saveAndFetchGeofence

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: initially saving the geofence for getting devices , after saving we got geofenceID with this id
      *              we caling api for getting devices, after fetching devices then gefence id will automatically
      *               deleted from api side.
      * @param {type} body, buttonType
      * @return {type} null
  */
   public saveAndFetchGeofence(){
    if(this.existingGeofenceDetail){
      this.fetchDevices(this.existingGeofenceDetail);
      return null;
    }
         const  obj:any ={
           coOrdinates:this.polygonArray?this.polygonArray:this.CircleArray,
           profileId:this.profileId,
           tenantId:this.tenantId,
           radius:this.circleRadius?this.circleRadius:null,
           unit:this.circleRadius?"K":null
           }
         this.showSpinner = this.loaderService.showProgressBar();
         this.GeoFenceControllerService.saveGeoFenceDetails({
         body:obj
         }).subscribe(res=>{
          this.loaderService.hideProgressBar(this.showSpinner);
            this.geoFenceId = res.geoFenceId;
            const data={
              geoFenceId:res.geoFenceId
                };
            this.fetchDevices(data);
           
            },
          err=>{
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
            }

      
 /**
      * @ngdoc method
      * @name createFota#reInitializeDeviceListSection

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: fetching device list by passing geofence ID and device types and tags
      * @param {type} body, buttonType
      * @return {type} null
  */

  public fetchDevices(data?){
    data.existingGeofenceDetail?this.proceedToFota=false:this.proceedToFota=true;
    this.Devicelocations =[];
    this.geoFencedeviceList =[];
    this.deviceListSection = false;
    this.showSpinner = this.loaderService.showProgressBar();
       const obj={
              geoFenceId:this.existingGeofenceId?this.existingGeofenceId:this.existingGeofenceDetail?.geoFenceId?this.existingGeofenceDetail.geoFenceId:this.geoFenceId,
              circle:(typeof this.existingGeofenceDetail?.radius =='number' || this.CircleArray?.length >=1),
              deviceTypeId:this.selectedDeviceTypeId?this.selectedDeviceTypeId:null,
              tags:this.selectedTags?this.selectedTags:null
            }
    
    this.getGeoFenceDeviceLIst(obj)
    .subscribe(resp=>{
     this.deviceListSection = true;
      if(resp.data.connectionStatus == "connectionClosed"){
        this.loaderService.hideProgressBar(this.showSpinner);
      
        /**
         * below if block code written for  need to save geofenceID on clicking viewexistinggeofence 
         *  becuase geofenceID deleting on fetchDevice operation so of after fetching devices on existing geofenceid need to resave same geofenceID
         */
        if(this.geoFencedeviceList?.length>=1 && data.existingGeofenceDetail){
          this.saveGeofence();
        }
        return ;
      }
      this.deviceList = JSON.parse(resp.data);
      this.columns=['select','name'];
      this.geoFencedeviceList.push({
         businessProfile:this.deviceList.profile,
         deviceId:this.deviceList.serialNo,
         deviceInterfaceProvider:this.deviceList.deviceInterfaceProvider,
         deviceTypeDetails:this.deviceList.deviceType,
         iotHubDetails:this.deviceList.iotHubDetails,
         solutionTypeDetails:this.deviceList.solutionType
        })
        this.changeDetectionRef.detectChanges();
        this.geoFenceTableRef.renderRows();
        this.Devicelocations.push({
        lat:this.deviceList.deviceLocation.lat,
        lng:this.deviceList.deviceLocation.lng
      })
      const bounds = new google.maps.LatLngBounds();
      this.Devicelocations.forEach((marker: any) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });
      this.myMap.fitBounds(bounds);
      const element = document.querySelector('.geoFenceSection');
      element.classList.contains("addFlex")?null:element.classList.add('addFlex');
      this.responseHandlerService.returnToastMessage('success', 'DEVICES_FETCHED_SUCCESSFULLY');
      },
    err=>{
      this.loaderService.hideProgressBar(this.showSpinner);
      })
  }

  public confirmSaveGeofence(content){
    this._matDialog.open(content, {
      maxHeight: "80vh",
      minWidth: "40vw",
      disableClose: true,
      autoFocus: false,
    });
    } 

  public confirmProceedToFota(){
    const message = this.translate.translateErrorMessages('CONFIRM_PROCEED_FOTA');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createFota();
      } else {
        this.abortAction();
      }
    });
  }


  public saveGeofence(){
    let obj;
    if(this.existingGeofenceDetail) {
       obj ={
        coOrdinates:this.existingGeofenceDetail.radius!==null?
                    ([{ lat:this.existingGeofenceDetail.coOrdinates[0].lat, 
                    lng:this.existingGeofenceDetail.coOrdinates[0].lng }])
                    :this.existingGeofenceDetail.coOrdinates,
        geoFenceName:this.existingGeofenceDetail.geoFenceName,
        profileId:this.existingGeofenceDetail.profileId,
        tenantId:this.tenantId,
        radius:this.existingGeofenceDetail.radius?this.existingGeofenceDetail.radius:null,
        unit:this.existingGeofenceDetail.radius?"K":null
        }      
    } else {
      if (!this.geoFenceForm.valid) {
        this.geoFenceForm.markAllAsTouched();
        return null;
      }
      if(this.existingGeofenceList.map(ele=>ele.geoFenceName==this.geoFenceForm.get('geoFenceName').value).includes(true)){
        this.responseHandlerService.returnToastMessage('error', "GEOFENCE_EXIST");
      }
           obj ={
           coOrdinates:this.polygonArray?this.polygonArray:this.CircleArray,
           geoFenceName:this.geoFenceForm.get('geoFenceName').value,
           profileId:this.profileId,
           tenantId:this.tenantId,
           radius:this.circleRadius?this.circleRadius:null,
           unit:this.circleRadius?"K":null
           }
      }
         this.showSpinner = this.loaderService.showProgressBar();
         this.GeoFenceControllerService.saveGeoFenceDetails({
         body:obj
         }).subscribe(res=>{
          this.existingGeofenceId = res.geoFenceId;
          this.loaderService.hideProgressBar(this.showSpinner);
          if(!this.existingGeofenceDetail){
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'GEOFENCE_SAVE_SUCCESSFULLY');
          }  
            this.proceedToFota = false;
           this.getGeoFenceDetails();
          },
          err=>{
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
      }

  /**
      * @ngdoc method
      * @name createFota#createFota

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: selected devices created to OTA
      * @param {type} body, buttonType
      * @return {type} null
  */
 
  public createFota(){
    const deviceList = this.selection['selected'].map((device) => {
      return { id: device };
    });
    this.Ota_Details ={
       deviceTypeDetails:  this.geoFencedeviceList[0].deviceTypeDetails,
        solutionTypeDetails: this.geoFencedeviceList[0].solutionTypeDetails,
        currentFirwareVersion: null,
        groupDetails: null,
        deviceList: deviceList,
        allDevicesFlg: false,
        deviceInterfaceProvider: this.geoFencedeviceList[0].deviceInterfaceProvider,
        businessProfile:  this.geoFencedeviceList[0].businessProfile,
        iotHubDetails:  this.geoFencedeviceList[0].iotHubDetails,
      };
      this.fotaSelectionControllerService
        .saveFotaSelection({
          body: this.Ota_Details,
        })
        .subscribe(
          (resp) => {
            if (resp) {
                this.ngOnInit();
            }
          },
          (err) => {
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }

    public confirmGeofenceDelete(event,data) {
        const message = this.translate.translateErrorMessages('CONFIRM_DELETE_GEOFENCE');
      const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
        maxWidth: '400px',
        disableClose: true, data: { message: message }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteGeofence(data)
        } else {
          this.abortAction();
        }
      });
    }
 
  public deleteGeofence(data){
        this.showSpinner = this.loaderService.showProgressBar();
        const obj ={
          body:[data.geoFenceId]
            }
      this.GeoFenceControllerService.deleteGeofence(obj).subscribe(
            res =>{
              this.loaderService.hideProgressBar(this.showSpinner);
             data.message?null:this.responseHandlerService.returnToastMessage('success', 'DELETED_SUCCESSFULLY');
            this.getGeoFenceDetails();
                   },
          err =>{
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
                 });
      }


    public viewExistingGeoFence(event,val:any){
      this.overlayCompleteEvent?this.overlayCompleteEvent.overlay.setMap(null):null;
      this.existingGeofenceDetail = val;
      this.existingGeofenceDetail['existingGeofenceDetail'] =true;
      if(val.radius==null){
        this.polygonIcon = POLYGON_SELECTED_ICON;
        this.ellipseIcon = CIRCLE_ICON;
        this.existViewCircle? this.existViewCircle.setMap(null):null;
        this.existViewPolygon? this.existViewPolygon.setMap(null):null;
        let coordinates=val.coOrdinates;
        this.existViewPolygon = new google.maps.Polygon({
            paths:coordinates,
            editable: true,
            draggable: true,
            fillColor: '#adaeaf',
            fillOpacity: 0.6,
            geodesic: true
          });
        this.existViewPolygon.setMap(this.myMap);
        let polygonbounds = new google.maps.LatLngBounds();
        for (let i = 0; i < coordinates.length; i++) {
          polygonbounds.extend(coordinates[i]);
        }
        this.myMap.fitBounds(polygonbounds);
    } else {
      this.ellipseIcon = CIRCLE_SELECTED_ICON;
      this.polygonIcon = POLYGON_ICON
      this.existViewCircle? this.existViewCircle.setMap(null):null;
      this.existViewPolygon? this.existViewPolygon.setMap(null):null;
        this.existViewCircle = new google.maps.Circle({
          center:{ lat:val.coOrdinates[0].lat, lng:val.coOrdinates[0].lng },
          radius: val.radius*1000,
          editable: true,
          draggable: true
        });
        this.existViewCircle.setMap(this.myMap);
        let circlebounds =  this.existViewCircle.getBounds();
        this.myMap.fitBounds(circlebounds);
      }
      this.fetchDevices( this.existingGeofenceDetail);
    }

       public mapTypeChange(val,selectedIcon){
        this.maptype = val;
        let icon = (selectedIcon == 'DEFAULT_ICON'?DEFAULT_ICON:
                  selectedIcon == 'SATELLITE_ICON'?SATELLITE_ICON:
                  selectedIcon == 'TERRAIN_ICON'?TERRAIN_ICON:null);
        this.layersIcon = icon;
       }

    /**
      * @ngdoc method
      * @name createFota#createGeoFence

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: this function will called for open dialogue box for select which shape and geofence name
      *
      * @param {type} body, buttonType
      * @return {type} null
  */
   
    /**
      * @ngdoc method
      * @name createFota#clearDrewShape

      * @methodOf
      * fota.controller:createFota
      *
      * @description
      * Description: clear existing draw shape between switched to one shape to other shape ( circle or polygon)
      *
      * @param {type} body, buttonType
      * @return {type} null
  */

    public clearDrewShape(){
      if(this.overlayCompleteEvent){
        this.overlayCompleteEvent.overlay.setMap(null);
        this.overlayCompleteEvent=null;
        this.polygonArray=undefined;
        this.CircleArray =undefined;
        this.ellipseIcon = CIRCLE_ICON;
        this.polygonIcon = POLYGON_ICON;
      }
      if(this.deviceListSection){
        this.reInitializeDeviceListSection();
      }
      if(this.existingGeofenceDetail){
          this.ellipseIcon = CIRCLE_ICON;
          this.polygonIcon = POLYGON_ICON; 
          this.existingGeofenceDetail = undefined;
          this.existViewCircle? this.existViewCircle.setMap(null):null;
          this.existViewPolygon? this.existViewPolygon.setMap(null):null;
      }
       }

/**
      * @ngdoc method
      * @name createFota#getGeoFenceDeviceLIst

      * @methodOf
      * fota.controller:getGeoFenceDeviceLIst
      *
      * @description
      * Description: calling Server Sent Event api from front End by using EventSource polyfill object 
      *              in typescript . for achieving streaming devices data.
      * @param {type} body, buttonType
      * @return {type} null
  */


       public getGeoFenceDeviceLIst(data): Observable<any> {
        const HandleSsePath = '/api/v1/geofence/getdevices';
         return new Observable(observer => {
          let getDeviceInfo ;
         let url;
         if(data.circle==true && data.deviceTypeId==null){
          url = this.env['apiUrl'] + '/devicemgmt'+HandleSsePath+'/'+data.geoFenceId+
                      '?circular='+data.circle;
         } else if(data.circle == false && data.deviceTypeId!==null && data.tags== null){
          url = this.env['apiUrl'] + '/devicemgmt'+HandleSsePath+'/'+data.geoFenceId+
                      '?deviceTypesId='+data.deviceTypeId;
         } else if (data.circle == false && data.deviceTypeId!==null && data.tags!== null){
         url= this.env['apiUrl'] + '/devicemgmt'+HandleSsePath+'/'+data.geoFenceId+
          '?deviceTypesId='+data.deviceTypeId+
          '&tagIds='+data.tags;
         } 
         else if(data.circle==true && data.deviceTypeId!==null && data.tags== null ){
         url= this.env['apiUrl'] + '/devicemgmt'+HandleSsePath+'/'+data.geoFenceId+
          '?circular='+data.circle+
          '&deviceTypesId='+data.deviceTypeId;
         } else if (data.circle==true && data.deviceTypeId!==null && data.tags!== null ){
         url= this.env['apiUrl'] + '/devicemgmt'+HandleSsePath+'/'+data.geoFenceId+
                      '?circular='+data.circle+
                      '&deviceTypesId='+data.deviceTypeId+
                      '&tagIds='+data.tags;
         } else {
          url =  this.env['apiUrl'] + '/devicemgmt'+HandleSsePath+'/'+data.geoFenceId;
         }
         const instance = this.keycloak.getKeycloakInstance();
          getDeviceInfo = new EventSourcePolyfill(url,{
            headers:{
              'Authorization': 'Bearer ' + instance.idToken,
              'realmName':'master'
            }
          })
          let deviceFlag = false;
          getDeviceInfo.onmessage = event => {
              let eventData = JSON.parse(event.data);
              if(eventData.statusCode){
                this.responseHandlerService.returnToastMessage('error',eventData.body);
                event.data={};
                event.data['connectionStatus']="connectionClosed";
                if(data.deviceTypeId !==null){
                  event.data['deleteGeoFence']=true;
                  event.data['geoFenceId']=data.geoFenceId;
                }
                observer.next(event);
                getDeviceInfo.close();
              }
              if(eventData.geoFenceDeviceComplete == false){
                this.noDeviceDataFound=true;
                  observer.next(event);
                  deviceFlag=true;
              } else  {
                event.data={};
                   if(!deviceFlag){
                    this.noDeviceDataFound=false;
                    this.responseHandlerService.returnToastMessage('error',"T_NO_DATA_FOUND");
                   }
                   event.data['connectionStatus']="connectionClosed";
                   observer.next(event);
                   getDeviceInfo.close();
              } 
           };
                getDeviceInfo.onerror = error => {
                  getDeviceInfo.close();
                  observer.error(error);
                  };
             });
      }

  }

