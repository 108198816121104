import { Component,Inject, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TranslateService } from '@ngx-translate/core';
import { BACK_LEFT, PROPOSAL_ICON_NO_FILL, DELETE_IMAGE, DOWNLOAD_ICON, CHEVRON_LEFT} from 'src/app/shared/constants/strings';
import { DatePipe } from '@angular/common';
import { ProposalControllerService } from 'src/app/services/Billing/services';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { GeneralConfigurationControllerService } from 'src/app/services/Billing/services';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { BciLoaderService } from '@bci-web-core/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from 'src/app/providers/translation-service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogPopupComponent } from '../../shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-proposal-details',
  templateUrl: './proposal-details.component.html',
  styleUrls: ['./proposal-details.component.scss']
})
export class ProposalDetailsComponent implements OnInit {
  public showSpinner :any;
  public backImage;
  public proposalImage;
  public proposalData;
  public proposalId;
  public ignoreList = [
    'id',
    'proposalInfrastructureConfigId',
    'proposalPeripheralConfigId',
    'proposalCreditId',
    'currencyType',
    'editable',
    'mssProposalStatus',
    'comments',
    'influencedSTP'
  ];
  public generalConfigIgnoreList = ['proposalCreditId','action','currencyType','creditCap']
  public dropDownValues: any = {};
  public generalConfig;
  public generalConfigDataSource;
  public generalConfigColumns;
  public infraConfig;
  public infraConfigDataSource;
  public infraConfigColumns;
  public peripheralConfig;
  public peripheralConfigDataSource;
  public peripheralConfigColumns;
  public saasConfig;
  public saasConfigDataSource;
  public saasConfigColumns;
  public saasConfigIgnoreList = ['saasId','action','proposalSaasConfigId']
  public proposalDescription: string;
  public proposalAction: string;
  public profileIdNotRequired = false;
  public proposalStatus:any;
  public uploadedDocuments;
  public uploadedDocumentsDataSource;
  public uploadedDocumentsColumns;
  public uploadedDocumentsIgnoreList = ['id','proposalId','url', 'createdDate', 'createdBy'];
  public downloadImage = DOWNLOAD_ICON;
  public deleteImage = DELETE_IMAGE;
  public permissionList;
  public termsAndConditionsControl: FormControl;



  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  constructor(private objectToIdConversionService: ObjectToIdConversionService,private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService, private generalConfigurationControllerService: GeneralConfigurationControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private router: Router, private translate: TranslateService, private proposalControllerService: ProposalControllerService,
    private route: ActivatedRoute, private responseHandlerService: ResponseHandlerService, private datePipe: DatePipe,private loaderService: BciLoaderService,private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translationService: TranslationService) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.proposalData = this.route.snapshot.paramMap['params'];
    this.backImage = CHEVRON_LEFT;
    this.proposalImage = PROPOSAL_ICON_NO_FILL;
    if(this.proposalData['id'] || this.proposalData['proposalId']) {
      this.proposalId = this.proposalData['proposalId']?this.proposalData['proposalId']:this.proposalData['id']
      this.getProposalDetails();
      this.displayUploadedDocuments(this.proposalId);
      this.getAccount();
      this.getRevenueTypes();
      this.getChannelPartner();
      this.getCurrencyTypes();
    }
  }

  public getProposalDetails() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService.getProposalDetails({proposalId:this.proposalId}).subscribe((resp)=> {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.proposalStatus = resp['status']
        this.getTenant(resp['account']['id']);
        this.getProfile(resp['tenant']['id']);
      this.generalConfig = {
           account: resp['account']['id'],
           tenant:resp['tenant']['id'],
           businessProfile:resp['profile']['id'],
           targetCurrency : resp['currencyType']['id'],
           revenueType: resp['revenueType']['id'],
           customerId: resp['customerId'],
           channelPartner:resp['channelType']['id'],
      }

      if(resp['creditInfos'] && resp['creditInfos'].length) {
        this.generalConfigDataSource = resp['creditInfos'];
        this.generalConfigDataSource.forEach(res => {
          res['currency'] = res['currencyType']['name'];
          res['Credit Cap ( % )'] = res['creditCap'];
        })
        this.generalConfigColumns = this.generalConfigFilterColumns(Object.keys(resp['creditInfos'][0]));
      }

        if(resp['infraConfig']?.length) {
        const infraConfigs = [];
        this.infraConfig = resp['infraConfig'] as any[];
        this.infraConfig.forEach((config) => {
          infraConfigs.push({
            proposalInfrastructureConfigId:
              config['proposalInfrastructureConfigId'],
            infraService:
              this.objectToIdConversionService.convertObjectToName(
                config['infraService']
              ),
              customName: config['customName'],
            offerType: this.objectToIdConversionService.convertObjectToName(
              config['offerType']
            ),
            reservationType:
              this.objectToIdConversionService.convertObjectToName(
                config['reservationType']
              ),
            discountPercentage: config['discountPercentage'],
            inControlTower: config['inControlTower'],
           
          });
        });
        this.infraConfigDataSource = infraConfigs;
        this.infraConfigColumns = this.filterColumns(
          Object.keys(infraConfigs[0])
        );
        }
       

        if(resp['peripheralConfig']?.length) {
        const peripheralConfigs = [];
        this.peripheralConfig = resp['peripheralConfig'];
        if (this.peripheralConfig.length) {
          this.peripheralConfig.forEach((config) => {
            peripheralConfigs.push({
              proposalPeripheralConfigId:
                config['proposalPeripheralConfigId'],
              peripheralService:
                this.objectToIdConversionService.convertObjectToName(
                  config['supportType']
                ),
              'discountPercentage (%)': config['discountPercentage'],
            });
          });
          this.peripheralConfigDataSource = peripheralConfigs;
          this.peripheralConfigColumns = this.filterColumns(
            Object.keys(peripheralConfigs[0])
          );
        }
        }
        if(resp['saasConfig']) {
          const saasConfigss = [];
          this.saasConfig = resp['saasConfig'];
          if (this.saasConfig.length) {
            this.saasConfig.forEach((config,index) => {
              saasConfigss.push({
                proposalSaasConfigId:
                  config['proposalSaasConfigId'],
                  saasService:
                  this.objectToIdConversionService.convertObjectToName(
                    config['saasService']
                  ),
                'discountPercentage (%)': config['discountPercentage'],
                onlineListPrice:config['rate']
              });
            });
            this.saasConfigDataSource = saasConfigss;
            this.saasConfigColumns = this.saasConfigFilterColumns(
              Object.keys(saasConfigss[0])
            );
          }
          }
          if(resp['proposalTnc']) {
            this.termsAndConditionsControl = new FormControl('');
            this.termsAndConditionsControl.setValue(resp['proposalTnc'])
          }

        this.loaderService.hideProgressBar(this.showSpinner);
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    })
  }

  public displayUploadedDocuments(proposalId) {
    this.proposalControllerService.getProposalDocuments({ proposalId: proposalId }).subscribe(resp => {
      if (resp) {
        this.uploadedDocuments = resp;
        if(resp.length) {
        for (const data of this.uploadedDocuments) {
          data['uploadedOn'] = this.datePipe.transform(data['createdDate'], 'd-M-yyyy h:mm a');
          data['uploadedBy'] = data['createdBy'];
          data['action'] = null;
        }
        this.uploadedDocumentsDataSource = this.uploadedDocuments;
        this.uploadedDocumentsColumns = this.uploadedDocumentsFilterColumns(Object.keys(this.uploadedDocuments[0]));
      }
    }
    },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public gotoDeleteConfirmDialog(proposalId, id) {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages('DELETE_DOCUMENT_MESSAGE') }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner = this.loaderService.showProgressBar();
        this.proposalControllerService.deleteProposalDocuments({proposalId: proposalId, id: id})
        .subscribe( apiresponse => {
          this.responseHandlerService.returnToastMessage('success', 'PROPOSAL_DOCUMENT_DELETED_SUCCESSFULLY');
          this.loaderService.hideProgressBar(this.showSpinner);
          dialogRef.close();
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      } else {
        this._matDialog.closeAll();
      }
    });
  }
  
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public generalConfigFilterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.generalConfigIgnoreList.indexOf(item) <= -1);
    }
  }

  public saasConfigFilterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.saasConfigIgnoreList.indexOf(item) <= -1);
    }
  }

  public uploadedDocumentsFilterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.uploadedDocumentsIgnoreList.indexOf(item) <= -1);
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getRevenueTypes() {
    this.generalConfigurationControllerService.findAllRevenueTypes().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['revenueType'] = resp;
        } else {
          this.dropDownValues['revenueType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['revenueType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getChannelPartner() {
    this.generalConfigurationControllerService
      .findAllChannelPartners()
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['channelPartner'] = resp;
          } else {
            this.dropDownValues['channelPartner'] = [];
          }
        },
        (err) => {
          this.dropDownValues['channelPartner'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getCurrencyTypes() {
    this.generalConfigurationControllerService.findAllCurrencyTypes().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['targetCurrency'] = resp;
          this.dropDownValues['creditCurrency'] = resp;
        } else {
          this.dropDownValues['targetCurrency'] = [];
          this.dropDownValues['creditCurrency'] = [];
        }
      },
      (err) => {
        this.dropDownValues['targetCurrency'] = [];
        this.dropDownValues['creditCurrency'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public navigateBack() {
    if(this.proposalData.hasOwnProperty('accountId')) {
      this.router.navigate(['billing/proposals',{accountId:this.proposalData['accountId'],tenantId:this.proposalData['tenantId'],profileId:this.proposalData['profileId']}]);
    } else {
      this.router.navigate(['billing/proposals']);
    }
  }

  public gotoProposalApproveRejectDialog(content, action) {
    this.proposalDescription = null;
    this.proposalAction = action;
    if (this.proposalId !== undefined) {
      if (this.proposalId) {
        this.proposalId = this.proposalId
      }
      if (this.proposalId) {
        this.profileIdNotRequired = true;
      }
    }
    this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
  }

  public approveRejectProposal() {
    if (!this.proposalDescription && this.proposalAction === 'REJECT') {
      this.responseHandlerService.returnToastMessage('error', 'REASON_IS_MANDATORY');
      return;
    }
    if (this.proposalId !== undefined) {
      if (this.proposalId) {
        this.proposalId = this.proposalId
      }
      }
    this.showSpinner = this.loaderService.showProgressBar();
    const proposalStatus = this.proposalAction === 'APPROVE';
    const description = this.proposalDescription;
    const bodyJSON = { 'proposalId': this.proposalId, 'status': proposalStatus, 'description': description };
    this.onClose();
    this.proposalControllerService.approveRejectProposals({ body: bodyJSON })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage
          ('success', proposalStatus ? 'PROPOSAL_APPROVE_SUCCESS' : 'PROPOSAL_REJECT_SUCCESS');
          this.router.navigate(['billing/proposals']);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public onClose() {
    this._matDialog.closeAll();
  }

}
