/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AccountControllerService,
  BusinessProfileControllerService,
  DomainControllerService,
  MapControllerService,
  PlatformAdminUtilityControllerService, SolutionTypeControllerService, SubscriptionControllerService, TenantControllerService
} from 'src/app/services/Platform/services';
import {
  DeviceTypeControllerService, IoTHubControllerService, ManufacturerControllerService, DpsControllerService,
  DeviceMgmtUtilityControllerService,
  StorageAccountControllerService,
  AwsIoTHubControllerService,
  ZeliotControllerService
} from 'src/app/services/DeviceMgmt/services';
import { ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/env.service';
import { KeycloakService } from 'keycloak-angular';
import { TranslationService } from 'src/app/providers/translation-service';
import { EDIT_IMAGE, ADD_IMAGE,WHITE_DELETE_ICON, AWS_BUCKET_NAMING_RULES_LINK, AVAILABLE_ICON, NOT_AVAILABLE_ICON } from 'src/app/shared/constants/strings';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { BciLoaderService } from '@bci-web-core/core';
import { SimProviderControllerService } from 'src/app/services/simmanagement/services/sim-provider-controller.service';
import { SimProviderProfileDto } from 'src/app/services/simmanagement/models/sim-provider-profile-dto';
import { CommonDetails } from 'src/app/services/simmanagement/models/common-details';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
/**
  * @ngdoc component
  * @name SettingsModule.component:SettingsComponent
  *
  *
  * @description
  * description: To display and set master data for Super Admin to use like manufacturer names,IoT Hubs,DPS,Device Types etc.
  *
*/
export class SettingsComponent implements OnInit {
  public settingsList: Array<String> = [];
  public formData;
  public jsonFileLoaded: string;
  public settingType: string;
  public data: any[];
  public columns: any[];
  public showSpinner :any;
  public pageTitle: string;
  public permission: string;
  public dropDownValues: any = {};
  public mode: string = null;
  public permissionList: Array<String> = [];
  public column: string;
  public settingName: string;
  public selectSolutionType = false;
  public subscriptionIdObj;
  public bucketNamingRulesLink;
  ignoreList = ['id', 'links', 'componentId', 'active', 'dpsObject', 'solutionTypeObject', 'manufacturerObject', 'fileListObject',
    'primaryConnectionString', 'secondaryConnectionString', 'dps', 'deviceProperties', 'devicePropertyObject', 'manufacturer',
    'solutionTypeIdObject', 'fileTypeExtensionList', 'deviceInterfaceProviderList', 'solutionTypeId', 'isLinkedToBlobStorage',
    'isLinkedToBusinessProfile', 'oauthClientId', 'oauthClientSecret', 'scopes', 'scopesObject', 'thingsNameSpace', 'protocolObject',
    'solDevIntfcProviderId', 'deviceInterfaceProviderObject', 'queueKey', 'region', 'accessKeyId', 'secretAccessKey', 'zeliot',
    'queueName', 'primaryConnString', 'subscriptionIdObject', 'subscription', 'connectionString', 'vortoModelId', 'accessKey',
    'mapProjectApiKeys', 'projectId', 'available', 'protocol', 'isDpsLinkedToIothub', 'iotHubObject','etasClientId','etasClientSecret'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChild('tabs', { static: true }) tabs: MatTabGroup;
  editImage;
  addImage;
  availableImage;
  notAvailableImage;
  solutionTypeValues: any = [];
  selectedSolution: any;
  selectedProvider;
  deviceInterfaceProviderValues: any = [];
  deviceInterfaceProviderForDPS: any = [];
  matTabOptions;
  simMatTabOptions:any=[];
  simActiveTabsIndex:number =0;
  public subscriptionConnectionString: string;
  givenSubscriptionConnectionString: any;
  public profileId: string;
  public tenantId: string;
  public accountId: string;
  public solutionTypeId:string;
  public selectedSolutionType:any;
  public tableTopSpace:any;
  public mapProjectApiKeysLength;
  deleteImage: string;
  public providerList:CommonDetails[];
  public profileList: SimProviderProfileDto[];
  public selectedProfiles: string[];
  public selectedSimProvider: CommonDetails;
  public requiredError: boolean;
  constructor(
    private env: EnvService, private translate: TranslationService, private route: ActivatedRoute, private httpService: HttpClient,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService, private domainControllerService: DomainControllerService,
    private ioTHubControllerService: IoTHubControllerService, private manufacturerControllerService: ManufacturerControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private _matDialog: MatDialog, private responseHandlerService: ResponseHandlerService, private dpsService: DpsControllerService,
    private keycloakAngular: KeycloakService, private deviceMgmtUtilityControllerService: DeviceMgmtUtilityControllerService,
    private objectToIdConversionService: ObjectToIdConversionService, private subscriptionControllerService: SubscriptionControllerService,
    private storageAccountControllerService: StorageAccountControllerService, private _snackBar: MatSnackBar,
    private awsIoTHubControllerService: AwsIoTHubControllerService, private zeliotControllerService: ZeliotControllerService,
    private mapControllerService: MapControllerService,
   private loaderService: BciLoaderService,
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private SimProviderControllerService:SimProviderControllerService) {
  }

  ngOnInit() {
    this.getAccount();
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.httpService
      .get('./assets/form-fields/settings.json')
      .subscribe(data => {
        this.settingsList = data as string[];
        this.settingsList = this.settingsList['fields'];
      });
    if (this.permissionList.indexOf('R_MAP_PRO') > -1) {
      this.fetchData('gmap-configuration', 'GMAP_CONFIGURATION');
    } else {
      this.fetchData('domain', 'DOMAIN');
    }
    this.editImage = EDIT_IMAGE;
    this.deleteImage =WHITE_DELETE_ICON;
    this.addImage = ADD_IMAGE;
    this.availableImage = AVAILABLE_ICON;
    this.notAvailableImage = NOT_AVAILABLE_ICON;
    this.bucketNamingRulesLink = AWS_BUCKET_NAMING_RULES_LINK;
         this.simMatTabOptions=[{ label: "SIM_PROVIDER", value: "sim-provider" },
                                { label: "SIM_PROFILE", value: "sim-profile" },
                                { label: "PROVIDER_&_PROFILE", value: "provider-profile" }];
    }
  /**
    * @ngdoc method
    * @name SettingsComponent#gotoModal
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * opens the modal to perform edit and confirmation operations.
    *
    * @param {type} content,pageName, data, mode
    * @return {type} null
  */
  public gotoModal(content, pageName: string, data = null, mode?) {
     this.formData = data;
    this.pageTitle = pageName;
    this.mode = mode;
    switch (pageName) {
      case 'SIM':
        break;
      case 'IOT_HUB':
        this.getSolutionType();
        if (!this.formData) {
          this.getDpsData(null);
        } else {
          this.getDpsData(this.formData['dpsObject']);
        }
        break;
      case 'DEVICE_TYPE':
        this.dropDownValues['manufacturer'] = [];
        if (this.formData && this.formData['solutionTypeObject']) {
          this.selectedSolution = this.formData['solutionTypeObject']['id'];
          this.getDeviceInterfaceProviders(this.formData['solutionTypeObject']['id']);
        }
        if (this.formData && this.formData['deviceInterfaceProviderObject']) {
          if (this.formData['deviceInterfaceProvider'] === this.env['providerZelIot']) {
            this.getManufacturer('true');
          } else {
            this.getManufacturer('false');
          }
          if (this.formData['manufacturerObject']) {
            this.getSupportedProtocols(this.formData['manufacturerObject']['id']);
          }
          this.getAllDeviceProperties(this.formData['deviceInterfaceProviderObject']['id']);
        }
        this.getSolutionType();
        if (!this.formData) {
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
        break;
      case 'STORAGE_ACCOUNT':
        this.fileFormatList();
        this.getSolutionType();
        if (this.formData && this.formData['solutionTypeObject']) {
          this.getDeviceInterfaceProviders(this.formData['solutionTypeObject']['id']);
        }
        if (this.formData && this.formData['deviceInterfaceProviderObject']['name'] === this.env['providerBosch']) {
          this.getSubscriptions(this.formData['deviceInterfaceProviderObject']['id'], this.formData['solutionTypeObject']['id']);
        }
        if (this.formData && this.formData['deviceInterfaceProviderObject']['name'] === this.env['providerAws']) {
          this.getIotHubList(this.formData['solutionTypeObject']['id'], this.formData['deviceInterfaceProviderObject']['id']);
        }
        break;
      case 'SUBSCRIPTION':
        this.getSolutionType();
        break;
      case 'SOLUTION_TYPE':
        if (this.formData && this.formData['deviceInterfaceProviderList']) {
          if (this.formData['deviceInterfaceProviderList'].length) {
            this.formData['disclaimer'] = true;
          }
          this.formData['deviceInterfaceProviderList'].forEach(providerListItem => {
            this.dropDownValues['deviceInterfaceProvider'].forEach(provider => {
              if (providerListItem.id === provider.id || provider.active === false) {
                provider['isDisabled'] = true;
              }
            });
          });
        }
        break;
      case 'DPS':
        if (this.formData && this.formData['solutionTypeObject']) {
          this.getDeviceInterfaceProviders(this.formData['solutionTypeObject']['id']);
        }
        break;
      case 'AWS_IOT_CORE':
        if (this.formData && this.formData['solutionTypeId']) {
          this.getSolutionType();
        }
        break;
      case 'GMAP_CONFIGURATION':
        this.getRestrictionTypes();
        break;
    }
    if (this.formData && this.formData['manufacturerObject']) {
      this.formData['manufacturer'] = this.objectToIdConversionService.convertIdToObject(this.formData['manufacturerObject']);
    }
    if (this.formData && this.formData['protocolObject']) {
      this.formData['protocol'] = this.objectToIdConversionService.convertIdToObject(this.formData['protocolObject']);
    }
    if (this.formData && this.formData['solutionTypeId']) {
      this.formData['solutionType'] = this.formData['solutionTypeId'];
    }
    if (this.formData && this.formData['solutionTypeObject']) {
      this.formData['solutionType'] = this.objectToIdConversionService.convertIdToObject(this.formData['solutionTypeObject']);
    }
    if (this.formData && this.formData['solutionTypeIdObject']) {
      this.formData['solutionTypeIdObject'] = this.objectToIdConversionService.convertIdToObject(this.formData['solutionTypeIdObject']);
    }
    if (this.formData && this.formData['dpsObject']) {
      this.formData['dps'] = this.objectToIdConversionService.convertIdToObject(this.formData['dpsObject']);
    }
    if (this.formData && this.formData['iotHubObject']) {
      this.formData['iotHub'] = this.objectToIdConversionService.convertIdToObject(this.formData['iotHubObject']);
    }
    if (this.formData && this.formData['subscriptionIdObject']) {
      this.formData['subscriptionId'] = this.objectToIdConversionService.convertIdToObject(this.formData['subscriptionIdObject']);
      this.subscriptionIdObj = this.formData['subscriptionIdObject'];
    }
    if (this.formData && this.formData['scopesObject']) {
      this.formData['scopeString'] = this.formData['scopesObject'];
    }
    if (this.formData && this.formData['devicePropertyObject']) {
      this.formData['deviceProperties'] = this.formData['devicePropertyObject'].map(deviceProperty => {
        if (deviceProperty['active']) {
          return deviceProperty['id'];
        }
      });
    }
    if (this.formData && this.formData['deviceInterfaceProviderObject']) {
      this.formData['deviceInterfaceProvider'] = this.objectToIdConversionService.convertIdToObject
        (this.formData['deviceInterfaceProviderObject']);
    }
    if (this.formData && this.formData['fileListObject']) {
      this.formData['fileTypeExtensionList'] = this.formData['fileListObject'].map(fileType => {
        if (fileType && fileType['active']) {
          return fileType['id'];
        }
      });
    }
    if (this.formData && this.formData['deviceInterfaceProviderList']) {
      this.formData['deviceInterfaceProvider'] = this.formData['deviceInterfaceProviderList'].map(deviceInterfaceProvider => {
        if (deviceInterfaceProvider && deviceInterfaceProvider['active']) {
          return deviceInterfaceProvider['id'];
        }
      });
    }
    if (this.formData && this.formData['mapProjectApiKeys']) {
      this.mapProjectApiKeysLength = this.formData['mapProjectApiKeys'].length
      this.formData['mapProjectApiKeys'].forEach(mapProjectKey => {
        if (typeof (mapProjectKey['restrictionType']) === 'object') {
          mapProjectKey['restrictionType'] = mapProjectKey['restrictionType']['id'];
        }
      });
    }
    if (mode === 'edit' || mode === 'eua') {
      this._matDialog.open(content, { maxHeight: '80vh', minWidth: '15vw', disableClose: true, data });
    } else {
      this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data, autoFocus: false });

    }
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#fetchData
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * fetches the particular master data when the tab is clicked.
      *
      * @param {type} name,title
      * @return {type} null
    */
  public fetchData(name: string, title: string) {
   this.showSpinner = this.loaderService.showProgressBar();
    this.data = null;
    this.columns = null;
    this.settingName = name;
    switch (name) {
       case 'sim':
        this.loaderService.hideProgressBar(this.showSpinner);
        this.pageTitle = title;
        this.simActiveTabsIndex==1? this.simActiveTabsIndex=1: this.simActiveTabsIndex=0;
         this.column = 'single';
         this.settingType = this.jsonFileLoaded = this.simMatTabOptions[this.simActiveTabsIndex].value;
         if(this.settingType=='sim-provider'){
         this.SimProviderControllerService
         .getAllSimProviders()
         .subscribe(
           resp => {
             this.permission = 'SIM_';
             this.loaderService.hideProgressBar(this.showSpinner);
             if (resp?.length) {
               this.data = resp;
              this.columns = ["name","action"];
             } else {
              this.data=null;
               this.loaderService.hideProgressBar(this.showSpinner);
               this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
             }
           },
           err => {
             this.loaderService.hideProgressBar(this.showSpinner);
             this.responseHandlerService.returnToastMessage('error', err.error.message);
           }
         );
          } 
          if(this.settingType =='sim-profile'){
            this.SimProviderControllerService
            .getAllSimProfiles()
            .subscribe(
              resp => {
                this.permission = 'SIM_';
                this.loaderService.hideProgressBar(this.showSpinner);
                if (resp?.length) {
                  this.data = resp;
                 this.columns = ["name","action"];
                } else {
                  this.data=null;
                  this.loaderService.hideProgressBar(this.showSpinner);
                  this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
                }
              },
              err => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', err.error.message);
              }
            );
          }
         break;
      case 'interface-type':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'single';
        this.platformAdminUtilityControllerService
          .findAllInterfaceTypes()
          .subscribe(
            resp => {
              this.permission = 'IT_';
              if (resp && resp.length) {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.data = resp;
                this.columns = this.filterColumns(Object.keys(this.data[0]));
              } else {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
              }
            },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'device':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'multi';
        this.getDeviceInterfaceProviders('');
        this.deviceTypeControllerService
          .findAllDeviceTypes()
          .subscribe(
            resp => {
              this.permission = 'DEVT_';
              this.loaderService.hideProgressBar(this.showSpinner);
              if (resp && resp.length) {
                this.data = resp;
                this.data.forEach((device) => {
                  device['solutionTypeObject'] = device['solutionType'];
                  device['solutionType'] = device['solutionType'].name;
                  device['manufacturerObject'] = device['manufacturer'];
                  device['protocolObject'] = device['protocol'];
                  device['devicePropertyObject'] = device['deviceProperties'];
                  device['deviceInterfaceProviderObject'] = device['deviceInterfaceProvider'];
                  device['deviceInterfaceProvider'] = device['deviceInterfaceProvider'].name;
                  if (device['iotHub']) {
                   device['iotHubObject'] = device['iotHub'];
                   device['iotHub'] = device['iotHub'].name;
                  }
                  if (this.checkPermission('DEVT_U')) {
                    device['action'] = null;
                  }
                });
                this.columns = this.filterColumns(Object.keys(this.data[0]));
              } else {
                 this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
              }
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'iot-cluster':
        this.getDeviceInterfaceProviders('');
        this.selectedProvider = '';
         this.loaderService.hideProgressBar(this.showSpinner);
        break;
      case 'domain':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'single';
        this.loaderService.hideProgressBar(this.showSpinner);
        this.domainControllerService.findAllDomains().subscribe(
          resp => {
            this.permission = 'DOM_';
            if (resp && resp.length) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.data = resp;
              for (const data of this.data) {
                if (this.checkPermission('DOM_U')) {
                  data['action'] = null;
                }
              }
              this.columns = this.filterColumns(Object.keys(this.data[0]));
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
            }
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
      case 'solution-type':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'single';
        this.getDeviceInterfaceProviders('');
        this.solutionTypeControllerService
          .findAllSolutionTypes({})
          .subscribe(
            resp => {
              this.permission = 'ST_';
              this.loaderService.hideProgressBar(this.showSpinner);
              if (resp && resp.length) {
                this.data = resp;
                for (const data of this.data) {
                  const deviceInterfaceProviderValues = [];
                  data['deviceInterfaceProviderList'] = data['deviceInterfaceProvider'];
                  data['deviceInterfaceProviderList'].forEach((deviceInterfaceProvider) => {
                    if (deviceInterfaceProvider['active'] === true) {
                      deviceInterfaceProviderValues.push(this.translate.translateErrorMessages(deviceInterfaceProvider['name']));
                    }
                    data['deviceInterfaceProvider'] = deviceInterfaceProviderValues;
                  });
                  if (this.checkPermission('ST_U')) {
                    data['action'] = null;
                  }
                }
                this.columns = this.filterColumns(Object.keys(this.data[0]));
              } else {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
              }
            },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'device-manufacturer':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'single';
        this.manufacturerControllerService
          .findAllManufacturers()
          .subscribe(
            resp => {
              this.permission = 'MAN_';
              this.loaderService.hideProgressBar(this.showSpinner);
              if (resp && resp.length) {
                this.data = resp;
                for (const data of this.data) {
                  if (this.checkPermission('MAN_U')) {
                    data['action'] = null;
                  }
                }
                this.columns = this.filterColumns(Object.keys(this.data[0]));
              } else {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
              }
            },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'dps':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'multi';
        this.getSolutionType();
        this.getDeviceInterfaceProviders('');
        this.dpsService
          .findAllDps()
          .subscribe(
            resp => {
              this.permission = 'DPS_';
              this.loaderService.hideProgressBar(this.showSpinner);
              if (resp && resp.length) {
                this.data = resp;
                this.data.forEach((dps) => {
                  dps['solutionTypeObject'] = dps['solutionType'];
                  dps['solutionType'] = dps['solutionType'].name;
                  dps['deviceInterfaceProviderObject'] = dps['deviceInterfaceProvider'];
                  dps['deviceInterfaceProvider'] = this.translate.translateErrorMessages(dps['deviceInterfaceProvider'].name);
                  if (this.checkPermission('DPS_U')) {
                    dps['action'] = null;
                  }
                });
                this.columns = this.filterColumns(Object.keys(this.data[0]));
              } else {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
              }
            },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'config-service':
      case 'blob-storage':
        this.permission = 'STR_ACC_';
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'multi';
        this.loaderService.hideProgressBar(this.showSpinner);
        this.selectSolutionType = false;
        this.solutionTypeValues = [];
        this.selectedSolution = '';
        this.selectedProvider = '';
        this.getSolutionTypeValues();
        this.getDeviceInterfaceProviders(this.selectedSolution);
        break;
      case 'gmap-configuration':
        this.pageTitle = title;
        this.settingType = this.jsonFileLoaded = name;
        this.column = 'single';
        this.getRestrictionTypes();
        this.loaderService.hideProgressBar(this.showSpinner);
        this.permission = 'MAP_';
        break;
      case 'eua':
        this.pageTitle = title;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.permission = 'EUA_';
        break;
    }
  }

  public getIotDetails(provider) {
    // this.showSpinner = this.loaderService.showProgressBar();
    this.data = null;
    this.columns = null;
    if (provider === this.env['providerBosch']) {
      this.pageTitle = 'SUBSCRIPTION';
      this.jsonFileLoaded = 'subscription';
      this.settingType = 'iot-cluster';
      this.column = 'multi';
      this.subscriptionControllerService.getAllSubscriptions().subscribe(
        resp => {
          this.permission = 'IOTHUB_';
          this.loaderService.hideProgressBar(this.showSpinner);
          if (resp && resp.length) {
            this.data = resp;
            for (const data of this.data) {
              data['active'] = true;
              data['scopesObject'] = [];
              data['scopes'].forEach(scope => {
                data['scopesObject'].push(scope['scopeString']);
              });
              data['solutionTypeObject'] = data['solutionType'];
              data['solutionType'] = data['solutionType'].name;
              if (this.checkPermission('IOTHUB_U')) {
                data['action'] = null;
              }
            }
            this.columns = this.filterColumns(Object.keys(this.data[0]));
          } else {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    } else if (provider === this.env['providerAws']) {
      this.getSolutionType();
      this.pageTitle = 'AWS_IOT_CORE';
      this.settingType = 'iot-cluster'; this.jsonFileLoaded = 'aws-iot-cluster';
      this.column = 'multi';
       this.loaderService.hideProgressBar(this.showSpinner);
      this.awsIoTHubControllerService.findAllAwsIoTHubs().subscribe(
        resp => {
          this.permission = 'IOTHUB_';
          if (resp && resp.length) {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.data = resp;
            this.data.forEach((iotHub) => {
              iotHub['solutionTypeObject'] = iotHub['solutionType'];
              iotHub['solutionType'] = iotHub['solutionType'].name;
              if (this.checkPermission('IOTHUB_U')) {
                iotHub['action'] = null;
              }
            });
            this.columns = this.filterColumns(Object.keys(this.data[0]));
            this.columns.forEach((columnName, index) => {
              if (columnName === 'deviceInterfaceProvider') {
                this.columns.splice(index, 1);
              }
            });
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    } else if (provider === this.env['providerZelIot']) {
      this.getSolutionType();
      this.pageTitle = 'ZELIOT';
      this.settingType = 'iot-cluster';
      this.jsonFileLoaded = 'zel-iot-cluster';
      this.column = 'multi';
       this.loaderService.hideProgressBar(this.showSpinner);
      this.zeliotControllerService.findAll().subscribe(
        resp => {
           this.loaderService.hideProgressBar(this.showSpinner);
          if (resp && resp.length) {
            this.data = resp;
            this.data.forEach((iotHub) => {
              iotHub['solutionTypeObject'] = iotHub['solutionType'];
              iotHub['solutionType'] = iotHub['solutionType'].name;
              if (this.checkPermission('IOTHUB_U')) {
                iotHub['action'] = null;
              }
            });
            this.columns = this.filterColumns(Object.keys(this.data[0]));
            this.columns.forEach((columnName, index) => {
              if (columnName === 'deviceInterfaceProvider') {
                this.columns.splice(index, 1);
              }
            });
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    } else {
      this.pageTitle = 'IOT_HUB';
      this.settingType = this.jsonFileLoaded = 'iot-cluster';
      this.column = 'multi';
      this.ioTHubControllerService.findAllIoTHubs().subscribe(
        resp => {
          this.permission = 'IOTHUB_';
          if (resp && resp.length) {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.data = resp;
            this.data.forEach((iotHub) => {
              iotHub['dpsObject'] = iotHub['dps'];
              if (this.checkPermission('IOTHUB_U')) {
                iotHub['action'] = null;
              }
              if(!iotHub['etasEnable']) {
                iotHub['etasTenantName'] = null;
                iotHub['etasClientId'] = null;
                iotHub['etasClientSecret'] = null;
              }
            });
            this.columns = this.filterColumns(Object.keys(this.data[0]));
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    }
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#sendData
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * saves a particular record/master data by making the exact POST request for which tab is open.
      *
      * @param {type} data,name
      * @return {type} null
    */

  public sendData(data: any, name: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    switch (name) {
      case 'sim-provider':
        const providerObj=  {
          body:{
            simProvider:{
            name:data.name
          }
        }
        };
        this.SimProviderControllerService.registerSimProvider(providerObj).subscribe(
          resp =>{
            this._matDialog.closeAll();
            this.loaderService.hideProgressBar(this.showSpinner);
            this.fetchData(this.settingName,this.pageTitle);
            this.responseHandlerService.returnToastMessage('success', 'SIM_PROVIDER_REGISTERED');
          },
          err =>{
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        )
        break;
        case 'sim-profile':
          const SimProfileObj=  {
            body:{
              simProfile:{
              name:data.name
            }
          }
          };
          this.SimProviderControllerService.registerSimProfile(SimProfileObj).subscribe(
            resp =>{
              this._matDialog.closeAll();
              this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(this.settingName,this.pageTitle);
              this.responseHandlerService.returnToastMessage('success', 'SIM_PROFILE_REGISTERED');
            },
            err =>{
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          )
          break;
      case 'device':
        if (data['manufacturer']) {
          data['manufacturer'] = this.objectToIdConversionService.convertObjectToId(data['manufacturer']);
        }
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        if (data['deviceInterfaceProvider']) {
          data['deviceInterfaceProvider'] = this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']);
        }
        if (data['protocol']) {
          data['protocol'] = this.objectToIdConversionService.convertObjectToId(data['protocol']);
        }
        if (data['iotHub']) {
          data['iotHub'] = this.objectToIdConversionService.convertObjectToId(data['iotHub']);
        }
        const devicePropertiesValues = this.dropDownValues['deviceProperties'].map(deviceProperty => {
          if (data['deviceProperties'].findIndex(obj => obj === deviceProperty['id']) !== -1) {
            deviceProperty['active'] = true;
            return deviceProperty;
          } else {
            deviceProperty['active'] = false;
            return deviceProperty;
          }
        });
        data['deviceProperties'] = devicePropertiesValues;
        this.deviceTypeControllerService
          .createDeviceType({ body: data })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DEVICE_TYPE_CREATE_SUCCESS');
              this.fetchData(name, this.pageTitle);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'iot-cluster':
        this.saveIotDetails(this.selectedProvider['name'], data);
        break;
      case 'domain':
        this.domainControllerService.createDomain({ body: data }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'DOMAIN_CREATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
             this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
      case 'device-manufacturer':
        this.manufacturerControllerService
          .createManufacturer({ body: data })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DEVICE_MANUFACTURER_CREATE_SUCCESS');
              this.fetchData(name, this.pageTitle);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'dps':
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        if (data['deviceInterfaceProvider']) {
          data['deviceInterfaceProvider'] = this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']);
        }
        this.dpsService
          .createDps({ body: data })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DPS_CREATE_SUCCESS');
              this.fetchData(name, this.pageTitle);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'solution-type':
        if (data['dps']) {
          data['dps'] = this.objectToIdConversionService.convertObjectToId(data['dps']);
        }
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        const selectedDeviceInterfaceList = [];
        if (data.deviceInterfaceProvider) {
          data.deviceInterfaceProvider.forEach(element => {
            selectedDeviceInterfaceList.push(this.objectToIdConversionService.convertObjectToId(element));
          });
          data.deviceInterfaceProvider = selectedDeviceInterfaceList;
        } else {
          data.deviceInterfaceProvider = [];
        }
        this._snackBar.open(this.translate.translateErrorMessages('SOLUTION_TYPE_CREATE_INFO'), 'OK', {
          verticalPosition: 'top',
          panelClass: ['warning']
        });
        this.solutionTypeControllerService
          .createSolutionType({ body: data })
          .subscribe(
            resp => {
              this._snackBar.dismiss();
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'SOLUTION_TYPE_CREATE_SUCCESS');
              this.fetchData(name, this.pageTitle);
            },
            err => {
              this._snackBar.dismiss();
               this.loaderService.hideProgressBar(this.showSpinner);
             this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'config-service':
        this.platformAdminUtilityControllerService
          .saveEventHubDetails({ solutionTypeId: data['solutionTypeId'], body: data })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'CONFIG_SERVICE_CREATE_SUCCESS');
              this.selectedSolution = data.solutionTypeId;
              this.getConfigServices(data.solutionTypeId);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'blob-storage':
        data['active'] = true;
        if (this.selectedProvider.name !== this.env['providerZelIot']) {
          const fileList = this.dropDownValues['fileTypeExtensionList'].map(fileType => {
            if (data['fileTypeExtensionList'].findIndex(fileTypeExtension => fileTypeExtension === fileType['id']) !== -1) {
              fileType['active'] = true;
              return fileType;
            } else {
              fileType['active'] = false;
              return fileType;
            }
          });
          data['fileTypeExtensionList'] = fileList;
        } else if (this.selectedProvider.name === this.env['providerZelIot']) {
          data['fileSize'] = 0;
          data['maxNoFiles'] = 0;
          data['fileTypeExtensionList'] = [];
        }
        if (data['subscriptionId']) {
          data['subscription'] = this.objectToIdConversionService.convertObjectToId(data['subscriptionId']);
        }
        if (data['deviceInterfaceProvider']) {
          data['deviceInterfaceProvider'] = this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']);
        }
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        if (data['iotHub']) {
          data['iotHub'] = this.objectToIdConversionService.convertObjectToId(data['iotHub']);
        }
        this.storageAccountControllerService
          .saveStorageAccount({ body: data })
          .subscribe(
            resp => {
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'STORAGE_ACCOUNT_CREATE_SUCCESS');
              this.getStorageAccounts(data.solutionType.id);
               this.loaderService.hideProgressBar(this.showSpinner);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.getStorageAccounts(data.solutionType.id);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'gmap-configuration':
        data['mapProjectApiKeys'].forEach(key => {
          key['restrictionType'] = this.objectToIdConversionService.convertObjectToId(key['restrictionType']);
        });
        const mapProjectDto = {
          project: { name: data['project'], active: true },
          mapProjectApiKeys: data['mapProjectApiKeys']
        };
        this.mapControllerService
          .saveMapProjectForProfile({profileId:this.profileId,solutionTypeId:this.solutionTypeId,body: mapProjectDto })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'PROJECT_CREATE_SUCCESS');
              this.getDataBysolutionTypes();
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
    }
  }

  public saveIotDetails(provider, data) {
    switch (provider) {
      case this.env['providerAzure']:
        if (data['dps']) {
          data['dps'] = this.objectToIdConversionService.convertObjectToId(data['dps']);
        }
        this.ioTHubControllerService.createIoTHub({ body: data }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_CREATE_SUCCESS');
            this.getIotDetails(this.env['providerAzure']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
      case this.env['providerBosch']:
        this.deviceInterfaceProviderValues.forEach(deviceInterfaceProvider => {
          if (deviceInterfaceProvider.name === this.env['providerBosch']) {
            data['deviceInterfaceProvider'] = deviceInterfaceProvider['id'];
          }
        });
        const subscriptionDto = {
          deviceInterfaceProvider: data['deviceInterfaceProvider'],
          subscriptionName: data['subscriptionName'],
          oauthClientId: data['oauthClientId'],
          oauthClientSecret: data['oauthClientSecret'],
          primaryConnString: this.subscriptionConnectionString,
          scopes: data['scopes'],
          thingsNameSpace: data['thingsNameSpace']
        };
        this.subscriptionControllerService.saveSubscription({
          solutionTypeId: data['solutionType'],
          subscriptionId: data['subscriptionId'], body: subscriptionDto
        })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'SUBSCRIPTION_CREATE_SUCCESS');
              this.getIotDetails(this.env['providerBosch']);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case this.env['providerAws']:
        this.deviceInterfaceProviderValues.forEach(deviceInterfaceProvider => {
          if (deviceInterfaceProvider.name === this.env['providerAws']) {
            data['deviceInterfaceProvider'] = deviceInterfaceProvider['id'];
          }
        });
        //  this.showSpinner = this.loaderService.showProgressBar();
        const awsIoTHubDto = {
          deviceInterfaceProvider: data['deviceInterfaceProvider'],
          accessKeyId: data['accessKeyId'],
          awsIotCoreName: data['awsIotCoreName'],
          description: data['description'],
          secretAccessKey: data['secretAccessKey'],
          solutionType: this.objectToIdConversionService.convertObjectToId(data['solutionType'])
        };
        this.awsIoTHubControllerService.createAwsIoTHub({ body: awsIoTHubDto }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'AWS_IOT_CREATE_SUCCESS');
            this.getIotDetails(this.env['providerAws']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
      case this.env['providerZelIot']:
        this.deviceInterfaceProviderValues.forEach(deviceInterfaceProvider => {
          if (deviceInterfaceProvider.name === this.env['providerZelIot']) {
            data['deviceInterfaceProvider'] = deviceInterfaceProvider['id'];
          }
        });
        //  this.showSpinner = this.loaderService.showProgressBar();
        const ZeliotDto = {
          deviceInterfaceProvider: data['deviceInterfaceProvider'],
          accessKey: data['accessKey'],
          zelIotName: data['zelIotName'],
          description: data['description'],
          endpointUrl: data['endpointUrl'],
          solutionType: this.objectToIdConversionService.convertObjectToId(data['solutionType'])
        };
        this.zeliotControllerService.createZeliot({ body: ZeliotDto }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_CREATE_SUCCESS');
            this.getIotDetails(this.env['providerZelIot']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
    }
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#updateData
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * updates a particular record/master data by making the exact PATCH request for which tab is open
      * for a particular Id.
      *
      * @param {type} data,name
      * @return {type} null
    */
  public updateData(data: any, name: string) {
     this.showSpinner = this.loaderService.showProgressBar();
    switch (name) {
      case 'device':
        if (data['manufacturer']) {
          data['manufacturer'] = this.objectToIdConversionService.convertObjectToId(data['manufacturer']);
        }
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        if (data['deviceInterfaceProvider']) {
          data['deviceInterfaceProvider'] = this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']);
        }
        if (data['protocol']) {
          data['protocol'] = this.objectToIdConversionService.convertObjectToId(data['protocol']);
        }
        if (data['iotHub']) {
          data['iotHub'] = this.objectToIdConversionService.convertObjectToId(data['iotHub']);
        }
        const devicePropertiesValues = this.dropDownValues['deviceProperties'].map(deviceProperty => {
          if (data['deviceProperties'].findIndex(obj => obj === deviceProperty['id']) !== -1) {
            deviceProperty['active'] = true;
            return deviceProperty;
          } else {
            deviceProperty['active'] = false;
            return deviceProperty;
          }
        });
        data['deviceProperties'] = devicePropertiesValues;
        this.deviceTypeControllerService
          .updateDeviceType({
            id: this.formData['id'],
            body: data
          })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(name, this.pageTitle);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DEVICE_TYPE_EDIT_SUCCESS');
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              if (this.formData) {
                this.onClose(this.formData, err);
              } else {
                this.onClose(data, err);
               }
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'iot-cluster':
        this.updateIotDetails(this.selectedProvider['name'], data);
        break;
      case 'domain':
        this.domainControllerService
          .updateDomain({ id: this.formData['id'], body: data })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(name, this.pageTitle);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DOMAIN_EDIT_SUCCESS');
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'device-manufacturer':
        this.manufacturerControllerService
          .updateManufacturer({
            id: this.formData['id'],
            body: data
          })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(name, this.pageTitle);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DEVICE_MANUFACTURER_EDIT_SUCCESS');
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'dps':
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        if (data['deviceInterfaceProvider']) {
          data['deviceInterfaceProvider'] = this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']);
        }
        this.dpsService
          .updateDps({
            id: this.formData['id'],
            body: data
          })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(name, this.pageTitle);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'DPS_EDIT_SUCCESS');
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              if (this.formData) {
                this.onClose(this.formData, err);
              } else {
                this.onClose(data, err);
              }
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'solution-type':
        if (data['dps']) {
          data['dps'] = this.objectToIdConversionService.convertObjectToId(data['dps']);
        }
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        const selectedDeviceInterfaceList = [];
        if (data.deviceInterfaceProvider) {
          data.deviceInterfaceProvider.forEach(element => {
            selectedDeviceInterfaceList.push(this.objectToIdConversionService.convertObjectToId(element));
          });
          data.deviceInterfaceProvider = selectedDeviceInterfaceList;
        } else {
          data.deviceInterfaceProvider = [];
        }
        this.solutionTypeControllerService
          .updateSolutionType({
            id: this.formData['id'],
            body: data
          })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(name, this.pageTitle);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'SOLUTION_TYPE_EDIT_SUCCESS');
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              if (this.formData) {
                this.onClose(this.formData, err);
              } else {
                this.onClose(data, err);
              }
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'config-service':
        this.platformAdminUtilityControllerService
          .updateEventHubDetails({
            solutionTypeId: this.formData['solutionTypeId'],
            body: data
          })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'CONFIG_SERVICE_EDIT_SUCCESS');
              this.selectedSolution = data.solutionTypeId;
              this.getConfigServices(data.solutionTypeId);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'blob-storage':
        data['active'] = true;
        if (this.selectedProvider.name !== this.env['providerZelIot']) {
          const fileListValues = this.dropDownValues['fileTypeExtensionList'].map((fileType) => {
            if (data['fileTypeExtensionList'].findIndex(fileTypeExtension => fileTypeExtension === fileType['id']) !== -1) {
              fileType['active'] = true;
              return fileType;
            } else {
              fileType['active'] = false;
              return fileType;
            }
          });
          data['fileTypeExtensionList'] = fileListValues;
        } else if (this.selectedProvider.name === this.env['providerZelIot']) {
          data['fileSize'] = 0;
          data['maxNoFiles'] = 0;
          data['fileTypeExtensionList'] = [];
        }
        if (data['deviceInterfaceProvider']) {
          data['deviceInterfaceProvider'] = this.objectToIdConversionService.convertObjectToId(data['deviceInterfaceProvider']);
        }
        if (data['solutionType']) {
          data['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
        }
        if (data['subscriptionId']) {
          data['subscription'] = this.objectToIdConversionService.convertObjectToId(data['subscriptionId']);
        }
        if (data['iotHub']) {
          data['iotHub'] = this.objectToIdConversionService.convertObjectToId(data['iotHub']);
        }
        this.storageAccountControllerService
          .updateStorageAccount({
            storageAccountId: this.formData.id,
            body: data
          })
          .subscribe(
            resp => {
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'STORAGE_ACCOUNT_EDIT_SUCCESS');
              this.getStorageAccounts(data.solutionType.id);
               this.loaderService.hideProgressBar(this.showSpinner);
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              if (this.formData) {
                this.onClose(this.formData, err);
              } else {
                this.onClose(data, err);
              }
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case 'gmap-configuration':
        data['mapProjectApiKeys'].forEach(key => {
          if (!key['id']) {
            key['action'] = 'ADD';
          } else {
            key['action'] = 'UPDATE';
          }
          key['restrictionType'] = this.objectToIdConversionService.convertObjectToId(key['restrictionType']);
        });
        const deletedMapKeys = this.dynamicForm.dataSourceDeletedRows;
        if (deletedMapKeys && deletedMapKeys.length > 0) {
          deletedMapKeys.forEach(deletedkey => {
            deletedkey['restrictionType'] = this.objectToIdConversionService.convertObjectToId(deletedkey['restrictionType']);
          });
          data['mapProjectApiKeys'] = data['mapProjectApiKeys'].concat(deletedMapKeys);
        }
        const mapProjectDto = {
          project: { name: data['project'], active: this.formData['active'] },
          mapProjectApiKeys: data['mapProjectApiKeys']
        };
        this.mapControllerService
          .updateMapProject({ mapProjectId: this.formData['projectId'], body: mapProjectDto })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'PROJECT_UPDATE_SUCCESS');
              this.getDataBysolutionTypes();
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
             this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
    }
  }

  public updateIotDetails(provider, data) {
    //  this.showSpinner = this.loaderService.showProgressBar();
    switch (provider) {
      case this.env['providerAzure']:
        if (data['dps']) {
          data['dps'] = this.objectToIdConversionService.convertObjectToId(data['dps']);
        }
        this.ioTHubControllerService
          .updateIoTHub({ id: this.formData['id'], body: data })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.getIotDetails(this.env['providerAzure']);
              this.formData = null;
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'IOTHUB_EDIT_SUCCESS');
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              if (this.formData) {
                this.onClose(this.formData, err);
              } else { this.onClose(data, err); }
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
        break;
      case this.env['providerBosch']:
        this.deviceInterfaceProviderValues.forEach(deviceInterfaceProvider => {
          if (deviceInterfaceProvider.name === this.env['providerBosch']) {
            data['deviceInterfaceProvider'] = deviceInterfaceProvider['id'];
          }
        });
        const subscriptionDto = {
          deviceInterfaceProvider: data['deviceInterfaceProvider'],
          oauthClientId: data['oauthClientId'],
          oauthClientSecret: data['oauthClientSecret'],
          primaryConnString: this.subscriptionConnectionString,
          scopes: data['scopes'],
          thingsNameSpace: data['thingsNameSpace']
        };
        this.subscriptionControllerService.updateSubscription({
          solutionTypeId: data['solutionType'],
          subscriptionId: data['subscriptionId'], body: subscriptionDto
        })
          .subscribe(resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.getIotDetails(this.env['providerBosch']);
            this.formData = null;
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'SUBSCRIPTION_EDIT_SUCCESS');
          },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              if (this.formData) {
                this.onClose(this.formData, err);
              } else { this.onClose(data, err); }
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
        break;
      case this.env['providerAws']:
        this.deviceInterfaceProviderValues.forEach(deviceInterfaceProvider => {
          if (deviceInterfaceProvider.name === this.env['providerAws']) {
            data['deviceInterfaceProvider'] = deviceInterfaceProvider['id'];
          }
        });
        // this.showSpinner = this.loaderService.showProgressBar();
        const awsIoTHubDto = {
          deviceInterfaceProvider: data['deviceInterfaceProvider'],
          accessKeyId: data['accessKeyId'],
          awsIotCoreName: data['awsIotCoreName'],
          description: data['description'],
          secretAccessKey: data['secretAccessKey'],
          solutionType: this.objectToIdConversionService.convertObjectToId(data['solutionType'])
        };
        this.awsIoTHubControllerService.updateAwsIoTHub({ id: this.formData['id'], body: awsIoTHubDto }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'AWS_IOT_EDIT_SUCCESS');
            this.getIotDetails(this.env['providerAws']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
      case this.env['providerZelIot']:
        this.deviceInterfaceProviderValues.forEach(deviceInterfaceProvider => {
          if (deviceInterfaceProvider.name === this.env['providerZelIot']) {
            data['deviceInterfaceProvider'] = deviceInterfaceProvider['id'];
          }
        });
        //  this.showSpinner = this.loaderService.showProgressBar();
        const zelIotDto = {
          deviceInterfaceProvider: data['deviceInterfaceProvider'],
          accessKey: data['accessKey'],
          zelIotName: data['zelIotName'],
          description: data['description'],
          endpointUrl: data['endpointUrl'],
          solutionType: this.objectToIdConversionService.convertObjectToId(data['solutionType'])
        };
        this.zeliotControllerService.updateZeliot({ id: this.formData['id'], body: zelIotDto }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this._matDialog.closeAll();
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_EDIT_SUCCESS');
            this.getIotDetails(this.env['providerZelIot']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
        break;
    }
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#activateUser
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * activate a particular record/master data by making the exact PATCH request for which tab is open
      * for a particular Id.
      *
      * @param {type} content,pageName, data, mode
      * @return {type} null
    */
  public activateUser(data: any, name: string) {
    this._matDialog.closeAll();
    this.formData['active'] = true;
     this.showSpinner = this.loaderService.showProgressBar();
    switch (name) {
      case 'domain':
        this.domainControllerService.activateDomain({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DOMAIN_ACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'device':
        this.deviceTypeControllerService.activateDeviceType({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DEVICE_TYPE_ACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'iot-cluster':
        this.ioTHubControllerService.activateIoTHub({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_ACTIVATE_SUCCESS');
            this.getIotDetails(this.selectedProvider['name']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'device-manufacturer':
        this.manufacturerControllerService.activateManufacturer({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DEVICE_MANUFACTURER_ACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'dps':
        this.dpsService.activateDps({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DPS_ACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'solution-type':
        this.solutionTypeControllerService.activateSolutionType({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'SOLUTION_TYPE_ACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'aws-iot-cluster':
        this.awsIoTHubControllerService.activateAwsIoTHub({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'AWS_IOT_CORE_ACTIVATE_SUCCESS');
            this.getIotDetails(this.selectedProvider['name']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'zel-iot-cluster':
        this.zeliotControllerService.activateZeliot({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_ACTIVATE_SUCCESS');
            this.getIotDetails(this.selectedProvider['name']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'gmap-configuration':
        const mapProjectDto = {
          project: { name: data['project'], active: data['active'] },
          mapProjectApiKeys: data['mapProjectApiKeys']
        };
        this.mapControllerService
          .updateMapProject({ mapProjectId: data['projectId'], body: mapProjectDto })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'PROJECT_ACTIVATE_SUCCESS');
              this.getDataBysolutionTypes();
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
              this.formData['active'] = !this.formData['active'];
            }
          );
        break;
    }
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#deActivateUser
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      *deactivates a particular record/master data by making the exact PATCH request for which tab is open
      * for a particular Id.
      *
      * @param {type} data,name
      * @return {type} null
    */
  public deActivateUser(data: any, name: string) {
    this._matDialog.closeAll();
     this.showSpinner = this.loaderService.showProgressBar();
    switch (name) {
      case 'domain':
        this.domainControllerService.deactivateDomain({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DOMAIN_DEACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'device':
        this.deviceTypeControllerService.deactivateDeviceType({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DEVICE_TYPE_DEACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'iot-cluster':
        this.ioTHubControllerService.deactivateIoTHub({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_DEACTIVATE_SUCCESS');
            this.getIotDetails(this.selectedProvider['name']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'device-manufacturer':
        this.manufacturerControllerService.deactivateManufacturer({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DEVICE_MANUFACTURER_DEACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'dps':
        this.dpsService.deactivateDps({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DPS_DEACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'solution-type':
        this.solutionTypeControllerService.deactivateSolutionType({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'SOLUTION_TYPE_DEACTIVATE_SUCCESS');
            this.fetchData(name, this.pageTitle);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'aws-iot-cluster':
        this.awsIoTHubControllerService.deactivateAwsIoTHub({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'AWS_IOT_CORE_DEACTIVATE_SUCCESS');
            this.getIotDetails(this.selectedProvider['name']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'zel-iot-cluster':
        this.zeliotControllerService.deactivateZeliot({ id: this.formData['id'] }).subscribe(
          resp => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'IOTHUB_DEACTIVATE_SUCCESS');
            this.getIotDetails(this.selectedProvider['name']);
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.formData['active'] = !this.formData['active'];
          }
        );
        break;
      case 'gmap-configuration':
        const mapProjectDto = {
          project: { name: data['project'], active: data['active'] },
          mapProjectApiKeys: data['mapProjectApiKeys']
        };
        this.mapControllerService
          .updateMapProject({ mapProjectId: data['projectId'], body: mapProjectDto })
          .subscribe(
            resp => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this._matDialog.closeAll();
              this.responseHandlerService.returnToastMessage('success', 'PROJECT_DEACTIVATE_SUCCESS');
              this.getDataBysolutionTypes();
            },
            err => {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
              this.formData['active'] = !this.formData['active'];
            }
          );
        break;
    }
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#abortAction
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * action when abort button is clicked in any confirmation popup
      *
      * @param {type} null
      * @return {type} null
    */
  public abortAction() {
    this._matDialog.closeAll();
    if (this.mode === 'edit') {
      this.formData['active'] = !this.formData['active'];
    }
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#resetEua
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * resest the EUA of all the users of the admin
    *
    * @param {type} null
    * @return {type} null
  */
  public resetEua() {
    this._matDialog.closeAll();
     this.showSpinner = this.loaderService.showProgressBar();
    this.platformAdminUtilityControllerService.resetEula({ realm: this.env['realm'] })
      .subscribe(
        resp => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'EUA_RESET_SUCCESS');
          this.keycloakAngular.logout();
        },
        err => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#filterColumns
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * used to filter/group into array the selected columns of mat table
      *
      * @param {type} columns
      * @return {type} list of columns
    */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter(item => this.ignoreList.indexOf(item) <= -1);
    }
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#checkPermission
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * checks if a componenet has to be displaye for that particular user/role by looking out or the
    * permissions in the list
    *
    * @param {type} key
    * @return {type} boolean mentioning if componenent can be displayed or not
  */
  public checkPermission(key: string) {
     if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getManufacturer
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of manufacturers using GET method
    *
    * @param {type} null
    * @return {type} null
  */
  /* @description: to populate manufacturer dropdown */
  public getManufacturer(isZeliot) {
    this.manufacturerControllerService
      .findAllManufacturers({ active: 'true', zeliot: isZeliot })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['manufacturer'] = resp;
          } else {
            this.dropDownValues['manufacturer'] = [];
          }
        },
        err => {
          this.dropDownValues['manufacturer'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getRestrictionTypes() {
    this.mapControllerService
      .getAllRestrictionTypes()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['restrictionType'] = resp;
          } else {
            this.dropDownValues['restrictionType'] = [];
          }
        },
        err => {
          this.dropDownValues['restrictionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getSolutionType
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of solution types using GET method
    *
    * @param {type} null
    * @return {type} null
  */
  /* @description: to populate solution type dropdown */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;

            // to filter solutionTypes mapped with Bosch-Iot-suite, AWS_IoT_Core and ZELTOT provider
            if (this.settingType === 'iot-cluster') {
              const solutionList = [];
              const solutionListForAws = [];
              const solutionListForZelIoT = [];
              this.dropDownValues['solutionType'] = this.dropDownValues['solutionType'].map(solution => {
                solution['deviceInterfaceProvider'].forEach(provider => {
                  if (provider['name'] === this.env['providerBosch']) {
                    solutionList.push(solution);
                  } else if (provider['name'] === this.env['providerAws']) {
                    solutionListForAws.push(solution);
                  } else if (provider['name'] === this.env['providerZelIot']) {
                    solutionListForZelIoT.push(solution);
                  }
                });
              });
              if (this.selectedProvider['name'] === this.env['providerBosch'] && solutionList) {
                this.dropDownValues['solutionType'] = solutionList;
              } else if (this.selectedProvider['name'] === this.env['providerAws'] && solutionListForAws) {
                this.dropDownValues['solutionType'] = solutionListForAws;
              } else if (this.selectedProvider['name'] === this.env['providerZelIot'] && solutionListForZelIoT) {
                this.dropDownValues['solutionType'] = solutionListForZelIoT;
              }
            }
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getSolutionTypeConfig
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of solution types using GET method
    *
    * @param {type} null
    * @return {type} null
  */
  /* @description: to populate solution type dropdown */
  public getSolutionTypeValues() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionTypeId'] = resp;
            this.solutionTypeValues = resp;
          } else {
            this.dropDownValues['solutionTypeId'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionTypeId'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
  * @ngdoc method
  * @name SettingsComponent#getAllDeviceProperties
  *
  * @methodOf
  * SettingsModule.controller:SettingsComponent
  *
  * @description
  * to populatedevice properties
  *
  * @param {type} null
  * @return {type} null
*/
  public getAllDeviceProperties(deviceInterfaceProviderId: string) {
    this.deviceMgmtUtilityControllerService
      .getAllDeviceProperties({ providerId: deviceInterfaceProviderId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['deviceProperties'] = resp;
          } else {
            this.dropDownValues['deviceProperties'] = [];
          }
        },
        err => {
          this.dropDownValues['deviceProperties'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getDataBySolutionId
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of configService/storageaccount data based on solutiontypeId
    *
    * @param {type} null
    * @return {type} null
  */
  public getDataBySolutionId(solutionTypeId) {
    this.data = null;
    this.columns = null;
    this.selectSolutionType = false;
    if (!solutionTypeId) {
      this.selectSolutionType = true;
    } else {
      if (this.settingName === 'config-service') {
        this.getConfigServices(solutionTypeId);
      } else {
        this.getStorageAccounts(solutionTypeId);
      }
    }
  }

  public getDataByProvider(provider) {
    this.data = null;
    this.columns = null;
    if (this.settingName === 'iot-cluster') {
      this.getIotDetails(provider['name']);
    } else if (this.settingName === 'blob-storage') {
      this.getStorageAccounts(provider['id']);
    }
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getConfigServices
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of config services based on solutiontypeId
    *
    * @param {type} null
    * @return {type} null
  */
  public getConfigServices(solutionTypeId) {
     this.showSpinner = this.loaderService.showProgressBar();
    if (solutionTypeId) {
      this.platformAdminUtilityControllerService
        .getEventHubDetailsById({ solutionTypeId: solutionTypeId })
        .subscribe(
          resp => {
            this.permission = 'EVENTHUB_';
             this.loaderService.hideProgressBar(this.showSpinner);
            if (resp) {
              const eventHubValue = [];
              eventHubValue.push(resp);
              this.data = eventHubValue;
              this.data.forEach((device) => {
                device['solutionTypeIdObject'] = device['solutionTypeId'];
                if (this.checkPermission('EVENTHUB_U')) {
                  device['action'] = null;
                  device['active'] = true;
                }
              });
              this.columns = this.filterColumns(Object.keys(this.data[0]));
            } else {
               this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
            }
          },
          err => {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {
      this.selectSolutionType = true;
    }
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getStorageAccounts
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of storage accounts based on solutiontypeId
    *
    * @param {type} null
    * @return {type} null
  */
  public getStorageAccounts(solutionTypeId) {
    //  this.showSpinner = this.loaderService.showProgressBar();
    this.storageAccountControllerService
      .getAllStorageAccount({ solutionTypeId })
      .subscribe(
        resp => {
          this.permission = 'STR_ACC_';
           this.loaderService.hideProgressBar(this.showSpinner);
          if (resp && resp.length) {
            this.data = resp;
            this.data.forEach((blob) => {
              blob['subscriptionIdObject'] = blob['subscription'];
              if (blob['subscription']) {
                blob['subscriptionName'] = blob['subscription']['name'];
              }
              blob['solutionTypeObject'] = blob['solutionType'];
              blob['deviceInterfaceProviderObject'] = blob['deviceInterfaceProvider'];
              blob['deviceInterfaceProvider'] = blob['deviceInterfaceProvider'].name;
              blob['fileListObject'] = blob['fileTypeExtensionList'];
              if (blob['iotHub']) {
                blob['iotHubObject'] = blob['iotHub'];
                blob['iotHub'] = blob['iotHub']['name'];
              }
              if (this.checkPermission('STR_ACC_U')) {
                blob['action'] = null;
              }
            });
            this.columns = this.filterColumns(Object.keys(this.data[0]));
            this.columns.forEach((columnName, index) => {
              if (columnName === 'solutionType') {
                this.columns.splice(index, 1);
              }
            });
          } else {
             this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
           this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getDpsData
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of DPS using GET method
    *
    * @param {type} dpsData linked dps object
    * @return {type} null
  */
  /* @description: to populate dps dropdown */
  public getDpsData(dpsData) {
    const dpsList = [];
    this.dpsService.findAllDps({ active: 'true' }).subscribe(
      resp => {
        if (resp && resp.length) {
          if (this.settingType === 'iot-cluster' && this.selectedProvider['name'] === this.env['providerAzure']) {
            resp.forEach(data => {
              if (dpsData?.id === data.id) {
                dpsList.push(data);
              }
              if (!data.isDpsLinkedToIothub) {
                dpsList.push(data);
              }
            });
            if (dpsList.length) {
              this.dropDownValues['dps'] = dpsList;
            }
          } else {
              this.dropDownValues['dps'] = resp;
          }
        } else {
            this.dropDownValues['dps'] = [];
          }
    },
    err => {
      this.dropDownValues['dps'] = [];
       this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#fileFormatList
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of file types using GET method
    *
    * @param {type} null
    * @return {type} null
  */
  /* @description: to populate formats dropdown */
  public fileFormatList() {
    this.deviceMgmtUtilityControllerService
      .getAllFileTypeExtensions()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['fileTypeExtensionList'] = resp;
          } else {
            this.dropDownValues['fileTypeExtensionList'] = [];
          }
        },
        err => {
          this.dropDownValues['fileTypeExtensionList'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

 /**
   * @ngdoc method
   * @name SettingsComponent#onClose
   *
   * @methodOf
   * SettingsModule.controller:SettingsComponent
   *
   * @description
   * closes the modal to perform edit and confirmation operations on clicking close button .
   *
   * @param {type} data
   * @return {type} null
   */
  public onClose(data: any, err?: any) {
    // to convert the objcts to their resp names afetr the modal is closed
    if (this.formData) {
      this.formData['deviceInterfaceProvider'] = this.formData['deviceInterfaceProviderObject'] &&
        this.formData['deviceInterfaceProviderObject']['name'];
      this.formData['solutionType'] = this.formData['solutionTypeObject'] && this.formData['solutionTypeObject']['name'];
      if (this.formData['deviceInterfaceProviderList']) {
        const deviceInterfaceProviderValues = [];
        this.formData['deviceInterfaceProviderList'].forEach((deviceInterfaceProvider) => {
          if (deviceInterfaceProvider['active'] === true) {
            deviceInterfaceProviderValues.push(deviceInterfaceProvider['name']);
          }
          this.formData['deviceInterfaceProvider'] = deviceInterfaceProviderValues;
        });
        this.dropDownValues['deviceInterfaceProvider'].forEach(provider => {
          if (provider['isDisabled']) {
            provider['isDisabled'] = false;
          }
        });
      }
    }
    if (this.settingType === 'blob-storage' && this.formData && this.formData['iotHubObject']) {
      this.formData['iotHub'] = this.formData['iotHubObject']['name'];
    }
    if (this.settingType === 'dps' && this.deviceInterfaceProviderForDPS) {
      this.dropDownValues['deviceInterfaceProvider'] = this.deviceInterfaceProviderForDPS;
    }
     // tslint:disable-next-line
    err ? null:this._matDialog.closeAll();
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#matChange
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * to trigger action like fetching data in case tab changes.
    *
    * @param {type} click event
    * @return {type} null
  */
  public matChange(event) {
    if (this.permissionList.indexOf(this.settingsList[event.index]['permission']) > -1) {
      this.fetchData(this.settingsList[event.index]['type'], this.settingsList[event.index]['title']);
    }
    }

  public gotoConfirmDialog(data = null, settingName: string, mode?) {
    this.formData = data;
    this.mode = mode;
    let message;
    switch (mode) {
      case 'edit': if ((data['active'])) {
        message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_' + settingName);
      } else {
        message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_' + settingName);
      }
        break;
      case 'eua': message = this.translate.translateErrorMessages('RESET_EUA_SETTINGS');
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'edit': if (data['active']) {
            this.activateUser(data, settingName);
          } else {
            this.deActivateUser(data, settingName);
          }
            break;
          case 'eua': this.resetEua();
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#getDeviceInterFaceProvider
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * gets the list of device interface providers using GET method
      *
      * @param {type} solutionTypeId,profileId
      * @return {type} null
    */
  /* @description: to populate device interface provider dropdown */
  public getDeviceInterfaceProviders(solutionTypeId: string) {
    this.platformAdminUtilityControllerService
      .deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '' , active: 'true'})
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['deviceInterfaceProvider'] = resp;
            this.deviceInterfaceProviderValues = resp;
            /* To avoid multiple calls of deviceIntefaceProvider api while creating DPS, storing list
            of providers in 'deviceInterfaceProviderForDPS' while loading DPS tab*/
            if (this.settingType === 'dps' && resp.length > 1) {
              this.deviceInterfaceProviderForDPS = resp;
            }
          } else {
            if (resp && !resp.length) {
              this.responseHandlerService.returnToastMessage('error', 'SELECT_DIFFERENT_SOLUTION');
            }
            this.dropDownValues['deviceInterfaceProvider'] = [];
          }
        },
        err => {
          this.dropDownValues['deviceInterfaceProvider'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
      * @ngdoc method
      * @name SettingsComponent#getSelectedDeviceInterfaceProvider
      *
      * @methodOf
      * SettingsModule.controller:SettingsComponent
      *
      * @description
      * gets the selected device interface providers and submit button details from dynamic form
      *
      * @param {type} $event
      * @return {type} null
    */
  /* @description: to enable or disable form fields, based on device interface provider dropdown's value "BOSCH_IOC" */
  public getSelectedDeviceInterfaceProvider(event) {
    if (this.settingType === 'dps' && event.dropdownValue) {
      if (event.dropdownValue.name === this.env['providerBosch']) {
        this.responseHandlerService.returnToastMessage('warning', 'DPS_IS_NOT_APPLICABLE_FOR_BOSCH-IOC');
        event.submitButton['disabled'] = true;
      } else if (event.dropdownValue.name === this.env['providerAzure']) {
        event.submitButton['disabled'] = false;
      } else if (event.dropdownValue.name === this.env['providerAws']) {
        this.responseHandlerService.returnToastMessage('warning', 'DPS_IS_NOT_APPLICABLE_FOR_AWS');
        event.submitButton['disabled'] = true;
      } else if (event.dropdownValue.name === this.env['providerZelIot']) {
        this.responseHandlerService.returnToastMessage('warning', 'DPS_IS_NOT_APPLICABLE_FOR_ZELIOT');
        event.submitButton['disabled'] = true;
      }
    } else if (this.settingType === 'blob-storage') {
      if (event.child === 'deviceInterfaceProvider' && event.value) {
        this.selectedSolution = event.value;
      }
      if (!this.formData && event.dropdownValue) {
        this.selectedProvider = event.dropdownValue;
      } else if (this.formData && this.dynamicForm) {
        if (this.formData && this.formData['deviceInterfaceProviderObject']) {
          this.selectedProvider = this.formData['deviceInterfaceProviderObject'];
        }
      }
      if (event.dropdownValue && event.dropdownValue.name === this.env['providerBosch']) {
        this.getSubscriptions(event.dropdownValue.id, this.selectedSolution);
      }
      if (event.dropdownValue && event.dropdownValue.name === this.env['providerZelIot']) {
        if (this.dynamicForm) {
          this.dynamicForm.formBase.forEach(control => {
            if (control.name === 'fileSize' || control.name === 'maxNoFiles' || control.name === 'fileTypeExtensionList') {
              control['required'] = false;
            }
          });
          this.dynamicForm.createForm.controls['fileSize'].setValue(0);
          this.dynamicForm.createForm.controls['maxNoFiles'].setValue(0);
          this.dynamicForm.createForm.controls['fileSize'].updateValueAndValidity();
          this.dynamicForm.createForm.controls['maxNoFiles'].updateValueAndValidity();
          this.dynamicForm.createForm.controls['fileSize'].disable();
          this.dynamicForm.createForm.controls['maxNoFiles'].disable();
          this.dynamicForm.createForm.controls['fileTypeExtensionList'].disable();
          this.dynamicForm.createForm.controls['fileTypeExtensionList'].updateValueAndValidity();
        }
      } else {
        if (this.dynamicForm) {
          this.dynamicForm.formBase.forEach(control => {
            if (control.name === 'fileSize' || control.name === 'maxNoFiles' || control.name === 'fileTypeExtensionList') {
              control['required'] = true;
            }
          });
          this.dynamicForm.createForm.controls['fileSize'].enable();
          this.dynamicForm.createForm.controls['fileSize'].setValidators([Validators.required,
            Validators.min(1), Validators.max(20)]);
          this.dynamicForm.createForm.controls['maxNoFiles'].enable();
          this.dynamicForm.createForm.controls['maxNoFiles'].setValidators([Validators.required,
            Validators.maxLength(4), Validators.pattern('[1-9]|[1-9][0-9]+')]);
          this.dynamicForm.createForm.controls['fileTypeExtensionList'].enable();
          this.dynamicForm.createForm.controls['fileTypeExtensionList'].setValidators([Validators.required]);
          this.dynamicForm.createForm.controls['fileSize'].updateValueAndValidity();
          this.dynamicForm.createForm.controls['maxNoFiles'].updateValueAndValidity();
          this.dynamicForm.createForm.controls['fileTypeExtensionList'].updateValueAndValidity();
        }
      }
      if (event.dropdownValue && event.dropdownValue.name === this.env['providerAws'] && this.dynamicForm) {
        const patten = new RegExp('^[a-z0-9]((?!(\\.-|-\\.))[a-z0-9\\\\.-])+[a-z0-9]$' + '|' + '^[[0-9]+(\\.?)]+$');
        this.dynamicForm.createForm.controls['name'].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(63),
        Validators.pattern(patten)]);
        this.dynamicForm.formBase.forEach(control => {
          if (control.name === 'name') {
            control['errorMaxLengthMsg'] = 'AWS_STORAGENAME_SHOULD_BE_OF_MAXIMUM_63_CHARACTERS';
            control['errorPatternMsg'] = 'AWS_INVALID_STORAGE_MSG';
            control['link'] = this.bucketNamingRulesLink;
          }
        });
        this.dynamicForm.createForm.controls['name'].updateValueAndValidity();
        this.getIotHubList(this.selectedSolution, event.dropdownValue.id);
      } else if (this.dynamicForm) {
        this.dynamicForm.createForm.controls['name'].setValidators([Validators.required,
        Validators.minLength(3), Validators.maxLength(100)]);
        this.dynamicForm.formBase.forEach(control => {
          if (control.name === 'name') {
            control['errorMaxLengthMsg'] = 'STORAGENAME_SHOULD_BE_OF_MAXIMUM_100_CHARACTERS';
            delete control['pattern'];
            delete control['errorPatternMsg'];
            delete control['link'];
          }
        });
        this.dynamicForm.createForm.controls['name'].updateValueAndValidity();
      }
    } else if (this.settingType === 'iot-cluster' && !this.formData && this.selectedProvider.name === this.env['providerBosch']) {
      event['disabled'] = true;
    } else if (this.settingType === 'device') {
      if (event.child === 'deviceInterfaceProvider' && event.value) {
        this.selectedSolution = event.value;
      }
      if (event.dropdownValue) {
        if (event.dropdownValue.name === this.env['providerZelIot']) {
          this.getManufacturer('true');
          this.getIotHubList(this.selectedSolution, event.dropdownValue.id);
        } else if (event.dropdownValue.name !== this.env['providerZelIot']) {
          this.getManufacturer('false');
        }
        this.getAllDeviceProperties(event.dropdownValue.id);
      }
      if (event && event.child === 'protocol' && event.value) {
        this.getSupportedProtocols(event.value);
      }
      if (this.formData && this.formData['manufacturer'] && this.dynamicForm) {
        if (this.formData['deviceInterfaceProviderObject']['name'] === this.env['providerZelIot']) {
          this.dynamicForm.formBase.forEach(control => {
            if (control.name === 'manufacturer') {
              control.editable = false;
            }
          });
        } else {
          this.dynamicForm.formBase.forEach(control => {
            if (control.name === 'manufacturer') {
              control.editable = true;
            }
          });
        }
      }
      if (this.formData && this.dynamicForm && this.formData['deviceInterfaceProviderObject']) {
        this.getAllDeviceProperties(this.formData['deviceInterfaceProviderObject']['id']);
      }
    }
    if (event.child === 'deviceInterfaceProvider' && event.value) {
      this.getDeviceInterfaceProviders(event.value);
    }
  }

  public generateConnectionString(requestJson) {
    if ( this.givenSubscriptionConnectionString !== requestJson  && this.formData.primaryConnString !== requestJson) {
    this.subscriptionControllerService.generateSubcriptionConnString({ body: requestJson })
      .subscribe(resp => {
        this.subscriptionConnectionString = JSON.stringify(resp);
        this.givenSubscriptionConnectionString = this.subscriptionConnectionString;
        this.dynamicForm.control['primaryConnString'].patchValue(this.subscriptionConnectionString);
        this.responseHandlerService.returnToastMessage('success', 'SUBSCRIPTION_EDIT_SUCCESS');
        this.dynamicForm.submitButton['disabled'] = false;
      }, err => {
        this.dynamicForm.submitButton['disabled'] = true;
        this.responseHandlerService.returnToastMessage('error', 'FAILED_TO_GENERATE_SUBSCRIPTION_STRING');
      });
  } else {
    this.responseHandlerService.returnToastMessage('error', 'VALID_CONNECTION_STRING');
  }
}

  /**
     * @ngdoc method
     * @name SettingsComponent#getSubscriptions
     *
     * @methodOf
     * SettingsModule.controller:SettingsComponent
     *
     * @description
     * gets the list of subscriptions using GET method
     *
     * @param {type} null
     * @return {type} null
   */
  /* @description: to populate subscriptions dropdown */
  public getSubscriptions(providerId: string, solutionTypeId: string) {
    const arr = [];
    this.subscriptionControllerService.getAllSubscriptionsBasic({ providerId: providerId, solutionTypeId: solutionTypeId })
      .subscribe(resp => {
        if (resp && resp.length) {
          if (!this.formData) {
            this.dropDownValues['subscriptionId'] = [];
          }
          for (const value of resp) {
            if (value['isLinkedToBlobStorage'] === false) {
              arr.push(value);
            }
          }
          this.dropDownValues['subscriptionId'] = arr;
          if (this.subscriptionIdObj && this.formData) {
            this.dropDownValues['subscriptionId'].push(this.subscriptionIdObj);
          }
        } else {
          this.dropDownValues['subscriptionId'] = [];
        }
      },
        err => {
          this.dropDownValues['subscriptionId'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }

  /**
    * @ngdoc method
    * @name SettingsComponent#getSupportedProtocols
    *
    * @methodOf
    * SettingsModule.controller:SettingsComponent
    *
    * @description
    * gets the list of supported protocols for selected device interface providers
    *
    * @param {type} manufacturerId
    * @return {type} manufacturerId
  */
  /* @description: to populate protocol dropdown */
  public getSupportedProtocols(manufacturerId: string) {
    this.manufacturerControllerService.getProtocolsForManufacturer({ id: manufacturerId })
      .subscribe(resp => {
        if (resp && resp.length) {
          this.dropDownValues['protocol'] = resp;
        } else {
          this.dropDownValues['protocol'] = [];
        }
      },
        err => {
          this.dropDownValues['protocol'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }

  public determineColumnSize(columnName, title) {
    if (title === 'GMAP_CONFIGURATION' ) {
      this.tableTopSpace = true;
      if (columnName === 'action') {
        return 'large-column';
      } else {
        return 'small-column';
      }
    }
  }
  public getIotHubList(solutionTypeId: string, deviceInterfaceProviderId: string) {
    this.platformAdminUtilityControllerService.getIotHubsBySolDevIntfProvAndProfile({solutionTypeId: solutionTypeId,
      deviceInterfaceProviderId: deviceInterfaceProviderId}).subscribe(resp => {
      if (resp && resp.length) {
        this.dropDownValues['iotHub'] = resp;
      } else {
        this.dropDownValues['iotHub'] = [];
      }
    },
      err => {
        this.dropDownValues['iotHub'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'solutionType') {
          this.profileId = $event.value;
          this.getSolutionTypes($event.value);
        } 
        if ($event.child === 'businessAccount') {
          this.solutionTypeId = $event.value;
        }
      }
    }
  }
  

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  
  public getSolutionTypes(profileId: string) {
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({ businessProfileId: profileId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getDataBysolutionTypes() {
  this.dynamicForm.submitted = true;
  if(this.dynamicForm.createForm.valid) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.solutionTypeId = this.solutionTypeId;
    this.selectedSolutionType =  this.solutionTypeId;
    this.data = null;
    this.columns = null;
    this.mapControllerService.getMapProjectsforProfileAndSolutionType({profileId:this.profileId,solutionTypeId:this.solutionTypeId}).subscribe(
      resp => {
        this.permission = 'MAP_';
        if (resp && resp.length) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.data = resp;
          for (const data of this.data) {
            data['projectId'] = data['project']['id'];
            data['active'] = data['project']['active'];
            data['project'] = data['project']['name'];
            data['account'] = data['account'] ? data['account']['name'] : null;
            data['tenant'] = data['tenant'] ? data['tenant']['name'] : null;
            data['profile'] = data['profile'] ? data['profile']['name'] : null;
            data['solutionType'] = data['solutionType'] ? data['solutionType']['name'] : null;
            data['noOfKeys'] = data['mapProjectApiKeys'] ? data['mapProjectApiKeys'].length : 0;
            if (this.checkPermission('MAP_U')) {
              data['action'] = null;
            }
          }
          this.columns = this.filterColumns(Object.keys(this.data[0]));
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }
  }

  onReset(){
    this.dynamicForm.submitted = false;
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['solutionType'] = [];
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.solutionTypeId = null;
    this.data = null;
    this.columns = null;
    this.selectedSolutionType = null;
    this.dynamicForm.createForm.reset();
    this.getAccount();
  }

  public SimMatChange(event:MatTabChangeEvent){
    this.showSpinner = this.loaderService.showProgressBar();
  this.settingType= this.jsonFileLoaded= this.simMatTabOptions[this.simActiveTabsIndex].value;
    if( this.settingType=='sim-profile'){
      this.SimProviderControllerService
      .getAllSimProfiles()
      .subscribe(
        resp => {
          this.permission = 'ST_';
          this.loaderService.hideProgressBar(this.showSpinner);
          if (resp?.length) {
            this.data = resp;
           this.columns = ["name","action"];
          } else {
            this.data=null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    }
    if(this.settingType=='sim-provider'){
      this.SimProviderControllerService
      .getAllSimProviders()
      .subscribe(
        resp => {
          this.permission = 'ST_';
          this.loaderService.hideProgressBar(this.showSpinner);
          if (resp?.length) {
            this.data = resp;
           this.columns = ["name","action"];
          } else {
            this.data=null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    }
    if (this.settingType == 'provider-profile') {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.data = null;
      this.columns = null;
      this.getProvider();
    }
    }

    public confirmDelete(mesg,id){
      this.settingType = this.simMatTabOptions[this.simActiveTabsIndex].value;
      const message = this.translate.translateErrorMessages('CONFIRM_DELETE_'+mesg);
      const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
        maxWidth: '400px',
        disableClose: true, data: { message: message }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.showSpinner = this.loaderService.showProgressBar();
          if(this.settingType=='sim-provider'){
           
            const obj ={
              body:{
                simProviders:[id]
              }
            }
          this.SimProviderControllerService.deleteSimProviders(obj).subscribe(
            resp =>{
              this.loaderService.hideProgressBar(this.showSpinner);
              this.fetchData(this.settingName,this.pageTitle);
              this.responseHandlerService.returnToastMessage('success', 'SIM_PROVIDER_DELETED');
            },
            err =>{
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          )
          } 
           if(this.settingType=='sim-profile'){
                const obj ={
              body:{
                simProfiles:[id]
              }
            };
          this.SimProviderControllerService.deleteSimProfiles(obj).subscribe(
            resp =>{
              this.loaderService.hideProgressBar(this.showSpinner);
                this.fetchData(this.settingName,this.pageTitle);
              this.responseHandlerService.returnToastMessage('success', 'SIM_PROFILE_DELETED');
            },
            err =>{
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          )
           }
        } else {
          this.abortAction();
        }
      });
    }
    
    public getProvider() {
      this.SimProviderControllerService.getAllSimProviders().subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.providerList = resp;
          } else {
            this.providerList= [];
          }
        },
        (err) => {
          this.providerList= [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
  
    public getSimProfileById(providerId) {
      this.SimProviderControllerService.getAllSimProfilesBySimProviderId({simProviderId:providerId}).subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.profileList = resp;
            this.selectedProfiles = resp.map(profile => profile.active ? profile.id : null);
          } else {
            this.profileList = [];
          }
        },
        (err) => {
          this.profileList = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
  
    public saveMappingDetails() {
      if(this.selectedSimProvider && this.selectedProfiles.length >= 0) {
        const profilePropertyValues = this.profileList.map(profileProperty => {
          if (this.selectedProfiles.findIndex(obj => obj === profileProperty['id']) !== -1) {
            profileProperty['active'] = true;
            return profileProperty;
          } else {
            profileProperty['active'] = false;
            return profileProperty;
          }
        });
        const providerProfileObj = {
            body: {
              simProvider: this.objectToIdConversionService.convertObjectToId(this.selectedSimProvider),
              profiles: profilePropertyValues,
            },
          };
        this.SimProviderControllerService.saveSimProviderProfileMapping(providerProfileObj).subscribe(
          resp => {
            this._matDialog.closeAll();
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'PROVIDER_PROFILE_MAPPED');
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        )
      } 
      else {
        this.requiredError = true;
      } 
    }

    public simProviderChange(event)
    {
      this.selectedSimProvider = event;
      this.getSimProfileById(this.selectedSimProvider);
    }

    public onTabChange() {
      this.profileList = [];
      this.providerList = [];
      this.requiredError = false
      this.selectedSimProvider = null;
    }
}
