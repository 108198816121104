/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {OptInAndOptOutDto} from '../models/opt-in-and-opt-out-dto';
import {WhatsappResponseDto} from '../models/whatsapp-response-dto'
import { WhatsappRequestDto} from '../models/whatsapp-request-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappMessageControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /** Path part for operation `sendWhatsappMessage()` */
  static readonly SendWhatsappMessagePath = '/api/v1/sendMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendWhatsappMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWhatsappMessage$Response(
    params?: {
      accessKey: string;
      body?: WhatsappRequestDto
    },
  ): Observable<StrictHttpResponse<WhatsappResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WhatsappMessageControllerService.SendWhatsappMessagePath, 'post');
    if (params) {
      rb.header('accessKey', params.accessKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendWhatsappMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWhatsappMessage(
    params?: {
      accessKey: string,
      body?: WhatsappRequestDto
    },
  ): Observable<WhatsappResponseDto> {
    return this.sendWhatsappMessage$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappResponseDto>): WhatsappResponseDto => r.body)
    );
  }

  /** Path part for operation `optInMobileNumbers()` */
  static readonly OptInMobileNumbersPath = '/api/v1/opt-in';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `optInMobileNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  optInMobileNumbers$Response(
    params: {
      accessKey: string,
      body?: Array<OptInAndOptOutDto>
    },
  ): Observable<StrictHttpResponse<Array<OptInAndOptOutDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WhatsappMessageControllerService.OptInMobileNumbersPath, 'post');
    if (params) {
      rb.header('accessKey', params.accessKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: '*/*'})
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OptInAndOptOutDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `optInMobileNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  optInMobileNumbers(
    params: {
      accessKey: string;
      body?: Array<OptInAndOptOutDto>
    },
  ): Observable<Array<OptInAndOptOutDto>> {
    return this.optInMobileNumbers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OptInAndOptOutDto>>): Array<OptInAndOptOutDto> => r.body)
    );
  }
  /**
   * Path part for operation triggerFlowBuilder
   */
  static readonly TriggerFlowBuilderPath = '/api/v1/flow/trigger';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerFlowBuilder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerFlowBuilder$Response(params?: {
    body?: WhatsappRequestDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappMessageControllerService.TriggerFlowBuilderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `triggerFlowBuilder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerFlowBuilder(params?: {
    body?: WhatsappRequestDto
  }): Observable<string> {

    return this.triggerFlowBuilder$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
