/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { SEARCH_IMAGE } from '../constants/strings';
import { DatePipe } from '@angular/common';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DataSharingService } from 'src/app/providers/data-sharing.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.scss']
})
/**
 * @ngdoc component
 * @name SharedModule.component:SearchComponent
 *
 *
 * @description
 * description: To search devices and groups based on the user inputs
 *
 */
export class SearchComponentComponent implements OnInit, OnChanges {
  public jsonArray: any[];
  public fields: any = {};
  public searchImage;
  public submitted = false;
  @Input() searchData: any = {};
  @Input() comboFieldSearchArray: any = [];
  @Input() dropDownValues: any;
  @Output() searchFieldEmitter: EventEmitter<any> = new EventEmitter();
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();
  @Output() resetButtonClicked: EventEmitter<any> = new EventEmitter();
  fromDateGreaterthanTodate: boolean;
  dateFieldArray: any[];
  maxDate: Date;
  minDate: Date;
  @Input() selectedTab: any;
  @Input() selectionCriteria: any;
  @Input() gridLayout: any;
  @Input() displaySubmitButton: boolean;
  @ViewChild('searchForm', { static: true }) searchForm: NgForm;
  fieldDates: any;

  constructor(
    private responseHandlerService: ResponseHandlerService,
    private datePipe: DatePipe,
    private dataSharingService: DataSharingService
  ) {}
  ngOnInit() {
    this.searchImage = SEARCH_IMAGE;
    this.maxDate = new Date();
    this.comboFieldSearchArray.forEach((field) => {
      if (field['value']) {
        if (this.selectedTab) {
          field['value'] = this.selectedTab;
        }
        setTimeout(() => {
          this.fields[field['name']] = field['value'];
        });
        if (this.selectionCriteria) {
          // tslint:disable-next-line
          for (const searchCriteria in this.selectionCriteria) {
            this.fields[searchCriteria] =
              this.selectionCriteria[searchCriteria]['id'];
            if (this.selectionCriteria[searchCriteria]['loadChild']) {
              const value = {
                value: this.selectionCriteria[searchCriteria]['id'],
              };
              const name = {
                mode: 'loadChild',
                value: '',
                child:
                  this.selectionCriteria[searchCriteria]['loadChild'][
                    'childName'
                  ],
              };
              const searchField = {
                childField: {
                  mode: 'loadChild',
                  value: '',
                  child:
                    this.selectionCriteria[searchCriteria]['loadChild'][
                      'childName'
                    ],
                },
                name: searchCriteria,
              };
              this.selectValue(value, searchField, name);
            }
          }
        }
        this.selectValue({ value: field['value'] }, field, field['childField']);
      }
      if (field['disableOlderDates']) {
        this.minDate = new Date();
        this.minDate.setMonth(this.minDate.getMonth() - field['enablePeriod']);
      }
      if (field['enableYesterdayDates']) {
        this.fieldDates = field
        this.maxDate = new Date();
        this.maxDate.setDate(this.maxDate.getDate() - 1);
      }
    });
    this.dataSharingService.getData().subscribe((resp) => {
      if (resp && resp !== null) {
        this.fields = resp;
      }
    });
    if (this.searchData) {
      this.fields = this.searchData;
    }
  }

  ngOnChanges() {
    this.comboFieldSearchArray.forEach((field) => {
      if (field['value']) {
        this.fields[field['name']] = field['value'];
        this.selectValue({ value: field['value'] }, field, field['childField']);
      }
    });
  }
  /**
   * @ngdoc method
   * @name SearchComponent#selectValue
   *
   * @methodOf
   * SharedModule.controller:SearchComponent
   *
   * @description
   * Description: to handle event after dropdown is selected
   *
   * @param {type} event, name
   * @return {type} name description
   */
  public selectValue(event: any, searchField: any, name: any = null) {
    if (searchField.name === 'criteria') {
      this.submitted = false;
      this.searchForm.form.markAsUntouched();
    }
    if (searchField.isDisable) {
      this.comboFieldSearchArray.forEach((field) => {
        if (field.disable && event.value === field.disable.value) {
          this.fields[field.name] = undefined;
          field['disabled'] = true;
        } else {
          field['disabled'] = false;
        }
        if (field['type'] === 'date') {
          field['required'] = false;
          this.fromDateGreaterthanTodate = false;
        }
      });
    }
    if (
      searchField.validate &&
      searchField.childField &&
      searchField.childField.dependentArray
    ) {
      this.comboFieldSearchArray.map((field) => {
        searchField.childField.dependentArray.forEach((dependentValue) => {
          if (field.name === dependentValue) {
            field['required'] = this.fields[searchField['name']] ? true : false;
          }
        });
      });
    }
    if (searchField.childField) {
      if (searchField.childField.dependentArray) {
        searchField.childField.dependentArray.forEach((dependentValue) => {
          // to clear the list of all dependent dropdowns
          this.dropDownValues[dependentValue] = [];
          // to avoid auto-selection of option when only one option is available which will hamper the call to next dropdown values
          this.fields[dependentValue] = undefined;
        });
      }
      this.valueSelected.emit({
        value: event.value,
        child: name,
        parentName: searchField.name,
      });
    }
    if (searchField.returnData) {
      this.valueSelected.emit({
        value: event.value,
        parentName: searchField.name,
      });
    }
  }

  /**
   * @ngdoc method
   * @name SearchComponent#onSearchClick
   *
   * @methodOf
   * SharedModule.controller:SearchComponent
   *
   * @description
   * Description: to emit selected dropdown values for bulk search
   *
   * @param {type}
   * @return {type}
   */
  public onSearchClick() {
    this.submitted = true;
    if (this.searchForm.invalid) {
      return;
    }
    this.searchFieldEmitter.emit(this.fields);
    this.submitted = false;
  }

  public onReset() {
    this.resetButtonClicked.emit('resetButton');
    this.searchForm.reset();
    this.submitted = false;
  }

  /**
   * @ngdoc method
   * @name SearchComponent#dateSelected
   *
   * @methodOf
   * SharedModule.controller:SearchComponent
   *
   * @description
   * Description: Make date fields mandatory if either of fromDate or toDate is selected.
   *
   * @param {type} null
   * @return {type} null
   */
  public dateSelected() {
    let hasDateField;
    hasDateField = this.comboFieldSearchArray.some((formField) => {
      return formField.type === 'date';
    });
    if (hasDateField === true) {
      this.dateFieldArray = this.comboFieldSearchArray;
    }
    if (this.fields.fromDate || this.fields.toDate) {
      if (this.dateFieldArray !== undefined && this.dateFieldArray !== null) {
        this.dateFieldArray.map((formField) => {
          if (formField.type === 'date') {
            formField.required = true;
          }
        });
      }
    }
    if (this.fields.fromDate && !this.fields.toDate) {
      if (this.fieldDates?.enableYesterdayDates) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.fields.toDate = yesterday;
      } else {
        this.fields.toDate = new Date();
      }
    } else if (this.fields.fromDate && this.fields.toDate) {
      this.fromDateGreaterthanTodate =
        this.fields.toDate < this.fields.fromDate;
      if (this.fromDateGreaterthanTodate) {
        this.searchForm.form.controls['fromDate'].setErrors({
          invalidDate: true,
        });
      } else {
        this.searchForm.form.controls['fromDate'].setErrors(null);
      }
    }
  }

  chosenMonthHandler(selectedMonth, datepicker, fieldName) {
    this.fields[fieldName] = this.datePipe.transform(selectedMonth, 'MM-yyyy');
    datepicker.close();
    if (this.fields.fromDate && !this.fields.toDate) {
      this.fields.toDate = this.datePipe.transform(new Date(), 'MM-yyyy');
    } else if (this.fields.fromDate && this.fields.toDate) {
      const fromDate = new Date(
        `${this.fields.fromDate.split('-')[1]}/${
          this.fields.fromDate.split('-')[0]
        }/01`
      );
      const toDate = new Date(
        `${this.fields.toDate.split('-')[1]}/${
          this.fields.toDate.split('-')[0]
        }/01`
      );
      if (fromDate && toDate) {
        this.fromDateGreaterthanTodate = fromDate.getTime() > toDate.getTime();
        if (this.fromDateGreaterthanTodate) {
          this.searchForm.form.controls['fromDate'].setErrors({
            invalidDate: true,
          });
        } else {
          this.searchForm.form.controls['fromDate'].setErrors(null);
        }
      }
    }
  }
}
