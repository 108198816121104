<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{'WHATSAPP_CAMPAIGN_MANAGEMENT' | translate}}</span>
      </span>
    </div>
    <mat-tab-group class="demo-tab-group stretched-tabs" mat-stretch-tabs [(selectedIndex)]="selectedIndex">
      <div *ngFor="let tab of configurationsList">
        <mat-tab *ngIf="checkPermission(tab['permission'])">
          <ng-template mat-tab-label>
            <div>{{ tab["title"] | translate }}</div>
          </ng-template>
          <section class="tab-body">
            <span *ngIf="tab['name'] === 'create-job'">
              <app-dynamic-form [jsonFile]="jsonFileLoaded" [fileTransfer]="true"
                [columnsInForm]="'multi'" [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
                (formEmitter)="onUpload($event,UploadContent)" (buttonClickEvent)="onDownload()">
              </app-dynamic-form>
              <button (click)="onDownload()" mat-raised-button class="button save-continue-button button-submit"> {{'DOWNLOAD_TEMPLATE' | translate}}</button>
              <a class="link-right" href="javascript:void(0);" [ngClass]="{'disabled-link': !isTestCampaignActive}" (click)="isTestCampaignActive && openTestCampaignForm(testCampaignContent)">{{'TEST_CAMPAIGN' | translate}}</a>
              <ng-template #testCampaignContent let-modal>
                <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
                <div class="modal-width">
                  <div class="modal-header">
                    <h5 class="modal-title rerun-title">{{'TEST_CAMPAIGN' | translate}}</h5>
                  </div>
                  <div class="form-spacing">
                    <form>
                      <div [formGroup]="testCampaignForm">
                        <div>
                          <mat-form-field>
                            <mat-label class="label">{{'ACCESS_KEY' | translate}}
                                <span class="mandatory">*</span>
                            </mat-label>
                            <mat-select [formControlName]="'accessKey'" class="dynamic-input" [placeholder]="'SELECT' | translate">
                              <mat-option *ngFor="let val of accessKeyList"[value]="val.keyName" [accessKey]="val.accessKey">{{val.keyName}}</mat-option>
                          </mat-select>
                          </mat-form-field>
                          <div *ngIf="submitted || testCampaignForm.controls['accessKey']?.touched && testCampaignForm.controls['accessKey']?.errors">
                            <mat-error class="mat-error-class" *ngIf="testCampaignForm.controls['accessKey']?.errors?.required">
                              {{'ACCESS_KEY' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                        </div>
                      </div>
                      <div>
                        <mat-form-field>
                          <mat-label class="label">{{'SEND_TO' | translate}}
                              <span class="mandatory">*</span>
                          </mat-label>
                          <textarea matInput class="input-field" [formControlName]="'sendTo'" [placeholder]="'ONLY_ENTER_UPTO_10_MOBILE_NUMBERS' | translate" rows="3"></textarea>
                        </mat-form-field>
                        <div *ngIf="submitted || testCampaignForm.controls['sendTo']?.touched && testCampaignForm.controls['sendTo']?.errors">
                          <mat-error class="mat-error-class" *ngIf="testCampaignForm.controls['sendTo']?.errors?.required">
                            {{'SEND_TO' | translate}} {{'IS_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error class="mat-error-class" *ngIf="testCampaignForm.controls['sendTo']?.errors?.maxlength">
                            {{'MAX_LENGTH_EXCEEDED' | translate}}
                          </mat-error>
                          <mat-error class="mat-error-class" *ngIf="testCampaignForm.controls['sendTo']?.errors?.pattern">
                            {{'INVALID_MOBILE_NO_PATTERN_OR_MAXIMUM_LIMIT_REACHED' | translate}}
                          </mat-error>
                      </div>
                    </div>
                    <div>
                      <div *ngFor="let _ of [].constructor(placeholderCount); let i = index">
                        <mat-form-field>
                          <mat-label class="label">{{'MESSAGE_PARAM' | translate}}{{i+1}}
                            <span class="mandatory">*</span>
                          </mat-label>
                          <input matInput type="text" class="input-field" [formControlName]="'param'+(i+1)" [placeholder]="'MESSAGE_PARAM_CONTENT'|translate">
                        </mat-form-field>
                        <div *ngIf="submitted || testCampaignForm.controls['param'+(i+1)]?.touched && testCampaignForm.controls['param'+(i+1)]?.errors">
                          <mat-error class="mat-error-class" *ngIf="testCampaignForm.controls['param'+(i+1)]?.errors?.required">
                            {{'MESSAGE_PARAM' | translate}}{{ 'IS_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error class="mat-error-class" *ngIf="testCampaignForm.controls['param'+(i+1)]?.errors?.maxlength">
                            {{'MESSAGE_PARAM_MAX_LENGTH' | translate}}
                          </mat-error>
                        </div>
                      </div>                                                                      
                    </div>
                    </div>
                    </form>
                    <div>
                  <section class="submit-section">
                    <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
                    <button (click)="optInPhoneNumbers()" mat-raised-button class="button button-submit">{{ 'SUBMIT' | translate }}</button>
                  </section>
                </div>
                    </div>
                </div>
              </ng-template>

              <ng-template #UploadContent let-modal>
                <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
                <div class="modal-width">
                  <div>
                    <div class="modal-header">
                      <h5 class="modal-title" id="modal-basic-title">
                        {{ 'FILE_UPLOAD' | translate }}
                      </h5>
                    </div>
                    <div class="modal-body">
                      <input type="file" class="file-uploader" id="file" (change)="selectFiles($event)" />
                    </div>
                    <div class="modal-footer">
                      <button [disabled]="!fileForUpload" (click)="getEstimatedCost($event,costContent)" mat-raised-button
                        class="button-submit">
                        {{ 'SUBMIT' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </span>

            <span *ngIf="tab['name'] === 'manage-job'">

              <app-search-component *ngIf="bulkWhatsappData" [comboFieldSearchArray]="bulkWhatsappData"
              [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
              (searchFieldEmitter)="getWhatsappJobs()" [searchData]="searchCriteria" >
            </app-search-component>

              <mat-table [dataSource]="bulkWhatsappTableData" class="table-data">
                <div *ngFor="let column of bulkWhatsappColumns">
                  <ng-container [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
                    </mat-header-cell>
                    <mat-cell class="tooltip" *matCellDef="let dataName; index as i">
                      <span *ngIf="column !== 'action'" title="{{ dataName[column] | translate }}" class="cell-value">
                        {{ dataName[column] | translate }}
                      </span>
                      <span *ngIf="column === 'action'" class="action-buttons">
                        <img *ngIf="dataName['status'] !== 'Canceled'" class="icon-hover action-icons" [src]="downloadImage" [title]="'DOWNLOAD_FILE' | translate" (click)="downloadFile(dataName['jobId'])" >
                        <img *ngIf="dataName['cancelEnabled'] === true" class="icon-hover action-icons cancel-icon" [src]="rejectImage" [title]="'CANCEL' | translate" (click)="gotoConfirmDialog(dataName['jobId'])">
                        <img *ngIf="dataName['status'] === 'Completed'" [src]="viewImage" [title]="'VIEW' | translate" class="icon-hover viewIcon" (click)="displayPieChart(dataName)">
                        <img *ngIf="dataName['linkTrackingEnabled'] === true" [src]="linkIcon" [title]="'LINK_TRACKING' | translate" class="icon-hover link-icon" (click)="displayLinkTracking(dataName)">
                        <img [src]="reRunIcon" [title]="'ReRun' | translate" class="icon-hover rerun-icon" (click)="goToReRunDialog(content,dataName['jobId'],dataName['categoryName'],dataName['phoneNumberCount'])">
                      </span>
                    </mat-cell>
                  </ng-container>
                </div>
                <mat-header-row *matHeaderRowDef="bulkWhatsappColumns"></mat-header-row>
                <mat-row *matRowDef="let data; columns: bulkWhatsappColumns"></mat-row>
              </mat-table>
            </span>
          </section>
        </mat-tab>
      </div>
    </mat-tab-group>
    </section>
    </section>

    <ng-template #content let-modal>
      <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
      <div class="modal-width">
        <div class="modal-header">
          <h5 class="modal-title rerun-title">{{'RE_RUN' | translate}}</h5>
        </div>
        <div class="form-spacing">
          <form>
            <div [formGroup]="reRunForm">
              <div>
                <mat-form-field>
                  <mat-label class="label">{{'JOB_NAME' | translate}}
                      <span class="mandatory">*</span>
                  </mat-label>
                <input matInput type="text" class="input-field" [formControlName]="'jobName'" [placeholder]="'JOB_NAME' | translate"/>
                </mat-form-field>
                <div *ngIf="submitted || reRunForm.controls['jobName']?.touched && reRunForm.controls['jobName']?.errors">
                  <mat-error class="mat-error-class" *ngIf="reRunForm.controls['jobName']?.errors?.required">
                    {{'JOB_NAME' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error class="mat-error-class" *ngIf="reRunForm.controls['jobName']?.errors?.maxlength">
                    {{'JOB_NAME_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS' | translate }}
                  </mat-error>
              </div>
            </div>
            <div>
              <mat-form-field>
                <mat-label class="label">{{'DATE'| translate}}
                    <span class="mandatory">*</span>
                </mat-label>
              <input matInput [matDatepicker]="picker" (dateChange)="checkTimeAndDate()"[formControlName]="'jobStartDate'" [placeholder]="'DATE' | translate" [min]="todayDate"/>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <div *ngIf="submitted || reRunForm.controls['jobStartDate']?.touched && reRunForm.controls['jobStartDate']?.errors">
                <mat-error class="mat-error-class" *ngIf="reRunForm.controls['jobStartDate']?.errors?.required">
                  {{'DATE'| translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
            </div>
          </div>
          <div>
            <mat-form-field>
              <mat-label class="label">{{'UPDATE_START_TIME' | translate}}
                  <span class="mandatory">*</span>
              </mat-label>
              <span class="info-icon">
                <img [src]="infoIcon" alt="info" [title]="'T_SCHEDULED_TIME_SHOULD_BE_ATLEAST_15_MINUTES_GREATER_THAN_CURRENT_TIME' | translate" class="info-images">
              </span>
              <input matInput type="time" (change)="timeValidator(16)" [formControlName]="'jobStartTime'">
              </mat-form-field>
              <div *ngIf="submitted || reRunForm.controls['jobStartTime']?.touched && reRunForm.controls['jobStartTime']?.errors">
               <div *ngIf="reRunForm.controls['jobStartTime']?.errors?.required">
                <mat-error class="mat-error-class">
                  {{'UPDATE_START_TIME' | translate}}{{'IS_REQUIRED' | translate }}
                </mat-error>
              </div>
                <mat-error *ngIf="reRunForm.controls['jobStartTime']?.errors?.incorrect" class="mat-error-class">
                      {{ 'T_SCHEDULED_TIME_SHOULD_BE_ATLEAST_15_MINUTES_GREATER_THAN_CURRENT_TIME' | translate }}
                  </mat-error>
            </div>
        </div>
          </div>
          </form>
          <div>
        <section class="submit-section">
          <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
          <button (click)="onSubmit(costContent)" mat-raised-button class="button button-submit">{{ 'SUBMIT' | translate }}</button>
        </section>
      </div>
          </div>
      </div>
    </ng-template>

    <ng-template #costContent let-modal>
      <div class="modal-width">
        <div class="modal-header">
          <h3 class="modal-title cost-title">{{currencySymbol}} {{estimatedCost}}</h3>
          <h5 class="modal-basic-title">{{'ESTIMATED_COST' | translate}}</h5>
        </div>
        <div>
          <h6 class="modal-body">{{'ESTIMATED_COST_DESCRIPTION'| translate}}</h6>
        </div>
        <div class="modal-footer">
          <button (click)="onClose()" mat-button class="button button-close"> {{'CANCEL'| translate}}
          </button>
          <button (click)="reRunJobId?executeReRun():uploadExcelFile()" mat-raised-button class="button-submit"> {{'CONFIRM'|translate}}
          </button>
        </div>
      </div>
    </ng-template>