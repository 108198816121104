import { EventEmitter, Injectable } from "@angular/core";
import * as Highcharts from "highcharts";

@Injectable({
  providedIn: "root",
})
export class BarChartService {
  defaultOptions;
  chart;
  getOptions() {
    this.defaultOptions = {
      chart: {
        type: "column",
        height: 35 + "%",
      },
      title: {
        text: "",
      },
      rangeSelector: {
        enabled: false,
      },
      legend: {
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          states: {
            inactive: {
              enabled: false
            },
            hover: {
              enabled: false
            }
          }
        },
      },
      tooltip: {
        headerFormat: "{point.x}<br>",
        pointFormat:
          '<span style="color: {point.color}">{point.name}</span>: {point.y}<br>',
      },
      series: [],
    };
    return this.defaultOptions;
  }
  constructor() {}

  public createChart(container, options, chartName = null) {
    const chartOptions = this.getOptions();
    if (chartName === "gMapChart") {
      chartOptions["legend"] = {
        enabled: false,
      };
    }
    const chartData = [];
    const series = [];
    options.forEach((data) => {
      const chartDataObject = {
        name: [],
        data: [],
        color: "",
        fillColor: {},
      };
      options[0].values.forEach((values, xVal) => {
        Object.keys(values).forEach((key, index) => {
          if (!series[index]) {
            series.push({
              data: [],
            });
          }
          series[index].data.push({
            name: key,
            x: xVal,
            y: values[key],
            color: options[0].colors[xVal][key],
          });
        });
      });

      chartDataObject.color = data.color;
      chartDataObject.fillColor = {
        linearGradient: [0, 0, 0, 150],
        stops: [
          [0, data.color],
          [1, Highcharts.color(data.color).setOpacity(0).get("rgba")],
        ],
      };
      chartOptions.xAxis = {
        categories: data.dates,
        min: 0,
        max: data.dates.length - 1,
      };
      chartData.push(chartDataObject);
    });
    chartOptions.series = series.length ? series : [{}];
    const element = document.createElement("div");
    container.appendChild(element);
    if (!!chartOptions.chart) {
      chartOptions.chart["renderTo"] = element;
    } else {
      options.chart.renderTo = "column";
    }
    this.chart = new Highcharts.Chart(chartOptions);
    return this.chart;
  }

  public getChart() {
    if (this.chart) {
      return this.chart;
    } else {
      return null;
    }
  }

  public destroyChart(chart) {
    if (chart && chart.userOptions) {
      chart.destroy();
      chart = null;
      this.chart = null;
    }
    return chart;
  }

  public redrawChart(chart, data) {
    chart.addSeries({
      name: data.name,
      data: data.values,
      color: data.color,
      fillColor: {
        linearGradient: [0, 0, 0, 150],
        stops: [
          [0, data.color],
          [1, Highcharts.color(data.color).setOpacity(0).get("rgba")],
        ],
      },
    });
  }

  public updateChart(chart, legendName, displayType) {
    if (chart.series) {
      chart.series.forEach((individualSeries) => {
        if (individualSeries.name === legendName) {
          if (displayType === "hide" && individualSeries.visible) {
            individualSeries.hide();
          } else if (displayType === "show") {
            individualSeries.show();
          }
        }
      });
    }
  }
}
