/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from "@angular/core";
import { AsideCard } from "../../shared/aside-nav-card/aside-nav-card.component";
import { HttpClient } from "@angular/common/http";
import {
  DeviceControllerService,
  DeviceTypeControllerService,
} from "src/app/services/DeviceMgmt/services";
import { DeviceSearchDto, CommonId } from "src/app/services/DeviceMgmt/models";
import { ActivatedRoute, Router } from "@angular/router";
import {
  TenantControllerService,
  BusinessProfileControllerService,
  SolutionTypeControllerService,
  PlatformAdminUtilityControllerService,
} from "src/app/services/Platform/services";
import { DynamicSearchServiceService } from "src/app/providers/dynamic-search-service.service";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MANAGE,
  REBOOT_IMAGE,
  RESTART_IMAGE,
  MORE_OPTION,
  THUMBUP_IMAGE,
  THUMBDOWN_IMAGE,
  DEVICE_ICON,
  DOCUMENT_IMAGE,
  DELETE_IMAGE,
  CLOUD_UPLOAD_ICON,
  CSV_FILE,
  GOOGLE_CSV_FORMAT,
} from "src/app/shared/constants/strings";
import { TranslationService } from "src/app/providers/translation-service";
import { RightsideNavitemsService } from "src/app/providers/rightside-navitems.service";
import { ResponseHandlerService } from "src/app/providers/response-handler-service";
import { SearchComponentComponent } from "src/app/shared/search-component/search-component.component";
import { StaticTagsComponent } from "src/app/shared/static-tags/static-tags.component";
import { DeviceSimMapControllerService } from "src/app/services/DeviceMgmt/services/device-sim-map-controller.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTabGroup } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BciLoaderService } from "@bci-web-core/core";
import { DynamicFormComponent } from "src/app/shared/dynamic-form/dynamic-form";
/**
 * @ngdoc component
 * @name device.component:deviceSimMapping
 *
 *
 * @description
 * description: To perform crud operations on the list of devices.
 *
 *
 */
@Component({
  selector: "app-device-sim-mapping",
  templateUrl: "./device-sim-mapping.component.html",
  styleUrls: ["./device-sim-mapping.component.scss"],
})
export class DeviceSimMappingComponent implements OnInit {
  public showSpinner: any;
  public deviceData;
  public columns: any[];
  public defaultColumns: any[];
  public dropDownValues: any = {};
  ignoreList = [
    "id",
    "links",
    "account",
    "manufacturer",
    "devicePropertySupport",
    "deviceInterfaceProvider",
  ];
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public formData: any;
  public data;
  public deviceCount = 0;
  public matTabOptions = [];
  public currentTabValue;
  public permissionList: Array<String> = [];
  selection = new SelectionModel<CommonId>(true, []);
  public mode: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  searchDto: any = null;
  configureImage: string;
  rebootImage: string;
  deleteImage: string;
  restartImage: string;
  fotaImage: string;
  connectedImage: string;
  disconnectedImage: string;
  registeredImage: string;
  boschIotIcon: string;
  azureIcon: string;
  awsIcon: string;
  zeliotIcon: string;
  thumbUpImage: string;
  thumbDownImage: string;
  moreOptionsImage: string;
  tenantId: any;
  solutionTypeId: any;
  deviceTypeId: any;
  fotaDetails = {};
  hideFotaBtn = true;
  tabToBeLoaded;
  index = 0;
  selectionCriteria;
  selectedTab;
  selectAllDevices = false;
  public deviceIcon: string;
  public selectedTag: any;
  public jsonFileLoaded;
  public tagsArray = [];
  public deviceList = [];
  public availableTags = [];
  public searchCriteria;
  public deviceInterfaceProviderId: string;
  public profileId: string;
  public deviceTunnelIcon: string;
  public excelFiles: any = [];
  documentImage = DOCUMENT_IMAGE;
  deleteImg = DELETE_IMAGE;
  public sampleFile: any;
  cloudUploadImage = CLOUD_UPLOAD_ICON;
  bulkSimMappingForm: UntypedFormGroup;
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @ViewChild(StaticTagsComponent) dynamicForm: StaticTagsComponent;
  selectedDevices: any;
  deviceSerialNo: any;
  deviceEnrollmentControllerService: any;
  gridEnable: boolean;
  public delinkingForm: UntypedFormGroup;
  delinkSimSupport: any;
  @ViewChild(DynamicFormComponent) dynamicForms: DynamicFormComponent;
  constructor(
    private translate: TranslationService,
    private route: ActivatedRoute,
    private dynamicSearchServiceService: DynamicSearchServiceService,
    private tenantControllerService: TenantControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private deviceControllerService: DeviceControllerService,
    private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog,
    private httpService: HttpClient,
    private router: Router,
    private rightsideNavitemsService: RightsideNavitemsService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private DeviceSimMapControllerService: DeviceSimMapControllerService,
    private loaderService: BciLoaderService,
    private UntypedFormBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar, private formBuilder: UntypedFormBuilder


  ) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data["permissionList"];
    this.pageKey = "device";
    //this.jsonFileLoaded = 'attach-tag';
    this.searchCriteria = "ADVANCE";
    this.rightsideNavitemsService
      .getRightsideNavItems(MANAGE, this.pageKey)
      .then((navItemsList) => {
        this.asideMenus = navItemsList as Array<AsideCard>;
      });
    if (this.permissionList.indexOf("DEV_SER_BP") > -1) {
      this.matTabOptions.push({ label: "Business", value: "business" });
    }
    if (this.permissionList.indexOf("DEV_SER_ST") > -1) {
      this.matTabOptions.push({ label: "Solution", value: "solution" });
    }
    if (this.matTabOptions[0].value === "business") {
      this.getTenant();
      this.currentTabValue = "business";
    } else if (this.matTabOptions[0].value === "solution") {
      this.getSolutionType();
      this.currentTabValue = "solution";
    }
    this.httpService
      .get("./assets/form-fields/device-sim-mapping-search.json")
      .subscribe((data) => {
        this.dynamicSearchServiceService.setFormData(data as string[]);
        this.formData = this.dynamicSearchServiceService.generateDynamicForm(
          this.currentTabValue
        );
        this.getSolutionType();
        this.tabGroup.selectedIndex = 1;
        this.dropDownValues =
          this.dynamicSearchServiceService.generateDynamicFormDropdown(
            this.formData
          );
      });
    this.selectedTab = "BASIC";
    this.getSelectedIndex();
    this.getSolutionType();
    this.rebootImage = REBOOT_IMAGE;
    this.restartImage = RESTART_IMAGE;
    this.moreOptionsImage = MORE_OPTION;
    this.deviceIcon = DEVICE_ICON;
    this.thumbUpImage = THUMBUP_IMAGE;
    this.thumbDownImage = THUMBDOWN_IMAGE;
    this.bulkSimMappingForm = this.UntypedFormBuilder.group({
      excelFile: ['', Validators.required]
    });
    this.delinkingForm = this.formBuilder.group({
      linkSim1_Id: [''],
      linkSim2_Id: ['']
    });
  }

  public submitData(event) {
    this.showSpinner = this.loaderService.showProgressBar();
    let arr : Array<string> = [];
    if (event.linkSim1_Id) {
      arr.push(event.linkSim1_Id);
    } 
    if (event.linkSim2_Id) {
      arr.push(event.linkSim2_Id);
    }
    this.DeviceSimMapControllerService.linkDeviceToSim({
      deviceSerialNo: this.deviceSerialNo,
      body: { deviceSerialNo: this.deviceSerialNo, simRefIds: arr },
    }).subscribe(
      (resp) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.onClose();
        this.responseHandlerService.returnToastMessage(
          "success",
          "LINK_SIM_DEVICE_SUCCESS"
        );
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          "error",
          err.error.message
        );
      }
    );
  }

  /**
   * @ngdoc method
   * @name deviceSimMappingComponent#matChange
   *
   * @methodOf
   * device.controller:deviceSimMappingComponent
   *
   * @description
   * to trigger action like fetching data in case tab changes.
   *
   * @param {type} click event
   * @return {type} null
   */
  public matChange(event) {
    this.data = null;
    this.columns = null;
    this.currentTabValue = this.matTabOptions[event.index].value;
    this.formData = this.dynamicSearchServiceService.generateDynamicForm(
      this.currentTabValue
    );
    this.dropDownValues =
      this.dynamicSearchServiceService.generateDynamicFormDropdown(
        this.formData
      );
    this.selection.clear();
    if (event.index === 0) {
      this.getTenant();
    } else if (event.index === 1) {
      this.getSolutionType();
    }
  }

  /**
   * @ngdoc method
   * @name deviceSimMappingComponent#getSelectedIndex
   *
   * @methodOf
   * device.controller:getSelectedIndex
   *
   * @description
   * Change the default selection of Tab to solution.
   *
   * @param {type} click event
   * @return {type} null
   */
  getSelectedIndex(): number {
    if (this.fotaDetails["searchDto"]) {
      return 1;
    }
    return 0;
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#searchDevicesEmitter
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description:
   *
   * @param {type}
   * @return {type}
   */
  public searchDevicesEmitter(event) {
    this.searchDevices(event);
    this.data = null;
    this.columns = null;
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#searchDevices
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: Search device based business or solution
   *
   * @param {type} dropdown values
   * @return {type} details of group based on search
   */
  public searchDevices(searchData) {
    const tags = [];
    this.deviceCount = 0;
    let searchObj: DeviceSearchDto;
    if (this.currentTabValue === "business") {
      searchObj = {
        tenant: searchData.tenant && { id: searchData.tenant },
        businessProfile: searchData.businessProfile && {
          id: searchData.businessProfile,
        },
        state: searchData.state && searchData.state,
        status: searchData.status && searchData.status,
        deviceGroup: searchData.groupNameBusiness && {
          id: searchData.groupNameBusiness,
        },
        fromDate: searchData.fromDate && this.formatDate(searchData.fromDate),
        toDate: searchData.toDate && this.formatDate(searchData.toDate),
        id: searchData.id,
      };
      this.searchDto = { body: searchObj, page: 0, limit: this.pageSize };
    } else if (this.currentTabValue === "solution") {
      searchObj = {
        solutionType: searchData.solutionType && {
          id: searchData.solutionType,
        },
        deviceType: searchData.deviceType && { id: searchData.deviceType },
        state: searchData.state && searchData.state,
        status: searchData.status && searchData.status,
        deviceGroup: searchData.groupNameSolution && {
          id: searchData.groupNameSolution,
        },
        firmwareVersion: searchData.firmwareVersion && {
          id: searchData.firmwareVersion,
        },
        deviceInterfaceProvider: searchData.deviceInterfaceProvider && {
          id: searchData.deviceInterfaceProvider,
        },
        businessProfile: searchData.businessProfile && {
          id: searchData.businessProfile,
        },
        iotHub: searchData.iotHub && { id: searchData.iotHub },
        fromDate: searchData.fromDate && this.formatDate(searchData.fromDate),
        toDate: searchData.toDate && this.formatDate(searchData.toDate),
        id: searchData.id,
        tags: tags,
      };
      this.searchDto = { body: searchObj, page: 0, limit: this.pageSize };
    }

    if (searchData.criteria === "ADVANCE") {
      this.searchCriteria = "ADVANCE";
      this.deviceControllerService
        .getDeviceCountBySearchType({
          searchtype: this.currentTabValue,
          body: this.searchDto.body,
        })
        .subscribe(
          (resp) => {
            this.deviceCount = resp;
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              "error",
              err.error.message
            );
          }
        );
    } else {
      if (searchData.criteria === "BASIC") {
        this.searchCriteria = "BASIC";
      }
      this.deviceCount = 1;
    }
    this.getDevices(this.searchDto);
    this.selection.clear();
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#formatDate
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: Format the date in required format.
   *
   * @param {type} date
   * @return {type} date as a string
   */
  formatDate(date) {
    if (date !== undefined) {
      let day: string = date.getDate().toString();
      day = +day < 10 ? "0" + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? "0" + month : month;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#getDevices
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: Fetches devices list.
   *
   * @param {type} searchdto
   * @return {type} null
   */
  public getDevices(searchdto) {
    this.showSpinner = this.loaderService.showProgressBar();
    const arr = [];
    if (this.currentTabValue === "business") {
      this.deviceControllerService
      .getDeviceByBusinessProfile(searchdto)
        .subscribe(
          (resp) => {
            if (resp && resp["length"]) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.deviceData = resp;
              this.filterGridData();
              for (const data of this.deviceData) {
                data["action"] = null;
                data["profile"] = data["profile"] && data["profile"]["name"];
                data["solutionType"] =
                  data["solutionType"] && data["solutionType"]["name"];
                data["deviceType"] =
                  data["deviceType"] && data["deviceType"]["name"];
              }
              this.defaultColumns = this.filterColumns(
                Object.keys(this.deviceData[0])
              );
              if (this.defaultColumns.length) {
                for (const column of this.defaultColumns) {
                  if (
                    column === "serialNo" ||
                    column === "action" ||
                    column === "profile" ||
                    column === "solutionType"
                  ) {
                    arr.push(column);
                  }
                }
              }
              this.columns = arr;
            } else {
              this.data = null;
              this.columns = null;
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                "warning",
                "NO_DATA_AVAILABLE"
              );
            }
          },
          (err) => {
            this.data = null;
            this.columns = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              "error",
              err.error.message
            );
          }
        );
    } else if (this.currentTabValue === "solution") {
      this.deviceControllerService.getDeviceBySolutionType(searchdto).subscribe(
        (resp) => {
          if (resp && resp["length"]) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.deviceData = resp;
            this.filterGridData();
            for (const data of this.deviceData) {
              data["profile"] = data["profile"] && data["profile"]["name"];
              data["solutionType"] =
                data["solutionType"] && data["solutionType"]["name"];
              data["deviceType"] =
                data["deviceType"] && data["deviceType"]["name"];
              data["action"] = null;
            }

            this.defaultColumns = this.filterColumns(
              Object.keys(this.deviceData[0])
            );
            if (this.defaultColumns.length) {
              for (const column of this.defaultColumns) {
                if (
                  column === "serialNo" ||
                  column === "action" ||
                  column === "profile" ||
                  column === "solutionType"
                ) {
                  arr.push(column);
                }
              }
            }
            this.columns = arr;
          } else {
            this.data = null;
            this.columns = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              "warning",
              "NO_DATA_AVAILABLE"
            );
          }
        },
        (err) => {
          this.data = null;
          this.columns = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
    }
  }

  filterGridData() {
    let filterData = [];
    this.deviceData.map((data) =>
      data.devicePropertySupport.map((nestedData) => {
        if (
          (nestedData.devicePropertyName == "DUAL_SIM_SUPPORT" &&
            nestedData.active) ||
          (nestedData.devicePropertyName == "SINGLE_SIM_SUPPORT" &&
            nestedData.active)
        ) {
          filterData.push(data);
        }
      })
    );
    this.gridEnable = filterData.length !== 0;
    this.data = filterData;
    if (!this.gridEnable) {
      this.responseHandlerService.returnToastMessage(
        "error",
        "DEVICE_FILTER_MESSAGE"
      );
    }

  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#filterColumns
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
   *
   * @param {type} columns
   * @return {type} list of columns
   */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#checkPermission
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: Checks whether the logged in user has permission to perform crud operations.
   *
   * @param {type} key
   * @return {type} boolean
   */
  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#getTenant
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To populate tenant dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getTenant() {
    this.tenantControllerService
      .findAllTenantsBasic({ active: "true" })
      .subscribe(
        (resp) => {
          if (resp && resp["length"]) {
            this.dropDownValues["tenant"] = resp;
          } else {
            this.dropDownValues["tenant"] = [];
          }
        },
        (err) => {
          this.dropDownValues["tenant"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#updateDropdownValues
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To populate dependent dropdown.
   *
   * @param {type}  dropdown select event
   * @return {type} null
   */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      switch ($event.child.child) {
        case "businessProfile":
          if ($event.parentName === "tenant") {
            this.getProfile($event.value);
            this.tenantId = $event.value;
          }
          if ($event.parentName === "deviceInterfaceProvider") {
            this.deviceInterfaceProviderId = $event.value;
            this.getBusinessProfileOnSolution(
              this.solutionTypeId,
              this.deviceInterfaceProviderId
            );
          }
          break;
        case "deviceType":
          this.getDeviceType(
            this.solutionTypeId,
            this.deviceInterfaceProviderId
          );
          break;
        case "deviceInterfaceProvider":
          this.dropDownValues["tags"] = [];
          this.getDeviceInterfaceProviders($event.value);
          this.solutionTypeId = $event.value;
          break;
        case "iotHub":
          this.getIotHubList(
            this.solutionTypeId,
            this.deviceInterfaceProviderId,
            $event.value
          );
          this.profileId = $event.value;
      }
    } else if ($event.parentName === "solutionType") {
      this.dropDownValues["tags"] = [];
    }
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#getProfile
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To populate business profile dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues["businessProfile"] = resp;
          } else {
            this.dropDownValues["businessProfile"] = [];
          }
        },
        (err) => {
          this.dropDownValues["businessProfile"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  public getBusinessProfileOnSolution(
    solutionTypeId: string,
    deviceInterfaceProviderId: string
  ) {
    this.solutionTypeControllerService
      .findByBusinessProfilesListBasicForSolutionTypeId({
        solutionTypeId: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues["businessProfile"] = resp;
          } else {
            this.dropDownValues["businessProfile"] = [];
          }
        },
        (err) => {
          this.dropDownValues["businessProfile"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#getSolutionType
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To populate solution type dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: "true" })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues["solutionType"] = resp;
          } else {
            this.dropDownValues["solutionType"] = [];
          }
        },
        (err) => {
          this.dropDownValues["solutionType"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#getDeviceType
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To populate device type dropdown.
   *
   * @param {type} solutionTypeId, type
   * @return {type} null
   */
  public getDeviceType(solutionTypeId, deviceInterfaceProviderId) {
    this.deviceTypeControllerService
      .findAllDeviceTypesBasedOnSolutionType({
        id: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId,
        active: "true",
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues["deviceType"] = resp;
          } else {
            this.dropDownValues["deviceType"] = [];
          }
        },
        (err) => {
          this.dropDownValues["deviceType"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#pageEvent
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To fetch next list of devices.
   *
   * @param {type} event
   * @return {type} null
   */
  public pageEvent(event) {
    this.searchDto["page"] = event.pageIndex;
    this.searchDto["limit"] = event.pageSize;
    this.getDevices(this.searchDto);
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#onClose
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To close the popup.
   *
   * @param {type} null
   * @return {type} null
   */
  public onClose() {
    this._matDialog.closeAll();
  }

  /**
   * @ngdoc method
   * @name deviceSimMapping#gotoConfirmDialog
   *
   * @methodOf
   * device.controller:deviceSimMapping
   *
   * @description
   * Description: To open the popup.
   *
   * @param {type} null
   * @return {type} null
   */
  public linkSimDialogue(content, simSupport, data) {
    this.deviceSerialNo = data;
    this.DeviceSimMapControllerService.getLinkedSims({ deviceId: this.deviceSerialNo }
      ).subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          simSupport.devicePropertyName == "SINGLE_SIM_SUPPORT"
            ? (this.jsonFileLoaded = "device-sim-mapping-singleSimLink")
            : simSupport.devicePropertyName == "DUAL_SIM_SUPPORT"
              ? (this.jsonFileLoaded = "device-sim-mapping-dualSimLink")
              : null;
          this.formData['linkSim1_Id'] = resp.simReferenceId1;
          this.formData['linkSim2_Id'] = resp.simReferenceId2;
          this._matDialog.open(content, {
            maxHeight: "80vh",
            minWidth: "40vw",
            disableClose: true,
            autoFocus: false,
          });
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
 * @ngdoc method
 * @name deviceSimMapping#deLinkSimDialogue
 *
 * @methodOf
 * device.controller:deviceSimMapping
 *
 * @description
 * Description: To open delink sim popup.
 *
 * @param {any} content
 * @param {any} simSupport
 * @param {any} data
 */
  public deLinkSimDialogue(content, simSupport, data) {
    this.delinkingForm.reset();
    this.delinkingForm.controls['linkSim1_Id'].enable();
    this.delinkingForm.controls['linkSim2_Id'].enable();
    this.deviceSerialNo = data;
    this.showSpinner = this.loaderService.showProgressBar();
    this.delinkSimSupport = simSupport.devicePropertyName;
    this.DeviceSimMapControllerService.getLinkedSims({ deviceId: this.deviceSerialNo }
    ).subscribe(
      (resp) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.delinkingForm.controls['linkSim1_Id'].setValue(resp.simReferenceId1);
        this.delinkingForm.controls['linkSim2_Id'].setValue(resp.simReferenceId2);
        this._matDialog.open(content, {
          maxHeight: "80vh",
          minWidth: "40vw",
          disableClose: true,
          autoFocus: false,
        });
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          "error",
          err.error.message
        );
      }
    );
  }

  public submitDeLinkData(event) {
    this.showSpinner = this.loaderService.showProgressBar();
    let arr : Array<string> = [];
    if (this.delinkingForm.controls['linkSim1_Id'].value) {
      arr.push(this.delinkingForm.controls['linkSim1_Id'].value);
    } 
    if (this.delinkingForm.controls['linkSim2_Id'].value) {
      arr.push(this.delinkingForm.controls['linkSim2_Id'].value);
    }
    this.DeviceSimMapControllerService.delinkDeviceToSim({
      deviceSerialNo: this.deviceSerialNo,
      body: { deviceSerialNo: this.deviceSerialNo, simRefIds: arr },
    }).subscribe(
      (resp) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.onClose();
        this.responseHandlerService.returnToastMessage(
          "success",
          "DE_LINK_SIM_DEVICE_SUCCESS"
        );
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          "error",
          err.error.message
        );
      }
    );
  }

  public getDeviceInterfaceProviders(solutionTypeId) {
    this.platformAdminUtilityControllerService
      .deviceInterfaceProviders({
        solutionTypeId: solutionTypeId,
        profileId: "", active: 'true'
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues["deviceInterfaceProvider"] = resp;
          } else {
            this.responseHandlerService.returnToastMessage(
              "warning",
              "NO_DATA_AVAILABLE"
            );
            this.dropDownValues["deviceInterfaceProvider"] = [];
          }
        },
        (err) => {
          this.dropDownValues["deviceInterfaceProvider"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name determineColumnSize
   *
   * @methodOf
   * firmware.controller:deviceSimMapping
   *
   * @description
   * Description: This method will return large or small based on the column size requirements
   * @param {type} string
   * @return {type} string
   */
  public determineColumnSize(columnName) {
    if (
      columnName === "serialNo" ||
      columnName === "profile" ||
      columnName === "solutionType"
    ) {
      return "column-large";
    } else {
      return "column-small";
    }
  }

  public getIotHubList(
    solutionTypeId: string,
    deviceInterfaceProviderId: string,
    profileId: string
  ) {
    this.platformAdminUtilityControllerService
      .getIotHubsBySolDevIntfProvAndProfile({
        solutionTypeId: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId,
        profileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues["iotHub"] = resp;
          } else {
            this.dropDownValues["iotHub"] = [];
          }
        },
        (err) => {
          this.dropDownValues["iotHub"] = [];
          this.responseHandlerService.returnToastMessage(
            "error",
            err.error.message
          );
        }
      );
  }

  /**
    * @ngdoc method
    * @name deviceSimMapping#getCSVFile
    *
    * @methodOf
    * device.controller:DeviceSimMapping
    *
    * @description
    * Description: Fetches sample csv file link for download
    *
    * @param {type} event
    * @return {type} null
  */
  public getCSVFile() {
    this.DeviceSimMapControllerService.getDeviceSimMappingCsvTemplate()
      .subscribe(
        resp => {
          if (resp) {
            this.downloadFile(resp, "BulkDeviceSimMapping" + '.' + "csv")
          }
        },
        err => {
          this.sampleFile = '';
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name DeviceSimMapping#selectFiles
    *
    * @methodOf
    * SharedModule.controller:DeviceSimMapping
    *
    * @description
    * Description: To add seleted excelfiles into file array
    *
    * @param {type} event
    * @return {type} null
   */
  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.bulkSimMappingForm.value.excelFile = event.target.files[0];
      this.bulkSimMappingForm.get('excelFile').setValue(event.target.files[0]);
      if (this.bulkSimMappingForm.value.excelFile.type === CSV_FILE ||
        this.bulkSimMappingForm.value.excelFile.type === GOOGLE_CSV_FORMAT) {
        this.excelFiles.pop();
        this.excelFiles.push(event.target.files[0]);
      }
    }
  }

  /**
     * @ngdoc method
     * @name DeviceSimMapping#removeFile
     *
     * @methodOf
     * SharedModule.controller:DeviceSimMapping
     *
     * @description
     * Description: Removes files from file array
     *
     * @param index (number)
     * @param fileType (string)
     * @param deletionType (string)
    */
  removeFile(index: number, fileType, deletionType) {
    if (deletionType === 'excelFile') {
      if ((fileType === GOOGLE_CSV_FORMAT || fileType === CSV_FILE) && this.bulkSimMappingForm.value.excelFile) {
        this.excelFiles.splice(index, 1);
        this.bulkSimMappingForm.value.excelFile = null;
      }
    }
  }

  /**
    * @ngdoc method
    * @name DeviceSimMapping#selectFiles
    *
    * @methodOf
    * SharedModule.controller:DeviceSimMapping
    *
    * @description
    * Description: To calculate size of file.
    *
    * @param bytes (File size in bytes)
    * @param decimals (Decimals point)
    * @return {type} number
  */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const kiloBytes = 1024;
    const decimalValue = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const index = Math.floor(Math.log(bytes) / Math.log(kiloBytes));
    return parseFloat((bytes / Math.pow(kiloBytes, index)).toFixed(decimalValue)) + ' ' + sizes[index];
  }

  /**
   * @ngdoc method
   * @name DeviceSimMapping#downloadFile
   *
   * @methodOf
   * certificate.component:DeviceSimMapping
   *
   * @description
   * Description: Helper method to convert responce of file into downloadable file
   *
   * @param {type} data {any}, fileName {string}
   * @return {type} null
   */
  public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob([data], { type: 'application/csv' });
    FileSaver.saveAs(blob, fileName);
  }

  /**
   * @ngdoc method
   * @name DeviceSimMapping#uploadCSV
   *
   * @methodOf
   * certificate.component:DeviceSimMapping
   *
   * @description
   * Description:  method to upload selected csv file.
   *
   * @return {type} null
   */
  public uploadCSV() {
    this.showSpinner = this.loaderService.showProgressBar();
    let reqData;
    const data = this.bulkSimMappingForm.value;
    data.file = data['excelFile'];
    const params = {
      body: {
        file: data.file
      }
    };
    reqData = params;
    this.DeviceSimMapControllerService.linkDeviceToSimInBulk(reqData).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        if (resp[0]['error']) {
          this._snackBar
            .open(
              this.translate.translateErrorMessages(
                'BULK_SIM_MAP_PARTIAL_SUCCESS_MSG'
              ),
              'DOWNLOAD',
              {
                verticalPosition: 'top',
                panelClass: ['warning'],
              }
            )
            .onAction()
            .subscribe(() => {
              this.downloadFile(JSON.stringify(resp), "DeviceSimMappingResponse" + '.' + "txt");
            });
        } else {
          this.responseHandlerService.returnToastMessage(
            'success',
            'LINK_SIM_DEVICE_SUCCESS'
          );
          this.excelFiles = [];
          this.bulkSimMappingForm.reset;
        }
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }
public disbleField(field) {
  this.delinkingForm.controls[field].disable();
}
onReset() {
  this.delinkingForm.controls['linkSim1_Id'].enable();
  this.delinkingForm.controls['linkSim2_Id'].enable();
  this.delinkingForm.reset();
}

public resetForm($event) {
  if ($event) {
    this.dynamicForms.createForm.reset();
  }
}
}
