import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { SIM_REGISTRATION_ICON, SIM_VENDOR, SIM_PROVIDER } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BciLoaderService } from '@bci-web-core/core';
@Component({
  selector: 'app-register-sim',
  templateUrl: './register-sim.component.html',
  styleUrls: ['./register-sim.component.scss']
})
export class RegisterSimComponent implements OnInit {
  public jsonFileLoaded;
  public showSpinner :any;
  public pageKey: string;
  public dropDownValues: any = {};
  asideMenus: Array<AsideCard>;
  public permissionList: Array<String> = [];
  public registerSimIcon;
  public matTabOptions = [];
  public currentTabValue;
  public formData: any = {};
  public data;
  public index: number;
  public selectedVendor: string;
  public businessProfileId: string;
  public simProfiles: any;
  public simReferenceId: any;
  public simData;
  public registrationType: any;
  public parentFieldName: any;
  public simRegOptios: any;
  constructor(private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar,
    private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.registerSimIcon = SIM_REGISTRATION_ICON;
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.simRegOptios
    = [{
      "description": "This feature provides network operator based registration facility. To use this service, user should have a registered vendor.",
      "name": "Mobile Network Operator",
      "imgSrc": "../../assets/img/simVendorRegister.svg",
      "value": SIM_VENDOR
      },
      {
        "description": "This feature provides Sim Provider based registration facility with already existing sim providers. Basically, it's capturing the meta-info of the sim.",
        "name": "Sim Provider",
        "imgSrc": "../../assets/img/procureSim.svg",
        "value": SIM_PROVIDER
      }];
  }

 public getRegistrationType($event) {
  this.router.navigate([
    'sim/sim-registration',
    {
     jsonFileLoaded: ($event ==='withVendor'?'sim-individual-registration': 'sim-individual-registration-no-vendor')
    }
  ]);
 }
}
