/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ButtonTypeSupportDto } from '../models/button-type-support-dto';
import { CommonDetailsDto } from '../models/common-details-dto';
import { TemplateMappingDto } from '../models/template-mapping-dto';
import { TemplateResponseDto } from '../models/template-response-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappTemplateControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation whatsAppTemplateCategories
   */
  static readonly WhatsAppTemplateCategoriesPath = '/api/v1/category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `whatsAppTemplateCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  whatsAppTemplateCategories$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.WhatsAppTemplateCategoriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `whatsAppTemplateCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  whatsAppTemplateCategories(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.whatsAppTemplateCategories$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

  /**
   * Path part for operation fetchProfileMappedTemplates
   */
  static readonly FetchProfileMappedTemplatesPath = '/api/v1/profile/{profileId}/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchProfileMappedTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchProfileMappedTemplates$Response(params: {
    profileId: string;
  }
): Observable<StrictHttpResponse<Array<TemplateResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.FetchProfileMappedTemplatesPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchProfileMappedTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchProfileMappedTemplates(params: {
    profileId: string;
  }): Observable<Array<TemplateResponseDto>> {

    return this.fetchProfileMappedTemplates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TemplateResponseDto>>) => r.body as Array<TemplateResponseDto>)
    );
  }

  /**
   * Path part for operation createWhatsAppTemplate
   */
  static readonly CreateWhatsAppTemplatePath = '/api/v1/profile/{profileId}/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWhatsAppTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWhatsAppTemplate$Response(params: {
    profileId: string;
    templateMetaData: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<TemplateMappingDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.CreateWhatsAppTemplatePath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('templateMetaData', params.templateMetaData, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateMappingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createWhatsAppTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWhatsAppTemplate(params: {
    profileId: string;
    templateMetaData: string;
    body?: {
'file'?: Blob;
}
  }): Observable<TemplateMappingDto> {

    return this.createWhatsAppTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateMappingDto>) => r.body as TemplateMappingDto)
    );
  }

  /**
   * Path part for operation deleteTemplateById
   */
  static readonly DeleteTemplateByIdPath = '/api/v1/profile/{profileId}/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplateById$Response(params: {
    profileId: string;
    templateId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.DeleteTemplateByIdPath, 'delete');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('templateId', params.templateId, {});
    }

    return this.http.request(rb.build({
     responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplateById(params: {
    profileId: string;
    templateId: string;
  }): Observable<string> {

    return this.deleteTemplateById$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getMessageLanguage
   */
  static readonly GetMessageLanguagePath = '/api/v1/language';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMessageLanguage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageLanguage$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.GetMessageLanguagePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMessageLanguage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMessageLanguage(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.getMessageLanguage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

  /**
   * Path part for operation getButtonType
   */
  static readonly GetButtonTypePath = '/api/v1/buttonType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getButtonType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getButtonType$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.GetButtonTypePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getButtonType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getButtonType(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.getButtonType$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

  /**
   * Path part for operation getHeaderType
   */
  static readonly GetHeaderTypePath = '/api/v1/headerType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHeaderType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaderType$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.GetHeaderTypePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHeaderType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHeaderType(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.getHeaderType$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

  /**
   * Path part for operation getButtonTypeSupportList
   */
  static readonly GetButtonTypeSupportListPath = '/api/v1/button-type/support';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getButtonTypeSupportList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getButtonTypeSupportList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<ButtonTypeSupportDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.GetButtonTypeSupportListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ButtonTypeSupportDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getButtonTypeSupportList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getButtonTypeSupportList(params?: {
  }): Observable<Array<ButtonTypeSupportDto>> {

    return this.getButtonTypeSupportList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ButtonTypeSupportDto>>) => r.body as Array<ButtonTypeSupportDto>)
    );
  }

  /**
   * Path part for operation fetchWhatsAppTemplateDetails
   */
  static readonly FetchWhatsAppTemplateDetailsPath = '/api/v1/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchWhatsAppTemplateDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchWhatsAppTemplateDetails$Response(params: {
    templateId: string;
  }): Observable<StrictHttpResponse<TemplateMappingDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappTemplateControllerService.FetchWhatsAppTemplateDetailsPath, 'get');
    if (params) {
      rb.query('templateId', params.templateId, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateMappingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchWhatsAppTemplateDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchWhatsAppTemplateDetails(params: {
    templateId: string;
  }): Observable<TemplateMappingDto> {

    return this.fetchWhatsAppTemplateDetails$Response(params).pipe(
      map((r: StrictHttpResponse<TemplateMappingDto>) => r.body as TemplateMappingDto)
    );
  }

}
