import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { BciLoaderService } from '@bci-web-core/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PieChartService } from 'src/app/providers/pie-chart-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { WhatsappDeliveryReportControllerService } from 'src/app/services/Whatsapp-helper/services';
import { BulkWhatsappUploadControllerService } from 'src/app/services/Whatsapp/services';
import { CHEVRON_LEFT, DOWNLOAD_IMAGE, SEARCH_IMAGE, WHATSAPP_ICON } from 'src/app/shared/constants/strings';

@Component({
  selector: 'app-campaign-management-details',
  templateUrl: './campaign-management-details.component.html',
  styleUrls: ['./campaign-management-details.component.scss']
})
export class CampaignManagementDetailsComponent implements OnInit {
  public showSpinner :any;
  public dataSource;
  public columns: any[];
  public ignoreList = [];
  public solutionTypeId: string;
  public jobDetailsData;
  public chartDataObject = {
    title: '',
    subtitle: '',
    tooltip: '',
    details: []
  };
  public downloadImage;
  public totalJobsCount = 0;
  public totalWhatsappCount = 0;
  public successCount = 0;
  public failedCount = 0;
  public inProgressCount = 0;
  public whatsappJobName: string;
  public whatsappDetailsData;
  public whatsappIcon: string;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  public color;
  public backImage;
  public whatsappDetailsInputData = {};
  public status;
  public searchImage = SEARCH_IMAGE;
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public pieChartSubscription: Subscription;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('charts', { static: true }) public chartElement: ElementRef;
  whatsappJobId: string;
  constructor(
      private  whatsappDeliveryReportControllerService: WhatsappDeliveryReportControllerService , private router: Router, private translate: TranslateService,
      private route: ActivatedRoute, private responseHandlerService: ResponseHandlerService, private pieChartService: PieChartService,
      private datepipe: DatePipe,private loaderService: BciLoaderService,
      private bulkWhatsappUploadControllerService: BulkWhatsappUploadControllerService) { }
      
  ngOnInit() {
    this.whatsappJobId = this.route.snapshot.paramMap.get('whatsappJobId');
    this.backImage = CHEVRON_LEFT;
    this.searchImage = SEARCH_IMAGE;
    this.downloadImage = DOWNLOAD_IMAGE;
    this.whatsappIcon = WHATSAPP_ICON;
    this.color = {
      'IN_PROGRESS': '#35a6c9', 'FAILED': '#f75133', 'SUCCESS': '#78BE20'
    };
    if (this.whatsappJobId) {
      this.getWhatsappJobDeliveryStatus();
    }
  }

  ngOnDestroy() {
    if (this.pieChartSubscription) {
       this.pieChartSubscription.unsubscribe();
     }
    }


  public getWhatsappJobDeliveryStatus() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.bulkWhatsappUploadControllerService.getWhatsappJobByJobId({jobId: this.whatsappJobId}).subscribe((resp) => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.accountId = resp['accountDetails'].id;
        this.tenantId = resp['tenantDetails'].id;
        this.solutionTypeId = resp['solutionTypeDetails'].id;
        this.profileId = resp['profileDetails'].id;
        this.whatsappJobName = resp['jobname'];
        this.whatsappDetailsData = {
          WHATSAPP_DETAILS: [{ 'label': 'WHATSAPP_JOB_NAME', 'value': resp['jobname'] },
          { 'label': 'WHATSAPP_START_DATE', 'value': this.datepipe.transform(resp['startDateAndTime'], 'dd-MM-yyyy') },
          { 'label': 'STATUS', 'value': resp['status'] }
          ],
          SEARCH_CRITERIA: [
          { 'label': 'ACCOUNT', 'value': resp['accountDetails'].name },
          { 'label': 'TENANT', 'value': resp['tenantDetails'].name },
          { 'label': 'BUSINESS_PROFILE', 'value': resp['profileDetails'].name },
          { 'label': 'SOLUTION_TYPE', 'value': resp['solutionTypeDetails'].name }]
        };
        this.getWhatsappCount();
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }


  public getWhatsappCount() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappDeliveryReportControllerService.getSmsJobDeliveryStatus({jobId: this.whatsappJobId}).subscribe(resp => {
        if (resp && resp.length) {
        this.getTotalJobCount(resp);
        this.chartDataObject.details = this.getChartData(resp);
        this.chartDataObject.title = this.getTranslatedValues('WHATSAPP_STATUS');
        this.pieChartService.createChart(this.chartElement.nativeElement, this.chartDataObject);
        this.pieChartSubscription = this.pieChartService.functionNameEmitter.subscribe(status => {
          if (this.whatsappJobId) {
            this.getWhatsappDetailsInputData(status);
          }
        });
        this.loaderService.hideProgressBar(this.showSpinner);
      } else {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', 'NO_DATA_AVAILABLE');
      }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }


  public getWhatsappDetailsOnStatus(whatsappDetailsInputData) {
    this.jobDetailsData = null;
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappDeliveryReportControllerService.getSmsJobPhoneNumbersBasedOnStatus(whatsappDetailsInputData).subscribe((resp) => {
        if (resp) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.jobDetailsData = resp;
              this.jobDetailsData.forEach((response) => {
                if (response['deliveryTimeStamp']) {
                  response['deliveryTimeStamp'] = this.datepipe.transform(response['deliveryTimeStamp'], 'dd-MM-yyyy HH:mm:ss');
                }
              });
              this.columns = this.filterColumns(Object.keys(this.jobDetailsData[0]));
              this.dataSource = new MatTableDataSource<SettingCommonDto>(this.jobDetailsData);
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
            }
     },
     err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
    );
  }


  public getTotalJobCount(tempData) {
    const data = tempData;
    data.forEach(element => {
    if (element['status'] === 'SUCCESS') {
      this.successCount = element['count'];
    } else if (element['status'] === 'FAILED') {
     this.failedCount = element['count'];
    } else {
      this.inProgressCount = element['count'];
    }

    this.totalJobsCount += element['count'];
    }
  );
  }

  public getWhatsappDetailsInputData(status) {
    if (this.status && this.status !== status && this.paginator.pageIndex) {
          this.paginator.pageIndex = 0;
    }
    if (status === 'SUCCESS') {
     this.totalWhatsappCount = this.successCount;
    } else if (status === 'FAILED') {
      this.totalWhatsappCount = this.failedCount;
    } else {
      this.totalWhatsappCount = this.inProgressCount;
    }
    this.whatsappDetailsInputData = {
      jobId: this.whatsappJobId, status: status
    };
    this.status = status;
    this.getWhatsappDetailsOnStatus(this.whatsappDetailsInputData);
  }



  public pageEvent(event) {
    this.whatsappDetailsInputData['status'] = this.status;
    this.whatsappDetailsInputData['page'] = event.pageIndex;
    this.whatsappDetailsInputData['limit'] = event.pageSize;
    this.getWhatsappDetailsOnStatus(this.whatsappDetailsInputData);
  }

  public getChartData(whatsappStatusData) {
    const data = whatsappStatusData;
    data.forEach(element => {
      element['name'] = (this.getTranslatedValues(element['status']));
      element['y'] = (element['count'] * this.totalJobsCount) / 100;
      element['color'] = this.color[element['status']];
    });
    return data;
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }
  public navigateBack() {
    this.router.navigate(['whatsapp/campaign-management', {whatsappJobId : this.whatsappJobId , accountId: this.accountId,
       tenantId: this.tenantId,  solutionTypeId :  this.solutionTypeId, profileId : this.profileId}]);
    this.whatsappJobId = null;
  }

}
