/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MessageBto } from '../models/message-bto';
import { SmsTemplateDetailsDto } from '../models/sms-template-details-dto';
import { SmsTemplateDto } from '../models/sms-template-dto';

@Injectable({
  providedIn: 'root',
})
export class SmsTemplateControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSmsTemplates
   */
  static readonly GetSmsTemplatesPath = '/api/v1/businessProfile/{businessProfileId}/smstemplates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsTemplates$Response(params: {
    businessProfileId: string;
    smsTypeId?: string;
    headerId?: string;
  }): Observable<StrictHttpResponse<Array<SmsTemplateDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsTemplateControllerService.GetSmsTemplatesPath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.query('smsTypeId', params.smsTypeId, {});
      rb.query('headerId', params.headerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsTemplateDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsTemplates(params: {
    businessProfileId: string;
    smsTypeId?: string;
    headerId?: string;
  }): Observable<Array<SmsTemplateDetailsDto>> {

    return this.getSmsTemplates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SmsTemplateDetailsDto>>) => r.body as Array<SmsTemplateDetailsDto>)
    );
  }

  /**
   * Path part for operation saveSmsTemplate
   */
  static readonly SaveSmsTemplatePath = '/api/v1/businessProfile/{businessProfileId}/smstemplates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSmsTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSmsTemplate$Response(params: {
    businessProfileId: string;
    body?: Array<SmsTemplateDto>
  }): Observable<StrictHttpResponse<Array<SmsTemplateDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsTemplateControllerService.SaveSmsTemplatePath, 'post');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsTemplateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveSmsTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSmsTemplate(params: {
    businessProfileId: string;
    body?: Array<SmsTemplateDto>
  }): Observable<Array<SmsTemplateDto>> {

    return this.saveSmsTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SmsTemplateDto>>) => r.body as Array<SmsTemplateDto>)
    );
  }

  /**
   * Path part for operation deleteSmsTemplate
   */
  static readonly DeleteSmsTemplatePath = '/api/v1/businessProfile/{businessProfileId}/smstemplates/{smsTemplateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSmsTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSmsTemplate$Response(params: {
    businessProfileId: string;
    smsTemplateId: string;
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, SmsTemplateControllerService.DeleteSmsTemplatePath, 'delete');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.path('smsTemplateId', params.smsTemplateId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSmsTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSmsTemplate(params: {
    businessProfileId: string;
    smsTemplateId: string;
  }): Observable<MessageBto> {

    return this.deleteSmsTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

}
