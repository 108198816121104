import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procure-sim',
  templateUrl: './procure-sim.component.html',
  styleUrls: ['./procure-sim.component.scss']
})
export class ProcureSimComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
