import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BciLoaderService } from '@bci-web-core/core';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TranslationService } from 'src/app/providers/translation-service';
import { OtaUpdatePackageControllerService } from 'src/app/services/ETAS/services';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { CHEVRON_LEFT, FOTA_ICON_ADVANCE } from 'src/app/shared/constants/strings';

@Component({
  selector: 'app-update-package-details',
  templateUrl: './update-package-details.component.html',
  styleUrls: ['./update-package-details.component.scss'],
})
export class UpdatePackageDetailsComponent implements OnInit {
  public fotaIcon: string;
  public packageData: any;
  public routeIotHubId: string;
  public routeUpdatePackageId: string;
  public backImage;
  public showSpinner: any;
  public createUpdatedPackageData: any;
  public updatePackageId: string;
  routerSolutionTypeId: string;
  routeDeviceInterfaceProviderId: string;
  routeProfileId:string;
  etagHeader: string;
  downloadedFile: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslationService,
    private _matDialog: MatDialog,
    private loaderService: BciLoaderService,
    private otaUpdatePackageControllerService: OtaUpdatePackageControllerService,
    private objectToIdConversionService: ObjectToIdConversionService,
    private datePipe: DatePipe,
    private responseHandlerService: ResponseHandlerService
  ) {}

  ngOnInit(): void {
    this.routeIotHubId = this.route.snapshot.paramMap.get('iotHubId');
    this.routeUpdatePackageId = this.route.snapshot.paramMap.get('updatePackageId');
    this.routerSolutionTypeId = this.route.snapshot.paramMap.get('solutionTypeId');
    this.routeDeviceInterfaceProviderId = this.route.snapshot.paramMap.get('deviceInterfaceProviderId');
    this.routeProfileId = this.route.snapshot.paramMap.get('profileId');
    this.fotaIcon = FOTA_ICON_ADVANCE;
    this.backImage = CHEVRON_LEFT;
    this.getUpdatePackage();
  }

  getUpdatePackage() {
    this.showSpinner = this.loaderService.showProgressBar();
    const data = {
      iotHub: this.objectToIdConversionService.convertObjectToId(this.routeIotHubId)};
    const searchData = {
      body: data,
      updatePackageId: this.routeUpdatePackageId,
    };
    this.otaUpdatePackageControllerService
      .getUpdatePackageById(searchData)
      .subscribe((resp) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.updatePackageId = resp.updatePackageId;
        this.etagHeader = resp.etagHeader;
        this.downloadedFile = resp.fileName;
        this.packageData = { 
          'name': resp.name, 'contentType': resp.contentType,
          'agent':resp.updateAgent,'contentUrl':resp.contentUri,
          'sizeInBytes':resp.sizeInBytes,'hashe(md5)':resp.hashes.md5,
          'hashe(sha1)':resp.hashes.sha1,'id':resp.updatePackageId,
          'deleted':resp.deleted,
          'properties':resp.properties,
          }
       this.createUpdatedPackageData = {
        'Created:': this.datePipe.transform(resp.createdOn,'MMM dd yyyy, h:mm:ss a'),
        'Last Updated:': this.datePipe.transform(resp.lastUpdatedOn, 'MMM dd yyyy, h:mm:ss a')
       }
      }),
      (err) => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
        this.loaderService.hideProgressBar(this.showSpinner);
      };
  }

  public gotoConfirmDialog(data = null) {
     let message =  this.translate.translateErrorMessages('DELETE_UPDATE_PACKAGE_MESSAGE');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
        maxWidth: '400px',
        disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            this.deletePackage();
        } else {
          this._matDialog.closeAll();
        }
    });
}
  deletePackage() {
    const data = {
      iotHub: this.objectToIdConversionService.convertObjectToId(this.routeIotHubId)};
    const deleteRequest = {
      updatePackageId: this.updatePackageId,
      docVersion: this.etagHeader,
      body: data
    }
    this.showSpinner = this.loaderService.showProgressBar();
    this.otaUpdatePackageControllerService.deleteUpdatePackageById(deleteRequest).subscribe( resp=>{
    this.loaderService.hideProgressBar(this.showSpinner);
    this.responseHandlerService.returnToastMessage('success', 'PACKAGE_DELETED');
    this.router.navigate([
      'ota-advance/update-package',{ 
        iotHubId: this.routeIotHubId,
        solutionTypeId: this.routerSolutionTypeId,
        deviceInterfaceProviderId: this.routeDeviceInterfaceProviderId,
        profileId: this.routeProfileId},]);    
  }),
    (err) =>{
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
  }
  
  navigateBack() {
    this.router.navigate([
      'ota-advance/update-package',{ 
        iotHubId: this.routeIotHubId,
        solutionTypeId: this.routerSolutionTypeId,
        deviceInterfaceProviderId: this.routeDeviceInterfaceProviderId,
        profileId: this.routeProfileId,
       },
    ]);
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  getPropertyOrder(obj: any): string[] {
    return Object.keys(obj);
  }


  public downloadDynamicCertificate(){
    const data = {
      iotHub: this.objectToIdConversionService.convertObjectToId(this.routeIotHubId)};
    const downloadRequest = {
      updatePackageId: this.updatePackageId,
      body: data
    }
    this.showSpinner = this.loaderService.showProgressBar();
    this.otaUpdatePackageControllerService.updatePackageFileDownload(downloadRequest).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        if (resp) {
              this.downloadFile(resp, this.downloadedFile );
         }
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
   }

   public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob([data], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName);
  }
}
