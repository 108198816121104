<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{'WHATSAPP_TEMPLATE_CONFIGURATION' | translate}}</span>
      </span>
    </div>
    <div class="form-spacing">
      <div *ngIf="!formData">
        <app-dynamic-form [hideSubmitButton]="true" [jsonFile]="'whatsapp-manage-template'"
          [gridLayout]="'grid-container-whatsapp-manage-template'" [dropDownValues]="dropDownValues"
          (valueSelected)="updateDropdownValues($event)"></app-dynamic-form>
      </div>
      <div *ngIf="formData">
        <app-dynamic-form [hideSubmitButton]="true" [jsonFile]="'whatsapp-manage-template'" [formData]="formData"
          [gridLayout]="'grid-container-whatsapp-manage-template'" [dropDownValues]="dropDownValues"
          (valueSelected)="updateDropdownValues($event)"></app-dynamic-form>
      </div>
      <section class="section" >
        <button  [disabled]="!checkPermission('WSP_TMPL_C') ? true: profileIdCheck" mat-raised-button class="button-image" (click)="redirectTo()">{{'CREATE_WHATSAPP_TEMPLATE'|
          translate}}</button>
      <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
      </section>
    </div>
    <div class="table-container" *ngIf="templatesList">
      <mat-table [dataSource]="dataSource" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <span *ngIf="column!=='action' && column!=='active'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column==='action'">
                <img [src]="viewImage" class="actionIcon viewIcon" [title]="'VIEW' | translate"
                  (click)="viewTemplate(dataName)">
                <img [src]="deleteImage" class="actionIcon" (click)="gotoConfirmDialog(dataName)">
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
  </section>
</section>
