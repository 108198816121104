/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

export const DISCONNECTED = 'DISCONNECTED';
export const CONNECTED = 'CONNECTED';
export const TOAST_MSG_DURATION = 4000;
export const CREATE = 'create';
export const MANAGE = 'manage';
export const OVERVIEW = 'overview';
export const OVERVIEW_ACCOUNT_IMAGE = 'transparent url("../../assets/img/lock-closed.svg") no-repeat center 90px';
export const OVERVIEW_BUSINESSPROFILE_IMAGE = 'transparent url("../../assets/img/user.svg") no-repeat center 90px';
export const OVERVIEW_TENANT_IMAGE = 'transparent url("../../assets/img/home-user-in.svg") no-repeat center 90px';
export const OVERVIEW_USER_IMAGE = 'transparent url("../../assets/img/user-setting.svg") no-repeat center 90px';
export const OVERVIEW_DEVICE_IMAGE = 'transparent url("../../assets/img/devices.svg") no-repeat center 90px';
export const OVERVIEW_DONUT_PROPERTIES = { colors: ['#78BE20', '#FCAF17'], dimensions: { 'height': '300', 'width': '300' } };
export const EDIT_IMAGE = '../../assets/img/edit.svg';
export const DELETE_IMAGE = '../../assets/img/delete.svg';
export const CONFIGURE_IMAGE = '../../assets/img/configure.svg';
export const MORE_OPTION = '../../assets/img/options.svg';
export const FOTA_IMAGE = '../../assets/img/fota.svg';
export const RESTART_IMAGE = '../../assets/img/restart.svg';
export const REBOOT_IMAGE = '../../assets/img/reboot.svg';
export const ARROW_FORWARD_IMAGE = '../../assets/img/home/arrow_fwd.png';
export const ARROW_IMAGE = '../../assets/img/arrow.svg';
export const BOSCH_IMAGE = '../../assets/img/header/bosch.svg';
export const ADD_IMAGE = '../../assets/img/add.svg';
export const ACTIVE = 'ACTIVE';
export const SEARCH_IMAGE = '../../assets/img/search.png';
export const PLAY_IMAGE = '../../assets/img/play.svg';
export const PAUSE_IMAGE = '../../assets/img/pause.svg';
export const TERMINATE_IMAGE = '../../assets/img/terminate.svg';
export const DETAILS_IMAGE = '../../assets/img/details.svg';
export const ARROW_LEFT = '../../assets/img/arrow-left.png';
export const DOWNLOAD_IMAGE = '../../assets/img/download.svg';
export const DOCUMENT_IMAGE = '../../assets/img/document.svg';
export const CONCURRENCY = 2;
export const CONCURRENCY_MINLENGTH = 2;
export const CONCURRENCY_MAXLENGTH = 100;
export const CONCURRENCY_TYPE = 'RELATIVE';
export const JOBNAME_MAXLENGTH = 50;
export const TIMEINTERVAL_MINLENGTH = 1;
export const TIMEINTERVAL_MAXLENGTH = 3;
export const TIMEINTERVAL = 1;
export const OPERATING_TYPE = 'DeviceTwin';
export const THUMBUP_IMAGE = '../../assets/img/thumb-up.svg';
export const THUMBDOWN_IMAGE = '../../assets/img/thumb-down.svg';
export const COPY_IMAGE = '../../assets/img/copy.svg';
export const BACK_LEFT = '../../assets/img/back-left.svg';
export const CHEVRON_LEFT = '../../assets/img/chevron-left-icon.svg';
export const GENERATE_IMAGE = '../../assets/img/generate.svg';
export const HIDE_KEY = '../../assets/img/hide-key.svg';
export const SHOW_KEY = '../../assets/img/show-key.svg';
export const FIRMWAREVERSION_MAXLENGTH = 50;
export const ACCESS_KEY_ICON = '../../assets/img/menu-icons/key-management.svg';
export const ACCOUNT_ICON = '../../assets/img/menu-icons/account-management.svg';
export const TENANT_ICON = '../../assets/img/menu-icons/tenant-management.svg';
export const PROFILE_ICON = '../../assets/img/menu-icons/profile-management.svg';
export const USER_ICON = '../../assets/img/menu-icons/user-management.svg';
export const DEVICE_ICON = '../../assets/img/menu-icons/device-management.svg';
export const FIRMWARE_ICON = '../../assets/img/menu-icons/firmware-management.svg';
export const FOTA_ICON = '../../assets/img/menu-icons/fota-management.svg';
export const SCHEDULER_ICON = '../../assets/img/menu-icons/scheduler-management.svg';
export const REPORT_ICON = '../../assets/img/menu-icons/reports.svg';
export const SMS_ICON = '../../assets/img/menu-icons/sms-management.svg';
export const INFO_ICON_IMAGE = '../../assets/img/info-icon.svg';
export const ERROR_THRESHOLD_TYPE = 'PERCENTAGE';
export const CSV_FILE = 'application/vnd.ms-excel';
export const CALENDER_IMAGE = '../../assets/img/calender.svg';
export const CERTIFICATE_ICON = './../../assets/img/menu-icons/certificate-icon.svg';
export const CERTIFICATE_DASHBOARD_ICON = './../../assets/img/dashboard/certificate.svg';
export const DEVICE_DASHBOARD_ICON = './../../assets/img/dashboard/device.svg';
export const USER_DASHBOARD_ICON = './../../assets/img/dashboard/user.svg';
export const EMAIL_DASHBOARD_ICON = './../../assets/img/dashboard/mail.svg';
export const SMS_DASHBOARD_ICON = './../../assets/img/dashboard/message.svg';
export const WHATSAPP_DASHBOARD_ICON = './../../assets/img/dashboard/whatsapp.svg';
export const ACCOUNT_CARD_ICON = '../../assets/img/account-dashboard.svg';
export const TENANT_CARD_ICON = '../../assets/img/home/tenant- icon.svg';
export const PROFILE_CARD_ICON = '../../assets/img/profile-dashboard.svg';
export const BACK_ARROW = '../../assets/img/arrow.svg';
export const CONFIG_DASHBOARD_ICON = '../../assets/img/dashboard/configuration.svg';
export const C2D_DASHBOARD_ICON = '../../assets/img/dashboard/c2d.svg';
export const MAP_DASHBOARD_ICON = '../../assets/img/dashboard/map.svg';
export const ADD_TAGS = 'Add Tags';
export const REMOVE_TAGS = 'Remove Tags';
export const CONNECTED_ICON = '../../assets/img/connected.svg';
export const DISCONNECTED_ICON = '../../assets/img/disconnected.svg';
export const REGISTERED_ICON = '../../assets/img/registered.svg';
export const BOSCH_IOT_ICON = '../../assets/img/bosch-iot.svg';
export const AZURE_ICON = '../../assets/img/azure.svg';
export const AWS_ICON = '../../assets/img/aws.svg';
 // tslint:disable-next-line
export const VALIDITY_LEFT_MSG = "Selected parent certificate's total validity left in days are";
export const CUSTOMER_ID_MAXLENGTH = 50;
export const CREDIT_VALUE_MAXLENGTH = 50;
export const DECIMAL_VALUE_CHECK = '((\\d+)(\\.\\d{2}))$';
export const INFRASTRUCTURE_ICON = '../../assets/img/billing/infrastructure.svg';
export const GENERAL_INFO_ICON = '../../assets/img/billing/general-info.svg';
export const PERIPHERAL_ICON = '../../assets/img/billing/peripheral.svg';
export const SAAS_ICON = '../../assets/img/billing/saas.svg';
export const WORKFLOW_ICON = '../../assets/img/billing/workflow.svg';
export const PROPOSAL_ICON = '../../assets/img/billing/proposal.svg';
export const APPROVE_ICON = '../../assets/img/approve.svg';
export const REJECT_ICON = '../../assets/img/reject.svg';
export const USER_IMAGE = '../../assets/img/header/user.svg';
export const MAIL_ICON = '../../assets/img/header/mail.svg';
export const OPT_OUT_ICON = '../../assets/img/header/opt-out.svg';
export const DETAILS_ICON_BLUE = '../../assets/img/access-key/info.svg';
export const VIEW_ICON = '../../assets/img/view.svg';
export const AWS_BUCKET_NAMING_RULES_LINK = 'https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html';
export const CLOCK_24_7_ICON = '../../assets/img/billing/clock-24-7.svg';
export const LOCK_OPEN_ICON = '../../assets/img/billing/lock-open.svg';
export const UPLOAD_ICON = '../../assets/img/billing/upload.svg';
export const ACTIVITY_LOG = '../../assets/img/billing/activity-log.svg';
export const STANDBY_ICON = '../../assets/img/billing/standby.svg';
export const WATCHON_ICON = '../../assets/img/billing/watchOn.svg';
export const DOWNLOAD_ICON = '../../assets/img/billing/download.svg';
export const LOCK_CLOSE_ICON = '../../assets/img/billing/lock-close.svg';
export const RIGHT_ARROW_ICON = '../../assets/img/billing/arrow-right.svg';
export const BILLING_LIST_ICON = '../../assets/img/billing/billing-list.svg';
export const OPERATION_CONFIGURATION_ICON = '../../assets/img/billing/operation-configuration.svg';
export const REVIEW_OK_ICON = '../../assets/img/billing/review-ok.svg';
export const REVIEW_NOT_OK_ICON = '../../assets/img/billing/review-not-ok.svg';
export const CI_CHARGES_ICON = '../../assets/img/billing/CI_Charges.svg';
export const CONVERSION_ICON = '../../assets/img/billing/Conversion.svg';
export const SG_AND_A_ICON = '../../assets/img/billing/SG&A.svg';
export const OT_RT_ICON = '../../assets/img/billing/OT_RT.svg';
export const CURRENCY_CHARGES_ICON = '../../assets/img/billing/currency-charges.svg';
export const SAAS_CHARGES_ICON = '../../assets/img/billing/saas-charges.svg';
export const PERIPHERAL_CHARGES_ICON = '../../assets/img/billing/peripheral-charges.svg';
export const WORKFLOW_DEFAULT_ICON = '../../assets/img/billing/workflow-default-icon.svg';
export const AVAILABLE_ICON = '../../assets/img/available.svg';
export const NOT_AVAILABLE_ICON = '../../assets/img/not-available.svg';
export const MCP_ICON = '../../assets/img/MCP.svg';
export const WEBSITE_ICON = '../../assets/img/website.svg';
export const LINKEDIN_ICON = '../../assets/img/linkedIn.svg';
export const YOUTUBE_ICON = '../../assets/img/youtube.svg';
export const TWITTER_ICON = '../../assets/img/twitter.svg';
export const FACEBOOK_URL = 'https://www.facebook.com/BoschIndia';
export const TWITTER_URL = 'https://twitter.com/boschindia';
export const YOUTUBE_URL = 'https://www.youtube.com/watch?v=z_UDS7iBS4Y';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/bosch-india';
export const SERVICE_DESK_ICON = '../../assets/img/service-agent.svg';
export const BRAND_STORE_URL = 'https://mss.boschindia-mobilitysolutions.com/partners/mcp';
export const SALES_CONTACT_URL = 'https://www.mobilitymarketplace.io/get-in-touch';
export const SALES_QUERY_URL = 'https://mss.boschindia-mobilitysolutions.com/partners/mcp#req-info';
export const GOOGLE_CSV_FORMAT = 'text/csv';
export const ZELIOT_ICON = '../../assets/img/zeliot.svg';
export const SUPPORT_PORTAL_ICON = '../../assets/img/web-portal.svg';
export const INFRA_SERVICES_URL = 'https://www.mobilitymarketplace.io/cloud-services/cloud-infrastructure/listing?asset-type=cloud-infrastructure';
export const SOFTWARE_SERVICES_URL = 'https://www.mobilitymarketplace.io/services/software-services/listing?asset-type=software-services';
export const PERIPHERAL_SERVICES_URL = 'https://mss.boschindia-mobilitysolutions.com/partners/mcp/peripheralservices';
export const CUSTOMER_SUPPORT_PORTAL_URL = 'https://desk.zoho.in/portal/mcpcutomerhelpdesk/en/home';
export const SUPPORTED_ATTESTATION_TYPE = 'X509';
export const ZELIOT_ATTESTATION_TYPE = 'NA';
export const SIMCARD_ICON = '../../assets/img/menu-icons/simcard.svg';
export const VENDOR_REGISTRATION_ICON = '../../assets/img/simVendorRegister.svg';
export const SIM_REGISTRATION_ICON = '../../assets/img/registerSim.svg';
export const SIM_MANAGE_ICON = '../../assets/img/manageSim.svg';
export const SIM_PROCURE_ICON = '../../assets/img/procureSim.svg';
export const VENDOR_AIRTEL = 'Airtel';
export const SIM_PLAN_ICON = '../../assets/img/simPlan.svg';
export const FILE_SIZE_LIMIT = 'For selected storage account maximum firmware size can be uploaded is ';
export const CONTACT_ADMIN = 'Please contact system administrator, to increase size up to 20MB.';
export const DEVICE_TUNNELING_ICON = '../../assets/img/deviceTunneling.svg';
export const DEVICE_TUNNELING_ICON_WHITE = '../../assets/img/deviceTunnelingWhite.svg';
export const DOWNLOAD_SAMPLE_FILE_NO_LINK = 'DOWNLOAD_SAMPLE_FILE';
export const WHITE_LISTING_SIM_ICON = '../../assets/img/simWhiteListing.svg';
export const APPROVE_ICON_NO_FILL = '../../assets/img/approve-icon.svg';
export const REJECT_ICON_NO_FILL = '../../assets/img/reject-icon.svg';
export const PROPOSAL_ICON_NO_FILL = '../../assets/img/billing/proposal-icon.svg';
export const DOWNLOAD_ICON_NO_FILL = '../../assets/img/billing/download-icon.svg';
export const HOST_COPY_ICON = '../../assets/img/copyHost.svg';
export const PORT_COPY_ICON = '../../assets/img/copyPort.svg';
export const COPY_ENDPOINT_IMAGE = '../../assets/img/copyEndPoint.svg';
export const WHATSAPP_ICON = '../../assets/img/menu-icons/whatsapp-management.svg';
export const CLOUD_UPLOAD_ICON = '../../assets/img/cloud-upload.svg';
export const LINKED_ICON = '../../assets/img/linkedImage.svg';
export const NOT_LINKED_ICON = '../../assets/img/notLinkedImage.svg';
export const DOWNLOAD_ICON_SMALL ='../../assets/img/download-icon-small.svg';
export const SUPPORTED_WHATSAPP_VIDEO_FORMAT = '.m4v,.mp4';
export const SUPPORTED_WHATSAPP_IMAGE_FORMAT = '.png,.pjp,.jpg,.jpeg,.pjpeg';
export const SUPPORTED_WHATSAPP_DOCUMENT_FORMAT = '.pdf';
export const DEV_STATS_MENU_ICON = '../../assets/img/deviceStatsWhite.svg';
export const DEV_STATS_ACTION_ICON = '../../assets/img/deviceStatsBlack.svg';
export const INWARD_DISPLAY_ICON = '../../assets/img/inwardDisplay.svg';
export const OUTWARD_DISPLAY_ICON = '../../assets/img/outwardDisplay.svg';
export const CLOSE_ICON = '../../assets/img/close.svg';
export const BACK_LEFT_SMALL = '../../assets/img/back-left-small.svg';
export const WHITE_DELETE_ICON = '../../assets/img/back-delete.svg';
export const WATCH_ON = '../../assets/img/watch-on.svg';
export const BLACK_DOWNLOAD_ICON = '../../assets/img/black-download.svg';
export const SIM_VENDOR = 'withVendor';
export const SIM_PROVIDER = 'withoutVendor';
export const PAPER_CLIP = '../../assets/img/paperclip.svg';
export const SYS_GRPH = 'systemGraphs';
export const APPLN_GRPH = 'applicationGraphs';
export const SYS_GPH_ICON = '../../assets/img/sysGraphs.svg';
export const APPLN_GPH_ICON = '../../assets/img/applnGraphs.svg';
export const C2D_WHITE_ICON = '../../assets/img/cloud-to-device.svg';
export const C2D_BLACK_ICON = '../../assets/img/black-cloud-to-device.svg';
export const CIRCLE_ICON = '../../assets/img/ellipse.svg';
export const POLYGON_ICON = '../../assets/img/polygon.svg';
export const SATELLITE_ICON = '../../assets/img/satellite.svg';
export const DEFAULT_ICON = '../../assets/img/default.svg';
export const LAYERS_ICON = '../../assets/img/layers.svg';
export const TERRAIN_ICON = '../../assets/img/terrain.svg';
export const POLYGON_SELECTED_ICON = '../../assets/img/polygonSelected.svg';
export const CIRCLE_SELECTED_ICON = '../../assets/img/circleSelected.svg';
export const SPINNER_GIF = '../../assets/img/gif/spinner.gif';
export const PDF_ICON = '../../assets/img/pdf.svg';
export const WHATSAPP_ANALYTICS_ICON ='../../assets/img/whatsapp-analytics.svg';
export const WHATSAPP_BUSINESS_NUM_ICON = '../../assets/img/whatsAppBusiness.svg';
export const WHATSAPP_QUALITY_ICON = '../../assets/img/qualityRating.svg';
export const WHATSAPP_MSG_LIMIT_ICON = '../../assets/img/messagingLimit.svg';
export const LINK_TRACKING = '../../assets/img/link.svg';
export const FOTA_ICON_ADVANCE = '../../assets/img/menu-icons/ota-advance.svg';
export const WHATSAPP_DARK_BACKGROUND = '../../assets/img/whatsapp-background-dark.png';
export const WHATSAPP_LIGHT_BACKGROUND = '../../assets/img/whatsapp-background-light.png';
export const WHATSAPP_DOCUMENT = '../../assets/img/whatsapp-document.png';
export const WHATSAPP_LOCATION = '../../assets/img/whatsapp-location.png';
export const RERUN_ICON = '../../assets/img/rerun-icon.svg';
export const REFRESH_WITH_TEXT = '../../assets/img/refresh-withtext.svg';
export const OPT_OUT = '../../assets/img/opt-out.svg';
export const FILTER_ICON= '../../assets/img/filter-icon.svg';
export const DOWNLOAD_ICON_BLUE = '../../assets/img/download-icon-blue.svg';
