import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    DELETE_IMAGE,
    DOWNLOAD_ICON,
    WHATSAPP_ICON,
  } from 'src/app/shared/constants/strings';
import {
    AccountControllerService,
    TenantControllerService,
    BusinessProfileControllerService,
  } from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { WhatsappDeliveryReportControllerService } from 'src/app/services/Whatsapp-helper/services';
import { WhatsappTemplateControllerService,BulkWhatsappUploadControllerService } from 'src/app/services/Whatsapp/services';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BciLoaderService } from '@bci-web-core/core';
import { MatDialog } from '@angular/material/dialog';
import { DateValidatorService } from 'src/app/providers/date-validator.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { TranslationService } from 'src/app/providers/translation-service';

@Component({
  selector: 'app-whatsapp-status',
  templateUrl: './whatsapp-status.component.html',
  styleUrls: ['./whatsapp-status.component.scss']
})

export class WhatsappStatusComponent implements OnInit {
  public whatsappIcon: string;
  public permissionList: Array<String> = [];
  public dropDownValues: any = {};
  public disableDownload = true;
  public accountId: string;
  public whatsappStatusData: any;
  public data: any[];
  public tenantId: string;
  public solutionTypeId;
  public profileId: string;
  public profileName: string;
  public showSpinner: any;
  public downloadExcelJson: any;
  public submitRequestJson: any;
  public statusData;
  public reportData;
  public reportDateToggle:boolean = true;
  public disableSubmitRequest = true;
  public columns: any[];
  public configurationsList: Array<String> = [];
  public jsonFileLoaded: string;
  public toastDuration_10_second = 10000;
  public ignoreList = ['templateName', 'templateContent', 'profileName'];
  public ignoreListRequest = ['id','profileId','request','comments','recordCount','reportLink','retryCount','updatedDt'];
  public downloadImage: string;
  public deleteImage: string;
  public reportColumn: any[];

  constructor(
    private httpsService: HttpClient,
    private route: ActivatedRoute,
    private accountControllerService:AccountControllerService,
    private responseHandlerService : ResponseHandlerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private bulkWhatsappUploadControllerService :BulkWhatsappUploadControllerService,
    private whatsappDeliveryReportControllerService: WhatsappDeliveryReportControllerService,
    private whatsappTemplateControllerService:WhatsappTemplateControllerService,
    private loaderService: BciLoaderService,
    private datePipe: DatePipe,
    private _matDialog: MatDialog, private dateValidatorService: DateValidatorService,
    private translationService : TranslationService
    ) { 
  }

  ngOnInit(): void {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.getAccount();
    this.getTemplateCategories();
    this.whatsappIcon = WHATSAPP_ICON;
    if (this.permissionList.indexOf('WSP_RPT_R') > -1) {
        this.httpsService
          .get('./assets/form-fields/whatsapp-status.json')
          .subscribe((data) => {
            this.data = data as string[];
            this.whatsappStatusData = this.data['fields'];
          });
      }
    this.downloadImage = DOWNLOAD_ICON;
    this.deleteImage = DELETE_IMAGE;
  }

  public updateDropdownValues($event) {
    this.disableDownload = true;
    if ($event.value !== undefined) {
        if ($event.child.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child.child === 'solutionType') {
          this.getSolutionType($event.value);
          this.profileId = $event.value;
          this.getProfileMappedTemplates($event.value);
          this.getWhatsappJobs();
          this.generateExcelFilename($event.value);
        }
        if ($event.child.child === 'jobName') {
          this.solutionTypeId = $event.value;
          this.getWhatsappJobs();
        }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getTemplateCategories() {
    this.whatsappTemplateControllerService
      .whatsAppTemplateCategories({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['category'] = resp;
          } else {
            this.dropDownValues['category'] = [];
          }
        },
        (err) => {
          this.dropDownValues['category'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getWhatsappJobs() {
    this.dropDownValues['jobName'] = [];
    if (this.profileId) {
      const params = {
        status: 'completed',
        body: {
          profileId: this.profileId,
          solutionTypeId: this.solutionTypeId ? this.solutionTypeId : null
        }
      };
      this.bulkWhatsappUploadControllerService.getWhatsappJobsByprofileIdAndSolutionTypeId(params)
      .subscribe( resp => {
        if (resp && resp.length) {
          resp.forEach( row => {
            this.dropDownValues['jobName'].push({ id: row['jobId'], name: row['jobName']});
          });
        } else {
          this.dropDownValues['jobName'] = [];
        }
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
      });
    }
  }

  public getProfileMappedTemplates(profileId: string) {
    this.dropDownValues['templateName'] = [];
    this.whatsappTemplateControllerService
      .fetchProfileMappedTemplates({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach(template => {
              this.dropDownValues['templateName'].push({name: template['templateName'], id: template['id']});
            })
          } else {
            this.dropDownValues['templateName'] = [];
          }
        },
        (err) => {
          this.dropDownValues['templateName'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public generateExcelFilename(profileId) {
    this.dropDownValues['businessProfile'].forEach( row => {
      if (row['id'] === profileId) {
        this.profileName = row['name'];
      }
    });
  }

  public downloadExcel() {
    this.showSpinner = this.loaderService.showProgressBar();
    let timestamp: Date;
    this.whatsappDeliveryReportControllerService.downloadWhatsappDeliveryReports({body: this.downloadExcelJson})
    .subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        timestamp = new Date();
        const FileSaver = require('file-saver');
        FileSaver.saveAs(resp, this.profileName.concat('_', this.datePipe.transform(timestamp, 'yyyy-mm-dd_hh.mm.ss'), '.xlsx'));
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

 

  public whatsappStatusEmitter(data: any) {
    if (data['businessProfile'] === undefined) {
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_SELECT_MANDATORY_FIELDS'
      );
      return;
    }

    if (data['mobileNumber']) {
      const regex = new RegExp('^(\\+\\d{1,3})?\\*?\\d{0,10}\\*?$');
      const validator = regex.test(data['mobileNumber']);
      if (!validator) {
        this.responseHandlerService.returnToastMessage(
          'error',
          'INVALID_MOBILE_NUMBER'
        );
        return;
      }
    }
    if (data['fromDate'] && data['toDate']) {
      data['fromDate'] = this.datePipe.transform(
        data['fromDate'],
        'yyyy-MM-dd'
      );
      data['toDate'] = this.datePipe.transform(data['toDate'], 'yyyy-MM-dd');
      if (data['fromDate'] > data['toDate']) {
        this.responseHandlerService.returnToastMessage(
          'error',
          'FROMDATE_LESSTHAN_TODATE'
        );
        return;
      }
    }
    this.getWhatsappStatus(data);
  }

  public getWhatsappStatus(data: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    let responseData = [];
    const requestJson = {
      profileId: data['businessProfile'],
      solutionTypeId: data['solutionType'],
      templateId: data['templateName'],
      fromDate: this.datePipe.transform(data['fromDate'], 'yyyy-MM-dd'),
      toDate: this.datePipe.transform(data['toDate'], 'yyyy-MM-dd'),
      mobileNumber: (data['mobileNumber'] === '' ? null : data['mobileNumber']),
      channelId: data['category'],
      jobId: data['jobName'],
    };
    this.whatsappDeliveryReportControllerService
      .getWhatsappDeliveryReports({ body: requestJson })
      .subscribe( (resp) => {
    this.disableDownload = false;
    this.loaderService.hideProgressBar(this.showSpinner);
    this.downloadExcelJson = requestJson;
    this.submitRequestJson = requestJson;
    if (resp['whatsappDeliveryReportDtoList'] !== null && resp['messageCode'] === 'SUCCESS') {
      this.statusData = null;
      this.reportDateToggle = false;
      responseData = resp['whatsappDeliveryReportDtoList'];
      responseData.forEach((field) => {
        field['profile'] = field['profile'].name;
        field['solutionType'] = field['solutionType'].name;
      });
      this.loaderService.hideProgressBar(this.showSpinner);
      responseData = resp['whatsappDeliveryReportDtoList'];
      this.statusData = new MatTableDataSource<any>(responseData);
      this.columns = this.filterColumns(Object.keys(responseData[0]));
    } else if (resp['count'] > 50 && resp['count'] < 10000) {
      this.statusData = null;
      this.reportDateToggle = true;
      this.disableDownload = false;
      this.disableSubmitRequest = false;
      this.responseHandlerService.returnToastMessage('warning','EXCESS_RECORDS',this.toastDuration_10_second);
    } else if (resp['count'] > 10000) {
      this.statusData = null;
      this.reportDateToggle = true;
      this.disableSubmitRequest = false;
      this.disableDownload = true;
      this.responseHandlerService.returnToastMessage('warning','EXCESS_RECORDS_10K',this.toastDuration_10_second);
    } else if (resp['messageCode'] === 'NO_RESULTS') {
      this.statusData = null;
      this.reportDateToggle = true;
      this.disableDownload = true;
      this.disableSubmitRequest = true;
      this.responseHandlerService.returnToastMessage('warning', 'NO_RESULTS');
    }
    },(err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public filterColumnRequest(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter(
        (item) => this.ignoreListRequest.indexOf(item) <= -1
      );
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  submitRequest() {
    this.showSpinner = this.loaderService.showProgressBar();
    let responseData = [];
    this.reportData = null;
    this.reportDateToggle = true;
    this.whatsappDeliveryReportControllerService
      .submitWhatsappdeliveryRequest({ body: this.submitRequestJson })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          responseData = resp['deliveryRequests'];
          if (resp['deliveryRequests'] !== null &&resp['messageCode'] === 'SUCCESS') {
            this.responseHandlerService.returnToastMessage(
              'success',
              'DELIVERY_REQUEST_MESSAGE',
              this.toastDuration_10_second
            );
            responseData = resp['deliveryRequests'];
            this.loaderService.hideProgressBar(this.showSpinner);
            responseData = resp['deliveryRequests'];
            for (const data of responseData) {
              data['createdDt'] = this.dateValidatorService.convertUTCToLocalDate(data['createdDt']);
              data['updatedDate'] = this.dateValidatorService.convertUTCToLocalDate(data['updatedDt']);
              data['action'] = null;
            }
            this.reportData = new MatTableDataSource<any>(responseData);
            this.reportColumn = this.filterColumnRequest(
              Object.keys(responseData[0])
            );
          }
          if (resp['deliveryRequests'] !== null &&resp['messageCode'] === 'BAD_REQUEST') {
            this.responseHandlerService.returnToastMessage('warning','DELIVERY_BAD_REQUEST_MESSAGE',this.toastDuration_10_second);
            responseData = resp['deliveryRequests'];
            this.loaderService.hideProgressBar(this.showSpinner);
            responseData = resp['deliveryRequests'];
            for (const data of responseData) {
              data['createdDt'] = this.dateValidatorService.convertUTCToLocalDate(data['createdDt']);
              data['updatedDate'] = this.dateValidatorService.convertUTCToLocalDate(data['updatedDt']);
              data['action'] = null;
            }
            this.reportData = new MatTableDataSource<any>(responseData);
            this.reportColumn = this.filterColumnRequest(
              Object.keys(responseData[0])
            );
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  downloadRequest(id,profileName) {
    this.showSpinner = this.loaderService.showProgressBar();
    let timestamp: Date;
    this.whatsappDeliveryReportControllerService
      .getZippedFile({ requestId: id })
      .subscribe((resp) => {
        timestamp = new Date();
        const FileSaver = require('file-saver');
        FileSaver.saveAs(resp,profileName.concat('_',this.datePipe.transform(timestamp, 'yyyy-mm-dd_hh.mm.ss'), '.zip'));
      }, (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      });
    // Delay for 3 seconds
      setTimeout(() => {
        this.loaderService.hideProgressBar(this.showSpinner);
      }, 3000);
      setTimeout(() => {
        this.responseHandlerService.returnToastMessage('success','DOWLOAD_OF_YOUR_REPORT_WILL_BEGIN_SHORTLY');
      }, 3000);
  }

  deleteRequest(id) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappDeliveryReportControllerService
      .deleteFile({ requestId: id })
      .subscribe((resp) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success','DELIVERY_REQUEST_SUCCESS');
        this.viewRequest();
      },  (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      });
  }

  viewRequest(firstTimeLoad?) {
    this.showSpinner = this.loaderService.showProgressBar();
    let responseData = [];
    this.reportData = null;
    this.reportDateToggle = true;
    this.whatsappDeliveryReportControllerService
      .getAllWhatsappdeliveryReports()
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          responseData = resp;
          if (resp && resp.length) {
            responseData = resp;
            for (const data of responseData) {
              data['createdDt'] = this.dateValidatorService.convertUTCToLocalDate(data['createdDt']);
              data['updatedDate'] = this.dateValidatorService.convertUTCToLocalDate(data['updatedDt']);
              data['action'] = null;
            }
            this.reportData = new MatTableDataSource<any>(responseData);
            this.reportColumn = this.filterColumnRequest(
              Object.keys(responseData[0])
            );
          } else {
            if(!firstTimeLoad){
              this.responseHandlerService.returnToastMessage('warning','NO_DATA_AVAILABLE');
            }
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
      * @ngdoc method
      * @name WhatsappStatusComponent#gotoConfirmDialog
      *
      * @methodOf
      * whatsapp.controller:WhatsappStatusComponent
      *
      * @description
      * Description: Opens the popup.
      *
      * @param {type} null
      * @return {type} null
  */
 
  public gotoConfirmDialog(id) {
    const message = this.translationService.translateErrorMessages('DELIVERY_REQUEST_DELETE');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteRequest(id);
      } else {
        this.abortAction();
      }
    });
  }

  /**
      * @ngdoc method
      * @name WhatsappStatusComponent#abortAction
      *
      * @methodOf
      * whatsapp.controller:WhatsappStatusComponent
      *
      * @description
      * Description: Close the popup.
      *
      * @param {type} null
      * @return {type} null
  */
  public abortAction() {
    this._matDialog.closeAll();
  }

}
