<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{'CONNECTION_DETAILS' | translate}}</span>
      </span>
    </div>
<div class="form-spacing">
<app-dynamic-form [jsonFile]="'whatsapp-profile-connection'"
[gridLayout]="'grid-container-whatsapp-profile-connection'" [dropDownValues]="dropDownValues"  (valueSelected)="updateDropdownValues($event)"
(formEmitter)="onSubmit($event)"(resetForm)="onReset()" [emitValueAfterReset]="false"></app-dynamic-form>
    </div>
  </section>
  </section>




