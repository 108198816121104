/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { C2DTwinPropertyControllerService, C2DDirectMethodControllerService } from 'src/app/services/DeviceMgmt/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DEVICE_ICON } from 'src/app/shared/constants/strings';
import { BciLoaderService } from '@bci-web-core/core';

/**
  * @ngdoc component
  * @name device.component:configureDevice
  *
  *
  * @description
  * description: To get and update device twin properties.
  *
  *
*/
@Component({
  selector: 'app-configure-device',
  templateUrl: './configure-device.component.html',
  styleUrls: ['./configure-device.component.scss']
})
export class ConfigureDeviceComponent implements OnInit {
  showSpinner :any
  public deviceId: string = null;
  public enrollmentType: string = null;
  public deviceProperties: any;
  public deviceIcon: string;

  constructor(private route: ActivatedRoute, private router: Router, private responseHandlerService: ResponseHandlerService,
    private c2dTwinPropertyControllerService: C2DTwinPropertyControllerService,
    private C2dDirectMethodControllerService: C2DDirectMethodControllerService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.deviceIcon = DEVICE_ICON;
    this.deviceId = this.route.snapshot.paramMap.get('value');
    this.enrollmentType = this.route.snapshot.paramMap.get('enrollment');
    if (this.deviceId === null) {
      if (this.enrollmentType === 'individual') {
        this.router.navigate(['devices/manage-devices']);
      } else {
        this.router.navigate(['devices/manage-groups']);
      }
    } else {
      this.C2dDirectMethodControllerService.getDesiredProperties({
        id: this.deviceId, body:
          { methodName: 'GetDeviceTwinProperties' }
      })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.deviceProperties = resp;
            this.deviceProperties = this.deviceProperties['par'];
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            if (this.enrollmentType === 'individual') {
              this.router.navigate(['devices/manage-devices']);
            } else {
              this.router.navigate(['devices/manage-groups']);
            }
          }
        );
    }
  }

  /**
    * @ngdoc method
    * @name configureDevice#configureDevice
    *
    * @methodOf
    * device.controller:configureDevice
    *
    * @description
    * Description: Updates device twin properties
    *
    * @param {type} data
    * @return {type} null
  */
  configureDevice(data) {
    const desiredPropertyDtoObj = { 'desiredProperties': data };
    const desiredPropertyDto = { body: desiredPropertyDtoObj, id: this.deviceId };
    this.c2dTwinPropertyControllerService
      .updateTwinProperties(desiredPropertyDto)
      .subscribe(
        resp => {
          this.responseHandlerService.returnToastMessage('success', 'CONFIGURE_DEVICE_SUCCESS');
          this.router.navigate(['devices/manage-devices']);
        },
        err => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }
}
