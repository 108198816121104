/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, EventEmitter, Input, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { DELETE_IMAGE, DOCUMENT_IMAGE, CSV_FILE, EDIT_IMAGE, CLOUD_UPLOAD_ICON, DOWNLOAD_ICON_SMALL } from '../constants/strings';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog} from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ConfirmationDialogPopupComponent } from '../confirmation-dialog-popup/confirmation-dialog-popup.component';
import { TranslationService } from 'src/app/providers/translation-service';
import { DatePipe } from '@angular/common';
import { checkboxAsRadioGroupService} from 'src/app/providers/Checkbox-AsRadioGroup-service'
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.html',
  styleUrls: ['dynamic-form.scss']
})
/**
  * @ngdoc component
  * @name SharedModule.component:DynamicForm
  *
  *
  * @description
  * description: To render form dynamically based on the json name provided
  *
*/
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() jsonFile: any;
  @Input() formData: any;
  @Input() dropDownValues: any;
  @Input() columnsInForm: string;
  @Input() saveAndContinue = false;
  @Input() fileTransfer = false;
  @Input() checkboxFlag: boolean;
  @Input() emitValueAfterReset = true;
  @Input() hideSubmitButton = false;
  @Input() gridLayout: string;
  @Input() formArrayGridLayout: string;
  @Input() formArrayLength: any;
  @Output() formEmitter: EventEmitter<any> = new EventEmitter();
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('submitButton', { static: true }) submitButton: ElementRef;
  @Output() buttonClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitFormOnreset: EventEmitter<any> = new EventEmitter();
  @Output() emitFormOnBlur: EventEmitter<any> = new EventEmitter();
  @Output() emitFormOnFocus: EventEmitter<any> = new EventEmitter();
  @Output() emitOnFormArrayReset: EventEmitter<any> = new EventEmitter();
  @Output() resetForm: EventEmitter<any> = new EventEmitter();
  @Output() selectedTags: EventEmitter<any> = new EventEmitter();
  @Output() checkboxChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() emitOnInputBlur: EventEmitter<any> = new EventEmitter();
  createForm: UntypedFormGroup;
  originalData: UntypedFormGroup;
  originalFormBase: any[];
  submitted = false;
  array: any[];
  formBase: any[];
  arrayFieldsComplete: any[];
  files: any = [];
  deleteImage = DELETE_IMAGE;
  downloadIconSmall = DOWNLOAD_ICON_SMALL;
  editImage = EDIT_IMAGE;
  documentImage = DOCUMENT_IMAGE;
  cloudUploadImage = CLOUD_UPLOAD_ICON;
  sampleFile: string;
  sampleFileDownloadApi: string;
  isAfterDeleteFile = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  stringArray: any = [];
  selectedValuesArray: any = [];
  filteredStrings: Observable<string[]>;
  public disableField = false;
  public validityMessage: string;
  public dataSource = [];
  public dataSourceWithIds = [];
  public columns = [];
  todayDate = new Date();
  fromDateGreaterthanTodate = false;
  public hiddenColumns: any = [];
  public dataSourceDeletedRows = [];
  public hideTableActionButtons = false;
  public selectedDateTime;
  public startDateAndTime;
  public checkTime;
  public selectedDate;
  public imageFileForUpload;
  public uploadedFileName;
  public imgSrc: string | ArrayBuffer;
  public formArrayLengthBeforeEdit;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('inputCtrl') inputCtrl: ElementRef<HTMLInputElement>;
   constructor(
    private translate: TranslateService,
    private httpService: HttpClient, private formBuilder: UntypedFormBuilder,
    private responseHandlerService: ResponseHandlerService, private _matDialog: MatDialog, private translationService: TranslationService,
    private datePipe: DatePipe,private checkboxAsRadioGroupService:checkboxAsRadioGroupService
  ) { }

  ngOnInit() {
    this.httpService
      .get('./assets/form-fields/' + this.jsonFile + '.json')
      .subscribe(data => {
        this.array = data as string[];
        this.formBase = this.toFormBase();
        this.createForm = this.toFormGroup(this.formBase, this.formData);
        this.generateDynamicFormDropdown(this.arrayFieldsComplete);
        this.arrayFieldsComplete.forEach(formElement => {
          if (formElement.childField && formElement.childField['mode'] === 'disable') {
            this.selectValue({ value: this.createForm.value[formElement.name] }, formElement);
          } else if (formElement.isParent && this.formData) {
            if (formElement.type === 'checkbox' && this.formData[formElement.name] !== undefined) {
              formElement.value = this.formData[formElement.name];
              this.selectNewField({ checked: this.formData[formElement.name] }, formElement);
            } else if (formElement.type === 'select' && this.formData[formElement.name] !== undefined) {
              this.selectNewField({ value: this.formData[formElement.name] }, formElement);
            }
          }
          if (formElement.editable === false && this.formData && this.formData[formElement.name]) {
            formElement.readOnly = true;
          }
          if (formElement.type === 'formArray' && this.formData) {
            formElement['fields'].forEach(element => {
              if (element.editable === false) {
                element.readOnly = true;
              }
            });
          }
          if (formElement.type === 'arrayOfFields') {
            this.checkForDisabledFields(formElement['fields'], this.formData[formElement['name']]);
                }
          if (this.checkboxFlag === true) {
            this.updateCheckbox(formElement);
          }
        });

        this.originalFormBase = Object.assign({}, this.formBase);
        if (this.formData) {
          this.originalData = Object.assign({}, this.formData);
        } else {
          this.originalData = Object.assign({}, this.createForm.value);
        }
      });
    this.valueSelected.emit(this.submitButton);
  }

  checkForDisabledFields(fields, formData) {
    fields.forEach(field => {
      if (field['isDisable'] && ((formData && formData[field['disable'].parent]) || !formData)) {
        field['disabled'] = true;
      }
      if (field.type === 'checkbox' && formData) {
        field['value'] = formData[field['name']];
      }
      if (field.type === 'number' && formData) {
         field = formData;
        }
    });
  }

  changeDisableState(event, fields, innerFormGroup?) {
    fields.forEach((field) => {
      field['disabled'] = event.checked && field['isDisable'];
      if (field['type'] === 'number' && field['disabled']) {
        field['value'] = field['defaultValue'];
        innerFormGroup
          ? this.createForm.patchValue({
              [innerFormGroup]: { [field['name']]: field['defaultValue'] },
            })
             // tslint:disable-next-line
          : null;
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('formData')) {
      this.originalData = changes.formData.currentValue;
    }

    if (changes.formArrayLength) {
      this.formArrayLengthBeforeEdit = this.formArrayLength;
    }
  }

  public toFormBase() {
    const arrayFields = [];
    this.arrayFieldsComplete = [];
    this.array['fields'].forEach(formField => {
      if (!formField.parent) {
        arrayFields.push(formField);
      }
      this.arrayFieldsComplete.push(formField);
    });
    return arrayFields;
  }

  /**
    * @ngdoc method
    * @name DynamicForm#generateDynamicFormDropdown
    *
    * @methodOf
    * SharedModule.controller:DynamicForm
    *
    * @description
    * Description: to generate dropdown values dynamically
    *
    * @param {type} name description
    * @return {type} name description
  */
  public generateDynamicFormDropdown(formArray) {
    // this.dropDownValues = {};
    for (const formField of formArray) {
      if ((formField['type'] === 'select' || formField['type'] === 'checkbox') && (this.dropDownValues[formField['name']] === null ||
        this.dropDownValues[formField['name']] === undefined)) {
        this.dropDownValues[formField['name']] = formField['values'];
      }
      if (formField['type'] === 'formArray') {
        this.generateDynamicFormDropdown(formField['fields']);
      }
    }
    return this.dropDownValues;
  }

  /**
   * @ngdoc method
   * @name DynamicForm#toFormGroup
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: Dynamically creates form control instances based on the JSON file received from server.
   *
   * @param {type} formBase
   * @return {type} null
 */
  public toFormGroup(formBase: any, formData: any) {
    const group: any = {};
    formBase.forEach(formField => {
      if (!formField.parent && formField.type !== 'arrayOfFields' && formField.type !== 'formArray') {
        group[formField['name']] = this.getFormControl(formField, formData);
      } else if (formField.type === 'formArray') {
        group[formField['name']] = this.getFormArray(formField, formData);
      } else {
        if (formData) {
          group[formField['name']] = this.toFormGroup(formField.fields, formData[formField['name']]);
        } else {
          group[formField['name']] = this.toFormGroup(formField.fields, formData);
        }
      }
    });
    return new UntypedFormGroup(group);
  }

  public getFormControl(formField, formData) {
    const group =
      formField['required'] &&
        formField['file']
        ? new UntypedFormArray([], [Validators.required]
        ) :
        formField['required'] &&
          formField['pattern'] &&
          formField['minlength'] &&
          formField['maxlength']
          ? new UntypedFormControl(
            (formData && formData[formField['name']]) ||
            formField['value'] ||
            null,
            [
              Validators.required,
              Validators.pattern(formField['pattern']),
              Validators.minLength(formField['minlength']),
              Validators.maxLength(formField['maxlength']),
              this.noWhitespaceValidator
            ]
          )
          : formField['required'] &&
            formField['pattern'] &&
            formField['minlength']
            ? new UntypedFormControl(
              (formData && formData[formField['name']]) ||
              formField['value'] ||
              null,
              [
                Validators.required,
                this.noWhitespaceValidator,
                Validators.pattern(formField['pattern']),
                Validators.minLength(formField['minlength'])
              ]
            )
            : formField['required'] &&
              formField['pattern'] &&
              formField['maxlength']
              ? new UntypedFormControl(
                (formData && formData[formField['name']]) ||
                formField['value'] ||
                null,
                [
                  Validators.required,
                  this.noWhitespaceValidator,
                  Validators.pattern(formField['pattern']),
                  Validators.maxLength(formField['maxlength'])
                ]
              )
              : formField['required'] &&
                formField['minlength'] &&
                formField['maxlength']
                ? new UntypedFormControl(
                  (formData && formData[formField['name']]) ||
                  formField['value'] ||
                  null,
                  [
                    Validators.required,
                    this.noWhitespaceValidator,
                    Validators.minLength(formField['minlength']),
                    Validators.maxLength(formField['maxlength'])
                  ]
                )
                : formField['pattern'] &&
                  formField['minlength'] &&
                  formField['maxlength']
                  ? new UntypedFormControl(
                    (formData && formData[formField['name']]) ||
                    formField['value'] ||
                    null,
                    [
                      Validators.pattern(formField['pattern']),
                      Validators.minLength(formField['minlength']),
                      Validators.maxLength(formField['maxlength'])
                    ]
                  )
                  : formField['min'] && formField['max'] && formField['required']
                    ? new UntypedFormControl(
                      formData && (formData[formField['name']] !== undefined) ?
                        formData[formField['name']] :
                        (formField['value'] ||
                          null),
                      [Validators.required, Validators.min(formField['min']), Validators.max(formField['max'])]
                    )
                    : formField['min'] && formField['max']
                      ? new UntypedFormControl(
                        formData && (formData[formField['name']] !== undefined) ?
                          formData[formField['name']] :
                          (formField['value'] ||
                            null),
                        [Validators.min(formField['min']), Validators.max(formField['max'])]
                      )
                      : formField['pattern'] && formField['maxlength']
                        ? new UntypedFormControl(
                          (formData && formData[formField['name']]) ||
                          formField['value'] ||
                          null,
                          [
                            Validators.pattern(formField['pattern']),
                            Validators.maxLength(formField['maxlength'])
                          ]
                        )
                        : formField['pattern'] && formField['minlength']
                          ? new UntypedFormControl(
                            (formData && formData[formField['name']]) ||
                            formField['value'] ||
                            null,
                            [
                              Validators.pattern(formField['pattern']),
                              Validators.minLength(formField['minlength'])
                            ]
                          )
                          : formField['required'] && formField['minlength']
                            ? new UntypedFormControl(
                              (formData && formData[formField['name']]) ||
                              formField['value'] ||
                              null,
                              [
                                Validators.required,
                                this.noWhitespaceValidator,
                                Validators.minLength(formField['minlength'])
                              ]
                            )
                            : formField['required'] && formField['maxlength']
                              ? new UntypedFormControl(
                                (formData && formData[formField['name']]) ||
                                formField['value'] ||
                                null,
                                [
                                  Validators.required,
                                  this.noWhitespaceValidator,
                                  Validators.maxLength(formField['maxlength'])
                                ]
                              )
                              : formField['maxlength'] && formField['minlength']
                                ? new UntypedFormControl(
                                  (formData && formData[formField['name']]) ||
                                  formField['value'] ||
                                  null,
                                  [
                                    Validators.maxLength(formField['maxlength']),
                                    Validators.minLength(formField['minlength'])
                                  ]
                                )
                                : formField['pattern'] && formField['required']
                                  ? new UntypedFormControl(
                                    (formData && formData[formField['name']]) ||
                                    formField['value'] ||
                                    null,
                                    [Validators.pattern(formField['pattern']), Validators.required, this.noWhitespaceValidator]
                                  )
                                  : formField['required']
                                    ? new UntypedFormControl(
                                      (formData && formData[formField['name']]) ||
                                      formField['value'] ||
                                      null,
                                      [Validators.required, this.noWhitespaceValidator]
                                    )
                                    : formField['pattern']
                                      ? new UntypedFormControl(
                                        (formData && formData[formField['name']]) ||
                                        formField['value'] ||
                                        null,
                                        Validators.pattern(formField['pattern'])
                                      )
                                      : formField['maxlength']
                                        ? new UntypedFormControl(
                                          (formData && formData[formField['name']]) ||
                                          formField['value'] ||
                                          null,
                                          Validators.maxLength(formField['maxlength'])
                                        )
                                        : formField['minlength']
                                          ? new UntypedFormControl(
                                            (formData && formData[formField['name']]) ||
                                            formField['value'] ||
                                            null,
                                            Validators.minLength(formField['minlength'])
                                          )
                                          : new UntypedFormControl(
                                            (formData && formData[formField['name']]) ||
                                            formField['value'] ||
                                            null
                                          );
    return group;
  }

  public getFormArray(formField, formData) {
    const arrayofFields: any = [];
    const fieldObject = {};
    let formArray: any;
    if (formData) {
      for (const data of formData[formField['name']]) {
        formField.fields.forEach(field => {
          fieldObject[field['name']] = this.getFormControl(field, data);
        });
        arrayofFields.push(this.formBuilder.group(fieldObject));
      }
      formArray = new UntypedFormArray(arrayofFields);
      return formArray;
    }
    formField.fields.forEach(field => {
      arrayofFields[field['name']] = this.getFormControl(field, formData);
    });
    formArray = new UntypedFormArray([
      this.formBuilder.group(arrayofFields)]);
    return formArray;
  }

  public addFormGroup(controlName) {
    let formArray;
    formArray =
      this.toFormGroup(controlName.fields, null);
    (<UntypedFormArray>this.createForm.get(controlName.name)).push(formArray);
  }

  public addToTable(control, groupName) {
    let isDuplicateRecord = false;
    let canProceed = true;
    const rowObj = {};
    let emptyColumnCount = 0;
    const uniqueColumns = {};
    const row = this.createForm.value[control.name][0];
    control.fields.forEach(fieldControl => {
      if (fieldControl.hasOwnProperty('checkFieldEmpty')) {
        fieldControl.required = true;
      }
      if (fieldControl.required && !row[fieldControl.name]) {
        this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_MANDATORY_FIELDS');
        canProceed = false;
      } else if (!fieldControl.required) { // Restrict adding empty rows when user is not selected all the non mandatory fields.
        if (!row[fieldControl.name]) { emptyColumnCount++; }
        if (emptyColumnCount === control.fields.length) {
          this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_FIELDS');
          canProceed = false;
        }
      }
      if (!fieldControl.disabled) {
        rowObj[fieldControl.name] = row[fieldControl.name];
      }
      if (fieldControl.hasOwnProperty('hidden')) {
        if (fieldControl.hidden) { this.hiddenColumns.push(fieldControl.name); }
      }
      if (fieldControl.hasOwnProperty('unique')) {
        uniqueColumns[fieldControl.name] = fieldControl.unique;
        uniqueColumns['type'] = fieldControl.type;
      } else {
        uniqueColumns[fieldControl.name] = false;
      }
      if(fieldControl.hasOwnProperty('pattern')){
        if(row[fieldControl.name]!==null) {
          if(!(new RegExp(fieldControl.pattern).test(row[fieldControl.name]))){
          const patternControl:any =this.createForm.get(control.name);
          const controlName= patternControl.controls[0].controls[fieldControl.name];
          controlName.setErrors({...controlName.errors,pattern:true});
          canProceed = false;
        } 
      }
      }
    });
    this.dataSourceWithIds.forEach(data => {
      if (!isDuplicateRecord) {
        const columns = this.filterHiddenColumns(Object.keys(data));
        columns.forEach(key => {
          if (data[key] && rowObj[key]) {
          if (uniqueColumns[key]){
            if(uniqueColumns['type']=='text' && data[key].toLowerCase() === rowObj[key].toLowerCase()) {
              isDuplicateRecord = true;
            } else if(uniqueColumns['type']=='number' && data[key] === rowObj[key]) {
              isDuplicateRecord = true;
            } else {
              if( data[key] === rowObj[key]) {
                isDuplicateRecord = true;
                 }
            }
          } 
          }
        });
      }
    });

    if (this.dataSourceWithIds.length > 0 && isDuplicateRecord) {
      this.responseHandlerService.returnToastMessage('error', 'RECORD_ALREADY_EXIST');
      return;
    }
    if (canProceed) {
      rowObj['action'] = 'UPDATED';
      this.dataSourceWithIds.push(rowObj);
      this.buttonClickEvent.emit('Button clicked');
    }
    if (!canProceed) { return; }
    const controls = control.fields as any[];
    controls.forEach((monthcontrol) => {
      if (monthcontrol.type === 'monthPicker') {
        const controlName = monthcontrol.name;
        this.dataSource.forEach(data => {
          if (data[controlName] && row[controlName] && data[controlName] === row[controlName]) {
            this.responseHandlerService.returnToastMessage('error', 'DUPLICATE_DATE_RANGE_MESSAGE');
            canProceed = false;
            this.dataSourceWithIds.splice(-1);
          }
        });
      }
      if (canProceed && monthcontrol.type === 'select') {
        const controlName = monthcontrol.name;
        const dropDownValues = this.dropDownValues[controlName] as any[];
        dropDownValues.forEach((value) => {
          if (value.id === row[controlName]) {
            row[controlName] = value.name;
          }
        });
      }
    });
    if (!canProceed) { return; }
    this.dataSource.push(row);
    this.columns = this.filterHiddenColumns(Object.keys(this.dataSource[0]));
    this.columns.push('action');
    control.fields.forEach(field => {
      if (!field.disabled) {
        groupName.get(field.name).reset();
      }
      groupName.get(field.name).clearValidators();
      groupName.get(field.name).updateValueAndValidity();
    });
    this.emitOnFormArrayReset.emit({createForm: this.createForm, formBase: this.formBase, message:"FormArray Reset"});
    this.table?.renderRows();
    this.dataSource.forEach(data => {
      data['hideEditIcon'] = false;
    });
  }

  public filterHiddenColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.hiddenColumns.indexOf(item) <= -1));
    }
  }

  public removeFormGroup(index: number, controlName) {
    if(this.formArrayLength) {
      if(index < this.formArrayLength) {
        this.formArrayLength = this.formArrayLength - 1; 
      }
    }
    this.createForm.value[controlName.name].forEach((field, i) => {
      if (i === index) {
        field['action'] = 'DELETE';
        this.dataSourceDeletedRows.push(field);
      }
    });
    (<UntypedFormArray>this.createForm.get(controlName.name)).removeAt(index);

  }

  get control() {
    return this.createForm.controls;
  }

  /**
   * @ngdoc method
   * @name DynamicForm#noWhitespaceValidator
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: to validate fields for null values
   *
   * @param {type} control
   * @return {type}
  */
  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  }

  /**
   * @ngdoc method
   * @name DynamicForm#alertLang
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: to change the language
   *
   * @param {type} event
   * @return {type}
 */
  public alertLang(event: any) {
    this.translate.use(event.target.value);
  }

  /**
   * @ngdoc method
   * @name DynamicForm#selectValue
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: to handle functionalities on selection of dropdowns
   *
   * @param {type} event, formfield name
   * @return {type}
 */
  public selectValue(event: any, field: any = null) {
    let dropdownValue;
    if (field['childField']['mode'] === 'disable' && field['childField']['value'] === event.value) {
      field['childField']['child'].forEach(formField => {
        this.createForm.get(formField).disable();
      });
    } else if (event.source && field['childField']['mode'] === 'disable' &&
    field['childField']['value'] === event.source.selected.viewValue) {
      field['childField']['child'].forEach(formField => {
        this.createForm.get(formField).disable();
      });
    } else if (field['childField']['mode'] === 'disable' && field['childField']['value'] !== event.value) {
      field['childField']['child'].forEach(formField => {
        this.createForm.get(formField).enable();
      });
    } else {
      if (this.createForm.controls[field['childField']['child']] !== undefined) {
        this.createForm.controls[field['childField']['child']].setValue(null);
      }
      if (field.returnData) {
        if (field.type === 'optGroup') {
          this.dropDownValues[field['name']].forEach(valuesArray => {
            if (valuesArray.values) {
              dropdownValue = valuesArray.values.find(dropDownOption => dropDownOption.id === event.value);
              if (dropdownValue) {
                this.valueSelected.emit({ value: dropdownValue, child: field['childField']['child'] });
              }
            }
          });
        } else {
          dropdownValue = this.dropDownValues[field['name']].find(dropDownOption => dropDownOption.id === event.value);
          this.valueSelected.emit({ value: dropdownValue, child: field['childField']['child'] });
        }
      } else {
        let childFields = [];
        const fields = field['childField']['child'];
        Array.isArray(fields) ? childFields = fields : childFields.push(fields);
        childFields.forEach(childField => {
          this.arrayFieldsComplete.forEach(parentControl => {
            if (parentControl.type === 'formArray') {
              this.createForm.controls[parentControl.name].patchValue([{ [childField]: null }]);
            } else {
              this.createForm.controls[childField].setValue(null);
            }
          });
        });
        this.valueSelected.emit({ value: event.value, child: field['childField']['child'] });
      }
    }
  }

  /**
   * @ngdoc method
   * @name DynamicForm#onSubmit
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: to send form value on click of submit
   *
   * @param {type}
   * @return {type}
 */
  public onSubmit() {
    this.submitted = true;
    if (this.createForm.invalid) {
      return;
    }
    if (this.dataSource && this.dataSource.length) {
      this.createForm.value.table = this.dataSourceWithIds;
    }
    this.formEmitter.emit(this.createForm.value);
    this.originalData = this.createForm.value;
    this.submitted = false;
  }

  /**
   * @ngdoc method
   * @name DynamicForm#selectNewField
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: to generate new form elements based on dropdown selection
   *
   * @param {type} event, formfield
   * @return {type}
 */
  public selectNewField(event, field) {
    let dropdownValue;
    let isparent;
    const arr = [];
    if (field['type'] === 'select') {
      dropdownValue = this.dropDownValues[field['name']].find(dropDownOption => dropDownOption.id === event.value);
      if (field['viewValueRequired']) {
        this.valueSelected.emit({ dropdownValue, viewValue: event.source.selected.viewValue });
      } else {
        this.valueSelected.emit({ dropdownValue, submitButton: this.submitButton });
      }
      if (dropdownValue && dropdownValue.level === undefined) {
        dropdownValue.level = field.level;
      }
    } else if (field['type'] === 'checkbox') {
      dropdownValue = this.dropDownValues[field['name']].find(dropDownOption => dropDownOption.id === event.checked);
      if (field['emitEvent']) {
        this.checkboxChangeEvent.emit(this.createForm);
      }
      this.arrayFieldsComplete.forEach(parentControl => {
        if (parentControl.name === field['name'] && parentControl.childField && parentControl.childField['required'] === true) {
          const childControlName = parentControl.childField['child'];
          this.arrayFieldsComplete.forEach(childControl => {
            if (childControl.name === childControlName) {
              childControl.required = event.checked;
            }
          });
        }
      });
    } else if (field['type'] === 'drag-and-drop-multiple-file') {
      if (this.files.length > 1) {
        dropdownValue = { level: field.level, name: field.name };
      } else {
        dropdownValue = { level: field.level, name: '' };
      }
    }
    this.array['fields'].forEach(formField => {
      if (formField.parent && dropdownValue) {
        if (formField.parent.value === dropdownValue.name && formField.parent.field === field.name && !this.formBase.includes(formField)) {
          this.formBase.push(formField);
          if (formField.type !== 'formArray') {
            const newField = this.getFormControl(formField, this.formData);
            this.createForm.addControl(formField.name, newField);
          } else {
            const newField = this.getFormArray(formField, this.formData);
            this.createForm.addControl(formField.name, newField);
          }
          this.generateDynamicFormDropdown(this.formBase);
        } else if (formField.parent.value !== dropdownValue.name || formField.parent.field !== field.name) {
          const index = this.formBase.indexOf(formField);
          if (index > -1 && formField.level > dropdownValue.level) {
            this.formBase.splice(index, 1);
            this.createForm.removeControl(formField.name);
            if (this.formData) {
              this.formData = {};
            }
          }
        }
      } if (formField.parent && !dropdownValue) {
        if (formField.parent.field === field.name && this.formBase.includes(formField)) {
          const index = this.formBase.indexOf(formField);
          if (index > -1) {
            this.formBase.splice(index, 1);
            this.createForm.removeControl(formField.name);
          }
        }
      }
      if (formField.parent && formField.parent.values && dropdownValue) {
        for (const val of formField.parent.values) {
          if (!this.formBase.includes(formField) && formField.parent.field === field.name && val && val.value === dropdownValue.name) {
            this.formBase.push(formField);
            if (formField.type !== 'formArray') {
              const newField = this.getFormControl(formField, this.formData);
              this.createForm.addControl(formField.name, newField);
            }
          }
        }
      }
      if (formField.parent && formField.parent.length > 1 && dropdownValue) {
        formField.parent.forEach(parentField => {
          if (parentField.value === this.createForm.controls[parentField.field].value || parentField.field === field.name
            && parentField.value === dropdownValue.name) {
            isparent = true;
            arr.push(isparent);
          }
        });
        if (arr.length > 1 && !this.formBase.includes(formField) && !arr.includes(false)) {
          this.formBase.push(formField);
          if (formField.type !== 'formArray') {
            const newField = this.getFormControl(formField, this.formData);
            this.createForm.addControl(formField.name, newField);
          }
        }
      }
    });
  }

  /**
   * @ngdoc method
   * @name DynamicForm#onReset
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: Resets the form fields.
   *
   * @param {type} formBase
   * @return {type} null
 */
  public onReset() {
  this.formArrayLength = this.formArrayLengthBeforeEdit;
  if (this.emitValueAfterReset) {
    Object.keys(this.originalFormBase).forEach((formField) => {
      if (this.originalFormBase[formField].childField && this.originalData[this.originalFormBase[formField].name]) {
        this.selectValue({ value: this.originalData[this.originalFormBase[formField].name] }, this.originalFormBase[formField]);
      }
      if (this.originalData[this.originalFormBase[formField].name] !== this.createForm.value[this.originalFormBase[formField].name]) {
        if (this.originalFormBase[formField].type === 'arrayOfFields') {
          this.checkForDisabledFields(this.originalFormBase[formField]['fields'], this.originalData[this.originalFormBase[formField].name]);
        }
        if (this.originalFormBase[formField].isParent) {
          if (this.originalFormBase[formField].type === 'checkbox') {
            this.selectNewField({ checked: this.originalData[this.originalFormBase[formField].name] }, this.originalFormBase[formField]);
          } else if (this.originalFormBase[formField].type === 'select' ||
            this.originalFormBase[formField].type === 'drag-and-drop-multiple-file') {
            this.selectNewField({ value: this.originalData[this.originalFormBase[formField].name] }, this.originalFormBase[formField]);
          }
        }
      }
    });
  }
    this.imgSrc = null;
    this.imageFileForUpload = null;
    this.uploadedFileName = null;
    if (this.files.length > 0) {
      this.files = [];
    }
    this.submitButton['disabled'] = false;
    this.createForm.reset(this.originalData);
    this.submitted = false;
    this.resetForm.emit('resetButtonClicked');
    this.emitFormOnreset.emit({createForm: this.createForm, formBase: this.formBase});
    if (this.selectedValuesArray && this.selectedValuesArray.length) {
      this.selectedValuesArray = [];
    }
  }

  /**
   * @ngdoc method
   * @name DynamicForm#selectFiles
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: assign the selected files to file formfield
   *
   * @param {type} event
   * @return {type} null
 */
  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.createForm.value.file = event.target.files[0];
      if (this.createForm.value.file.type === CSV_FILE && this.files.length > 0) {
        this.files.pop();
        this.files.push(this.createForm.value.file);
      } else {
        this.files.push(this.createForm.value.file);
      }
    }
  }

  /**
   * @ngdoc method
   * @name DynamicForm#convertTobase64
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: convert the selected file content to base64
   *
   * @param {type} event
   * @return {type} null
  */
  public convertTobase64(event, isMultiple) {
    if (event) {
      if (!isMultiple) {
        this.files = [];
      }
      for (const file of event.target.files) {
        this.files.push(file);
      }

      const fileReader: FileReader = new FileReader();
      let text;
      if (event.target.files && event.target.files.length > 0) {
        fileReader.onloadend = (e) => {
          text = fileReader.result;
          this.createForm.get('base64Certificate').setValue(btoa(text));
        };
        fileReader.readAsText(new Blob([event.target.files[0]]));
      }

    }
    this.isAfterDeleteFile = false;
  }

  /**
   * @ngdoc method
   * @name DynamicForm#removeFile
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: To remove file
   *
   * @param {type} index
   * @return {type} null
  */
  removeFile(index: number) {
    this.files.splice(index, 1);
    this.createForm.patchValue({
      'base64Certificate': null
    });
    this.createForm.value.base64Certificate = null;
    this.isAfterDeleteFile = true;
  }

  /**
  * @ngdoc method
  * @name DynamicForm#onFileDropped
  *
  * @methodOf
  * SharedModule.controller:DynamicForm
  *
  * @description
  * Description: on file drop handler
  * @return {type} null
  */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
  * @ngdoc method
  * @name DynamicForm#fileBrowseHandler
  *
  * @methodOf
  * SharedModule.controller:DynamicForm
  *
  * @description
  * Description: handle file from browsing
  * @return {type} null
  */
  fileBrowseHandler(event) {
    this.prepareFilesList(event.target.files);
    event.target.value = '';
  }

  /**
  * @ngdoc method
  * @name DynamicForm#deleteFile
  *
  * @methodOf
  * SharedModule.controller:DynamicForm
  *
  * @description
  * Description: Delete file from files list
  * @param index (File index)
  * @return {type} null
  */
  deleteFile(index: number, event, field) {
    this.files.splice(index, 1);
    (<UntypedFormArray>this.createForm.get('file')).removeAt(index);
    if (this.files.length < 2) {
      this.selectNewField(event, field);
    }
  }

  /**
  * @ngdoc method
  * @name DynamicForm#prepareFilesList
  *
  * @methodOf
  * SharedModule.controller:DynamicForm
  *
  * @description
  * Description: Convert Files list to normal array list
  * @param files (Files List)
  * @return {type} null
  */
  prepareFilesList(files: Array<any>) {
    if (files) {
      for (const file of files) {
        if (this.files.length > 0) {
          if (this.files.find((newFile) => newFile.name === file.name) === undefined) {
            this.files.push(file);
            const reader = new FileReader();
            reader.onload = () => {
              const fileFormControl = this.createFormControl(file);
              (<UntypedFormArray>this.createForm.get('file')).push(fileFormControl);
            };
            reader.readAsDataURL(file);
          } else {
            this.responseHandlerService.returnToastMessage('warning', 'Duplicate file');
          }
        } else {
          this.files.push(file);
          const reader = new FileReader();
          reader.onload = () => {
            const fileFormControl = this.createFormControl(file);
            (<UntypedFormArray>this.createForm.get('file')).push(fileFormControl);
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }

  /**
  * @ngdoc method
  * @name DynamicForm#createFormControl
  *
  * @methodOf
  * SharedModule.controller:DynamicForm
  *
  * @description
  * Description: create form control for files
  * @param data
  * @return {type} FormControl
  */
  createFormControl(data): UntypedFormControl {
    return new UntypedFormControl(data);
  }

  /**
  * @ngdoc method
  * @name DynamicForm#formatBytes
  *
  * @methodOf
  * SharedModule.controller:DynamicForm
  *
  * @description
  * Description: To calculate size of file.
  *
  * @param bytes (File size in bytes)
  * @param decimals (Decimals point)
  * @return {type} number
  */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const kiloBytes = 1024;
    const decimalValue = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const index = Math.floor(Math.log(bytes) / Math.log(kiloBytes));
    return parseFloat((bytes / Math.pow(kiloBytes, index)).toFixed(decimalValue)) + ' ' + sizes[index];
  }

  public textButtonClick(fieldName) {
    this.buttonClickEvent.emit(this.createForm.value[fieldName]);
  }

  /**
     * @ngdoc method
     * @name DynamicForm#addValues
     *
     * @methodOf
     * SharedModule.controller:DynamicForm
     *
     * @description
     * Description: Triggers event to add selected tag to array
     *
     * @param {type} event: MatAutocompleteSelectedEvent
     * @return {type} null
    */
  addValues(event: MatChipInputEvent): void {
    this.selectedTags.emit(event.value);
  }

  /**
     * @ngdoc method
     * @name DynamicForm#remove
     *
     * @methodOf
     * SharedModule.controller:DynamicForm
     *
     * @description
     * Description: Triggers the event for selected value to remove from list of tags
     *
     * @param {type} event: MatAutocompleteSelectedEvent
     * @return {type} null
    */
  removeEntities(event) {
    this.selectedTags.emit({ tag: event, action: 'removeSelectedAttribute' });
  }

  /**
     * @ngdoc method
     * @name DynamicForm#selectEntitiesFromList
     *
     * @methodOf
     * SharedModule.controller:DynamicForm
     *
     * @description
     * Description: Triggers the event with selected value from list of tags
     *
     * @param {type} event: MatAutocompleteSelectedEvent
     * @return {type} null
    */
  selectEntitiesFromList(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags.emit({ selectedTag: event.option.value });
  }

  /**
     * @ngdoc method
     * @name DynamicForm#filterList
     *
     * @methodOf
     * SharedModule.controller:DynamicForm
     *
     * @description
     * Description:Filter the tags list based on key pressed event
     *
     * @param {type} value: string
     * @return {type} null
    */
  filterExistingList($event) {
    this.formBase.forEach(formControl => {
      if (formControl.type === 'mat-chip-list') {
        if (this.createForm.controls[formControl.name]) {
          this.filteredStrings = this.createForm.controls[formControl.name].valueChanges.pipe(
            startWith(null),
            map((value: string | null) => value ? this.filterStringsList(value) : this.stringArray.slice()));
        }
      }
    });
  }

  /**
     * @ngdoc method
     * @name DynamicForm#filterStringsList
     *
     * @methodOf
     * SharedModule.controller:DynamicForm
     *
     * @description
     * Description: Filter the list of tags
     *
     * @param {type} value: string
     * @return {type} null
    */
  private filterStringsList(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.stringArray.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  public gotoEditConfirmDialog(control, data, index) {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages(control.msg) }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editTableRow(control, data, index);
      } else {
        this._matDialog.closeAll();
      }
    });
  }

  public editTableRow(formControlName, data, index) {
    const controls = formControlName.fields as any[];
    controls.forEach((control) => {
      if (control.type === 'select') {
        const controlName = control.name;
        const dropDownValues = this.dropDownValues[controlName] as any[];
        dropDownValues.forEach((value) => {
          if (value.name === data[controlName]) {
            data[controlName] = value.id;
            if (control.hasOwnProperty('childField')) {
              this.valueSelected.emit({ value: value.id, child: control.childField.child });
            }
          }
        });
      }
    });
    (<UntypedFormArray>this.createForm.controls[formControlName.name]).patchValue([data]);
    this.dataSource.forEach(fileData => {
      fileData['hideEditIcon'] = true;
    });
    this.deleteRow(index);
  }

  public gotoDeleteConfirmDialog(index, control) {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages(control.msg) }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTableRow(index, control);
      } else {
        this._matDialog.closeAll();
      }
    });
  }

  private deleteTableRow(index, control) {
    this.dataSourceWithIds.forEach((data, i) => {
      if (i === index) {
        data['action'] = 'DELETED';
        this.dataSourceDeletedRows.push(data);
      }
    });
    this.deleteRow(index);
    // Make form controls non mandatory when table row is deleted
    const formGroup = this.createForm.controls[control.name]['controls'][0];
    control.fields.forEach(field => {
      formGroup.get(field.name).clearValidators();
      formGroup.get(field.name).updateValueAndValidity();
    });
  }

  private deleteRow(index) {
    this.dataSource = this.dataSource.filter((_, i) => {
      return i !== index;
    });
    this.dataSourceWithIds = this.dataSourceWithIds.filter((_, i) => {
      return i !== index;
    });
    if (this.dataSource.length === 0) {
      this.columns = null;
    }
  }

  public chosenMonthHandler(selectedMonth, datepicker, controlName, fieldName) {
    const formattedDate = this.datePipe.transform(selectedMonth, 'yyyy-MM');
    (<UntypedFormArray>this.createForm.controls[controlName.name]).patchValue([{
      [fieldName]: formattedDate,
    }]);
    datepicker.close();
    this.dateSelected(controlName, 'monthPicker');
  }

  public dateSelected(controlName, fieldType) {
    let fromDateString, toDateString;
    this.arrayFieldsComplete.forEach(formField => {
      if (formField.type === fieldType) {
        fromDateString = formField.label === 'FROM_DATE' ? this.createForm.controls[formField.name] : fromDateString;
        toDateString = formField.label === 'TO_DATE' ? this.createForm.controls[formField.name] : toDateString;
      }
      if (formField.type === 'formArray') {
        formField.fields.forEach(field => {
          if (field.type === fieldType) {
            fromDateString = field.label === 'FROM_DATE' ? this.createForm.controls[controlName.name].value[0][field.name] : fromDateString;
            toDateString = field.label === 'TO_DATE' ? this.createForm.controls[controlName.name].value[0][field.name] : toDateString;
          }
        });
      }
    });
    if (fromDateString && toDateString) {
      const fromDate = new Date(`${fromDateString.split('-')[1]}/01/${fromDateString.split('-')[0]}`);
      const toDate = new Date(`${toDateString.split('-')[1]}/01/${toDateString.split('-')[0]}`);
      this.fromDateGreaterthanTodate = fromDate.getTime() > toDate.getTime();
      if (this.fromDateGreaterthanTodate) {
        this.responseHandlerService.returnToastMessage('error', 'FROMDATE_LESSTHAN_TODATE');
        return;
      }
    }
  }

  public updateCheckbox(formElement) {
    formElement['fields'].forEach(field => {
          if (field['type'] === 'checkbox') {
            field['disabled'] = !this.checkboxFlag;
          }
      });
  }
  public checkDate(name, child, validateDate, fromDate,toDate) {
      if (this.createForm.controls[name].value) {
        this.selectedDate = new Date(this.createForm.controls[name].value);
        if(child) {
          this.createForm.controls[child].reset();
        }
        if(validateDate && fromDate && toDate) {
          if (this.createForm.controls[fromDate].value || this.createForm.controls[toDate].value) {
            this.formBase.map((formField) => {
                if (formField.type === 'date' && formField.validate) {
                  formField.required = true;
                }
              });
              this.createForm.controls[fromDate].setValidators(Validators.required);
              this.createForm.controls[fromDate].updateValueAndValidity();
              this.createForm.controls[toDate].setValidators(Validators.required);
              this.createForm.controls[toDate].updateValueAndValidity();
            }
         if (this.createForm.controls[fromDate].value && this.createForm.controls[toDate].value) {
            this.fromDateGreaterthanTodate =
              this.createForm.controls[toDate].value <= this.createForm.controls[fromDate].value;
            if (this.fromDateGreaterthanTodate) {
              this.createForm.controls[fromDate].setErrors({
                invalidDate: true,
              });
            } else {
              this.createForm.controls[fromDate].setErrors(null);
            }
          }
        }
      } else {
        if(validateDate) {
          if (!this.createForm.controls[fromDate].value && !this.createForm.controls[toDate].value) {
            this.formBase.map((formField) => {
                if (formField.type === 'date' && formField.validate) {
                  formField.required = false;
                }
              });
              this.createForm.controls[fromDate].clearValidators();
              this.createForm.controls[fromDate].updateValueAndValidity();
              this.createForm.controls[toDate].clearValidators();
              this.createForm.controls[toDate].updateValueAndValidity();
              this.createForm.controls[fromDate].setErrors(null);
              this.createForm.controls[toDate].setErrors(null);
          }
        }
      }
  }

  public timeValidator(name,maxTime?) {
    if (this.createForm.controls[name].value && this.selectedDate) {
      const currentDate = new Date();
      const time = this.createForm.controls[name].value.match(/(\d+)(:(\d\d))?\s*(p?)/);
   const updatedCurrentDate = new Date(currentDate.getTime() + maxTime * 60 * 1000);
      if (time) {
        if (this.selectedDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)) {
          this.selectedDateTime = new Date();
          this.selectedDateTime.setHours(Number(time[1]) + (Number(time[1]) < 12 && time[4] ? 12 : 0));
            this.selectedDateTime.setMinutes(Number(time[3]) || 0);
            this.selectedDateTime.setSeconds(0, 0);
            const start_time = this.selectedDateTime;
            const diff = (Number(new Date()) - Number(start_time)) / (1000 * 60);
            this.checkTime = diff > 1 ? true : false;
            if (maxTime) {
              const diff =  (Number(updatedCurrentDate) - Number(this.selectedDateTime)) / (1000 * 60);
              this.checkTime = diff > 1 ? true : false;
            }
            if (!this.checkTime) {
              this.createForm.controls[name].setErrors(null);
            } else {
              this.createForm.controls[name].setErrors({
                incorrect: true,
              });
            }
        } else {
          this.createForm.controls[name].setErrors(null);
        }
      }
    }
  }

  public onDownload() {
    this.buttonClickEvent.emit('download');
  }

  public showMaxLengthError(message) {
    this.responseHandlerService.returnToastMessage('error', message);
  }

   /**
   * @ngdoc method
   * @name DynamicForm#clearUploadedFiles
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: cleared selected files for uploaded while dropdown changes
   *
   * @param {type} event
   * @return {type} null
   */
    public clearUploadedFiles() {
     this.files = [];
    }

/**
   * @ngdoc method
   * @name DynamicForm#downloadFile
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: this method will emit event to parent component where samplefile download method will be called
   *
   * @param {type} event
   * @return {type} null
 */
  public downloadFile($event) {
    if ($event) {
      this.valueSelected.emit('downloadSampleFile');
    }
  }

  public downloadImageFile(url) {
    window.location.assign(url);
  }

  public validateImageFile(event, formControlName, maxSizeBytes, maxSizeMB, height, width) {
    if (event.target.files && event.target.files.length > 0) {
      const fileFormat = event.target.files[0].name.split('.').pop().toLowerCase();
      if (fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'jpeg') {
        if (event.target.files[0].size <= maxSizeBytes) {
          this.imageFileForUpload = event.target.files[0];
          this.uploadedFileName = event.target.files[0].name;
          const reader = new FileReader();
          reader.onload = res =>  {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = image => {
            const imgHeight = image.currentTarget['height'];
            const imgWidth = image.currentTarget['width'];
            if (imgHeight !== height || imgWidth !== width ) {
              this.imgSrc = null;
              this.imageFileForUpload = null;
              this.uploadedFileName = null;
              this.createForm.controls[formControlName].setValue(this.imageFileForUpload);
              this.responseHandlerService.returnToastMessage('error', 'IMAGE_DIMENSION_ERROR_350X350');
            } else {
              this.imgSrc = reader.result;
              this.createForm.controls[formControlName].setValue(this.imageFileForUpload);
            }

            };
          };
          reader.readAsDataURL(this.imageFileForUpload);
        } else {
          this.imgSrc = null;
          this.imageFileForUpload = null;
          this.uploadedFileName = null;
          this.createForm.controls[formControlName].setValue(this.imageFileForUpload);
          this.responseHandlerService.returnToastMessage('error', `UPLOAD_IMAGE_LESS_THAN_${maxSizeMB}`);
        }
      } else {
        this.responseHandlerService.returnToastMessage('error', 'UPLOAD_VALID_IMAGE_FILE');
      }
      event.target.value = null;
    }
  }

  public textInputonblur () {
    this.emitFormOnBlur.emit({createForm: this.createForm, formBase: this.formBase});
  }

  public textInputonfocus() {
    this.emitFormOnFocus.emit({createForm: this.createForm, formBase: this.formBase});
  }

/**
   * @ngdoc method
   * @name DynamicForm#handleSelected
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: this method will converting checkboxes as a Radio buttons group based on checkbox Id value
   *
   * @param {type} event
   * @return {type} null
 */
  
  public handleSelected(val){
  const unselectCheckBoxValue= this.checkboxAsRadioGroupService.getCheckBoxVal(this.dropDownValues,this.createForm,val);
  unselectCheckBoxValue? this.createForm.controls.deviceProperties.setValue(unselectCheckBoxValue):null;
}
public onInput(event): void {
  this.emitOnInputBlur.emit({event,formBase: this.formBase})
}

/**
   * @ngdoc method
   * @name DynamicForm#titleCaseCovert
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: Converts a string from camelCase or PascalCase to title case by adding spaces between words and capitalizing the first letter of each word.
   *
   * @param {type} string
   * @return {type} The converted string in title case.
 */
titleCaseCovert(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}
/**
   * @ngdoc method
   * @name DynamicForm#transFormColumn
   *
   * @methodOf
   * SharedModule.controller:DynamicForm
   *
   * @description
   * Description: If a field within a form array matches the given column name and has the "fieldLabel" property set to "true" in the JSON configuration, 
   * it applies a translation to the column label using the translation service. If no match is found or "fieldLabel" is not "true" for the field, 
   * it retains the original column name and then converts it to title case. The resulting transformed column label is returned.
   *
   * @param {type} string
   * @return {type} The transformed column label.
 */
transFormColumn(column: string): string {
  let result = column;
  this.arrayFieldsComplete.forEach((formField) => {
    if (formField.type === "formArray") {
      formField.fields.forEach((field) => {
        if (field.name === column && field.fieldLabel === "true") {
          result = this.translationService.translateErrorMessages(field.label);
        }
      });
    }
  });
  return this.titleCaseCovert(result);
}

  onResetForm() {
   this.resetForm.emit('reset')
}

}
