/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import {
  ACCOUNT_CARD_ICON, BACK_LEFT, C2D_DASHBOARD_ICON, CALENDER_IMAGE,
  CLOSE_ICON,
  CONFIG_DASHBOARD_ICON, DEVICE_DASHBOARD_ICON, EMAIL_DASHBOARD_ICON,
  MAP_DASHBOARD_ICON,
  PROFILE_CARD_ICON, SMS_DASHBOARD_ICON, TENANT_CARD_ICON, USER_DASHBOARD_ICON, WHATSAPP_DASHBOARD_ICON
} from 'src/app/shared/constants/strings';
import {
  AccountControllerService, TenantControllerService, BusinessProfileControllerService
} from 'src/app/services/Platform/services';
import { DashBoardControllerService } from 'src/app/services/Report/services';
import { DatePipe } from '@angular/common';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { AreaChartService } from 'src/app/providers/area-spline-chart-service';
import { TranslateService } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { BarChartService } from 'src/app/providers/column-bar-chart-service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MapControllerServices } from 'src/app/services/Billing/services/map-controller.service';
import { MapControllerService } from 'src/app/services/Platform/services/map-controller.service';

/**
  * @ngdoc component
  * @name home.component:home
  *
  *
  * @description
  * description: Displays list of different add buttons.
  *
  *
*/
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  calenderImage;
  cardsList;
  cols;
  dateRangeList = [];
  defaultDateRange;
  assetChart;
  transactionChart;
  assetData = [];
  subscriptionData = [];
  todayDate = new Date();
  accountIcon;
  tenantIcon;
  profileIcon;
  backLeftImage;
  selectedAssetsOptions = [];
  selectedTransactionsOptions = [];
  subscriptionArrayList;
  assetArrayList;
  public gMapShowHide : boolean = false;
  public data: any[];
  public gMapDataJson: any;
  public barChartArea:boolean = false;
  public dataSource;
  public columns: any[];
  public gMaplList:any[];
  public closeIcon: string;
  public dropDownValues: any = {};
  public ignoreList = ['serviceId',];
  public totalCostOf:number = 0;
  private defaultSummaryObj:any;
  public TimeZone = '14:00(GMT-7)';
  public colorCodes = {
    "Autocomplete - Per Request": "#fa7600",
    "Autocomplete without Places Details - Per Session": "#9a9716",
    "Autocomplete (included with Places Details) - Per Session": "#31d8d0",
    "Directions": "#ff8f00",
    "Mobile Native Dynamic Maps": "#e91e63",
    "Basic Data": "#9fabe4",
    "Directions Advanced": "#01a4bb",
    "Geocoding": "#7cb342",
    "Distance Matrix": "#ff5722",
    "Query Autocomplete - Per Request": "#00786a",
    "Atmosphere Data": "#f7598e",
    "Distance Matrix Advanced": "#4f65bc",
    "Places - Text Search": "#fa937f",
    "Contact Data": "#c1175a",
    "Dynamic Maps": "#4184f3",
    "Places Details": "#a247bb",
    "Places Nearby Search": "#0fbfff",
    "Static Maps":"#fa7700"
};


  @ViewChild('assetsCharts', { static: true }) public assetChartElement: ElementRef;
  @ViewChild('subscriptionCharts', { static: true }) public subscriptionChartElement: ElementRef;
  @ViewChild('allAssetsSelected', { static: true }) private allAssetsSelected: MatOption;
  @ViewChild('allTransactionsSelected', { static: true }) private allTransactionsSelected: MatOption;

  public permissionList: Array<String> = [];
  constructor(private route: ActivatedRoute,    private router: Router,
    private httpService: HttpClient,
    private accountControllerService: AccountControllerService, private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService, private areaChartService: AreaChartService,
    private responseHandlerService: ResponseHandlerService,
    private dashBoardControllerService: DashBoardControllerService, private datePipe: DatePipe, private translate: TranslateService,
    private _matDialog: MatDialog,private mapControllerServices:MapControllerServices , private mapControllerService: MapControllerService,
    private barChartService:BarChartService) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.getTimeIntervals();
    this.todayDate.setDate(this.todayDate.getDate() - 1);
    this.httpService
      .get('./assets/dashboard/dashboard-cards.json')
      .subscribe(data => {
        this.cardsList = data['superAdmin'];
        this.cols = 3;
      });
      this.httpService
      .get('./assets/form-fields/gmap-dashboard.json')
      .subscribe((data) => {
        this.data = data as string[];
        this.gMapDataJson = this.data['fields'];
      });
    this.calenderImage = CALENDER_IMAGE;
    this.accountIcon = ACCOUNT_CARD_ICON;
    this.tenantIcon = TENANT_CARD_ICON;
    this.profileIcon = PROFILE_CARD_ICON;
    this.backLeftImage = BACK_LEFT;
    this.closeIcon = CLOSE_ICON;
    this.subscriptionArrayList = ['SMS', 'WHATSAPP', 'EMAIL', 'CONFIG', 'C2D', 'MAPS'];
    this.assetArrayList = ['USERS', 'DEVICES'];
    this.assetArrayList.forEach(assetLegend => {
      this.selectedAssetsOptions.push(assetLegend);
    });
    this.subscriptionArrayList.forEach(transactionLegend => {
      this.selectedTransactionsOptions.push(transactionLegend);
    });
    this.selectedAssetsOptions.push('ALL');
    this.selectedTransactionsOptions.push('ALL');
    this.allAssetsSelected.select();
    this.allTransactionsSelected.select();
    this.getAllCounts();
    this.getTransactionSummary(this.defaultDateRange);
    this.getAssetSummary(this.defaultDateRange, true);
    this.getGmapServices();
    this.getSkuIdsWithService();
    this.getMapProjectsList();
    const fragment = this.route.snapshot.url.join('/');
      if (fragment === 'home/transactions') {
        setTimeout(() => {
          window.scrollTo({
            top:this.checkPermission('ACC_C')? 630:500,
            left: 0, 
            behavior: 'smooth',
          });
        },500);
      }
  }

  public onDateRangeSelection(dateSelected) {
    this.getAssetSummary(dateSelected.value, true);
    if (this.selectedTransactionsOptions.length === 1 && this.selectedTransactionsOptions[0] === "MAPS") {
    } else {
    this.getTransactionSummary(dateSelected.value);
    }
  }

  public getAllCounts() {
    if (this.checkPermission('ACC_C')) {
      this.accountControllerService
        .findAllAccountsCount()
        .subscribe(
          resp => {
            if (this.cardsList && this.cardsList.length > 0) {
              this.cardsList[0]['data'] = { 'active': resp['active'], 'inactive': resp['inactive'], 'total': resp['total'] };
            }
          },
          err => {
          });
    }

    if (this.checkPermission('TEN_C')) {
      this.tenantControllerService
        .findAllTenantsCount()
        .subscribe(
          resp => {
            if (this.cardsList && this.cardsList.length > 0) {
              this.cardsList[1]['data'] = { 'active': resp['active'], 'inactive': resp['inactive'], 'total': resp['total'] };
            }
          },
          err => {
          });
    }

    if (this.checkPermission('BP_C')) {
      this.businessProfileControllerService
        .getCountOfBusinessProfiles({ type: 'count' })
        .subscribe(
          resp => {
            if (this.cardsList && this.cardsList.length > 0) {
              this.cardsList[2]['data'] = { 'active': resp['active'], 'inactive': resp['inactive'], 'total': resp['total'] };
            }
          },
          err => {
          });
    }
  }

  /**
    * @ngdoc method
    * @name home#checkPermission
    *
    * @methodOf
    * home.controller:home
    *
    * @description
    * Description:  Checks whether the user has permission to access create component of different modules.
    *
    * @param {type} key
    * @return {type} boolean
  */
  checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public getTimeIntervals() {
   const params =  {
      dashboardType: 'home'
     }
    this.dashBoardControllerService.getAllTimeIntervals(params).subscribe(resp => {
      this.dateRangeList = resp;
      this.dateRangeList.forEach(option => {
        if (option['label'] === 'LAST_WEEK') {
          this.defaultDateRange = option['id'];
        }
      });
    }, err => {
      this.responseHandlerService.returnToastMessage('error', err.message);
    });
  }

  public getTransactionSummary(intervalId) {
    this.areaChartService.destroyChart(this.transactionChart);
    this.barChartService.destroyChart(this.barChartService.getChart());
    this.subscriptionData = [];
    this.defaultDateRange = intervalId;
    let transactionChart;
    const summaryData = [];
    const summaryObj = {
      'endDate': this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      'intervalId': intervalId
    };
    this.dashBoardControllerService.dashBoardSmsReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#4191FF';
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['icon'] = SMS_DASHBOARD_ICON;
      resp['count'] = resp['cumulative'];
      transactionChart = this.drawChart(this.subscriptionChartElement, transactionChart, resp, this.subscriptionData, 'transaction');
      summaryData.push(resp);
    }, err => {
    });

    this.dashBoardControllerService.dashBoardWhatsappReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#1bc943';
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['icon'] = WHATSAPP_DASHBOARD_ICON;
      resp['count'] = resp['cumulative'];
      transactionChart = this.drawChart(this.subscriptionChartElement, transactionChart, resp, this.subscriptionData, 'transaction');
      summaryData.push(resp);
    }, err => {
    });

    this.dashBoardControllerService.dashBoardEmailReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#271850';
      resp['icon'] = EMAIL_DASHBOARD_ICON;
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['count'] = resp['cumulative'];
      transactionChart = this.drawChart(this.subscriptionChartElement, transactionChart, resp, this.subscriptionData, 'transaction');
      summaryData.push(resp);
    }, err => {
    });

    this.dashBoardControllerService.dashBoardConfigSummaryReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#feb019';
      resp['icon'] = CONFIG_DASHBOARD_ICON;
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['count'] = resp['cumulative'];
      transactionChart = this.drawChart(this.subscriptionChartElement, transactionChart, resp, this.subscriptionData, 'transaction');
      summaryData.push(resp);
    }, err => {
    });

    this.dashBoardControllerService.dashBoardC2DSummaryReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#f83245';
      resp['icon'] = C2D_DASHBOARD_ICON;
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['count'] = resp['cumulative'];
      transactionChart = this.drawChart(this.subscriptionChartElement, transactionChart, resp, this.subscriptionData, 'transaction');
      summaryData.push(resp);
    }, err => {
    });

    this.dashBoardControllerService.dashBoardMapsReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#00629e';
      resp['icon'] = MAP_DASHBOARD_ICON;
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['count'] = resp['cumulative'];
      transactionChart = this.drawChart(this.subscriptionChartElement, transactionChart, resp, this.subscriptionData, 'transaction');
      summaryData.push(resp);
    }, err => {
    });
  }

  public getAssetSummary(intervalId, active) {
    this.areaChartService.destroyChart(this.assetChart);
    this.assetData = [];
    this.defaultDateRange = intervalId;
    let assetChart;
    const summaryData = [];
    const summaryObj = {
      'endDate': this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      'intervalId': intervalId,
      'active': active
    };
    this.dashBoardControllerService.dashBoardUsersSummaryReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#4191ff';
      resp['icon'] = USER_DASHBOARD_ICON;
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['count'] = resp.values[resp.values.length - 1];
      assetChart = this.drawChart(this.assetChartElement, assetChart, resp, this.assetData, 'asset');
      if (this.selectedAssetsOptions.indexOf('USERS') === -1) {
        this.areaChartService.updateChart(assetChart, this.getTranslatedValues('USERS'), 'hide');
      }
      summaryData.push(resp);
    }, err => {
    });
    this.dashBoardControllerService.dashBoardDeviceSummaryReport({ body: summaryObj }).subscribe(resp => {
      resp['color'] = '#1bc943';
      resp['icon'] = DEVICE_DASHBOARD_ICON;
      resp['name'] = this.getTranslatedValues(resp['name']);
      resp['count'] = resp.values[resp.values.length - 1];
      summaryData.push(resp);
      assetChart = this.drawChart(this.assetChartElement, assetChart, resp, this.assetData, 'asset');
      if (this.selectedAssetsOptions.indexOf('DEVICES') === -1) {
        this.areaChartService.updateChart(assetChart, this.getTranslatedValues('DEVICES'), 'hide');
      }
    }, err => {
    });
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }

  public drawChart(chartElement, transactionChart, summaryData, data, chartType) {
    if (transactionChart) {
      this.areaChartService.redrawChart(transactionChart, summaryData);
    } else {
      transactionChart = this.areaChartService.createChart(chartElement.nativeElement, [summaryData]);
      if (chartType === 'transaction') {
        this.transactionChart = transactionChart;
      } else if (chartType === 'asset') {
        this.assetChart = transactionChart;
      }
    }
    data.push(summaryData);
    return transactionChart;
  }

  public filterGraph(chartType) {
    if (this.selectedTransactionsOptions.length === 1 && this.selectedTransactionsOptions[0] === 'WHATSAPP') {
      this.router.navigate(['whatsapp/data-analytics']);
      }

    if (chartType === 'asset') {
      if (this.allAssetsSelected.selected) {
        this.allAssetsSelected.deselect();
      }
      if (this.assetArrayList.length === this.selectedAssetsOptions.length) {
        this.allAssetsSelected.select();
      }
      this.assetArrayList.forEach(assetLegend => {
        if (this.selectedAssetsOptions.indexOf(assetLegend) === -1) {
          this.areaChartService.updateChart(this.assetChart, this.getTranslatedValues(assetLegend), 'hide');
        } else {
          this.areaChartService.updateChart(this.assetChart, this.getTranslatedValues(assetLegend), 'show');
        }
      });
    } else if (chartType === 'transaction') {
      if (this.selectedTransactionsOptions.length === 1 && this.selectedTransactionsOptions[0] === "MAPS") {
        this.gMapShowHide = true;
        this.barChartArea = true;
        this.areaChartService.destroyChart(this.transactionChart);
        this.defaultSummaryObj = {
          "startDate":'',
          "endDate":this.datePipe.transform(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
          "projectNames":[],
          "serviceIds":[],
          "skuIds":[]
        };
        this.getGMapSummary(this.defaultSummaryObj);
      }
      else {
      this.gMapShowHide = false;
      this.barChartArea = false;
      if(this.barChartService.getChart()){
        this.areaChartService.destroyChart(this.barChartService.getChart())
      }
     if( this.selectedTransactionsOptions.length === 0){
      this.barChartService.destroyChart(this.barChartService.getChart());
      this.areaChartService.destroyChart(this.areaChartService.getChart());
     }
     if (!(this.selectedTransactionsOptions.length === 0 || this.areaChartService.getChart())){
      this.getTransactionSummary(this.defaultDateRange);
    }
      if (this.allTransactionsSelected.selected) {
        this.allTransactionsSelected.deselect();
      }
      if (this.subscriptionArrayList.length === this.selectedTransactionsOptions.length) {
        this.allTransactionsSelected.select();
      }
      this.subscriptionArrayList.forEach(subscriptionLegend => {
        if (this.selectedTransactionsOptions.indexOf(subscriptionLegend) === -1) {
          this.areaChartService.updateChart(this.transactionChart, this.getTranslatedValues(subscriptionLegend), 'hide');
        } else {
          this.areaChartService.updateChart(this.transactionChart, this.getTranslatedValues(subscriptionLegend), 'show');
        }
      });
    }
  }
}

  public toggleAllSelection(chartType) {
    if (chartType === 'asset') {
      if (this.allAssetsSelected.selected) {
        this.selectedAssetsOptions = [];
        this.assetArrayList.forEach(asset => {
          this.selectedAssetsOptions.push(asset);
          this.areaChartService.updateChart(this.assetChart, this.getTranslatedValues(asset), 'show');
        });
        this.selectedAssetsOptions.push('ALL');
      } else {
        this.selectedAssetsOptions = [];
        this.assetArrayList.forEach(asset => {
          this.areaChartService.updateChart(this.assetChart, this.getTranslatedValues(asset), 'hide');
        });
      }
    } else if (chartType === 'transaction') {
     this.gMapShowHide = false;
     this.barChartArea = false;
     if (this.barChartService.getChart()) {
      this.areaChartService.destroyChart(this.barChartService.getChart());
    }
     if (!(this.selectedTransactionsOptions.length === 0 || this.areaChartService.getChart())){
      this.getTransactionSummary(this.defaultDateRange);
    }
      if (this.allTransactionsSelected.selected) {
        this.selectedTransactionsOptions = [];
        this.subscriptionArrayList.forEach(transaction => {
          this.selectedTransactionsOptions.push(transaction);
          this.areaChartService.updateChart(this.transactionChart, this.getTranslatedValues(transaction), 'show');
        });
        this.selectedTransactionsOptions.push('ALL');
      } else {
        this.selectedTransactionsOptions = [];
        this.subscriptionArrayList.forEach(transaction => {
          this.areaChartService.updateChart(this.transactionChart, this.getTranslatedValues(transaction), 'hide');
        });
      }
    }
  }

  public getGMapSummary(summaryObj) {
    this.barChartService.destroyChart(this.transactionChart);
    let transactionChart;
    this.totalCostOf = 0;
    this.dashBoardControllerService.dashBoardGmapsReport({ body: summaryObj }).subscribe(resp => {
      const colorArray = [];
      for (const obj of resp.values) {
        const colorObj = {};
        for (const key in obj) {
          if (key in this.colorCodes) {
            colorObj[key] = this.colorCodes[key];
          }
        }
        colorArray.push(colorObj);
      }
      const gMapRequest = {
        dates:resp.dates,
        values:resp.values,
        colors: colorArray
      };
    transactionChart = this.drawGMapChart(this.subscriptionChartElement, transactionChart, gMapRequest, this.subscriptionData);
    this.totalCostOf = resp.totalCost;
    if (resp.consolidatedUsage['length']) {
      const gmapTable: any[] = [];
      resp.consolidatedUsage.forEach(obj => {
        const skuDescription = obj.skuDescription;
        const color = this.colorCodes[skuDescription];
        gmapTable.push({
            'Color':color,
            'SKU': obj.skuDescription,
            'Service': obj.serviceName,
            "SKU ID": obj.skuId,
            'Usages': obj.usageAmount,
            'Cost($)': obj.mcpCost
        });
      });
      this.gMaplList = gmapTable;
      this.dataSource = new MatTableDataSource<any>(this.gMaplList);
      this.columns = this.filterColumns(
        Object.keys(this.gMaplList[0]));
    } else{
      this.gMaplList = null;
      this.dataSource = null;
      this.columns = null;
      this.totalCostOf = 0;
    }
     }, err => {
    });
   }

 
  public drawGMapChart(chartElement, gMapChart, summaryData, data) {
    if (gMapChart) {
      this.barChartService.redrawChart(gMapChart, summaryData);
    } else {
      gMapChart = this.barChartService.createChart(chartElement.nativeElement, [summaryData],'gMapChart');
      this.transactionChart = gMapChart;
    }
    data.push(summaryData);
    return gMapChart;
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child.child === 'SKUID'){
        this.getSkuIdsWithService($event.value);
      }
    }
  }

  
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  openModal(content) {
    this._matDialog.open(content, { maxHeight: '100vh', minWidth: '30vw', disableClose: true, autoFocus: false });
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  onSubmit(data){
    const requestJson ={
      projectNames: data['Project']?data['Project']:[],
      serviceIds: data['serviceType']?data['serviceType']:[],
      skuIds: data['SKUID']?data['SKUID']:[],
      startDate: data['fromDate']?this.datePipe.transform(data['fromDate'],'yyyy-MM-dd') : '',
      endDate: data['toDate'] ? this.datePipe.transform(data['toDate'], 'yyyy-MM-dd') : this.datePipe.transform(new Date().setDate(new Date().getDate() - 1), 'yyyy-MM-dd'),
  }
   this.getGMapSummary(requestJson)
  }

 
  public getMapProjectsList() {
    this.mapControllerService.getMapProjectsforSolutionAdmin().subscribe(
      (resp) => {
        if (resp && resp['length']) {
          const transformedData = resp.map(projectName => ({
            id: projectName,
            name: projectName
        }));
          this.dropDownValues['Project'] = transformedData;
        } else {
          this.dropDownValues['Project'] = [];
        }
      },
      (err) => {
        this.dropDownValues['Project'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getGmapServices() {
    this.mapControllerServices.findAllCurrencyTypes1({}).subscribe(
      (resp) => {
        if (resp && resp['length']) {
       const transformedData =  resp.map(service => ({
            id: service.serviceId,
            skuDescription: service.skuDescription,
            skuId: service.skuId,
            name: service.serviceName
        }));
          this.dropDownValues['serviceType'] = transformedData;
        } else {
          this.dropDownValues['serviceType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['serviceType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getSkuIdsWithService(serviceIds?) {
    this.mapControllerServices.findSkuIdsWithServiceIds({serviceIds:serviceIds}).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          const transformedData =  resp.map(obj => ({
            id: obj.skuId,
            skuDescription: obj.serviceName,
            skuId: obj.skuId,
            name: obj.skuDescription
        }));
          this.dropDownValues['SKUID'] = transformedData;
        } else {
          this.dropDownValues['SKUID'] = [];
        }
      },
      (err) => {
        this.dropDownValues['SKUID'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

}
