import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  WHATSAPP_ICON, CHEVRON_LEFT
} from 'src/app/shared/constants/strings';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BciLoaderService } from '@bci-web-core/core';
import { PieChartService } from 'src/app/providers/pie-chart-service';
import { AreaChartService } from 'src/app/providers/area-spline-chart-service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DateValidatorService } from 'src/app/providers/date-validator.service';
import { DashBoardControllerService } from 'src/app/services/Report/services';
import { WhatsappDashboardControllerService } from 'src/app/services/Report/services';
import { DatePipe } from '@angular/common';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';

import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';

@Component({
  selector: 'app-data-analytics',
  templateUrl: './data-analytics.component.html',
  styleUrls: ['./data-analytics.component.scss']
})
export class DataAnalyticsComponent implements OnInit, OnDestroy {

  public permissionList: Array<String> = [];
  public whatsappIcon = WHATSAPP_ICON;
  public backImage = CHEVRON_LEFT;
  public showSpinner:any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public chartTitle: string ;
  public cards = [];
  public dateRangeList = [];
  public pieChart;
  public color;
  public chartDataObject = {
    title: '',
    subtitle: '',
    tooltip: '',
    details: []
  };
  public totalMessagesPieChartCount;
  public pieChartSubscription: Subscription;
  @ViewChild('pieChart', { static: true }) public chartElement: ElementRef;

public showLineChart = false;
public whatsappLineChart;
public subscriptionData=[];
public currentDate = new Date();
public maxDate;
public minDate;

@ViewChild('lineChart', { static: true }) public subscriptionChartElement: ElementRef;
@ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
@ViewChild('cardsContainer') cardsContainer: ElementRef;

//DATEFORM
public dateSelected;
public dateRangeForm;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pieChartService: PieChartService,
    private areaChartService: AreaChartService,
    private translate: TranslateService,
    private responseHandlerService: ResponseHandlerService,
    private loaderService: BciLoaderService,
    private datePipe: DatePipe,
    private dateValidatorService:DateValidatorService,
    private dashBoardControllerService: DashBoardControllerService,
    private whatsappDashboardControllerService: WhatsappDashboardControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private accountControllerService: AccountControllerService,
  ) {}

  ngOnInit(): void {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.maxDate = new Date();
    this.maxDate.setDate(this.currentDate.getDate());
    this.minDate = new Date();
    this.minDate.setDate(this.currentDate.getDate() - 365);
    this.color = {
      'SENT': '#808080', 'FAILED': '#ff0000', 'DELIVERED': '#57b3e2', 'READ': '#78BE20' 
    };
     this.getAccount();
     this.calculateDate();
     this.createDateRangeForm();
     this.getTimeIntervals();
     this.loadStatusData();
  }
  public loadStatusData() {
    this.showSpinner = this.loaderService.showProgressBar();
    Promise.all([this.displayLineChart(), this.displayCards(), this.displayPieChart()])
    .then(([lineChartValue, cardsValue, pieChartValue]) => {
      if(cardsValue) {
        this.cardsContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
      this.showSpinner = this.loaderService.hideProgressBar(this.showSpinner);
    })
    .catch((error) => {
      this.showSpinner = this.loaderService.hideProgressBar(this.showSpinner);
    });
}

  // DATE RANGE FORM
  public createDateRangeForm() {
    this.dateRangeForm = new FormGroup({
      'dateRange': this.getFormControl()
    });
  }

  public getFormControl() {
    return  new FormControl('');
  }

  public addFormControl(controlName,control) {
    this.dateRangeForm.addControl(controlName, control);
  }

  public removeFromDateToDate() {
    if(this.dateRangeForm.controls['fromDate']) {
      this.dateRangeForm.removeControl('fromDate');
    }
    if(this.dateRangeForm.controls['toDate']) {
      this.dateRangeForm.removeControl('toDate');
    }
  }
  
// DATE RANGE

  public calculateDate(dateSelected = "LAST_WEEK") {
      if ( dateSelected === "YESTERDAY") {
        this.dateSelected = this.dateValidatorService.getYesterday();
        this.chartTitle = "DAILY_OVERVIEW_OF_DELIVERY_STATUS";
      } else if ( dateSelected === "LAST_MONTH") {
        this.dateSelected = this.dateValidatorService.getLastThirtyDaysRange();
        this.chartTitle = "DAILY_OVERVIEW_OF_DELIVERY_STATUS";
      } else if (dateSelected === "CUSTOM_DATE_RANGE") {
        this.chartTitle = "DAILY_OR_MONTHLY_OVERVIEW_OF_DELIVERY_STATUS";
        this.dateSelected = this.dateValidatorService.getCustomDateRange(this.dateRangeForm.controls['fromDate'].value,this.dateRangeForm.controls['toDate'].value,false);
      } else if(dateSelected === "LAST_WEEK") {
      this.dateSelected = this.dateValidatorService.getLastSevenDaysRange();
      this.chartTitle = "DAILY_OVERVIEW_OF_DELIVERY_STATUS";
      } else if(dateSelected === "LAST_6_MONTHS") {
        this.dateSelected = this.dateValidatorService.getLastOneEightyDaysRange();
        this.chartTitle = "MONTHLY_OVERVIEW_OF_DELIVERY_STATUS";
      } else if(dateSelected === "LAST_YEAR") {
        this.dateSelected = this.dateValidatorService.getLastThreeSixtyFiveDaysRange();
        this.chartTitle = "MONTHLY_OVERVIEW_OF_DELIVERY_STATUS";
      }
  }

  public getTimeIntervals() {

    const params =  {
      dashboardType: 'whatsapp'
     }
    this.dashBoardControllerService.getAllTimeIntervals(params).subscribe(resp => {
      this.dateRangeList = resp;
      this.dateRangeList.forEach(option => {
        if (option['label'] === 'LAST_WEEK') {
          this.dateRangeForm.controls['dateRange'].setValue(option['id']);
        }
      });
    }, err => {
      this.responseHandlerService.returnToastMessage('error', err.message);
    });
}

public onDateRangeSelection(dateSelected) {
this.dateRangeList.forEach(option => {
 if (option['id'] === dateSelected['value']) {
   if(option['label'] === 'CUSTOM_DATE_RANGE') {
    this.addFormControl('fromDate',this.getFormControl());
    this.addFormControl('toDate',this.getFormControl());
  }
   else {
    this.calculateDate(option['label']);
    this.removeFromDateToDate();
    this.loadStatusData();
   }
 }
});
}

public checkDate() {
if(this.dateRangeForm.controls['fromDate'].value && this.dateRangeForm.controls['toDate'].value ) {
 const isValidDate = this.dateValidatorService.validateDateRange(this.dateRangeForm.controls['fromDate'].value,this.dateRangeForm.controls['toDate'].value );
 if((isValidDate.hasOwnProperty('invalidDateRange') && !isValidDate['invalidDateRange'])|| isValidDate.hasOwnProperty('invalidDate')) 
 {
   this.dateRangeForm.controls['fromDate'].setErrors(null);
   this.calculateDate("CUSTOM_DATE_RANGE");
   this.loadStatusData();
 } else {
   this.dateRangeForm.controls['fromDate'].setErrors({
     invalidDate: true,
   });    
 }
}
}

// PIE CHART
public displayPieChart(): Promise<any> {
  return new Promise((resolve, reject) => {
    if(this.pieChart) {
      this.pieChartService.destroyChart(this.pieChart);
    }
    const body = {
      intervalId:this.dateRangeForm.controls['dateRange'].value,
      profileId:this.profileId?this.profileId:null,
      fromDate: this.dateRangeForm.controls['fromDate'] && this.dateRangeForm.controls['fromDate'].value?this.datePipe.transform(new Date(this.dateRangeForm.controls['fromDate'].value), 'yyyy-MM-dd'):"",
      toDate:this.dateRangeForm.controls['toDate'] && this.dateRangeForm.controls['toDate'].value?this.datePipe.transform(new Date(this.dateRangeForm.controls['toDate'].value), 'yyyy-MM-dd'):this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    }
    this.whatsappDashboardControllerService.getWhatsappDeliveryStatusPieChart({body}).subscribe((resp)=> {
      if(resp) {
        this.totalMessagesPieChartCount = this.getTotalCount(resp);
        if(this.totalMessagesPieChartCount) {
          this.chartDataObject.details = this.getChartData(resp);
          this.chartDataObject.title = this.getTranslatedValues('DELIVERY_STATUS');
          this.pieChart= this.pieChartService.createChart(this.chartElement.nativeElement, this.chartDataObject);
          this.pieChartSubscription = this.pieChartService.functionNameEmitter.subscribe(status => {
          });
        }
          resolve(true);
      }
    },
    (err) => {
       this.responseHandlerService.returnToastMessage(
         'error',
         err.error.message
       );
       resolve(false);
     })
  });
}

public getTotalCount(response) {
  let totalCount = 0;
  response.forEach((resp) => {
    if(resp.hasOwnProperty('deliveryStatusCount')) {
      totalCount += resp.deliveryStatusCount;
    } else {
      totalCount += resp.count;
    }
  });
  return totalCount;
}

  public getChartData(whatsappStatusData) {
    const data = whatsappStatusData;
    data.forEach(element => {
      element['name'] = (this.getTranslatedValues(element['status'] === 'SENT' ? 'SENT_CAPS': 
      element['status'] ==='FAILED'?'FAILED_CAPS':element['status'] ==='DELIVERED'?'DELIVERED_CAPS':
      element['status'] ==='READ'?'READ_CAPS':''));
      element['y'] = (element['count'] * this.totalMessagesPieChartCount) / 100;
      element['color'] = this.color[element['status']];
    });
    return data;
  }

  // LINE CHART

public displayLineChart(): Promise<any> {
  return new Promise((resolve, reject) => {
    if(this.areaChartService.getChart()) {
      this.showLineChart = false;
      this.areaChartService.destroyChart(this.areaChartService.getChart());
    }
  
    this.subscriptionData = [];
    const summaryData = [];
    let whatsappLineChart;
  
    const body = {
      intervalId:this.dateRangeForm.controls['dateRange'].value,
      profileId:this.profileId?this.profileId:null,
      fromDate: this.dateRangeForm.controls['fromDate'] && this.dateRangeForm.controls['fromDate'].value?this.datePipe.transform(new Date(this.dateRangeForm.controls['fromDate'].value), 'yyyy-MM-dd'):"",
      toDate:this.dateRangeForm.controls['toDate'] && this.dateRangeForm.controls['toDate'].value?this.datePipe.transform(new Date(this.dateRangeForm.controls['toDate'].value), 'yyyy-MM-dd'):this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    }
    this.whatsappDashboardControllerService.getWhatsappDeliveryStatusLineChart({body}).subscribe((resp)=> {
      if(resp && resp.length) {
        if(!resp.every(resp => resp['cumulative'] === 0)) {
          this.showLineChart = true;
          resp.forEach(status => {
            if(status['name'] === 'SENT') {
              status['color'] = '#808080';
              status['name'] = this.getTranslatedValues('SENT_CAPS');
            } else if (status['name'] === 'DELIVERED') {
              status['color'] = '#4191FF';
              status['name'] = this.getTranslatedValues('DELIVERED_CAPS');
            } else if (status['name'] === 'READ') {
              status['color'] = '#78BE20';
              status['name'] = this.getTranslatedValues('READ_CAPS');
            } else if (status['name'] === 'FAILED') {
              status['color'] = '#ff0000';
              status['name'] = this.getTranslatedValues('FAILED_CAPS');
            }
            status['icon'] = WHATSAPP_ICON;
            status['count'] = status['cumulative'];
            whatsappLineChart = this.drawChart(this.subscriptionChartElement, whatsappLineChart, status, this.subscriptionData);
            summaryData.push(status);
          })
        }
        resolve(true);
      }
    },
    (err) => {
       this.responseHandlerService.returnToastMessage(
         'error',
         err.error.message
       );
       resolve(false);
     })
  });
}

public drawChart(chartElement, whatsappLineChart, summaryData, data) {
  if (whatsappLineChart) {
    this.areaChartService.redrawChart(whatsappLineChart, summaryData);
  } else {
    whatsappLineChart = this.areaChartService.createChart(chartElement.nativeElement, [summaryData],"whatsappChart");
      this.whatsappLineChart = whatsappLineChart;
  }
  data.push(summaryData);
  return whatsappLineChart;
}

  public displayCards(): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        intervalId:this.dateRangeForm.controls['dateRange'].value,
        profileId:this.profileId?this.profileId:null,
        fromDate: this.dateRangeForm.controls['fromDate'] && this.dateRangeForm.controls['fromDate'].value?this.datePipe.transform(new Date(this.dateRangeForm.controls['fromDate'].value), 'yyyy-MM-dd'):"",
        toDate:this.dateRangeForm.controls['toDate'] && this.dateRangeForm.controls['toDate'].value?this.datePipe.transform(new Date(this.dateRangeForm.controls['toDate'].value), 'yyyy-MM-dd'):this.datePipe.transform(new Date(), 'yyyy-MM-dd')
      }
      this.whatsappDashboardControllerService.getWhatsappDeliveryStatusCards({body}).subscribe((resp)=> {
        if(resp) {
          const totalCount =  this.getTotalCount(resp);
          resp.forEach(card => {
            card['title'] = this.dateSelected;
            card['deliveryStatusPercentage'] = totalCount === 0 ? 0 :  this.calculatePercentage(card['deliveryStatusCount'],totalCount)
            if(card['deliveryStatus'] === 'SENT') {
              card['class'] = 'sent-card';
            }
            if(card['deliveryStatus'] === 'DELIVERED') {
              card['class'] = 'delivered-card';
            }
            if(card['deliveryStatus'] === 'READ') {
              card['class'] = 'read-card';
            }
            if(card['deliveryStatus'] === 'FAILED') {
              card['class'] = 'failed-card';
            }
              })
          this.cards = resp;
          if(totalCount) {
            resolve(true);
          } else {
            resolve(false);
          }        }
      },
      (err) => {
         this.responseHandlerService.returnToastMessage(
           'error',
           err.error.message
         );
         resolve(false);
       })
    });
  }

  public calculatePercentage(statusCount, totalCount) {
    const percentage = (statusCount * 100) / totalCount;
    const roundedPercentage = Math.round(percentage);
    return roundedPercentage.toString() + "%";
  }
  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }

  
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'status') {
          this.profileId = $event.value;
          this.loadStatusData();
        }
      }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
        return false;
    }
    return true;
}

  public navigateBack() {
    this.router.navigate(['home/transactions']);
  }

  ngOnDestroy() {
    if (this.pieChartSubscription) {
       this.pieChartSubscription.unsubscribe();
       this.areaChartService.destroyChart(this.whatsappLineChart);
     }
    }

    public onReset() {
      this.dynamicForm.createForm.reset();
      if(this.accountId || this.tenantId || this.profileId) {
        this.accountId = null;
        this.tenantId = null;
        this.dropDownValues['businessProfile'] = [];
        this.dropDownValues['account'] = [];
        this.dropDownValues['tenant'] = [];
        this.getAccount();
        if(this.profileId) {
          this.profileId = null;
          this.loadStatusData();
        }
      }
    }
}

