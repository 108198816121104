import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { WhatsappRequestDto } from '../models/whatsapp-request-dto';
import { WhatsappResponseDto } from '../models/whatsapp-response-dto';

@Injectable({ providedIn: 'root' })
export class WhatsappMessageControllerV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly SendWhatsappMessagePath = '/api/v2/sendMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendWhatsappMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWhatsappMessage$Response(
    params?: {
      accessKey: string,
      body?: WhatsappRequestDto
    },
  ): Observable<StrictHttpResponse<WhatsappResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, WhatsappMessageControllerV2Service.SendWhatsappMessagePath, 'post');
    if (params) {
      rb.header('accessKey', params.accessKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'application/json'
      })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendWhatsappMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendWhatsappMessage(
    params?: {
      accessKey: string,
      body?: WhatsappRequestDto
    },
  ): Observable<WhatsappResponseDto> {
    return this.sendWhatsappMessage$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappResponseDto>): WhatsappResponseDto => r.body)
    );
  }

}
