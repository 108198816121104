import { Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
  SmsTemplateControllerService,
  SmsChannelControllerService,
  BulkSmsUploadControllerService,
} from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { BciLoaderService } from '@bci-web-core/core';
import { DateValidatorService } from 'src/app/providers/date-validator.service';
import {
  DOWNLOAD_ICON,
  SPINNER_GIF,
  SMS_ICON,
  REFRESH_WITH_TEXT,
} from 'src/app/shared/constants/strings';
import { SmsDeliveryReportControllerService } from 'src/app/services/SMS/services';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-sms-status',
  templateUrl: './sms-status.component.html',
  styleUrls: ['./sms-status.component.scss'],
})
export class SmsStatusComponent implements OnInit {
  public configurationsList: Array<String> = [];
  public dropDownValues: any = {};
  public showSpinner: any;
  public data: any[];
  public smsStatusData: any;
  public accountId: string;
  public tenantId: string;
  public solutionTypeId;
  public profileId: string;
  public senderId: string;
  public dropdownList: [];
  public submitted = false;
  public permissionList: Array<String> = [];
  public ignoreList = ['comments', 'downloadLink', 'profileId','request','requestId','user','updatedDt'];
  public submitRequestJson: any;
  public smsIcon;
  public reportData;
  public reportDateToggle:boolean = true;
  public reportColumn: any[];
  public downloadImage: string;
  public readonly toastDuration_10_second = 10000;
  public deleteImage: string;
  public spinnerGIF :string;
  public reportName :string;
  reportNameForm: FormGroup;
  public refreshIcon:string;

  constructor(
    private httpsService: HttpClient,
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private smsTempateControllerService: SmsTemplateControllerService,
    private smsChannelControllerService: SmsChannelControllerService,
    private smsDeliveryReportControllerService: SmsDeliveryReportControllerService,
    private bulkSmsControllerService: BulkSmsUploadControllerService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private loaderService: BciLoaderService, 
    private _matDialog: MatDialog, private dateValidatorService: DateValidatorService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.getAccount();
    this.getSmsChannels();
    if (this.permissionList.indexOf('SMS_RPT_R') > -1) {
      this.httpsService
        .get('./assets/form-fields/sms-status.json')
        .subscribe((data) => {
          this.data = data as string[];
          this.smsStatusData = this.data['fields'];
        });
    }
    this.smsIcon = SMS_ICON;
    this.downloadImage = DOWNLOAD_ICON;
    this.spinnerGIF = SPINNER_GIF;
    this.refreshIcon = REFRESH_WITH_TEXT
    this.viewRequest(true);
    this.initializeForm();
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
        if ($event.child.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child.child === 'solutionType') {
          this.getSolutionType($event.value);
          this.profileId = $event.value;
          this.getSmsTemplateNames($event.value);
          this.getSmsJobs();
          this.getSmsHeader($event.value);
        }
        if ($event.child.child === 'jobName') {
          this.solutionTypeId = $event.value;
        }
    }
  }


  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSmsHeader(data: any) {
    this.businessProfileControllerService
      .getSmsHeaderByBusinessProfile({ businessProfileId: data })
      .subscribe(
        (resp) => {
          const senderId = resp.map(item => ({ 'id': item.senderId,'name': item.senderId}));
          this.dropDownValues['senderId'] = senderId;
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSmsChannels() {
    this.smsChannelControllerService.getAllSmsChanels({}).subscribe(
      (resp) => {
        this.dropDownValues['channel'] = resp;
      },
      (err) => {
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getSmsTemplateNames(profileId) {
    this.smsTempateControllerService
      .getSmsTemplates({ businessProfileId: profileId })
      .subscribe(
        (resp) => {
          const templateNames = [];
          resp.forEach((row) => {
            templateNames.push({
              id: row['traiTemplateId'],
              name: row['smsTemplateName'],
            });
          });
          this.dropDownValues['templateName'] = templateNames;
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public smsStatusEmitter(data: any,content) {
    this.reportNameForm.reset();
    if (data['businessProfile'] === undefined) {
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_SELECT_MANDATORY_FIELDS'
      );
      return;
    }

    if (data['mobileNumber']) {
      const regex = new RegExp('^(\\+[1-9]{2})?\\*?\\d{0,10}\\*?$');
      const validator = regex.test(data['mobileNumber']);
      if (!validator) {
        this.responseHandlerService.returnToastMessage(
          'error',
          'INVALID_MOBILE_NUMBER'
        );
        return;
      }
    }
    if (data['fromDate'] && data['toDate']) {
      data['fromDate'] = this.datePipe.transform(
        data['fromDate'],
        'yyyy-MM-dd'
      );
      data['toDate'] = this.datePipe.transform(data['toDate'], 'yyyy-MM-dd');
      if (data['fromDate'] > data['toDate']) {
        this.responseHandlerService.returnToastMessage(
          'error',
          'FROMDATE_LESSTHAN_TODATE'
        );
        return;
      }
    }
   this._matDialog.open(content);
   this.getSmsStatus(data)
  }

  public getSmsStatus(data: any) {
    const requestJson = {
      profileId: data['businessProfile'],
      solutionTypeId: data['solutionType'],
      senderId: data['senderId'],
      templateId: data['templateName'],
      fromDate: this.datePipe.transform(data['fromDate'], 'yyyy-MM-dd'),
      toDate: this.datePipe.transform(data['toDate'], 'yyyy-MM-dd'),
      mobileNumber: (data['mobileNumber'] === '' ? null : data['mobileNumber']),
      channelId: data['channel'],
      jobId: data['jobName'],
    };
    this.submitRequestJson = requestJson;
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public getSmsJobs() {
    this.dropDownValues['jobName'] = [];
    if (this.profileId) {
      const params = {
        status: 'completed',
        body: {
          profileId: this.profileId,
          solutionTypeId: this.solutionTypeId ? this.solutionTypeId : null
        }
      };
      this.bulkSmsControllerService.getSmsJobsByprofileIdAndSolutionTypeId(params)
      .subscribe( resp => {
        if (resp && resp.length) {
          resp.forEach( row => {
            this.dropDownValues['jobName'].push({ id: row['jobId'], name: row['jobName']});
          });
        } else {
          this.dropDownValues['jobName'] = [];
        }
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
      });
    }
  }

  public onClose() {
    this._matDialog.closeAll();
  }
  
  allowedCharactersValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const allowedCharactersRegex = /^[0-9a-zA-Z_.-]*$/;
      const isValid = allowedCharactersRegex.test(control.value);
      return isValid ? null : { invalidCharacters: true };
    };
  }
  
  initializeForm() {
    this.reportNameForm = this.fb.group({
      reportName: ['', [Validators.required, Validators.maxLength(50),this.allowedCharactersValidator()]],
    });
  }
  get formControls() {
    return this.reportNameForm.controls;
  }

  submitRequest() {
    this.submitted = true;
    if (this.reportNameForm.invalid) {
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    this._matDialog.closeAll();
    let reportName = this.reportNameForm.value;
    this.submitRequestJson.reportName = reportName.reportName;
    this.smsDeliveryReportControllerService
      .submitSmsdeliveryRequest({ body: this.submitRequestJson })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.handleApiResponse(resp);
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }
  
  private handleApiResponse(resp: any): void {
    this.responseHandlerService.returnToastMessage('success','DELIVERY_REQUEST_MESSAGE',this.toastDuration_10_second);
    let responseData: any[] = resp;
    responseData = responseData || [];
    responseData.forEach((data) => {
      data['createdDt'] = this.dateValidatorService.convertUTCToLocalDate(data['createdDt']);
      data['updatedDate'] = this.dateValidatorService.convertUTCToLocalDate(data['updatedDt']);
      data['download'] = null;
    });
    this.reportData = new MatTableDataSource<any>(responseData);
    this.reportColumn = this.filterColumns(Object.keys(responseData[0]));
  }

  viewRequest(firstTimeLoad?) {
    if(!firstTimeLoad){
      this.showSpinner = this.loaderService.showProgressBar();
    }
    let responseData = [];
    this.reportData = null;
    this.reportDateToggle = true;
    this.smsDeliveryReportControllerService
      .getAllSmsdeliveryReports()
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          
          responseData = resp;
          if (resp && resp.length) {
            responseData = resp;
            for (const data of responseData) {
              data['createdDt'] = this.dateValidatorService.convertUTCToLocalDate(data['createdDt']);
              data['updatedDate'] = this.dateValidatorService.convertUTCToLocalDate(data['updatedDt']);
              data['download'] = null;
            }
            this.reportData = new MatTableDataSource<any>(responseData);
            this.reportColumn = this.filterColumns(
              Object.keys(responseData[0])
            );
          } else {
            if(!firstTimeLoad){
              this.responseHandlerService.returnToastMessage('warning','NO_DATA_AVAILABLE');
            }
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  downloadReport(){
  setTimeout(() => {
    this.responseHandlerService.returnToastMessage('success','DOWLOAD_OF_YOUR_REPORT_WILL_BEGIN_SHORTLY');
  }, 2000);
}

}
