<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <div class="heading-label">
          <img class="page-icon" [src]="whatsappIcon" />
          <span>{{'DELIVERY_STATUS' | translate}}</span>
        </div>
        <div class="button-container">
          <button mat-raised-button (click)="viewRequest()" [title]="'CLICK_HERE_TO_VIEW_OR_REFRESH_YOUR_SUBMITTED_REQUEST'|translate" >{{ 'VIEW_REQUEST' | translate }}</button>
        </div>
      </div>
      <app-search-component *ngIf="whatsappStatusData" [comboFieldSearchArray]="whatsappStatusData"
            [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
            (searchFieldEmitter)="whatsappStatusEmitter($event)">
    </app-search-component>
    <div class="download-button">
      <button mat-raised-button [disabled]="disableDownload"
                (click)="downloadExcel()">{{ 'DOWNLOAD_EXCEL' | translate }}</button>
      <button mat-raised-button [disabled]="disableSubmitRequest"
                (click)="submitRequest()">{{ 'SUBMIT_REQUEST' | translate }}</button>
    </div>
    <mat-table *ngIf="!reportDateToggle" [dataSource]="statusData" class="table-data">
      <div *ngFor="let column of columns">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
          </mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName; index as i">
            <span title="{{ dataName[column] }}" class="cell-value">
              {{ dataName[column] }}
            </span>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let data; columns: columns"></mat-row>
    </mat-table> 
    <!-- Report data Table  -->
    <mat-table *ngIf="reportDateToggle" [dataSource]="reportData" class="table-data">
      <div *ngFor="let column of reportColumn">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
          </mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName; index as i">
            <span title="{{ dataName[column] }}" class="cell-value">
              {{ dataName[column] }}
            </span>
            <span *ngIf="column==='action'">
              <img *ngIf="dataName['status'] === 'Completed'" 
              [src]="downloadImage" class="modify-button" (click)="downloadRequest(dataName['id'],dataName['profileName'])">
              <img  *ngIf="dataName['status'] !== 'InProgress'" 
              [src]="deleteImage" class="modify-button" (click)="gotoConfirmDialog(dataName['id'])">
            </span>
          </mat-cell>
        </ng-container>
     </div>
     <mat-header-row *matHeaderRowDef="reportColumn"></mat-header-row>
     <mat-row *matRowDef="let data; columns: reportColumn"></mat-row>
</mat-table> 
    
</section>
</section>
